import { StatusCategory, Color as ColorApi } from '../../../../graphql-codegen/generated';
import { ColorNuance } from '../../types/nuance.types';
import { SubColorTheme } from '../../types/theme.types';
import { colorUtils } from '../../utils/cssFunctions.util';
import { BOX_SHADOW_COLOR_Z4 } from '../../utils/styles.util';
import { Color, LegacyColor } from '../baseColors';

const text = {
  primary: Color.White,
  secondary: Color.Grey500,
  hover: Color.Grey300,
  opposite: Color.Black,
  disabled: Color.Grey500,
  highlight: Color.Black,

  white: Color.White,
  black: Color.Black,
  red: Color.OrangeRed600,
};

const background = {
  primary: Color.Black,
  secondary: Color.Grey900,
  secondaryElevated: colorUtils.lighten(Color.Grey900, 0.08),
  tertiary: Color.Grey800,
  opposite: Color.White,
  hover: Color.Grey700,
  hoverSoft: Color.Grey850,
  disabled: Color.Grey900,
  transparent: 'transparent',

  red: Color.OrangeRed600,
  grey: Color.Grey100,
  white: Color.White,
  black: Color.Black,
};

const border = {
  primary: Color.Grey800,
  secondary: Color.Grey900,
  hover: Color.Grey700,
  input: 'transparent',

  white: Color.White,
  red: Color.OrangeRed600,
  grey: Color.Grey400,

  surprisingGreyDark: colorUtils.rgba(Color.White, 0.05),
};

export const colors: SubColorTheme = {
  text,
  background,
  border,
  authLayout: {
    content: {
      bg: Color.Grey100,
    },
    sidebar: {
      bg: Color.Blue700,
    },
  },
  avatar: {
    bg: Color.Black,
    bgOpposite: Color.White,
    bgLight: colorUtils.rgba(Color.Grey850, 0.5),
    pending: Color.Grey700,
    pendingLight: Color.Grey800,
  },
  board: {
    textButton: {
      bgHover: Color.Grey900,
      colorHover: Color.Grey400,
    },
  },
  body: {
    bg: background.primary,
    color: Color.Grey300,
  },
  button: {
    light: {
      bg: Color.Grey850,
      bgHover: Color.Grey800,
      bgActive: Color.Grey700,
    },
    outlined: {
      bg: Color.Grey800,
      bgHover: Color.Grey700,
      bgActive: Color.Grey800,
      colorHover: text.primary,
      colorActive: text.primary,
    },
    ternary: {
      color: text.primary,
      bg: Color.Grey800,
      bgHover: Color.Grey700,
      bgActive: Color.Grey600,
      bgFocus: Color.Grey800,
    },
    secondary: {
      bgHover: Color.Grey800,
      bgFocus: Color.Grey800,
      bgActive: Color.Grey700,
      color: Color.White,
      enableUserColor: false,
    },
    warningSecondary: {
      color: text.red,
    },
    secondaryV2: {
      bg: background.transparent,
      bgHover: Color.Grey800,
      bgActive: colorUtils.lighten(Color.Grey800, 0.03),
      color: Color.Grey500,
      colorHover: Color.White,
    },
    outlinedAlt: {
      bg: 'rgba(255, 255, 255, 0.08)',
      bgHover: 'rgba(255, 255, 255, 0.1)',
      bgActive: 'rgba(255, 255, 255, 0.12)',
      border: 'rgba(255, 255, 255, 0.06)',
      color: Color.White,
    },
    alt: {
      bg: Color.Grey800,
      bgHover: Color.Grey850,
      bgActive: Color.Grey850,
      border: Color.Grey800,
      color: Color.White,
    },
    nospacePrimary: {
      color: Color.Blue400,
      colorHover: Color.Blue500,
    },
  },
  bulkActions: {
    bg: background.tertiary,
    border: 'transparent',
    outline: Color.Grey800,
  },
  card: {
    bg: Color.Grey900,
    bgHover: colorUtils.lighten(Color.Grey900, 0.02),
    checkbox: {
      bg: Color.Grey800,
      color: 'transparent',
      border: Color.Grey700,
    },
    parent: {
      border: Color.Grey850,
      color: text.disabled,
      colorAlt: text.secondary,
      colorHover: text.primary,
      bgHover: colorUtils.lighten(Color.Grey850, 0.05),
      bgHoverAlt: colorUtils.lighten(Color.Grey800, 0.02),
      bgActive: colorUtils.lighten(Color.Grey800, 0.08),
    },
  },
  commandbar: {
    category: {
      color: text.disabled,
      bg: colorUtils.rgba(Color.Grey850, 0.1),
    },
    input: {
      color: text.primary,
      placeholder: colorUtils.transparentize(text.primary, 0.7),
      icon: colorUtils.transparentize(text.primary, 0.7),
    },
    action: {
      color: text.primary,
      colorHover: text.primary,
      bgHover: colorUtils.transparentize(Color.Grey700, 0.75),
    },
    shortCut: {
      color: text.secondary,
      bg: Color.Grey850,
    },
    tag: {
      bg: background.tertiary,
      color: text.primary,
    },
    highlight: {
      color: text.opposite,
      bg: colorUtils.transparentize(Color.Yellow300, 0.3),
    },
    createDoc: {
      color: 'hsl(var(--cycle400))',
    },
  },
  comments: {
    color: Color.Grey300,
  },
  docItem: {
    actionButton: {
      color: Color.Grey700,
      colorAlt: Color.Grey500,
      colorHover: Color.Grey500,
      bgHover: Color.Grey800,
      bgActive: Color.Grey700,
    },
  },
  docMention: {
    bg: Color.Grey800,
  },
  docPanel: {
    bg: background.secondary,
    border: Color.Grey800,
    overlay: 'rgba(0, 0, 0, 0.8)',
    header: {
      breadCrumbSeparatorColor: Color.Grey800,
    },
    rightPanel: {
      border: Color.Grey850,
    },
    hierarchy: {
      bg: Color.Grey850,
      bgModal: Color.Grey850,
      bgHover: colorUtils.lighten(Color.Grey850, 0.02),
      border: background.tertiary,
    },
    insightButton: {
      enableUserColor: false,
    },
    insightCard: {
      bgHover: Color.Grey850,
    },
    releaseNote: {
      border: Color.Grey800,
    },
  },
  docProcess: {
    color: Color.Grey200,
    colorProcessed: colorUtils.lighten(Color.Cycle, 0.08),
    bg: Color.Grey900,
    bgHover: Color.Grey850,
    bgActive: Color.Grey800,
    border: Color.Grey800,
  },
  dropdown: {
    border: Color.Grey800,
    color: text.primary,
    bg: Color.Grey850,
    sep: Color.Grey800,
    outline: Color.Grey800,
  },
  dropZone: {
    bg: Color.Grey900,
  },
  editor: {
    code: {
      bg: Color.Grey800,
      border: Color.Grey700,
      text: Color.Blue400,
    },
    hr: colorUtils.rgba(Color.White, 0.12),
    text: Color.White,
    textHighlight: colorUtils.rgba(Color.White, 0.08),
    filePreview: {
      bg: Color.Grey850,
      bgHover: Color.Grey800,
    },
    discover: {
      bg: Color.Grey850,
    },
    link: 'hsl(var(--cycle200))',
  },
  emojiPicker: {
    theme: 'dark',
  },
  flow: {
    bg: Color.Grey900,
  },
  flowElement: {
    bg: Color.Grey850,
    color: Color.White,
    border: Color.Cycle,
    preview: {
      bg: Color.Grey700,
    },
    placeholder: {
      border: Color.Grey800,
      bgHover: Color.Grey850,
    },
  },
  flowPath: {
    stroke: Color.Grey800,
  },
  group: {
    bg: Color.Grey900,
    feedbackList: {
      bg: Color.Grey850,
      bgHover: Color.Grey800,
      bgActive: Color.Grey850,
    },
  },
  mark: {
    color: Color.Grey100,
    bg: colorUtils.rgba(Color.Yellow300, 0.5),
  },
  input: {
    bg: Color.Grey800,
    bgAlt: Color.Grey800,
    hover: Color.Grey800,
  },
  inputRadio: {
    bg: background.tertiary,
  },
  notificationCenter: {
    border: Color.Grey850,
    color: text.primary,
    bg: Color.Grey900,
    card: {
      bgHover: Color.Grey850,
      boxBgHover: Color.Grey800,
    },
  },
  placeholder: {
    bg: Color.Grey850,
  },
  releases: {
    border: Color.Grey850,
    bg: Color.Grey900,
    bgActive: 'rgba(0, 0, 0, 0.2)',
    icon: Color.Grey400,
    iconActive: Color.Grey500,
    bullet: Color.Grey800,
    bulletActive: Color.Grey600,
    gradient: `
      linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
      linear-gradient(90.5deg, #A990FF 0.47%, #57CFEF 52.43%, #7FEA96 99.64%)`,
    learnMore: {
      border: 'transparent',
      bg: Color.Grey850,
      bgHover: colorUtils.lighten(Color.Grey850, 0.04),
      bgActive: Color.Grey850,
      color: Color.White,
    },
    loopClosed: {
      bg: Color.Green800,
      border: 'transparent',
      color: Color.White,
    },
    card: {
      bg: Color.Grey850,
      placeholder: Color.Grey800,
    },
    textButton: {
      color: Color.Grey500,
    },
    note: {
      date: Color.Grey500,
    },
    tag: {
      bg: Color.Grey800,
      bgDraft: '#F2B44080',
      bgPublished: 'rgba(87,152,88,0.3)',
    },
    modal: {
      border: Color.Grey850,
    },
  },
  scrollbar: {
    color: colorUtils.rgba(LegacyColor.White, 0.2),
    colorHover: colorUtils.rgba(LegacyColor.White, 0.5),
  },
  selectItem: {
    color: text.primary,
    bgHover: colorUtils.lighten(Color.Grey850, 0.03),
    bgActive: colorUtils.lighten(Color.Grey850, 0.05),
  },
  selectItemDanger: {
    colorHover: Color.Red300,
    bgHover: Color.Red800,
  },
  customProperty: {
    input: {
      bgHover: Color.Grey800,
    },
  },
  settings: {
    bg: background.primary,
    navigationItem: {
      bgHover: Color.Grey850,
      draggingBg: colorUtils.rgba(Color.Grey850, 0.94),
      draggingColor: Color.White,
      draggingBorderColor: 'transparent',
      menu: {
        bgHover: background.hover,
      },
    },
    row: {
      bgHover: Color.Grey850,
    },
    section: {
      bg: background.secondary,
      bgAlt: Color.Grey850,
      border: Color.Grey850,
      info: text.disabled,
    },
    listItem: {
      bg: Color.Grey850,
      bgHover: Color.Grey800,
    },
  },
  workflows: {
    status: {
      bg: Color.Grey850,
      bgHover: colorUtils.lighten(Color.Grey850, 0.05),
    },
    button: {
      color: Color.Grey700,
      colorHover: Color.Grey400,
      bg: Color.Grey900,
      bgHover: Color.Grey800,
      bgActive: colorUtils.lighten(Color.Grey800, 0.05),
    },
    buttonAlt: {
      color: Color.Grey500,
      colorHover: Color.Grey400,
      bg: Color.Grey800,
      bgHover: colorUtils.lighten(Color.Grey800, 0.05),
      bgActive: colorUtils.lighten(Color.Grey800, 0.1),
    },
  },
  sidebar: {
    bg: Color.Grey900,
    border: Color.Grey850,
    resize: Color.Grey800,
    header: {
      item: {
        color: Color.Grey500,
        colorHover: text.hover,
        bgHover: Color.Grey800,
        caret: {
          colorHover: Color.White,
        },
      },
    },
    mobile: {
      bg: colorUtils.darken(Color.Grey900, 0.02),
    },
    createDoc: {
      text: 'hsl(var(--cycle400))',
      icon: Color.White,
      bg: 'hsla(var(--cycle400), 0.2)',
    },
    droppableArea: {
      bg: Color.Grey800,
    },
  },
  selectPanel: {
    options: {
      bg: Color.Grey800,
    },
  },
  tag: {
    color: Color.Grey300,
    border: Color.Grey800,
    bg: Color.Grey850,
    bgHover: Color.Grey800,
    bgActive: Color.Grey850,
    bgAlt: Color.Grey800,
    greyAlt: {
      bg: Color.Grey800,
      bgHover: colorUtils.lighten(Color.Grey800, 0.05),
      bgActive: colorUtils.lighten(Color.Grey800, 0.1),
    },
  },
  toaster: {
    bg: Color.Grey800,
    buttons: {
      bgActive: Color.Grey400,
      bgHover: Color.Grey500,
      bg: Color.Grey700,
    },
  },
  tooltip: {
    bg: Color.Grey700,
    color: Color.White,
    textLight: Color.Grey400,
    shortcut: {
      bg: Color.Grey800,
      text: Color.Grey400,
    },
  },
  modal: {
    border: Color.Grey800,
    content: {
      bg: Color.Grey900,
      bgNoMask: Color.Grey900,
    },
    icon: {
      warning: {
        bg: background.red,
        color: background.white,
      },
    },
  },
  boardConfig: {
    filters: {
      fakeInputBg: Color.Grey900,
      element: {
        bg: Color.Grey850,
        bgHover: Color.Grey800,
        bgFocus: Color.Grey800,
        color: Color.Grey100,
      },
    },
    toolbar: {
      viewTypesBg: background.primary,
      selectedViewTypeBg: background.secondary,
      button: {
        bg: Color.Grey850,
      },
    },
    button: {
      bg: Color.Grey850,
      bgHover: Color.Grey800,
    },
    preview: {
      bg: colorUtils.darken(Color.Grey900, 0.03),
      swimlane: {
        bg: background.tertiary,
      },
      avatar: {
        bg: Color.Grey700,
        enableUserColor: false,
      },
      group: {
        bg: Color.Grey850,
      },
      card: {
        bg: Color.Grey800,
        title: {
          bg: Color.Grey700,
        },
      },
    },
  },
  roadmaps: {
    modal: {
      border: Color.Grey800,
      badge: {
        bg: Color.Blue200,
        color: Color.Grey900,
      },
    },
    template: {
      border: Color.Grey800,
      bg: Color.Grey850,
      footer: Color.Grey900,
      arrow: Color.Grey800,
      badge: {
        bg: Color.Grey700,
        color: Color.White,
      },
      docType: {
        bg: Color.Grey800,
      },
    },
  },
  userModal: {
    themePreview: {
      bg: Color.Grey800,
      bgHover: Color.Grey700,
    },
  },
  settingsModal: {
    leftPanel: {
      bg: background.secondary,
    },
    rightPanel: {
      bg: Color.Grey850,
      notifications: {
        setupSlack: {
          bg: background.tertiary,
        },
      },
    },
  },
  helpCenter: {
    button: {
      bg: Color.Grey800,
    },
  },
  actionButton: {
    outline: Color.Grey800,
    colorDisabled: text.disabled,
    colorHover: Color.Grey500,
    bgHover: Color.Grey800,
    bgActive: Color.Grey700,
    bgHoverDark: Color.Grey800,
    bgActiveDark: Color.Grey700,
    secondary: {
      bg: Color.Grey800,
      bgHover: colorUtils.lighten(Color.Grey800, 0.05),
      bgActive: colorUtils.lighten(Color.Grey800, 0.1),
      color: Color.Grey500,
      colorHover: Color.Grey500,
    },
    tertiary: {
      bg: null,
      bgHover: Color.Grey800,
      bgActive: colorUtils.lighten(Color.Grey800, 0.05),
      color: text.disabled,
      colorHover: Color.Grey500,
    },
  },
  customers: {
    link: {
      text: Color.Grey500,
      textHover: Color.White,
      bg: Color.Grey850,
      bgHover: Color.Grey800,
      border: colorUtils.rgba(Color.Grey100, 0.06),
    },
  },
  ui: {
    info: {
      default: {
        title: Color.Grey300,
        text: Color.Grey500,
        bg: Color.Grey850,
        bgHover: Color.Grey800,
      },
      danger: {
        title: Color.Red300,
        text: Color.Red400,
        bg: Color.Red900,
        bgHover: Color.Red800,
      },
    },
  },
  logo: {
    main: Color.White,
    alt: '#2E48ED',
  },
  popup: {
    bg: Color.Grey850,
    separator: colorUtils.rgba(Color.White, 0.1),
  },
  billing: {
    section: {
      bg: Color.Grey900,
      border: '1px solid rgba(0, 0, 0, 0.06)',
      shadow: '0px 2px 2px 0px rgba(0, 0, 0, 0.03)',
      separator: Color.Grey800,
    },
    history: {
      bg: Color.Grey800,
    },
    plan: {
      free: {
        bg: 'rgba(132, 35, 255, 0.3)',
        color: text.primary,
      },
      standard: {
        bg: 'rgba(43, 68, 231, 0.3)',
        color: text.primary,
      },
      business: {
        bg: Color.Grey850,
        color: text.primary,
        badge: {
          bg: Color.Grey900,
        },
      },
    },
    discount: {
      annual: {
        bg: Color.Green800,
        color: Color.Green300,
        border: colorUtils.lighten(Color.Green800, 0.15),
      },
    },
  },
  onboarding: {
    background: {
      body: Color.Grey850,
      aside: Color.Grey850,
      backdrop: colorUtils.rgba(Color.Black, 0.78),
      docPanel: background.tertiary,
    },
    integration: {
      circle: Color.Blue300,
    },
  },
  onboardingSlack: {
    congratulations: {
      tooltip: {
        bg: 'hsl(var(--cycle))',
        color: text.primary,
      },
    },
  },
  lightOnboarding: {
    layout: {
      headline: {
        color: Color.Blue500,
      },
      card: {
        bg: '#353535',
      },
    },
    background: {
      body: Color.Grey800,
      main: colorUtils.darken(Color.Grey900, 0.01),
      aside: '#292929',
      quote: Color.Grey800,
    },
    asideApp: {
      gradient: 'linear-gradient(164.2deg, rgba(36, 36, 36, 0) 37.74%, rgba(36, 36, 36, 0.5) 77.11%)',
      container: {
        border: 'transparent',
        bg: Color.Black,
      },
      sidebar: {
        bg: Color.Grey900,
      },
      sidebarItem: {
        color: text.disabled,
        bg: Color.Grey850,
      },
    },
    doneLoader: {
      color: Color.Grey700,
    },
    integration: {
      circle: Color.Blue400,
      installed: {
        color: Color.Green700,
      },
    },
    sources: {
      selectedBorder: 'hsla(var(--cycle), 0.2)',
      selected: 'hsla(var(--cycle), 0.2)',
      itemShadow: BOX_SHADOW_COLOR_Z4,
    },
  },
  home: {
    bg: Color.Black,
    separator: Color.Grey850,
    card: {
      bg: Color.Grey900,
      bgHover: Color.Grey850,
    },
    search: {
      bg: Color.Grey850,
      text: Color.Grey500,
    },
    cmdk: {
      bg: Color.Grey800,
      text: Color.Grey500,
    },
    table: {
      header: Color.Grey900,
      hover: Color.Grey900,
      bg: Color.Black,
    },
    integrations: {
      logo: {
        bg: colorUtils.transparentize(Color.Grey300, 0.9),
      },
      slack: {
        bg: 'hsl(var(--cycle), 0.15)',
      },
      modal: {
        border: Color.Grey800,
      },
    },
  },
  inbox: {
    sources: {
      bg: colorUtils.transparentize(Color.Grey850, 1),
      bgHover: Color.Grey850,
    },
    zero: {
      item: {
        text: Color.Grey800,
        description: colorUtils.rgba(Color.Grey800, 0.6),
        bg: colorUtils.lighten(Color.Black, 0.03),
      },
    },
    collectMore: {
      bg: Color.Grey900,
    },
  },
  chrome: {
    skeleton: {
      bg: Color.Grey800,
      title: colorUtils.rgba(Color.Grey700, 0.6),
    },
  },
  extractInsights: {
    quote: {
      bg: Color.Grey800,
    },
  },
  components: {
    AudioPlayer: {
      bg: Color.Grey850,
      border: Color.Grey800,
      volumeBg: Color.Grey800,
      volumeProgressBg: Color.Grey700,
      volumeProgress: Color.Grey500,
    },
    EditorAiMenu: {
      bg: 'linear-gradient(0deg, rgba(0, 0, 0, 0.70) 0%, rgba(0, 0, 0, 0.70) 100%), linear-gradient(91deg, #A990FF 0%, #57CFEF 52.39%, #7FEA96 100%)',
      buttonColor: Color.White,
      buttonBg: 'rgba(255, 255, 255, 0.06)',
      buttonBorder: 'rgba(255, 255, 255, 0.06)',
    },
    InsightCard: {
      card: {
        bg: Color.Grey850,
        border: Color.Grey800,
      },
      quote: {
        bg: Color.Grey850,
        bgHover: background.tertiary,
      },
    },
    InsightCardHieraryInsight: {
      card: {
        bg: colorUtils.lighten(Color.Grey850, 0.03),
      },
      quote: {
        bg: Color.Grey850,
        bgHover: colorUtils.lighten(Color.Grey850, 0.01),
      },
    },
    EditorIntegrationCard: {
      border: Color.Grey800,
    },
    FeedbackStatus: {
      [StatusCategory.NotStarted]: {
        main: Color.Grey400,
        text: Color.Grey400,
        bg: Color.Grey850,
        bgAlt: Color.Grey850,
        bgGroup: Color.Grey850,
        bgHover: Color.Grey800,
        bgActive: Color.Grey850,
        bgOpaque: Color.Grey850,
      },
      [StatusCategory.Started]: {
        main: Color.Blue400,
        text: Color.Blue400,
        bg: 'rgba(64, 88, 242, 0.3)',
        bgAlt: 'rgba(64, 88, 242, 0.3)',
        bgGroup: 'rgba(64, 88, 242, 0.3)',
        bgHover: colorUtils.lighten('rgba(64, 88, 242, 0.3)', 0.1),
        bgActive: colorUtils.lighten('rgba(64, 88, 242, 0.3)', 0.05),
        bgOpaque: colorUtils.mix('rgb(64, 88, 242)', background.primary, 0.3),
      },
      [StatusCategory.Completed]: {
        main: '#90CA91',
        text: '#90CA91',
        bg: 'rgba(87, 152, 88, 0.3)',
        bgAlt: 'rgba(87, 152, 88, 0.3)',
        bgGroup: 'rgba(87, 152, 88, 0.3)',
        bgHover: colorUtils.lighten('rgba(87, 152, 88, 0.3)', 0.1),
        bgActive: colorUtils.lighten('rgba(87, 152, 88, 0.3)', 0.05),
        bgOpaque: colorUtils.mix('rgb(87, 152, 88)', background.primary, 0.3),
      },
      [StatusCategory.Canceled]: {
        main: Color.Red400,
        text: Color.Red400,
        bg: 'rgba(184, 68, 68, 0.3)',
        bgAlt: 'rgba(184, 68, 68, 0.3)',
        bgGroup: 'rgba(184, 68, 68, 0.3)',
        bgHover: colorUtils.lighten('rgba(184, 68, 68, 0.3)', 0.1),
        bgOpaque: colorUtils.mix('rgb(184, 68, 68)', background.primary, 0.3),
      },
    },
    FreePlan: {
      default: {
        bg: Color.Grey850,
        bgHover: colorUtils.lighten(Color.Grey850, 0.02),
        text: {
          primary: Color.White,
          secondary: Color.Grey500,
        },
        cta: {
          bg: Color.Cycle,
          bgHover: colorUtils.lighten(Color.Cycle, 0.05),
          bgActive: colorUtils.lighten(Color.Cycle, 0.1),
          text: Color.White,
        },
      },
      warning: {
        bg: Color.Orange800,
        text: {
          primary: Color.White,
          secondary: Color.Orange600,
        },
        cta: {
          bg: Color.Orange700,
          bgHover: colorUtils.lighten(Color.Orange700, 0.05),
          bgActive: colorUtils.lighten(Color.Orange700, 0.1),
          text: Color.White,
        },
      },
      danger: {
        bg: Color.Red900,
        text: {
          primary: Color.White,
          secondary: Color.Red400,
        },
        cta: {
          bg: colorUtils.rgba(Color.Red600, 0.1),
          bgHover: colorUtils.rgba(Color.Red600, 0.15),
          bgActive: colorUtils.rgba(Color.Red600, 0.2),
          text: Color.Red300,
        },
      },
    },
    Toaster: {
      bg: Color.Grey900,
      border: Color.Grey800,
      color: Color.Grey700,
      light: Color.Grey700,
    },
    LearningCenterModal: {
      bg: Color.Grey900,
      bgHover: Color.Grey850,
    },
    MobileHeader: {
      border: Color.Grey850,
      closeButton: {
        color: text.secondary,
      },
    },
    MobileFooter: {
      bg: Color.Black,
      bgActive: Color.Grey900,
      border: Color.Grey900,
    },
    StateToaster: {
      bg: Color.Grey300,
      text: Color.Black,
    },
    Badge: {
      bg: Color.Grey800,
      text: Color.Grey500,
    },
    Tab: {
      bg: Color.Transparent,
      bgHover: Color.Grey850,
      bgActive: colorUtils.lighten(Color.Grey850, 0.1),
      text: Color.Grey500,
      textHover: Color.Grey500,
      textActive: Color.White,
    },
    TableOfContentNodeView: {
      text: Color.White,
      bg: colorUtils.transparentize(Color.White, 0.9),
    },
  },
  login: {
    bg: Color.Grey900,
    shadow: '0px 0px 20px rgba(0, 0, 0, 0.05), 0px 35px 70px rgba(0, 0, 0, 0.19)',
    link: {
      userColor: false,
    },
    wrapper: Color.Grey800,
  },
  icons: {
    CommentChatIcon: {
      primary: Color.Grey700,
      secondary: Color.Grey800,
    },
  },
  ugradeLayout: {
    bg: background.primary,
    desc: text.primary,
    activeText: {
      color: text.white,
    },
    mainBox: {
      bg: background.secondary,
    },
    pricingToggle: {
      switch: {
        bg: background.black,
      },
      switchToggle: {
        bg: Color.Grey850,
        border: border.primary,
        color: text.white,
      },
      discount: {
        color: text.white,
        bg: Color.Grey800,
      },
    },
  },
};

export const nuance: ColorNuance = {
  a: {
    bg: '#2958FF1A',
    main: '#6A8BFF',
    textHighlight: '',
    markHighlight: '',
    markHighlightBorder: '',
    tag: colorUtils.mix(Color.Grey900, Color.Violet500, 0.5),
  },
  b: {
    bg: '#3583DF1A',
    main: '#69ACFC',
    textHighlight: '',
    markHighlight: '',
    markHighlightBorder: '',
    tag: colorUtils.mix(Color.Grey900, Color.Blue500, 0.5),
  },
  c: {
    bg: '#13CD661A',
    main: '#13CD66',
    textHighlight: '',
    markHighlight: '',
    markHighlightBorder: '',
    tag: colorUtils.mix(Color.Grey900, Color.Green500, 0.6),
  },
  d: {
    bg: '#0DCAD61A',
    main: '#0DC9D6',
    textHighlight: '',
    markHighlight: '',
    markHighlightBorder: '',
    tag: colorUtils.mix(Color.Grey900, 'rgb(63, 143, 247)', 0.5),
  },
  e: {
    bg: '#6523F01A',
    main: '#A790FF',
    textHighlight: '',
    markHighlight: '',
    markHighlightBorder: '',
    tag: colorUtils.mix(Color.Grey900, Color.Purple500, 0.5),
  },
  f: {
    bg: '#D237EB1A',
    main: '#E769FC',
    textHighlight: '',
    markHighlight: '',
    markHighlightBorder: '',
    tag: colorUtils.mix(Color.Grey900, Color.Pink500, 0.5),
  },
  g: {
    bg: '#DF36351A',
    main: '#FF8484',
    textHighlight: '',
    markHighlight: '',
    markHighlightBorder: '',
    tag: colorUtils.mix(Color.Grey900, Color.OrangeRed500, 0.5),
  },
  h: {
    bg: '#FF00001A',
    main: '#FF6464',
    textHighlight: '',
    markHighlight: '',
    markHighlightBorder: '',
    tag: colorUtils.mix(Color.Grey900, Color.OrangeRed500, 0.5),
  },
  i: {
    bg: '#F078341A',
    main: '#FFA775',
    textHighlight: '',
    markHighlight: '',
    markHighlightBorder: '',
    tag: colorUtils.mix(Color.Grey900, Color.Orange500, 0.5),
  },
  j: {
    bg: '#FF430A1A',
    main: '#FF8762',
    textHighlight: '',
    markHighlight: '',
    markHighlightBorder: '',
    tag: colorUtils.mix(Color.Grey900, Color.Red500, 0.5),
  },
  k: {
    bg: '#FF5C001A',
    main: '#FF5C00',
    textHighlight: '',
    markHighlight: '',
    markHighlightBorder: '',
    tag: colorUtils.mix(Color.Grey900, Color.Red500, 0.5),
  },
  l: {
    bg: '#FFE6001A',
    main: '#E2CC00',
    textHighlight: '',
    markHighlight: '',
    markHighlightBorder: '',
    tag: colorUtils.mix(Color.Grey900, Color.Yellow500, 0.6),
  },
};

for (const key of Object.keys(nuance)) {
  const color = key as ColorApi;
  nuance[color].textHighlight = colorUtils.opacify(nuance[color].bg, 0.2);
  nuance[color].markHighlight = colorUtils.opacify(nuance[color].bg, 0.14);
  nuance[color].markHighlightBorder = colorUtils.opacify(nuance[color].bg, 0.20);
}
