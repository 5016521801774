import { useLanguage } from 'src/reactives/language.reactive';

import { useMaybeMeV2 } from '../api/useMe';

export const useUserLanguage = () => {
  const { me } = useMaybeMeV2();
  const [language, setLanguage] = useLanguage(me?.id);

  return {
    language,
    setLanguage,
  };
};
