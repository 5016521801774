import { ComponentProps, useEffect, useRef, useState } from 'react';
import { twJoin } from 'tailwind-merge';
import { useThrottledCallback } from 'use-debounce';

import { useEditorInstance } from 'src/components/Editor';

export const ScrollableContent = ({
  className, ...props
}: ComponentProps<'div'>) => {
  const editorRef = useEditorInstance();
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [borderTop, setBorderTop] = useState(false);
  const [borderBottom, setBorderBottom] = useState(false);

  const checkBorders = useThrottledCallback(() => {
    const el = containerRef.current;
    if (!el) return;
    setBorderTop(el.scrollTop > 0);
    setBorderBottom(el.scrollTop < el.scrollHeight - el.clientHeight);
  }, 100, { leading: false });

  useEffect(() => {
    const editor = editorRef.current;
    editor?.on('update', checkBorders);
    return () => {
      editor?.off('update', checkBorders);
    };
  }, [checkBorders, editorRef]);

  return (
    <div
      ref={containerRef}
      onScroll={checkBorders}
      className={twJoin(
        'shy-scrollbar focus-visible:outline-none flex-1',
        borderTop && 'border-t border-grey-200 dark:border-grey-800',
        borderBottom && 'border-b border-grey-200 dark:border-grey-800',
        className,
      )}
      {...props}
    />
  );
};
