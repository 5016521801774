import { twJoin } from 'tailwind-merge';

import releaseImageDark from './releaseImage.dark.webp';
import releaseImageLight from './releaseImage.light.webp';
import { useChangelogBuilderTheme } from '../ChangelogBuilderThemeProvider';
import { useThemeVariant } from '../shared/useThemeVariant';
import { useChangelogBuilderForm } from '../useChangelogBuilderForm';
import { ChangelogBuilderContentReleaseDropdown } from './ChangelogBuilderContentReleaseDropdown';

export function ChangelogBuilderContentRelease() {
  const { watch } = useChangelogBuilderForm();
  const { isDarkTheme } = useChangelogBuilderTheme();

  const contentClasses = useThemeVariant(watch('contentSection'));

  return (
    <ChangelogBuilderContentReleaseDropdown>
      <div>
        <h3 className={twJoin(
          'font-semibold text-(--changelog-headers-color)',
          contentClasses({
            CLASSIC: 'text-lg',
            SOBER: 'text-lg',
            UNIFORM: 'text-xl',
            UNIQUE: 'text-2xl',
            DISCREET: 'text-2xl',
            SHOW_OFF: 'text-4xl',
          }),
        )}
        >
          New bicycle
        </h3>
        <img
          src={isDarkTheme ? releaseImageDark : releaseImageLight}
          className="mt-4 aspect-[4/2] h-auto w-full rounded-xl object-cover"
          loading="lazy"
        />
        <h3
          className={twJoin(
            'mt-10 text-lg font-extrabold text-(--changelog-headers-color)',
            contentClasses({
              DISCREET: 'text-[24px]',
              UNIFORM: 'text-[21px]',
              CLASSIC: 'text-[18px]',
              SOBER: 'text-[18px]',
              SHOW_OFF: 'text-[36px]',
              UNIQUE: 'text-[24px]',
            }),
          )}
        >
          New bicycle by Cycle
        </h3>
        <p
          className={twJoin(
            'mt-4 text-base text-(--changelog-body-color)',
            contentClasses({
              'UNIFORM': 'text-[18px]/[24px]',
              'SOBER': 'text-[18px]/[24px]',
              'SHOW_OFF': 'text-[22px]/[32px]',
            }) ?? 'text-base',
          )}
        >
          Our new sick feature is a game changer. In the world of efficiency, we bring something new, the “we never know efficiency”.
          Something unpredictable. This feature is quite extraordinary in a way that we actually never know if it’s gonna work or not.
          Because like us, this feature is allowed to have up and downs.
        </p>
      </div>
    </ChangelogBuilderContentReleaseDropdown>
  );
}
