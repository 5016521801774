import { InfiniteScroll } from '@cycle-app/ui';
import { useListNav } from '@cycle-app/utilities';
import { FormEvent, useEffect, useMemo, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import LoadMore from 'src/components/LoadMore/LoadMore';
import { commandSections } from 'src/constants/commandSections.constants';
import { INPUT_ONCHANGE_DEBOUNCE } from 'src/constants/inputs.constant';
import { SEARCH_NEXT_PAGINATION_SIZE } from 'src/constants/search.constants';
import { useIsRoadmapsEnabled } from 'src/hooks';
import { useCommandResult } from 'src/hooks/commandPanel/useCommandResult';
import { useCompaniesResult } from 'src/hooks/commandPanel/useCompaniesResult';
import { useCustomersResult } from 'src/hooks/commandPanel/useCustomersResult';
import { useDocSearch } from 'src/hooks/commandPanel/useDocSearch';
import { useCommandbar, useSectionFilter } from 'src/reactives/commandbar.reactive';
import { useIsMobile } from 'src/reactives/responsive.reactive';

import { Categories, Category, CategoryName, Actions, QuickFilters } from '../CommandBarModal.styles';
import { CommandAction } from './CommandAction';
import CommandInput from './CommandInput/CommandInput';
import { Container, NoResult } from './CommandK.styles';
import { CommandMobileHeader } from './CommandMobileHeader/CommandMobileHeader';
import { CommandProductAreaFilter } from './CommandProductAreaFilter/CommandProductAreaFilter';
import { CommandSectionFilter } from './CommandSectionFilter';
import { CommandStatusFilter } from './CommandStatusFilter/CommandStatusFilter';

const CommandK = () => {
  const isRoadmapsEnabled = useIsRoadmapsEnabled();
  const [{ section }] = useCommandbar();
  const isCustomersSection = section === 'customers';
  const isCustomersEnabled = !section || isCustomersSection;
  const isCompaniesEnabled = !section || isCustomersSection;

  const [search, setSearch] = useState('');
  const [searchDebounced, setSearchDebounced] = useState('');
  const sectionFilter = useSectionFilter();
  const statusFilter = useSectionFilter();

  const [isSectionFilterOpen, setIsSectionFilterOpen] = useState(false);
  const [isStatusFilterOpen, setIsStatusFilterOpen] = useState(false);
  const [isProductAreaFilterOpen, setIsProductAreaFilterOpen] = useState(false);

  const feedbackDocsSearch = useDocSearch({
    text: searchDebounced,
    skip: !sectionFilter.feedback || !!section || !searchDebounced,
    category: 'feedback',
  });

  const insightsDocsSearch = useDocSearch({
    text: searchDebounced,
    skip: !sectionFilter.quotes || !!section || !searchDebounced,
    category: 'quotes',
  });

  const roadmapsDocsSearch = useDocSearch({
    text: searchDebounced,
    skip: !sectionFilter.features || !!section || !searchDebounced,
    category: 'features',
  });

  const customersResult = useCustomersResult(isCustomersEnabled);
  const companiesResult = useCompaniesResult(isCompaniesEnabled);

  const isFetching =
    feedbackDocsSearch.loading ||
    insightsDocsSearch.loading ||
    roadmapsDocsSearch.loading ||
    customersResult.isLoading ||
    companiesResult.isLoading;

  useEffect(() => {
    setSearch('');
    setSearchDebounced('');
  }, [section]);

  const {
    resultByCategory,
    entries,
    loading,
    hasNextPage,
    fetchNextPage,
  } = useCommandResult({
    search,
    searchDebounced,
    docsResult: [
      ...(sectionFilter.feedback ? feedbackDocsSearch.result : []),
      ...(sectionFilter.quotes ? insightsDocsSearch.result : []),
      ...(sectionFilter.features ? roadmapsDocsSearch.result : []),
    ],
    customersResult: customersResult.defaultResult,
    companiesResult: companiesResult.defaultResult,
  });
  const optionsValues = useMemo(() => entries.map(action => action.id), [entries]);

  const {
    listProps,
    itemProps,
    selected,
    hoverDisabled,
  } = useListNav({
    optionsValues,
    onSelect: (actionId) => entries.find(a => a.id === actionId)?.onSelect?.(),
    autoFocus: true,
    enabled: !isSectionFilterOpen && !isStatusFilterOpen && !isProductAreaFilterOpen,
  });

  const handleSetSearch = async (newValue: string) => {
    if (isCustomersEnabled) customersResult.searchCustomers(newValue);
    if (isCompaniesEnabled) companiesResult.searchCompanies(newValue);
    setSearchDebounced(newValue);
  };

  const handleSetSearchDebounced = useDebouncedCallback(handleSetSearch, INPUT_ONCHANGE_DEBOUNCE);

  /** Trigger search on section filter change */
  useEffect(() => {
    if (searchDebounced && !section) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      handleSetSearch(searchDebounced);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sectionFilter, statusFilter]);

  const isMobile = useIsMobile();

  const hasResult = entries.length > 0;

  const currentCategory = section ? commandSections[section] : null;

  return (
    <Container>
      <div>
        {isMobile && <CommandMobileHeader isLoading={loading || isFetching} />}
        <CommandInput
          search={search}
          onSearchUpdate={async (e) => {
            onSearchUpdate(e);
            await handleSetSearchDebounced(e.currentTarget.value);
          }}
          isLoading={loading || isFetching}
        />
      </div>

      <QuickFilters>
        <CommandSectionFilter
          open={isSectionFilterOpen}
          onOpenChange={setIsSectionFilterOpen}
        />

        {isRoadmapsEnabled && (
          <CommandStatusFilter
            open={isStatusFilterOpen}
            onOpenChange={setIsStatusFilterOpen}
          />
        )}

        <CommandProductAreaFilter
          open={isProductAreaFilterOpen}
          onOpenChange={setIsProductAreaFilterOpen}
        />
      </QuickFilters>

      <Categories {...listProps}>
        {
          hasResult ? (
            <>
              {resultByCategory.map(category => {
                /* eslint-disable no-nested-ternary */
                const searchQuery =
                  category.id === 'feedback' ? feedbackDocsSearch
                    : category.id === 'insights' ? insightsDocsSearch
                      : category.id === 'roadmaps' ? roadmapsDocsSearch
                        : null;
                /* eslint-enable no-nested-ternary */

                const actions = category.actions
                  .map(action => (
                    <CommandAction
                      key={action.id}
                      action={action}
                      selected={action.id === selected}
                      hoverDisabled={hoverDisabled && action.id !== selected}
                      searchText={search}
                      searchTextDebounced={searchDebounced}
                      {...itemProps(action.id)}
                    />
                  ));

                return (
                  <Category key={category.id}>
                    {category.label && (
                      <CategoryName>
                        {category.label}
                      </CategoryName>
                    )}
                    <Actions>
                      {category.id === 'workspaces'
                        ? (
                          <InfiniteScroll
                            isLoading={loading}
                            hasMoreData={hasNextPage}
                            loadMore={fetchNextPage}
                          >
                            {actions}
                          </InfiniteScroll>
                        ) : (
                          <>
                            {actions}
                            {searchQuery?.hasNextPage && (
                              <LoadMore
                                className="mt-1 justify-start"
                                count={SEARCH_NEXT_PAGINATION_SIZE}
                                onClick={searchQuery.loadMore}
                                loading={searchQuery.isLoadingMore}
                              />
                            )}
                          </>
                        )}
                    </Actions>
                  </Category>
                );
              })}
            </>
          ) : renderNoResult()
        }
      </Categories>
    </Container>
  );

  function onSearchUpdate(e: FormEvent<HTMLInputElement>) {
    setSearch(e.currentTarget.value);
  }

  function renderNoResult() {
    if (currentCategory?.disableSearch) {
      return null;
    }
    return (
      <Category>
        <CategoryName>
          No result
        </CategoryName>
        <Actions>
          <NoResult>
            {section
              ? 'No results. Try different search terms.'
              : 'Try: "Search" or "Go to view"'}
          </NoResult>
        </Actions>
      </Category>
    );
  }
};

export default CommandK;
