import { useApolloClient } from '@apollo/client';
import { FetchBoardsStarredDocument, BoardFragment } from '@cycle-app/graphql-codegen';
import { produce } from 'immer';
import { useCallback } from 'react';

import { useMaybeMeV2 } from 'src/hooks/api/useMe';

import { useWorkspaceContext } from '../../contexts/workspaceContext';

export const useCacheBoardStarred = () => {
  const productId = useWorkspaceContext(ctx => ctx.productId);
  const client = useApolloClient();
  const { me } = useMaybeMeV2();

  const remove = useCallback((boardId: string) => {
    if (!me?.id) return;
    client.cache.updateQuery({
      query: FetchBoardsStarredDocument,
      variables: {
        userId: me.id,
        pId: productId,
      },
    }, prev => produce(prev, draft => {
      if (draft?.node?.__typename === 'Me') {
        draft.node.boards.edges = draft.node.boards.edges.filter(edge => edge.node.id !== boardId);
      }
    }));
  }, [client.cache, me?.id, productId]);

  const add = useCallback((newBoard: BoardFragment) => {
    if (!me?.id) return;
    client.cache.updateQuery({
      query: FetchBoardsStarredDocument,
      variables: {
        userId: me.id,
        pId: productId,
      },
    }, prev => produce(prev, draft => {
      if (draft?.node?.__typename === 'Me') {
        draft.node.boards.edges.splice(0, 0, { node: newBoard });
      }
    }));
  }, [client.cache, me?.id, productId]);

  return {
    add,
    remove,
  };
};
