import { Editor } from '@tiptap/core';
import { FC, RefObject, useRef } from 'react';

import { DocAsk } from 'src/components/DocAsk';
import DocEditor from 'src/components/DocEditor/DocEditor';
import { QuoteCards } from 'src/components/QuoteCards';
import { EDITOR_MARGIN, QUOTE_CARD_WIDTH, QUOTE_BUTTON_WIDTH } from 'src/constants/editor.constants';
import { useDocPanelContext } from 'src/contexts/docPanelContext';
import { useIsOffline, useDocInsights, useDoc } from 'src/hooks';
import useDocCoverDropzone from 'src/hooks/doc/useCoverDropzone';
import { useDocId } from 'src/hooks/usePathParams';
import { useIsMobile } from 'src/reactives/responsive.reactive';

import { DocPanelBaseProps } from '../DocPanel.types';
import { DocPanelBody } from './DocPanelBody';
import {
  HeaderContainer,
  ScrollableContent,
  DocPanelActions,
  DocPanelDocAttributes,
  CoverContainer,
  DocPanelEditableTitle,
  EditorWrapper,
  PreTitle,
  MainContent,
  DocPanelSection,
  QuoteCardsContainer,
} from './DocPanelContent.styles';
import { useQuoteCardsCollapsed } from './useQuoteCardsCollapsed';
import DocPanelCover from '../DocPanelCover/DocPanelCover';

interface Props extends Omit<DocPanelBaseProps, 'getDropzonePropsTopBar' | 'doc'> {
  containerRef: RefObject<HTMLDivElement>;
  isDraggingCover: boolean;
  isUploadingCover: boolean;
  onEscapeEdition?: VoidFunction;
  focusOnMount?: boolean;
}

export const DocPanelContent: FC<React.PropsWithChildren<Props>> = ({
  containerRef,
  getDropzonePropsCover,
  mode = 'edit-doc',
  onOpenCoverInputFile,
  onTitleUpdated,
  onEscapeEdition,
  focusOnMount,
  isDraggingCover: isDraggingCoverFromTopOrCover,
  isUploadingCover: isUploadingCoverFromTopOrCover,
}) => {
  const docId = useDocId();
  const { doc: docBase } = useDoc(docId);

  const {
    getRootProps: getDropzonePropsTitleAndAttrs,
    isDragActive: isDragActiveFromTitleAndAttrs,
    isUploadingCover: isUploadingCoverTitleAndAttrs,
  } = useDocCoverDropzone({ docId: docId ?? '' });
  const isOffline = useIsOffline();

  const isDraggingCover = isDraggingCoverFromTopOrCover || isDragActiveFromTitleAndAttrs;
  const isUploadingCover = isUploadingCoverFromTopOrCover || isUploadingCoverTitleAndAttrs;

  const isMobile = useIsMobile();

  // The TipTap component
  const docEditor = useRef<Editor>();
  // The ProseMirror element
  const proseMirrorElement = useRef<HTMLDivElement | null>(null);

  const setEditor = useDocPanelContext(ctx => ctx.setEditor);
  const docPanelEditor = useDocPanelContext(ctx => ctx.editor);

  const quoteCardsEnabled = !isMobile;
  const query = useDocInsights(docId, {
    skip: !quoteCardsEnabled,
  });
  const showQuoteCards = quoteCardsEnabled && query.insights.length > 0;

  const {
    quoteCardsCollapsed,
    containerRef: docContentRef,
  } = useQuoteCardsCollapsed({ disabled: !showQuoteCards });

  // Space for the absolutely positioned feature cards
  let paddingRight = 0;
  if (showQuoteCards && quoteCardsCollapsed !== null) {
    paddingRight = EDITOR_MARGIN + (quoteCardsCollapsed ? QUOTE_BUTTON_WIDTH : QUOTE_CARD_WIDTH);
  }

  // Quote from block feature
  const quoteFromBlockEnabled = !!docBase && !isMobile && !isOffline;
  // Extra padding for the "add quote" button. TODO: refactor with an editorMargin variable instead of EDITOR_MARGIN
  const paddingLeft = quoteFromBlockEnabled ? 42 : 0;

  return (
    <ScrollableContent ref={docContentRef}>
      <MainContent
        style={{ paddingLeft }}
        animate={{ paddingRight }}
      >
        {docId && docBase?.cover?.url && (
          <CoverContainer>
            <DocPanelCover
              docId={docId}
              coverUrl={docBase.cover.url}
              isDragActive={isDraggingCover}
              getDropzoneProps={getDropzonePropsCover}
              onUpdateCoverClicked={onOpenCoverInputFile}
            />
          </CoverContainer>
        )}

        <HeaderContainer
          {...getDropzonePropsTitleAndAttrs()}
          // Intentional empty function to prevent the dropzone to open the finder on `Enter`
          onKeyDown={undefined}
          coloredTopBorder={!docBase?.cover?.url && (isDraggingCover || isUploadingCover)}
        >
          {docBase && (
            <>
              <PreTitle>
                {docId && (
                  <DocPanelActions
                    docId={docId}
                    onAddCoverClicked={onOpenCoverInputFile}
                    isDraggingCover={isDraggingCover}
                    isUploadingCover={isUploadingCover}
                    editor={docEditor?.current}
                  />
                )}
              </PreTitle>

              <DocPanelEditableTitle
                id={docBase.id}
                title={docBase.title}
                onTitleUpdated={onTitleUpdated}
                onNext={() => containerRef.current?.click()}
                onEscape={onEscapeEdition}
                onEnter={() => docPanelEditor?.commands.focus('start')}
                focusEndOnMount={focusOnMount}
              />

              <DocPanelDocAttributes
                doc={docBase}
                showDoctype={false}
                showDocId={false}
                showAssignee
                editor={docPanelEditor}
                showStatus
              />
            </>
          )}
        </HeaderContainer>

        <DocPanelBody quoteFromBlockEnabled={quoteFromBlockEnabled}>
          {showQuoteCards && quoteCardsCollapsed !== undefined && (
            <DocPanelSection>
              <QuoteCardsContainer>
                <QuoteCards
                  insights={query.insights}
                  collapsed={quoteCardsCollapsed}
                />
              </QuoteCardsContainer>
            </DocPanelSection>
          )}

          <EditorWrapper
            ref={el => {
              proseMirrorElement.current = el?.querySelector('.ProseMirror') ?? null;
            }}
            $isDisabled={isOffline}
            onClick={undefined}
          // () => {
          /**
           * We deactivate this cause is cause some unwanted editor focus when
           * user clicks on some elements like modal to add file/images for
           * instance.
           */
          // Create a new paragraph when clicking on div elements below the editor
          // TODO: we should find a better way to do this
          // if (!(e.target instanceof HTMLElement) || e.target.tagName !== 'DIV') return;
          // ProseMirror handles clicks itself
          // if (proseMirrorElement.current?.contains(e.target)) return;
          // docEditor.current?.chain().focus('end').createParagraphNear().run();
          // }}
          >
            <DocEditor
              docId={docId}
              parentRef={containerRef}
              displayLoader={mode === 'edit-doc'}
              onEscape={onEscapeEdition}
              showAddTemplate
              showIntegrations
              onEditorReady={editor => {
                docEditor.current = editor;
                setEditor(editor);
              }}
            />
          </EditorWrapper>
        </DocPanelBody>
      </MainContent>

      {docId && (
        <DocAsk
          docId={docId}
          className="absolute bottom-4 right-4 z-10"
        />
      )}
    </ScrollableContent>
  );
};
