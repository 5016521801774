import { Separator as RadixSeparator } from 'radix-ui';
import { ComponentPropsWithoutRef, forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';

interface SeparatorProps extends Omit<ComponentPropsWithoutRef<typeof RadixSeparator.Root>, 'asChild' | 'orientation'> {}

const waveSvg = `
<svg
  xmlns="http://www.w3.org/2000/svg"
  viewBox="5.5 0 18.5714 6"
>
  <path
    d="M1 3C4.09524 0.333333 7.19048 0.333333 10.2857 3C13.381 5.66667 16.4762 5.66667 19.5714 3C22.6667 0.333333 25.7619 0.333333 28.8571 3"
    stroke="currentcolor"
    stroke-width="1.5"
    fill="none"
  />
</svg>
`;

const maskImage = `url("data:image/svg+xml,${encodeURIComponent(waveSvg)}")`;

export const SeparatorWave = forwardRef<HTMLDivElement, SeparatorProps>(
  function SeparatorWave({
    style,
    className,
    ...props
  }, ref) {
    return (
      <RadixSeparator.Root
        {...props}
        ref={ref}
        orientation="horizontal"
        className={twMerge(
          'w-full bg-current text-grey-200 dark:text-grey-850 h-1.5 my-4',
          className,
        )}
        style={{
          maskRepeat: 'repeat-x',
          maskImage,
          ...style,
        }}
      />
    );
  },
);
