import { HIGHLIGHT_DATA_TYPE } from '@cycle-app/editor-extensions';
import { Range } from '@tiptap/core';
import { Node } from '@tiptap/pm/model';
import { useEffect, useState } from 'react';

import { useDocPanelContext } from 'src/contexts/docPanelContext';
import { insertQuoteMark } from 'src/utils/editor/editor.utils';

// eslint-disable-next-line import/no-mutable-exports
export let hideAddQuoteFromBlockButton = () => {};

type QuoteFromBlockState = {
  // Range of the block node in the editor
  range: Range;
  // Position of the "Add quote" button
  position: {
    left: number;
    top: number;
  };
};

/**
 * This hook is used to show a "Add quote" button when hovering a block node in the editor.
 * It listens to the mouse events on the container element and computes the position of the button
 * and the text range of the block node to highlight
 */
export const useQuoteFromBlock = (container: HTMLElement | null) => {
  const editor = useDocPanelContext(ctx => ctx.editor);

  const [speaker, setSpeaker] = useState<string | null>(null);

  // Button position and node range
  const [state, setState] = useState<QuoteFromBlockState | null>(null);

  // Active state of the button
  const [active, setActive] = useState(false);

  useEffect(() => {
    if (!container || !editor?.view) return;

    let hoveredElement: HTMLElement | null = null;

    const hideButton = () => {
      hoveredElement = null;
      setState(null);
      setActive(false);
    };

    hideAddQuoteFromBlockButton = hideButton;

    const onMouseMove = (e: Event) => {
      if (!(e.target instanceof HTMLElement)) return;

      // Skip if the hovered element is the same
      if (hoveredElement !== null && hoveredElement === e.target) return;
      hoveredElement = e.target;

      // Hide the quote button if the hovered element is the editor itself
      if (hoveredElement.classList.contains('ProseMirror')) {
        hideButton();
        return;
      }

      // Find the block node element that contains the hovered element
      let element = e.target;
      while (!element.parentElement?.classList.contains('ProseMirror')) {
        if (!element.parentElement) return;
        element = element.parentElement;
      }

      if (element.dataset.type === 'summary') {
        hideButton();
        return;
      }

      // Get the corresponding prosemirror position and block node
      const pos = editor.view.posAtDOM(element, 0);
      let result: Node | null = null;
      editor.view.state.doc.nodesBetween(pos, pos, n => {
        result = n;
        return false;
      });
      const node = result as Node | null;
      const content = node?.textContent.trim();

      // If the block node is empty, don't show the quote button
      if (!node || !content) {
        hideButton();
        return;
      }

      // Speaker button
      const isSpeakerParagraph = node?.firstChild?.marks.some(mark => mark.attrs[HIGHLIGHT_DATA_TYPE] === 'speaker');
      const speakerName = node?.firstChild?.textContent.trim();
      if (isSpeakerParagraph && speakerName) {
        setSpeaker(speakerName);
      } else {
        setSpeaker(null);
      }

      // Text range of the block node
      const range: Range = {
        from: pos,
        to: pos + node.nodeSize,
      };

      // Position of the "Add quote" button
      const containerRect = container.getBoundingClientRect();
      const elementRect = element.getBoundingClientRect();
      const elementPaddingTop = parseInt(getComputedStyle(element).paddingTop, 10);
      const xOffset = 12;

      setState({
        range,
        position: {
          left: elementRect.left - containerRect.left - xOffset,
          top: elementRect.top - containerRect.top + elementPaddingTop,
        },
      });
    };

    container.addEventListener('mousemove', onMouseMove);

    // eslint-disable-next-line consistent-return
    return () => {
      container.removeEventListener('mousemove', onMouseMove);
    };
  }, [container, editor, active]);

  const onClick = () => {
    if (!state || !editor) return;
    setActive(true);
    insertQuoteMark(state.range, editor);
  };

  return {
    position: state?.position,
    active,
    onClick,
    speaker,
  };
};
