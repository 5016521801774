
import { AskHistory } from './AskHistory';
import { useProductConversations } from '../../hooks/ask/useProductConversations';

export const AskProductHistory = ({
  onSelect, onAskNew,
}: { onSelect: (conversationId: string) => void; onAskNew: VoidFunction }) => {
  const {
    conversations, isLoading,
  } = useProductConversations();
  return (
    <AskHistory
      conversations={conversations}
      onSelect={onSelect}
      onAskNew={onAskNew}
      isLoading={isLoading}
    />
  );
};
