import { Feature } from '@cycle-app/graphql-codegen';
import { ButtonVariant, Spinner, Icon } from '@cycle-app/ui';
import { AiIcon, CloseIcon, ReleaseNoteIcon } from '@cycle-app/ui/icons';
import { FC, ReactNode, useEffect } from 'react';

import featureExportCsvImg from 'src/assets/feature-export-csv.png';
import featureInsightCreateImg from 'src/assets/feature-insight-create.png';
import featureInsightDeleteImg from 'src/assets/feature-insight-delete.png';
import featureInsightUpdateImg from 'src/assets/feature-insight-update.png';
import featureSourceImg from 'src/assets/feature-source.svg';
import featureUpdateFeedback from 'src/assets/feature-update-feedback.png';
import featureViewUpdateImg from 'src/assets/feature-view-update.png';
import featureViewsImg from 'src/assets/feature-views.svg';
import featureEditAttributeOptionsImg from 'src/assets/product-tour-roadmap-step-4.svg';
import { mapAddOnsData } from 'src/constants/addOn.constants';
import { PageId } from 'src/constants/routing.constant';
import { useMe } from 'src/hooks';
import { useCurrentBilling, useProduct } from 'src/hooks/api/useProduct';
import { useNavigateToSettings } from 'src/hooks/useNavigateToSettings';
import { useProductAddOnRequest } from 'src/hooks/useProductAddOnRequest';
import { useUrl } from 'src/hooks/useUrl';
import { getPermission } from 'src/reactives';
import { setFeatureToEnable } from 'src/reactives/features.reactive';
import { resetLimitationsModal, useLimitationsModal, useLimitationsModalValue, setLimitationsModal } from 'src/reactives/limitationsModal.reactive';
import { AddOn } from 'src/types/addOn.types';
import { Layer } from 'src/types/layers.types';
import { PermissionAction } from 'src/types/permissionAction.types';
import { insightName } from 'src/utils/doc.util';

import { mapCtaLabel } from './LimitationModal.constants';
import {
  StyledPortalModal,
  Title,
  Footer,
  StyledButton,
  StyledButtonFull,
  StyledTextButton,
  Desc,
  StyledSectionsIllustrationCard,
  AddOnLogoContainer,
  CloseButton,
} from './LimitationModal.styles';
import type { CtaType } from './LimitationModal.types';
import { Events } from '../../constants/analytics.constants';
import { trackAnalytics } from '../../utils/analytics/analytics';

export type LimitationModalProps = {
  children?: ReactNode;
};

const addOnsIntegrations: AddOn['name'][] = [
  'zendesk',
  'SALESFORCE',
  'attio',
  'fresh-desk',
  'grain-sight',
  'plan-hat',
  'snow-flake',
  'vitally',
  'jira',
  'confluence',
];

export const LimitationModal: FC<React.PropsWithChildren<LimitationModalProps>> = () => {
  const [{
    action, brand,
  }] = useLimitationsModal();
  const { me } = useMe();
  const currentBilling = useCurrentBilling();
  const getUrl = useUrl();
  const { navigate } = useNavigateToSettings();
  const { product } = useProduct();

  useEffect(() => {
    if (!action) return;
    trackAnalytics(Events.LimitationOccurred, {
      userEmail: me.email,
      workspaceId: product?.id,
      workspaceName: product?.name,
      workspaceSlug: product?.slug,
      action,
      addon: brand,
    });
    // Intentional disabling
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [action]);

  if (!action || !me.role || !currentBilling?.plan) return null;

  const data = getLimitationContent(action, brand);

  if (!data) return null;

  const ctaLink = getUrl(PageId.RequestMakerAccess);

  if ('modal' in data) return (
    <>
      {data.modal}
    </>
  );

  let button;
  if ('cta' in data && data.cta === 'request-add-on') {
    button = (
      <RequestAddOnButton>
        {mapCtaLabel[data.cta]}
      </RequestAddOnButton>
    );
  } else if ('cta' in data && data.cta === 'enable-roadmap') {
    button = (
      <StyledButtonFull
        $variant="primary"
        $useCycleColor
        $size="M"
        onClick={() => {
          if (!getPermission().canReadSettings) {
            setLimitationsModal({ action: 'SETTINGS_READ' });
            return;
          }
          navigate(PageId.SettingsFeatures);
          resetLimitationsModal();
          setFeatureToEnable(Feature.Roadmap);
        }}
      >
        {mapCtaLabel[data.cta]}
      </StyledButtonFull>
    );
  } else {
    button = (
      <StyledButton
        $variant={data.ctaVariant || 'primary'}
        $useCycleColor
        $size="M"
        onClick={resetLimitationsModal}
        to={ctaLink}
      >
        {mapCtaLabel[data.cta]}
      </StyledButton>
    );
  }

  return (
    <StyledPortalModal
      layer={Layer.ToasterModal}
      hide={resetLimitationsModal}
      $hasSmallDimension={data.cta === 'request-add-on'}
    >
      {data.cta === 'request-add-on' && (
        <CloseButton onClick={resetLimitationsModal}>
          <CloseIcon />
        </CloseButton>
      )}
      {data.content}
      <main>
        <Title>
          {data.title}
        </Title>
        <Desc>
          {data.description}
        </Desc>
      </main>
      <Footer>
        {button}
        <StyledTextButton
          onClick={resetLimitationsModal}
          $size="M"
          $variant="nospace"
        >
          Maybe later
        </StyledTextButton>
      </Footer>
    </StyledPortalModal>
  );
};

type GetContentData =
  | {
    title: string;
    content?: ReactNode;
    description: ReactNode;
    cta: CtaType;
    ctaVariant?: ButtonVariant;
    businessPlus?: boolean;
  }
  | {
    modal: ReactNode;
  };

export const getLimitationContent = (
  action: PermissionAction,
  brand: AddOn['name'] | null,
): GetContentData | null => {
  switch (action) {
    case 'VIEW_CUSTOM_CREATE':
      return {
        content: <StyledSectionsIllustrationCard />,
        title: 'Custom views & sections is a maker feature',
        description: 'Explore maker role to create custom views.',
        cta: 'request-maker',
      };

    case 'VIEW_CUSTOM_SECTION_CREATE':
      return {
        content: <StyledSectionsIllustrationCard />,
        title: 'Custom views & sections is a maker feature',
        description: 'Explore maker role to create custom sections.',
        cta: 'request-maker',
      };

    case 'EXPORT_CSV':
      return {
        title: 'CSV exports is a maker feature',
        content: (
          <div>
            <img
              alt=""
              src={featureExportCsvImg}
            />
          </div>
        ),
        description: 'Explore maker role to export any view in CSV to perform analyses of your choice',
        cta: 'request-maker',
      };

    case 'VIEW_CREATE':
      return {
        title: 'Create views is a maker feature',
        content: (
          <div>
            <img
              alt=""
              src={featureViewUpdateImg}
            />
          </div>
        ),
        description: 'Explore maker role to create, edit & duplicate views',
        cta: 'request-maker',
      };

    case 'VIEW_DELETE':
      return {
        title: 'Delete views is a maker feature',
        content: (
          <div>
            <img
              alt=""
              src={featureViewUpdateImg}
            />
          </div>
        ),
        description: 'Explore maker role to create, edit & duplicate views',
        cta: 'request-maker',
      };

    case 'INTEGRATION_ADD':
      return {
        title: 'Adding integrations is a maker feature',
        content: (
          <div>
            <img
              alt="sources"
              src={featureSourceImg}
            />
          </div>
        ),
        description: 'Explore maker role to add integration of your choice',
        cta: 'request-maker',
      };

    case 'INSIGHT_CREATE':
      return {
        title: `Create ${insightName()} is a maker feature`,
        content: (
          <div>
            <img
              alt=""
              src={featureInsightCreateImg}
            />
          </div>
        ),
        description: `Explore maker role to extract ${insightName()}s from feedback documents and mark them as processed.`,
        cta: 'request-maker',
      };

    case 'INSIGHT_DELETE':
      return {
        title: `Delete ${insightName()} is a maker feature`,
        content: (
          <div>
            <img
              alt=""
              src={featureInsightDeleteImg}
            />
          </div>
        ),
        description: `Explore maker role to extract ${insightName()}s from feedback documents and mark them as processed.`,
        cta: 'request-maker',
      };

    case 'INSIGHT_UPDATE':
      return {
        title: 'Update quote is a maker feature',
        content: (
          <div>
            <img
              alt=""
              src={featureInsightUpdateImg}
            />
          </div>
        ),
        description: `Explore maker role to extract ${insightName()}s from feedback documents and mark them as processed.`,
        cta: 'request-maker',
      };

    case 'VIEW_UPDATE':
      return {
        title: 'Edit view is a maker feature',
        content: (
          <div>
            <img
              alt=""
              src={featureViewUpdateImg}
            />
          </div>
        ),
        description: 'Explore maker role to create, edit & duplicate views',
        cta: 'request-maker',
      };

    case 'FEEDBACK_STATUS_UPDATE':
      return {
        cta: 'request-maker',
        title: 'Process feedback is a maker feature',
        description: 'Explore maker role to extract insights from feedback documents and mark them as processed.',
        content: (
          <div>
            <img
              alt="process-feedback"
              src={featureUpdateFeedback}
            />
          </div>
        ),
      };

    case 'RELEASE_UPDATE':
      return {
        cta: 'request-maker',
        title: 'Manage releases is a maker feature',
        description: 'Explore maker role to create releases, write release notes, and publish change log',
        content: (
          <div>
            <img
              alt="process-feedback"
              src="/images/pricing-manage-releases.svg"
            />
          </div>
        ),
      };

    case 'SETTINGS_READ':
      return {
        cta: 'request-maker',
        title: 'Manage settings is a maker feature',
        description: 'Explore maker role to manage workspace settings',
        content: (
          <div>
            <img
              alt="doc types"
              src={featureViewsImg}
            />
          </div>
        ),
      };

    case 'USE_AI':
      return {
        title: 'Cycle AI is a maker feature',
        description: 'Explore maker role to enhance your productivity with AI-powered tools',
        content: (
          <AiIcon
            hasGradient
            size={64}
          />
        ),
        cta: 'request-maker',
      };

    case 'HANDLE_ATTRIBUTES_OPTIONS':
      return {
        title: 'Editing properties is a maker feature',
        description: 'Explore maker role to edit properties and their options',
        cta: 'request-maker',
        content: (
          <div>
            <img
              alt="doc types"
              src={featureEditAttributeOptionsImg}
            />
          </div>
        ),
      };

    case 'USE_ADD_ON':
      return {
        title: brand ? `${mapAddOnsData[brand].name}${addOnsIntegrations.includes(brand) ? ' integration' : ''} is an add-on` : '',
        description: brand ? mapAddOnsData[brand].description : '',
        content: brand ? (
          <AddOnLogoContainer>
            {mapAddOnsData[brand].logo}
            <div />
          </AddOnLogoContainer>
        ) : null,
        cta: 'request-add-on',
        ctaVariant: 'alt',
        businessPlus: true,
      };

    case 'TRANSCRIPT':
      return {
        title: 'Transcript is a maker feature',
        content: (
          <AddOnLogoContainer>
            <ReleaseNoteIcon size={28} />
          </AddOnLogoContainer>
        ),
        description: 'Explore maker role to get transcripts from audio,video & image files.',
        cta: 'request-maker',
      };

    case 'ROADMAP_NEEDED_FOR_LINEAR':
      return {
        title: 'Enable Requests to install Linear',
        content: (
          <AddOnLogoContainer>
            <Icon
              name="feature"
              className="size-7"
            />
          </AddOnLogoContainer>
        ),
        description: "Once Requests are up and running, you'll seamlessly sync with your Linear issues and projects.",
        cta: 'enable-roadmap',
      };

    default:
      return null;
  }
};

type RequestAddOnButtonProps = {
  children: ReactNode;
};

const RequestAddOnButton = ({ children }: RequestAddOnButtonProps) => {
  const { brand } = useLimitationsModalValue();
  const {
    request, isRequesting,
  } = useProductAddOnRequest();
  return (
    <StyledButtonFull
      $variant="alt"
      $size="M"
      onClick={() => brand && request(brand)}
    >
      {isRequesting ? <Spinner /> : children}
    </StyledButtonFull>
  );
};
