import { Popover, PopoverProps, Icon } from '@cycle-app/ui';
import { LinkIcon } from '@cycle-app/ui/icons';
import { MouseEvent, PropsWithChildren, useRef } from 'react';
import { Instance } from 'tippy.js';

import { copyToClipboard } from 'src/utils/clipboard.utils';

type Props = Omit<PopoverProps, 'content'> & {
  label: string;
  url: string;
  onClickEdit?: (e: MouseEvent) => void;
};

export const UrlPropertyPopover = ({
  label,
  url,
  onClickEdit,
  children,
  ...props
}: PropsWithChildren<Props>) => {
  const tippyInstance = useRef<Instance | null>(null);
  return (
    <Popover
      placement="top"
      withPortal
      withWrapper={false}
      interactive
      animation
      delay={[200, 0]}
      popperOptions={{
        strategy: 'fixed',
      }}
      onMount={(instance: Instance) => {
        tippyInstance.current = instance;
      }}
      {...props}
      content={(
        <div
          className="relative min-w-80 max-w-96 cursor-auto rounded-[10px] border border-grey-200 bg-white text-primary shadow-z2 dark:border-grey-800 dark:bg-grey-850"
          onClick={e => e.stopPropagation()}
          onWheel={e => e.stopPropagation()}
        >
          <div className="flex flex-col gap-1.5 border-b border-grey-150 p-3 pb-2 dark:border-grey-800">
            <div className="flex items-center gap-2">
              <LinkIcon size={14} />
              <div className="text-body font-medium">
                {label}
              </div>
            </div>
            <div className="overflow-anywhere text-[13px] leading-5 text-secondary">
              {url}
            </div>
          </div>

          <div className="flex items-center gap-1 p-1">
            <button
              className="btn-tertiary"
              onClick={() => window.open(url, '_blank')}
            >
              <Icon
                name="arrow-right-up"
                className="size-3.5"
              />
              Open
            </button>

            <button
              className="btn-tertiary"
              onClick={() => {
                copyToClipboard({
                  text: url,
                  notification: 'Link successfully copied to clipboard',
                });
              }}
            >
              <Icon name="copy" />
              Copy link
            </button>

            {onClickEdit && (
              <button
                className="btn-tertiary"
                onClick={e => {
                  tippyInstance.current?.hide();
                  onClickEdit(e);
                }}
              >
                <Icon name="edit" />
                Edit link
              </button>
            )}
          </div>
        </div>
      )}
    >
      {children}
    </Popover>
  );
};
