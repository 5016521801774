import { useProductAreaAttribute } from 'src/hooks/api/useAttributes';

import { QuoteContentEditor } from './QuoteContentEditor';
import { QuotePanelAiState } from './QuotePanelAiState';
import { QuotePanelCover } from './QuotePanelCover';
import { QuotePanelCreationDate } from './QuotePanelCreationDate';
import { QuotePanelCreator } from './QuotePanelCreator';
import { QuotePanelCustomer } from './QuotePanelCustomer';
import { QuotePanelFeature } from './QuotePanelFeature';
import { QuotePanelFeedback } from './QuotePanelFeedback';
import { QuotePanelProductAreas } from './QuotePanelProductAreas';
import { QuotePanelProperties } from './QuotePanelProperties';
import { QuotePanelReporter } from './QuotePanelReporter';
import { QuotePanelSource } from './QuotePanelSource';

export const QuotePanelContent = ({ openNext }: { openNext?: () => void }) => {
  const { attributeName } = useProductAreaAttribute();
  return (
    <>
      <QuotePanelCover />

      <div className="grid grid-cols-[minmax(110px,auto)_1fr] items-center gap-x-6 gap-y-4 text-body text-secondary">
        <div>
          Creator
        </div>

        <div>
          <QuotePanelCreator />
        </div>

        <div>
          Creation date
        </div>
        <div>
          <QuotePanelCreationDate />
        </div>

        <div>
          Customer
        </div>
        <div className="justify-self-start">
          <QuotePanelCustomer />
        </div>

        <div>
          Reporter
        </div>
        <div className="justify-self-start">
          <QuotePanelReporter />
        </div>

        <div>
          Source
        </div>
        <div>
          <QuotePanelSource />
        </div>

        <div>
          AI tag
        </div>
        <div className="overflow-hidden">
          <QuotePanelAiState openNext={openNext} />
        </div>

        <div>
          {attributeName}
        </div>
        <div className="justify-self-start">
          <QuotePanelProductAreas />
        </div>

        <div>
          Linked request
        </div>
        <div className="-m-0.5 overflow-hidden p-0.5">
          <QuotePanelFeature />
        </div>

        <div>
          Linked feedback
        </div>

        <div className="-m-0.5 overflow-hidden p-0.5">
          <QuotePanelFeedback />
        </div>

        <div>
          Properties
        </div>
        <div className="justify-self-start">
          <QuotePanelProperties />
        </div>
      </div>

      <hr className="my-6 w-full border-t border-grey-150 dark:border-grey-850" />

      <QuoteContentEditor />
    </>
  );
};
