import { useFragment } from '@apollo/client';
import { DocAssigneeFragmentDoc, DoctypeType } from '@cycle-app/graphql-codegen';
import { AvatarLegacy } from '@cycle-app/ui';

import { AssigneePopover } from 'src/components/DocAssignee/AssigneePopover';
import { getUserLabel } from 'src/utils/users.util';

import { useQuoteId } from './QuoteContext';

export const QuotePanelReporter = () => {
  const quoteId = useQuoteId();
  const {
    data: doc, complete,
  } = useFragment({
    fragment: DocAssigneeFragmentDoc,
    fragmentName: 'DocAssignee',
    from: quoteId,
  });
  if (!complete || !doc?.assignee) return null;
  return (
    <AssigneePopover
      assignee={doc.assignee}
      docTypeType={DoctypeType.Insight}
      isDisabled
    >
      <div className="flex items-center gap-2 px-2 font-medium text-primary">
        <div className="flex size-4 items-center justify-center">
          <AvatarLegacy
            user={doc.assignee}
            size={16}
            className="scale-90"
          />
        </div>
        {getUserLabel(doc.assignee)}
      </div>
    </AssigneePopover>
  );
};
