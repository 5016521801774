import { Icon, TooltipLegacy } from '@cycle-app/ui';

import { ErrorBoundary } from 'src/components/ErrorBoundary';
import { SideModal } from 'src/components/PortalModal/SideModal';
import { ProductAreaPanel } from 'src/components/ProductAreaPanel';
import { PageId } from 'src/constants/routing.constant';
import { navigate } from 'src/hooks';
import { closeProductAreaModal, useProductAreaModal } from 'src/hooks/modals/useProductAreaModal';

export const ProductAreaModal = () => {
  const productAreaId = useProductAreaModal();
  return (
    <SideModal
      id={productAreaId}
      hide={closeProductAreaModal}
    >
      {productAreaId && (
        <div>
          <div className="absolute left-3 top-3 flex items-center gap-1">
            <button
              className="btn-tertiary btn-square"
              onClick={closeProductAreaModal}
            >
              <Icon name="close" />
            </button>
            <TooltipLegacy
              content="Open full page"
              withWrapper={false}
              withPortal
            >
              <button
                className="btn-tertiary btn-square"
                onClick={() => navigate(PageId.ProductHierarchy, { productHierarchy: productAreaId })}
              >
                <Icon name="expand" />
              </button>
            </TooltipLegacy>
          </div>
          <ErrorBoundary>
            <ProductAreaPanel
              className="mt-12"
              id={productAreaId}
            />
          </ErrorBoundary>
        </div>
      )}
    </SideModal>
  );
};
