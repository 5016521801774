import { useApolloClient } from '@apollo/client';
import {
  DocConversationCreateDocument,
  ConversationNodeDocument,
  ConversationRole,
  DocConversationsFragmentDoc,
  ProductConversationCreateDocument,
  ConversationFragment,
  ProductConversationsFragmentDoc,
} from '@cycle-app/graphql-codegen';
import { produce } from 'immer';

import { useWorkspaceContext } from '../../contexts/workspaceContext';
import useSafeMutation from '../useSafeMutation';
import { CONVERSATION_PAGINATION } from './useConversation';

type CreateInput = {
  question: string;
  title?: string;
  docId?: string;
  onSuccess?: (id: string) => void;
};

export const useConversationCreate = () => {
  const [mutationDoc, { loading }] = useSafeMutation(DocConversationCreateDocument);
  const [mutationProduct] = useSafeMutation(ProductConversationCreateDocument);
  const productId = useWorkspaceContext(ctx => ctx.productId);
  const client = useApolloClient();

  const addConversation = (data: { conversation: ConversationFragment; mutationId: string }) => {
    const {
      conversation, mutationId,
    } = data;
    // Prepare the node query with the Bot answer.
    // This also avoid the query when we navigate on the conversation.
    client.cache.writeQuery({
      id: conversation.id,
      query: ConversationNodeDocument,
      variables: {
        id: conversation.id,
        ...CONVERSATION_PAGINATION,
      },
      data: {
        node: {
          __typename: 'Conversation',
          ...conversation,
          role: ConversationRole.User,
          messages: {
            pageInfo: {
              endCursor: '',
              hasNextPage: false,
            },
            edges: [{
              __typename: 'ConversationEdge',
              cursor: '',
              node: {
                __typename: 'Conversation',
                // This id will be used by the ai stream to update the content.
                id: btoa(`Conversation_${mutationId}`),
                content: '',
                createdAt: conversation.createdAt,
                role: ConversationRole.Bot,
                // Display "Thinking..." state.
                _isPending: true,
              },
            }],
          },
        },
      },
    });
  };

  const create = ({
    question, title, docId, onSuccess,
  }: CreateInput) => {
    if (docId) {
      return mutationDoc({
        variables: {
          question,
          title,
          docId,
        },
        update: (cache, { data }) => {
          if (data?.createConversation) {
            const { conversation } = data.createConversation;
            addConversation(data.createConversation);
            // Add the conversation in the doc history
            cache.updateFragment({
              fragment: DocConversationsFragmentDoc,
              fragmentName: 'DocConversations',
              id: docId,
            }, prev => produce(prev, draft => {
              draft?.conversations.edges.push({
                __typename: 'ConversationEdge',
                node: {
                  __typename: 'Conversation',
                  ...conversation,
                },
              });
            }));
          }
        },
        onCompleted: (data) => {
          if (onSuccess && data.createConversation) {
            onSuccess(data.createConversation.conversation.id);
          }
        },
      });
    }
    return mutationProduct({
      variables: {
        question,
        title,
        productId,
      },
      update: (cache, { data }) => {
        if (data?.createConversation) {
          const { conversation } = data.createConversation;
          addConversation(data.createConversation);
          // Add the conversation in the doc history
          cache.updateFragment({
            fragment: ProductConversationsFragmentDoc,
            fragmentName: 'ProductConversations',
            id: productId,
          }, prev => produce(prev, draft => {
            draft?.conversations.edges.push({
              __typename: 'ConversationEdge',
              node: {
                __typename: 'Conversation',
                ...conversation,
              },
            });
          }));
        }
      },
      onCompleted: (data) => {
        if (onSuccess && data.createConversation) {
          onSuccess(data.createConversation.conversation.id);
        }
      },
    });
  };

  return {
    create,
    isLoading: loading,
  };
};
