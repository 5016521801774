import { GithubIssueStatus, getColorByGithubStatus, capitalize } from '@cycle-app/utilities';
import { FC } from 'react';

import { TooltipLegacy } from '../TooltipLegacy/Tooltip';

export type GithubStatusProps = {
  status: GithubIssueStatus;
};

export const GithubStatus: FC<GithubStatusProps> = ({ status = 'open' }) => (
  <TooltipLegacy
    content={capitalize(status)}
    placement="top"
    withWrapper={false}
  >
    <div
      className="inline-block size-2 rounded-full"
      style={{
        backgroundColor: getColorByGithubStatus(status),
      }}
    />
  </TooltipLegacy>
);
