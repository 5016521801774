import { BoardType } from '@cycle-app/graphql-codegen';
import { Icon, Spinner } from '@cycle-app/ui';
import { QuoteIcon } from '@cycle-app/ui/icons';
import { memo, MouseEvent } from 'react';

import { CustomViewIconEditable } from 'src/app/Main/Sidebar/SidebarBoard/SidebarBoardSections/SidebarBoardSection/SidebarItemBoard/CustomViewIcon';
import { useBoard, useIsBoardStarred } from 'src/hooks';
import { SectionParent } from 'src/utils/section.utils';

import { BoardLinkButton } from './BoardLinkButton';
import { ItemViewType } from './ItemViewType';
import { ViewDescription } from './ViewDescription';
import { ViewName } from './ViewName';
import {
  Item, Title, View, Actions, StyledBoardStarButton, StyledBoardOptions,
} from './ViewsList.styles';
import { useBoardConfigFilters } from '../../hooks/boards/useBoardConfigFilters';

type Props = {
  sectionParent: SectionParent;
  boardId: string;
  onClick?: (event: MouseEvent) => void;
  isOverlay?: boolean;
  isDragging?: boolean;
  isSmall: boolean;
};

export const ViewItem = memo(({
  sectionParent, boardId, onClick, isOverlay = false, isSmall, isDragging,
}: Props) => {
  const isBoardStarred = useIsBoardStarred();
  const board = useBoard(boardId);
  const viewType = board?.publishedBoardConfig?.viewType;
  const isInbox = sectionParent === 'inbox';
  const isDashboard = !viewType;
  if (!board || (board.type === BoardType.Custom && !viewType)) return null;
  return (
    <Item
      onClick={onClick}
      $isDragging={isDragging}
      $isOverlay={isOverlay}
      $isSmall={isSmall}
      $isInbox={isInbox}
    >
      <Title>
        <CustomViewIconEditable
          viewId={board.id}
          color={board.color}
          emoji={board.emoji}
          sectionType={board.section?.type}
        />

        <ViewName name={board.name} />

        {!isSmall && (
          <BoardLinkButton
            boardId={board.id}
          />
        )}
      </Title>

      <View>
        <ItemViewType
          viewType={isDashboard ? 'dashboard' : viewType}
          isSmall={isSmall}
        />
      </View>

      {isInbox && (
        <div className="flex items-center gap-2 overflow-hidden text-secondary">
          <FeedbackSectionType
            boardConfigId={board.publishedBoardConfig?.id}
            isDashboard={isDashboard}
          />
        </div>
      )}

      <ViewDescription
        boardId={board.id}
        description={board.description?.trim()}
      />

      <Actions>
        {!isSmall && (
          <StyledBoardStarButton
            boardId={board.id}
            tooltipPlacement="top"
            $visible={isBoardStarred(board.id)}
          />
        )}

        <StyledBoardOptions
          boardId={board.id}
          boardType={board.type}
          placement="bottom-end"
          tooltipPlacement="top"
          boardConfigId={board.publishedBoardConfig?.id}
          hideSettings
        />
      </Actions>
    </Item>
  );
});

const FeedbackSectionType = ({
  boardConfigId, isDashboard,
}: {
  boardConfigId: string | undefined;
  isDashboard: boolean;
}) => {
  const filters = useBoardConfigFilters(boardConfigId);

  if (isDashboard) {
    return (
      <>
        <QuoteIcon />
        {' '}
        Quotes
      </>
    );
  }

  if (filters.isLoading) {
    return <Spinner className="opacity-50" />;
  }

  if (filters.insightDocType.isTheOnlyOne) {
    return (
      <>
        <QuoteIcon />
        {' '}
        Quotes
      </>
    );
  }

  return (
    <>
      <Icon
        name="feedback"
        className="flex-none"
      />
      {' '}
      Docs
    </>
  );
};
