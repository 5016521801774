import { PARAM_PROFILE } from 'src/constants/routing.constant';
import { history } from 'src/providers';
import { getSearchParams } from 'src/utils/routing.utils';

export const getProfileIdParam = () => {
  const params = getSearchParams();
  return params.get(PARAM_PROFILE);
};

export const closeProfileModal = () => {
  const params = getSearchParams();
  params.delete(PARAM_PROFILE);
  history.push({ search: params.toString() });
};

export const openProfileModal = (profileId: string) => {
  const params = getSearchParams();
  params.delete(PARAM_PROFILE);
  // Order is important, the last modal opened will be displayed on top
  params.append(PARAM_PROFILE, profileId);
  history.push({ search: params.toString() });
};
