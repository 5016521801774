import { MotionConfig, AnimatePresence, motion, MotionProps, Variants  } from 'framer-motion';
import { Dialog } from 'radix-ui';
import { ComponentPropsWithoutRef, ReactNode, forwardRef } from 'react';
import { twJoin, twMerge } from 'tailwind-merge';

import { Icon } from '../Icon';

const motionProps: MotionProps = {
  initial: 'closed',
  animate: 'open',
  exit: 'closed',
  transition: {
    ease: 'easeInOut',
    duration: 0.15,
  },
};

const overlayVariants: Variants = {
  open: { opacity: 1 },
  closed: { opacity: 0 },
};

type MotionType = 'slide-up' | 'fade';

const getContentVariants = (type?: MotionType): Variants => {
  if (type === 'fade') {
    return {
      open: {
        opacity: 1,
        scale: 1,
      },
      closed: {
        opacity: 0,
        scale: 0.98,
      },
    };
  }

  // default: slide-up
  return {
    open: {
      opacity: 1,
      scale: 1,
      y: 0,
    },
    closed: {
      opacity: 0,
      scale: 0.98,
      y: 15,
    },
  };
};

interface OverlayProps extends Omit<ComponentPropsWithoutRef<typeof Dialog.Overlay>, 'forceMount' | 'asChild'> {
  zIndex?: number;
}

const Overlay = forwardRef<HTMLDivElement, OverlayProps>(
  function Overlay({
    zIndex,
    style,
    ...props
  }, ref) {
    return (
      <Dialog.Overlay
        ref={ref}
        forceMount
        asChild
        {...props}
      >
        <motion.div
          className={twJoin(
            'fixed inset-0 bg-black/50',
            props.className,
          )}
          style={{
            zIndex,
            ...style,
          }}
          variants={overlayVariants}
          {...motionProps}
        />
      </Dialog.Overlay>
    );
  },
);

interface ContentProps extends Omit<ComponentPropsWithoutRef<typeof Dialog.Content>, 'forceMount' | 'asChild'> {
  motionType?: MotionType;
  motionVariants?: MotionProps['variants'];
  zIndex?: number;
}

const Content = forwardRef<HTMLDivElement, ContentProps>(
  function Content({
    className,
    style,
    motionType,
    motionVariants,
    zIndex,
    children,
    ...props
  }, ref) {
    return (
      <Dialog.Content
        ref={ref}
        forceMount
        asChild
        {...props}
      >
        <motion.div
          className={twMerge(
            'fixed bg-white dark:bg-grey-850 rounded-lg text-primary top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 max-w-[90vw] max-h-[90vh] p-4 shy-scrollbar shadow-z5 rounded-xl',
            'outline outline-black/[.06] dark:outline-0 dark:border dark:border-white/10',
            className,
          )}
          style={{
            zIndex,
            ...style,
          }}
          variants={motionVariants ?? getContentVariants(motionType)}
          {...motionProps}
        >
          {children}
        </motion.div>
      </Dialog.Content>
    );
  },
);

interface TitleProps extends React.ComponentPropsWithoutRef<typeof Dialog.Title> {}

const Title = forwardRef<HTMLHeadingElement, TitleProps>(
  function Title(props, ref) {
    return (
      <Dialog.Title
        ref={ref}
        {...props}
        className={twJoin(
          'text-header-small font-medium text-primary mb-1',
          props.className,
        )}
      />
    );
  },
);

interface DescriptionProps extends React.ComponentPropsWithoutRef<typeof Dialog.Description> {}

const Description = forwardRef<HTMLHeadingElement, DescriptionProps>(
  function Description(props, ref) {
    return (
      <Dialog.Description
        ref={ref}
        {...props}
        className={twJoin(
          'text-body text-secondary mb-1',
          props.className,
        )}
      />
    );
  },
);

interface ModalProps extends
  Pick<ComponentPropsWithoutRef<typeof Dialog.Portal>, 'container'>,
  ContentProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  trigger?: ReactNode;
  close?: boolean;
  overlay?: boolean;
  zIndex?: number;
}

const Modal = ({
  trigger,
  close = true,
  overlay = true,
  zIndex = 30,
  open,
  onOpenChange,
  children,
  container,
  ...props
}: ModalProps) => {
  return (
    <MotionConfig reducedMotion="user">
      <Dialog.Root
        open={open}
        onOpenChange={onOpenChange}
      >
        {trigger && (
          <Dialog.Trigger asChild>
            {trigger}
          </Dialog.Trigger>
        )}

        <AnimatePresence>
          {open && (
            <Dialog.Portal
              forceMount
              container={container}
            >
              {overlay && (
                <Overlay
                  zIndex={zIndex}
                />
              )}
              <Content
                {...props}
                zIndex={zIndex + 1}
              >
                {children}

                {close && (
                  <Dialog.Close
                    className="btn-tertiary btn-square absolute top-4 right-4"
                    aria-label="Close"
                  >
                    <Icon name="close" />
                  </Dialog.Close>
                )}
              </Content>
            </Dialog.Portal>
          )}
        </AnimatePresence>
      </Dialog.Root>
    </MotionConfig>
  );
};

const CustomDialog = {
  Root: Dialog.Root,
  Trigger: Dialog.Trigger,
  Portal: Dialog.Portal,
  Close: Dialog.Close,
  Description,
  Overlay,
  Content,
  Title,
  Modal,
};

export { CustomDialog as Dialog };
