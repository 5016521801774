import {
  ActionButton, Input, Icon, TooltipLegacy, TextArea, Menu, Combobox,
} from '@cycle-app/ui';
import { useState } from 'react';
import { twJoin } from 'tailwind-merge';
import { useDebouncedCallback } from 'use-debounce';

import { INPUT_ONCHANGE_DEBOUNCE } from '../../constants/inputs.constant';
import { BoardConfigContextProvider } from '../../contexts/boardConfigContext';
import { useOptimizedBooleanState } from '../../hooks';
import { useProductAreaAttribute } from '../../hooks/api/useAttributes';
import { useProductArea } from '../../hooks/product/useProductArea';
import { useProductAreasCategories } from '../../hooks/product/useProductAreasCategories';
import { useWorkspaceProductAreaUpdate } from '../../hooks/product/useWorkspaceProductAreaUpdate';
import { ErrorPage } from '../ErrorPage';
import { ProductAreasTabs } from '../ProductAreasTabs';

export const ProductAreaPanel = ({
  id, className,
}: { id: string; className?: string }) => {
  const productArea = useProductArea(id);
  const { attributeName } = useProductAreaAttribute();
  if (!productArea) return <ErrorPage message={`${attributeName} not found`} />;
  return (
    <BoardConfigContextProvider skip>
      <div className={twJoin('pt-6 inline-block', className)}>
        <div className="px-8">
          <Category
            id={productArea.id}
            currentCategoryId={productArea.category?.id || null}
          />
          <div className="mt-4">
            <Name
              id={id}
              name={productArea?.value || ''}
            />
          </div>
          <Description
            id={id}
            description={productArea?.description || ''}
          />
        </div>
        <ProductAreasTabs id={id} />
      </div>
    </BoardConfigContextProvider>
  );
};

const Category = ({
  id, currentCategoryId,
}: { id: string; currentCategoryId: string | null }) => {
  const { categories } = useProductAreasCategories();
  const category = categories.find(cat => cat.id === currentCategoryId);
  const { moveCategory } = useWorkspaceProductAreaUpdate();
  const [open, setOpen] = useState(false);
  const removeItem = categories.find(cat => !cat.id)?.value;
  return (
    <Menu
      className="relative z-20 p-0"
      trigger={(
        <button
          type="button"
          className="btn btn-secondary btn-sm h-5 rounded-sm font-normal"
        >
          <div className="flex items-center gap-1">
            {category?.value}
            <Icon
              name="down"
              className="size-2.5 text-disabled"
            />
          </div>
        </button>
      )}
      open={open}
      onOpenChange={setOpen}
    >
      <Combobox.Root>
        <Combobox.Input />
        <Combobox.List>
          <Combobox.Empty>
            No results
          </Combobox.Empty>
          {removeItem && (
            <Combobox.Item
              onSelect={async () => {
                setOpen(false);
                await moveCategory({
                  id,
                  categoryId: null,
                  currentCategoryId,
                });
              }}
            >
              {removeItem}
            </Combobox.Item>
          )}
          {categories.filter(cat => cat.id).map(cat => (
            <Combobox.Item
              key={cat.id}
              onSelect={async () => {
                setOpen(false);
                await moveCategory({
                  id,
                  categoryId: cat.id,
                  currentCategoryId,
                });
              }}
            >
              {cat.value}
            </Combobox.Item>
          ))}
        </Combobox.List>
      </Combobox.Root>
    </Menu>
  );
};

const Name = ({
  name, id,
}: { name: string; id: string }) => {
  const [isEditing, {
    setFalseCallback: closeForm,
    setTrueCallback: openForm,
  }] = useOptimizedBooleanState(false);

  if (isEditing) {
    return (
      <NameForm
        id={id}
        name={name}
        close={closeForm}
      />
    );
  }

  return (
    <div className="group text-header-small font-medium">
      {name}
      <TooltipLegacy
        content="Edit name"
        placement="top"
        withWrapper={false}
      >
        <ActionButton
          className="relative ml-2 hidden! size-5 p-0! text-disabled group-hover:inline-flex!"
          onClick={e => {
            e.stopPropagation();
            openForm();
          }}
        >
          <Icon name="pen-fill" />
        </ActionButton>
      </TooltipLegacy>
    </div>
  );
};

const NameForm = ({
  name, close, id,
}: { name: string; close: VoidFunction; id: string }) => {
  const [value, setValue] = useState(name);
  const { update } = useWorkspaceProductAreaUpdate();

  // Submit on enter or blur as there is back-end validation on name.
  const submit = async () => {
    close();
    if (value.trim().length && value !== name) {
      await update({
        id,
        name: value,
      });
    }
  };

  return (
    <Input
      autoFocus
      onKeyUp={async e => {
        if (e.code === 'Escape') {
          e.stopPropagation();
          close();
        }
        if (e.code === 'Enter') {
          e.stopPropagation();
          await submit();
        }
      }}
      onBlur={submit}
      onChange={e => setValue(e.currentTarget.value)}
      value={value}
      inputSize="S"
    />
  );
};

const Description = ({
  description, id,
}: {
  description: string; id: string;
}) => {
  const [isEditing, {
    setFalseCallback: closeForm,
    setTrueCallback: openForm,
  }] = useOptimizedBooleanState(false);

  if (isEditing) {
    return (
      <DescriptionForm
        id={id}
        close={closeForm}
        value={description}
      />
    );
  }

  return (
    <div
      className={twJoin(
        'overflow-anywhere group mt-2 text-secondary',
        !description && 'italic text-disabled',
      )}
    >
      {description || 'No description'}
      <TooltipLegacy
        content="Edit description"
        placement="top"
        withWrapper={false}
      >
        <ActionButton
          className="relative ml-2 hidden! size-5 p-0! text-disabled group-hover:inline-flex!"
          onClick={e => {
            e.stopPropagation();
            openForm();
          }}
        >
          <Icon name="pen-fill" />
        </ActionButton>
      </TooltipLegacy>
    </div>
  );
};

export const DescriptionForm = ({
  value, close, id,
}: {
  id: string;
  value: string;
  close: VoidFunction;
}) => {
  const { update } = useWorkspaceProductAreaUpdate();

  const submit = (description: string) => {
    return update({
      id,
      description,
    });
  };

  const debouncedSubmit = useDebouncedCallback(submit, INPUT_ONCHANGE_DEBOUNCE);

  return (
    <TextArea
      className="mt-2 resize-none overflow-hidden"
      rows={6}
      defaultValue={value}
      onBlur={close}
      onChange={e => debouncedSubmit(e.target.value)}
      onClick={e => e.stopPropagation()}
      autoResize
      minHeight={140}
      onKeyUp={e => {
        if (e.code === 'Escape') {
          e.stopPropagation();
          close();
        }
      }}
    />
  );
};
