import { PARAM_QUOTE } from 'src/constants/routing.constant';
import { useLocationSelector } from 'src/hooks/router/useLocation';
import { navigateToQuotePage } from 'src/hooks/useNavigate';
import { history } from 'src/providers';
import { getParams, getSearchParams } from 'src/utils/routing.utils';

export const getQuoteIdParam = () => {
  const params = getSearchParams();
  return params.get(PARAM_QUOTE);
};

export const closeQuoteModal = () => {
  const params = getSearchParams();
  params.delete(PARAM_QUOTE);
  history.push({ search: params.toString() });
};

export const openQuoteModal = (quoteId: string, groupId?: string | null, metaKey = false) => {
  if (metaKey) {
    navigateToQuotePage(quoteId, metaKey);
    return;
  }
  // For the same quoteId, the modal must not be opened if the full page is already open
  if (getParams().quoteId === quoteId) {
    closeQuoteModal();
    return;
  }
  const params = getSearchParams();
  params.delete(PARAM_QUOTE);
  // Order is important, the last modal opened will be displayed on top
  params.append(PARAM_QUOTE, quoteId);
  history.push(
    { search: params.toString() },
    groupId ? { groupId } : undefined,
  );
};

export const useQuoteModal = () => useLocationSelector(getQuoteIdParam);
