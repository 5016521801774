import { InfiniteScroll, Skeleton } from '@cycle-app/ui';
import { useRect } from '@cycle-app/utilities';
import { UniqueIdentifier } from '@dnd-kit/core';
import { useState } from 'react';

import { useNavigate } from 'src/hooks';
import { useMoveBoardInSectionsList } from 'src/hooks/api/mutations/useBoardSectionsMutations';
import { getViewPageId, SectionParent } from 'src/utils/section.utils';
import { getBoardSlug } from 'src/utils/slug.util';

import { ViewItem } from './ViewItem';
import { Container, Total } from './ViewsList.styles';
import { ViewSortableItem } from './ViewSortableItem';
import { ViewsSortableContext } from './ViewsSortableContext';
import { useBoards } from '../../hooks/boards/useBoards';

type Props = {
  sectionParent: SectionParent;
  sectionId: string;
};

export const ViewsList = ({
  sectionId, sectionParent,
}: Props) => {
  const {
    boards, isLoading, hasNextPage, isLoadingMore, loadMore,
  } = useBoards(sectionId);
  return isLoading ? (
    <Container>
      <div className="h-14 flex items-center p-6">
        <Skeleton className="h-6! opacity-35" />
      </div>
      <div className="h-14 flex items-center p-6">
        <Skeleton className="h-6! opacity-35" />
      </div>
    </Container>
  ) : (
    <Items
      boards={boards}
      sectionId={sectionId}
      sectionParent={sectionParent}
      hasNextPage={hasNextPage}
      isLoadingMore={isLoadingMore}
      loadMore={loadMore}
    />
  );
};

type ItemProps = {
  boards: { id: string; name: string }[];
  hasNextPage: boolean;
  isLoadingMore: boolean;
  loadMore: () => Promise<{ id: string; name: string }[]>;
  sectionId: string;
  sectionParent: SectionParent;
};

const Items = ({
  boards, sectionParent, sectionId, hasNextPage, isLoadingMore, loadMore,
}: ItemProps) => {
  const { navigate } = useNavigate();
  const viewsCount = boards.length;
  const [items, setItems] = useState<UniqueIdentifier[]>(boards.map(board => board.id));
  const { moveBoardInSectionList } = useMoveBoardInSectionsList();
  const [ref, isSmall] = useRect(rect => rect.width < 850);

  return (
    <Container ref={ref}>
      {isSmall !== undefined && (
        <InfiniteScroll
          hasMoreData={hasNextPage}
          isLoading={isLoadingMore}
          loadMore={async () => {
            const newItems = await loadMore();
            if (newItems.length) {
              setItems(current => ([...current, ...newItems.map(board => board.id)]));
            }
          }}
        >
          <ViewsSortableContext
            items={items}
            setItems={setItems}
            overlay={id => (
              <ViewItem
                sectionParent={sectionParent}
                boardId={id.toString()}
                isSmall={isSmall}
                isOverlay
              />
            )}
            onDragEnd={event => {
              if (!sectionId || !event.over) return;
              const activeId = event.active.id.toString();
              const overId = event.over.id.toString();
              if (activeId === overId) return;
              const boardIds = boards.map(board => board.id);
              const isAfter = boardIds.indexOf(overId) > boardIds.indexOf(activeId);
              // eslint-disable-next-line @typescript-eslint/no-floating-promises
              moveBoardInSectionList({
                boardId: activeId,
                fromSectionId: sectionId,
                toSectionId: sectionId,
                position: isAfter ? {
                  after: overId,
                } : {
                  before: overId,
                },
              });
            }}
          >
            {props => items.map(id => boards.some(b => b.id === id) && (
              <ViewSortableItem
                id={id}
                key={id}
              >
                <ViewItem
                  sectionParent={sectionParent}
                  boardId={id.toString()}
                  isSmall={isSmall}
                  isDragging={props.isDragging}
                  onClick={event => {
                    const board = boards.find(b => b.id === id);
                    navigate(getViewPageId(sectionParent), { boardSlug: getBoardSlug(board) }, undefined, event?.metaKey);
                  }}
                />
              </ViewSortableItem>
            ))}
          </ViewsSortableContext>

          <Total>
            {hasNextPage && '+'}
            {`${viewsCount} views`}
          </Total>
        </InfiniteScroll>
      )}
    </Container>
  );
};
