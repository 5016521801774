import { CompanyFullFragment } from '@cycle-app/graphql-codegen/generated';
import { Icon } from '@cycle-app/ui';

import { TooltipClickToCopy } from 'src/components/TooltipClickToCopy';

export const CellCompanyLinkedProfiles = ({ company }: { company: CompanyFullFragment }) => {
  return (
    <div className="flex items-center gap-2">
      {externalIdsKeys.map(key => {
        const data = externalIdsData[key];
        const value = company[key];
        if (!value || !data) return null;
        return (
          <TooltipClickToCopy
            key={key}
            value={value}
            notification={`${data.name} successfully copied to clipboard`}
            title={(
              <div>
                <div>
                  {data.name}
                </div>
                <div>
                  {value}
                </div>
              </div>
            )}
          >
            {props => (
              <button
                className="cursor-pointer p-0"
                {...props}
              >
                {data.icon}
              </button>
            )}
          </TooltipClickToCopy>
        );
      })}
    </div>
  );
};

const externalIdsKeys = ['zendeskId', 'hubspotId', 'intercomId', 'pipedriveId', 'snowflakeId', 'attioId', 'salesforceId'] as const;

type ExternalIdsKeys = typeof externalIdsKeys[number];

const externalIdsData: Record<ExternalIdsKeys, { name: string; icon?: JSX.Element }> = {
  zendeskId: {
    name: 'Zendesk ID',
    icon: (
      <Icon
        name="brand/zendesk"
        className="size-4"
      />
    ),
  },
  hubspotId: {
    name: 'HubSpot ID',
    icon: (
      <Icon
        name="brand/hubspot"
        className="size-4"
      />
    ),
  },
  intercomId: {
    name: 'Intercom ID',
    icon: (
      <Icon
        name="brand/intercom"
        className="size-4"
      />
    ),
  },
  pipedriveId: {
    name: 'Pipedrive ID',
    icon: (
      <Icon
        name="brand/pipedrive"
        className="size-4"
      />
    ),
  },
  snowflakeId: {
    name: 'Snowflake ID',
    icon: (
      <Icon
        name="brand/snowflake"
        className="size-4"
      />
    ),
  },
  attioId: {
    name: 'Attio ID',
    icon: (
      <Icon
        name="brand/attio"
        className="size-4"
      />
    ),
  },
  salesforceId: {
    name: 'Salesforce ID',
    icon: (
      <Icon
        name="brand/salesforce"
        className="size-4"
      />
    ),
  },
};
