import { TooltipLegacy } from '@cycle-app/ui';
import { useRef, useState } from 'react';
import { twJoin } from 'tailwind-merge';

import { DropdownLayer } from 'src/components/DropdownLayer';
import { useBoardSections } from 'src/hooks/api/useBoardSections';
import { useBoardLink } from 'src/hooks/boards/useBoardLink';
import { BoardLinkPanel } from 'src/hooks/boards/useBoardMenuOptions';
import { useOverflow } from 'src/hooks/useOverflow';
import { setLimitationsModal, useGetPermission } from 'src/reactives';
import { getSectionName } from 'src/utils/viewSection.utils';

import { ViewAction } from './ViewsList.styles';

export const BoardLinkButton = ({ boardId }: { boardId: string }) => {
  const { canUpdateView } = useGetPermission();
  const {
    defaultSection, mappingBoardLinks, customSections, sectionsCount,
  } = useBoardSections();
  const {
    createBoardLink, removeBoardLink,
  } = useBoardLink();
  const boardLink = Object.values(mappingBoardLinks).find(link => link.board.id === boardId);
  const hasCustomSections = !!customSections.length;

  const placeholder = !hasCustomSections && defaultSection
    ? `Add to ${getSectionName(defaultSection, sectionsCount).toLowerCase()}`
    : 'Add to a section';

  const tooltipContent = !hasCustomSections && defaultSection
    ? `Remove from ${getSectionName(defaultSection, sectionsCount).toLowerCase()}`
    : 'Change section';

  const buttonRef = useRef<HTMLButtonElement>(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const sectionName = boardLink ? getSectionName(boardLink.section, sectionsCount) : null;
  const [spanRef, overflow] = useOverflow([sectionName]);

  return (
    <>
      <ViewAction
        ref={buttonRef}
        className={twJoin(!sectionName && 'flex-none')}
        forceFocus={dropdownOpen}
        $visible={!!boardLink}
        onClick={(e) => {
          e.stopPropagation();
          if (!canUpdateView) {
            setLimitationsModal({
              action: 'VIEW_UPDATE',
            });
            return;
          }
          if (hasCustomSections) {
            setDropdownOpen(true);
            return;
          }
          if (defaultSection && !boardLink) {
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            createBoardLink({
              boardId,
              sectionId: defaultSection.id,
            });
            return;
          }
          if (defaultSection && boardLink) {
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            removeBoardLink({
              boardLinkId: boardLink.id,
            });
          }
        }}
      >
        {sectionName
          ? (
            <span
              ref={spanRef}
              className="truncate"
            >
              {sectionName}
            </span>
          )
          : placeholder}
      </ViewAction>

      <div className="absolute">
        <TooltipLegacy
          {...overflow ? {
            title: tooltipContent,
            content: sectionName,
          } : {
            content: tooltipContent,
          }}
          disabled={!boardLink}
          placement="top"
          withPortal
          withWrapper={false}
          reference={buttonRef}
        />

        <DropdownLayer
          placement="bottom"
          withPortal
          withWrapper={false}
          visible={dropdownOpen}
          hide={() => setDropdownOpen(false)}
          reference={buttonRef}
          content={(
            <BoardLinkPanel
              viewId={boardId}
              onChange={() => setDropdownOpen(false)}
              boardLinkId={boardLink?.id}
              boardLinkSectionId={boardLink?.section.id}
              showHeader={false}
            />
          )}
        />
      </div>
    </>
  );
};
