import { ReleasePublicStatus } from '@cycle-app/graphql-codegen';
import { DotsMenu, SelectPanel, Icon } from '@cycle-app/ui';
import { ShareIcon, TrashIcon, LinkArrowIcon, LinkIcon, DuplicateIcon } from '@cycle-app/ui/icons';
import { useRef, useState } from 'react';

import { useRelease } from 'src/hooks/releases/useRelease';
import { useReleaseNote } from 'src/hooks/releases/useReleaseNote';
import { getNodeContents } from 'src/utils/html.utils';

import { HiddenEditorContainer } from './ReleaseNote.styles';
import { ReleaseNoteEditor } from './ReleaseNoteEditor';
import { useWorkspaceContext } from '../../contexts/workspaceContext';
import { useChangelog } from '../../hooks/releases/useChangelog';
import { setLimitationsModal, useGetPermission } from '../../reactives';
import { openRemoveReleaseNote } from '../../reactives/releases.reactive';
import { Layer } from '../../types/layers.types';
import { releaseNoteUrl } from '../../utils/changelog.utils';
import { copyToClipboard } from '../../utils/clipboard.utils';
import { DropdownLayer } from '../DropdownLayer';
import { SubmenuIcon } from './ReleaseNoteDocOpen.styles';
import { ReleaseNoteEditorTitle } from './ReleaseNoteEditorTitle';

type Submenu = 'share' | null;

type State = {
  isCopying: boolean;
  action: 'copy' | 'share-x' | 'share-linkdin' | null;
};

export const ReleaseNoteDocOpen = ({
  releaseId, noteId, isReadOnly,
}: {
  isReadOnly: boolean;
  releaseId: string;
  noteId: string;
}) => {
  const { release } = useRelease(releaseId);
  const { releaseNote } = useReleaseNote(noteId);
  const { changelog } = useChangelog();

  const [submenu, setSubmenu] = useState<Submenu>(null);
  const { canDeleteReleaseNote } = useGetPermission();
  const productSlug = useWorkspaceContext(ctx => ctx.productSlug);

  const setMouseEnterMenu = (value: string | null) => {
    setSubmenu(value as typeof submenu);
  };

  const hiddenEditorContainerRef = useRef<HTMLDivElement>(null);

  const [copy, setIsCopying] = useState<State>({
    isCopying: false,
    action: null,
  });

  if (!release || !releaseNote?.doc) return null;

  const isReleaseUnpublished = release.publicStatus === ReleasePublicStatus.Unpublished;
  const isChangelogPublished = changelog?.isPublished;
  const releaseNotePublicId = releaseNote.publicReleaseNote?.id;
  const isPrivate = !isChangelogPublished || isReleaseUnpublished || !releaseNotePublicId;
  const url = releaseNotePublicId ? releaseNoteUrl(productSlug, changelog?.domain, releaseNotePublicId) : null;

  return (
    <>
      <DotsMenu
        onMouseEnterItem={setMouseEnterMenu}
        onMouseLeaveItem={() => setSubmenu(null)}
        onHide={() => setSubmenu(null)}
        placement="bottom-end"
        options={[{
          value: 'share',
          icon: <ShareIcon />,
          label: 'Share',
          onSelect: () => setSubmenu('share'),
          end: <SubmenuIcon $isActive={submenu === 'share'} />,
          renderLabel: () => {
            return (
              <DropdownLayer
                layer={Layer.DropdownZ2}
                visible={submenu === 'share'}
                placement="left-start"
                offset={[-10, 40]}
                content={(
                  <SelectPanel
                    hideSearch
                    options={[
                      {
                        value: 'share-x',
                        icon: (
                          <Icon
                            name="brand/twitter"
                            className="size-4"
                          />
                        ),
                        label: 'X (Twitter)',
                        ...isPrivate && {
                          disabled: true,
                          tooltipContent: 'Publish the changelog and the release first',
                        },
                      },
                      {
                        icon: (
                          <Icon
                            name="brand/linkedin"
                            className="size-4"
                          />
                        ),
                        value: 'share-linkedin',
                        label: 'Linkedin',
                        ...isPrivate && {
                          disabled: true,
                          tooltipContent: 'Publish the changelog and the release first',
                        },
                      },
                      {
                        icon: <LinkIcon />,
                        value: 'copy-link',
                        label: 'Copy link',
                        ...isPrivate && {
                          disabled: true,
                          tooltipContent: 'Publish the changelog and the release first',
                        },
                      },
                      {
                        icon: <DuplicateIcon />,
                        value: 'copy-content',
                        label: 'Copy content',
                      },
                    ]}
                    onOptionChange={async (option) => {
                      if (option.value === 'copy-content') {
                        setIsCopying({
                          isCopying: true,
                          action: 'copy',
                        });
                      }
                      if (option.value === 'share-x') {
                        setIsCopying({
                          isCopying: true,
                          action: 'share-x',
                        });
                      }
                      if (option.value === 'share-linkedin') {
                        setIsCopying({
                          isCopying: true,
                          action: 'share-linkdin',
                        });
                      }
                      if (url && option.value === 'copy-link') {
                        copyToClipboard({
                          text: url,
                          notification: 'URL copied to clipboard',
                        });
                      }
                    }}
                  />
                )}
              >
                Share
              </DropdownLayer>
            );
          },
        }, {
          icon: <LinkArrowIcon size={16} />,
          value: 'open-changelog',
          label: 'Open in Changelog',
          ...isPrivate && {
            disabled: true,
            tooltipContent: 'Publish the changelog and the release first',
          },
          onSelect: () => {
            if (url) {
              window.open(url, '_blank');
            }
          },
        }, {
          value: 'delete',
          label: 'Delete',
          icon: <TrashIcon />,
          onSelect: canDeleteReleaseNote ? openRemoveReleaseNote(noteId) : () => setLimitationsModal({ action: 'RELEASE_UPDATE' }),
          disabled: isReadOnly,
          tooltipContent: isReadOnly ? 'Make updates or unpublish first' : null,
        }]}
      />

      {copy.isCopying && (
        <HiddenEditorContainer ref={hiddenEditorContainerRef}>
          <ReleaseNoteEditorTitle
            releaseNoteId={noteId}
            isReadOnly
          />
          <ReleaseNoteEditor
            releaseNoteId={noteId}
            isReadOnly
            onLoad={async (editor) => {
              // Create a new paragraph at the end
              // Images are not copied if they are the last element
              editor?.chain().focus('end').createParagraphNear().run();

              await getNodeContents(hiddenEditorContainerRef.current, (text) => {
                if (copy.action === 'copy') {
                  const separateFirstLine = (content: string) => {
                    const lines = content.trim().split('\n');
                    const firstLine = lines.shift();
                    return [firstLine, lines.join('\n')].join('\n\n');
                  };

                  copyToClipboard({
                    text: separateFirstLine(text),
                    showNotification: true,
                  });
                }
                if (changelog && releaseNotePublicId && (copy.action === 'share-x' || copy.action === 'share-linkdin') && url) {
                  const encodedText = encodeURIComponent(text || '');
                  const encodedUrl = encodeURIComponent(url);
                  if (copy.action === 'share-x') {
                    const twitterUrl = `https://twitter.com/intent/tweet?text=${encodedText}&url=${encodedUrl}`;
                    window.open(twitterUrl, '_blank', 'width=550,height=420');
                  }
                  if (copy.action === 'share-linkdin') {
                    const linkedinUrl = `https://www.linkedin.com/feed/?shareActive&mini=true&shareUrl=${encodedUrl}&text=${encodedText}`;
                    window.open(linkedinUrl, '_blank', 'width=600,height=600');
                  }
                }
                setIsCopying({
                  isCopying: false,
                  action: null,
                });
              });
            }}
          />
        </HiddenEditorContainer>
      )}
    </>
  );
};
