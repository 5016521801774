import { MateFragment } from '@cycle-app/graphql-codegen';
import { TooltipLegacy } from '@cycle-app/ui';
import { FC } from 'react';

import DropdownLayer from 'src/components/DropdownLayer/DropdownLayer';
import useOptimizedBooleanState from 'src/hooks/useOptimizedBooleanState';

import RealtimeMoreLine from './RealtimeMoreLine';
import { More, MoreUsersContainer } from './RealtimeUsers.styles';

interface Props {
  users: Array<MateFragment>;
}

const RealtimeMore: FC<Props> = ({ users }) => {
  const [dropdownIsVisible, {
    toggleCallback: toggleDropdown, setFalseCallback: hideDropdown,
  }] = useOptimizedBooleanState(false);
  return (
    <DropdownLayer
      visible={dropdownIsVisible}
      hide={hideDropdown}
      offsetY={8}
      content={(
        <MoreUsersContainer>
          {users.map(user => (
            <RealtimeMoreLine
              key={user.id}
              user={user}
              hideDropdown={hideDropdown}
            />
          ))}
        </MoreUsersContainer>
      )}
    >
      <TooltipLegacy
        content="View more"
        placement="bottom"
        withPortal
      >
        <More onClick={toggleDropdown}>
          <span>
            {`+${users.length}`}
          </span>
        </More>
      </TooltipLegacy>
    </DropdownLayer>
  );
};

export default RealtimeMore;
