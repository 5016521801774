import { LinearStatus as LinearStatusType } from '@cycle-app/graphql-codegen';
import {
  LinearStatusBacklogIcon,
  LinearStatusCanceledIcon,
  LinearStatusDoneIcon,
  LinearStatusInProgressIcon,
  LinearStatusToDoIcon,
} from '@cycle-app/ui/icons';
import { FC } from 'react';

import { TooltipLegacy, IconContainer } from './LinearStatus.styles';

type Props = {
  className?: string;
  status: Pick<LinearStatusType, 'name'>;
  tooltip?: boolean;
  size?: number;
};
export const LinearStatus: FC<React.PropsWithChildren<Props>> = ({
  className, status, tooltip = true, size,
}) => {
  return tooltip ? (
    <TooltipLegacy
      content={status.name}
      placement="top"
      withPortal
    >
      <IconContainer className={className}>
        {renderIcon()}
      </IconContainer>
    </TooltipLegacy>
  ) : (
    <IconContainer className={className}>
      {renderIcon()}
    </IconContainer>
  );

  function renderIcon() {
    switch (status.name) {
      case 'Todo':
        return <LinearStatusToDoIcon size={size} />;
      case 'In Progress':
        return <LinearStatusInProgressIcon size={size} />;
      case 'Done':
        return <LinearStatusDoneIcon size={size} />;
      case 'Canceled':
        return <LinearStatusCanceledIcon size={size} />;
      case 'Backlog':
      default:
        return <LinearStatusBacklogIcon size={size} />;
    }
  }
};
