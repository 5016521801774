import { DocBaseFragment, DoctypeType } from '@cycle-app/graphql-codegen';
import memoize from 'fast-memoize';
import { FC, useEffect, ReactNode, useState } from 'react';
import { twJoin } from 'tailwind-merge';

import { AskDoc } from 'src/components/AskV2/AskDoc';
import { DocPanelActivity } from 'src/components/DocPanelActivity';
import { DocPanelThreads } from 'src/components/DocPanelThreads';
import { FeedbackInsightList } from 'src/components/InsightsList';
import { useLocation } from 'src/hooks';
import useQueryParams from 'src/hooks/useQueryParams';
import { useGetDocPanel, Content, setDocPanel, getDocPanel } from 'src/reactives/docRightPanel.reactive';
import { setCommentNavigation } from 'src/reactives/notifications.reactive';
import { useIsMobile } from 'src/reactives/responsive.reactive';
import { insightName } from 'src/utils/doc.util';
import { COMMENT_SEARCH_PARAM } from 'src/utils/notifications.util';

import { CommentsCount } from './CommentsCount';
import {
  Container,
  DisplayedContent,
  StyledAction,
  StyledTabList,
  StyledTab,
} from './DocPanelRightPanel.styles';
import { DocRightPanelEmptyState } from './DocRightPanelEmptyState';
import { InsightsCount } from './InsightsCount';

interface Props {
  doc: DocBaseFragment;
}

const DocPanelRightPanel: FC<React.PropsWithChildren<Props>> = ({ doc }) => {
  const isMobile = useIsMobile();
  const location = useLocation();
  const docPanel = useGetDocPanel();
  const rightPanelContent = docPanel.content || Content.Comments;
  const rightPanelExpanded = docPanel.isExpanded;
  const askContent = docPanel.askContent;

  const onButtonClicked = memoize((content: Content) => () => {
    setDocPanel({
      content,
      isExpanded: !rightPanelExpanded || rightPanelContent !== content,
      autoFocus: true,
    });
  });

  useEffect(() => () => setDocPanel({
    isExpanded: false,
    isHovered: false,
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }), []);

  const searchCommentId = useQueryParams().get(COMMENT_SEARCH_PARAM);

  const isFeedback = doc.doctype.type === DoctypeType.Feedback;

  useEffect(() => {
    if (searchCommentId) {
      setCommentNavigation({ sidebarCommentId: searchCommentId });
      setDocPanel({
        isExpanded: true,
        content: Content.Comments,
      });
      return;
    }

    // Opens right panel from doc item actions
    if (location.state?.rightPanel) {
      setDocPanel({
        isExpanded: true,
        content: location.state.rightPanel,
      });
      return;
    }

    setDocPanel({
      isExpanded: false,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchCommentId, doc.id, location.state?.rightPanel]);

  const isExpanded = rightPanelExpanded;
  const insightsTabEnabled = isMobile && isFeedback;

  const tabs: {
    content: Content;
    label: ReactNode;
    isHidden?: boolean;
    badge?: ReactNode;
  }[] = [];

  tabs.push({
    content: Content.Comments,
    label: 'Comments',
    ...(isExpanded && {
      badge: (
        <CommentsCount
          doc={doc}
          className="group-[.force-focus]:bg-grey-200 group-[.force-focus]:dark:bg-grey-800"
        />
      ),
    }),
  });

  if (insightsTabEnabled) {
    tabs.push({
      content: Content.Insights,
      label: insightName({
        plural: true,
        uppercase: true,
      }),
      ...(isExpanded && {
        badge: (
          <InsightsCount
            doc={doc}
            className="group-[.force-focus]:bg-grey-200 group-[.force-focus]:dark:bg-grey-800"
          />
        ),
      }),
    });
  }

  tabs.push({
    content: Content.Activity,
    label: 'Activity',
  });

  const [isOpen, setIsOpen] = useState(isExpanded);
  useEffect(() => {
    if (!isExpanded) setIsOpen(false);
  }, [isExpanded]);

  return (
    <Container
      $collapsed={!isExpanded}
      index={tabs.findIndex(tab => tab.content === rightPanelContent)}
      onChange={index => {
        const content = tabs[index]?.content;
        if (!content) return;
        setDocPanel({ content });
      }}
      onKeyDown={() => setDocPanel({ autoFocus: false })}
      onMouseEnter={() => setDocPanel({ isHovered: true })}
      onMouseLeave={() => setDocPanel({ isHovered: false })}
      onTransitionEnd={e => {
        if (e.target === e.currentTarget && getDocPanel().isExpanded) {
          setIsOpen(true);
        }
      }}
    >
      {isOpen && isFeedback && askContent && (
        <AskDoc
          docId={doc.id}
          content={askContent}
        />
      )}
      {(isOpen && (!isFeedback || !askContent)) && (
        <>
          {!isMobile && (
            <StyledTabList>
              {tabs.map((tab) => (
                <StyledTab
                  key={tab.content}
                  $isHidden={!!tab.isHidden}
                >
                  {!tab.isHidden && (
                    <StyledAction
                      className={twJoin('group', rightPanelContent === tab.content && 'force-focus')}
                      role="button"
                      tabIndex={0}
                      onClick={onButtonClicked(tab.content)}
                    >
                      {tab.label}
                      {tab.badge}
                    </StyledAction>
                  )}
                </StyledTab>
              ))}
            </StyledTabList>
          )}

          <DisplayedContent
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            {rightPanelContent === Content.Comments && isMobile && (
              <DocRightPanelEmptyState doc={doc} />
            )}

            {rightPanelContent === Content.Comments && !isMobile && (
              <DocPanelThreads
                docId={doc.id}
                docTitle={doc.title.trim() || 'Untitled'}
              />
            )}

            {rightPanelContent === Content.Activity && (
              <DocPanelActivity />
            )}

            {insightsTabEnabled && rightPanelContent === Content.Insights && (
              <FeedbackInsightList doc={doc} />
            )}
          </DisplayedContent>
        </>
      )}
    </Container>
  );
};

export default DocPanelRightPanel;
