import { ThemeType } from '@cycle-app/graphql-codegen';
import { boxShadowZ4, Button as ButtonUI, typo, ShyScrollbarCss, colorUtils } from '@cycle-app/ui';
import styled, { css } from 'styled-components';

export const AiMenuContainer = styled.div`
  ${ShyScrollbarCss}
  display: flex;
  flex-direction: column;
  background: ${p => p.theme.colors.components.EditorAiMenu.bg};
  max-width: calc(100vw - 32px);
  width: 600px;
  ${boxShadowZ4}
  border-radius: 12px;
  border: 1px solid ${p => colorUtils.transparentize(p.theme.colors.border.primary, 0.6)};
  pointer-events: all;
  max-height: calc(100vh - 48px);
`;

type PromptContainerProps = {
  $hasBorder: boolean;
};

export const PromptContainer = styled.div<PromptContainerProps>`
  display: flex;
  align-items: center;
  padding: 12px;
  gap: 8px;
  ${p => p.$hasBorder && css`
    border-bottom: 1px solid ${colorUtils.transparentize(p.theme.colors.border.primary, 0.6)};
  `}
`;

export const PromptClose = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 18px;
  right: 12px;
  color: ${p => p.theme.colors.text.disabled};
  cursor: pointer;
  padding: 2px;
  z-index: 100;
  border-radius: 4px;

  &:hover {
    background: ${p => (p.theme.isDark
    ? colorUtils.transparentize(p.theme.colors.background.opposite, 0.94)
    : p.theme.colors.button.noColor.bg)};
  }

  svg {
    width: 12px;
    height: 12px;
  }
`;

export const ButtonsContainer = styled.div`
  padding: 12px;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 8px;
  display: flex;
`;

type ButtonProps = {
  forceFocus?: boolean;
};

export const Button = styled(ButtonUI) <ButtonProps>`
  --color: ${p => p.theme.colors.components.EditorAiMenu.buttonColor};
  ${typo.caption500}
  display: flex;
  padding: 2px 8px;
  align-items: center;
  gap: 4px;
  border-radius: 6px;
  color: ${p => p.theme.colors.components.EditorAiMenu.buttonColor};
  background: ${p => (p.forceFocus
    ? colorUtils.darken(p.theme.colors.components.EditorAiMenu.buttonBg, 0.03)
    : p.theme.colors.components.EditorAiMenu.buttonBg)};
  border: 1px solid ${p => p.theme.colors.components.EditorAiMenu.buttonBorder};
  box-shadow: none;

  &:hover, &:focus {
    background: ${p => {
    const opacity = p.theme.themeType === ThemeType.Sunrise ? '0.04' : '0.3';
    return colorUtils.darken(p.theme.colors.components.EditorAiMenu.buttonBg, Number(opacity));
  }};
    box-shadow: none;
  }
  &:active {
    background: ${p => colorUtils.darken(p.theme.colors.components.EditorAiMenu.buttonBg, 0.03)};
  }

  svg {
    flex: none;
    width: 14px;
    height: 14px;
  }
`;

export const SuggestionContainer = styled.div`
  ${typo.body400}
  color: ${p => p.theme.colors.text.primary};
  padding: 12px 28px 0px 12px;
  ${ShyScrollbarCss}
  max-height: 500px;
`;

export const AiIsWriting = styled.p`
  ${typo.body500}
  background: linear-gradient(90deg, #A990FF 0.38%, #57CFEF 63.15%, #7FEA96 120.18%);
  background-clip: text;
  /* stylelint-disable-next-line property-no-vendor-prefix */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

export const PromptInput = styled.input`
  ${typo.body400}
  color: ${p => p.theme.colors.text.primary};
  border: none;
  outline: none;
  width: 100%;
  background: transparent;
  pointer-events: none;
  cursor: text;
`;
