/**
 * Low-level abstraction of Radix DropdownMenu primitives with custom styles and animations
 */
import { AnimatePresence, motion, MotionProps, Variants } from 'framer-motion';
import { DropdownMenu } from 'radix-ui';
import { forwardRef, useRef } from 'react';
import { twMerge } from 'tailwind-merge';

interface Props extends Omit<DropdownMenu.DropdownMenuSubContentProps, 'forceMount' | 'asChild'> {
  open?: boolean;
  motionVariants?: MotionProps['variants'];
}

export const SubContent = forwardRef<HTMLDivElement, Props>(
  function SubContent({
    open = false,
    motionVariants = defaultVariants,
    className,
    children,
    ...props
  }, forwardedRef) {
    const ref = useRef<HTMLDivElement>(null);
    return (
      <AnimatePresence>
        {open && (
          <DropdownMenu.SubContent
            ref={forwardedRef}
            forceMount
            asChild
            collisionPadding={8}
            {...props}
          >
            <motion.div
              ref={ref}
              className={twMerge(
                'bg-white flex flex-col p-1 items-stretch dark:bg-grey-850 rounded-lg outline! outline-black/[6%]! dark:shadow-none dark:outline-grey-800! shadow-z3 text-primary shy-scrollbar',
                'max-h-[var(--radix-dropdown-menu-content-available-height)] max-w-[var(--radix-dropdown-menu-content-available-width)]',
                className,
              )}
              animate={open ? 'open' : 'closed'}
              initial="closed"
              exit="closed"
              variants={motionVariants}
              style={{
                transformOrigin: 'var(--radix-dropdown-menu-content-transform-origin)',
              }}
            >
              {children}
            </motion.div>
          </DropdownMenu.SubContent>
        )}
      </AnimatePresence>
    );
  },
);

const defaultVariants = {
  open: {
    opacity: 1,
    scale: 1,
    transition: {
      type: 'spring',
      duration: 0.2,
    },
  },
  closed: {
    opacity: 0,
    scale: 0.9,
    transition: {
      type: 'spring',
      duration: 0.2,
    },
  },
} satisfies Variants;
