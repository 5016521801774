import { openPlain } from 'src/services/plain';

export const openChat = () => {
  if (import.meta.env.VITE_PLAIN_KEY) {
    openPlain();
    return;
  }
  if (import.meta.env.VITE_INTERCOM_KEY) {
    openIntercom();
    return;
  }
};

const openIntercom = () => {
  if (!window.Intercom) {
    console.warn('Intercom not instanciated');
    return;
  }
  try {
    window.Intercom('show');
  } catch (error) {
    console.group();
    console.warn('Error trying to open Intercom');
    console.warn('Intercom error', error);
    console.warn('Intercom', window.Intercom);
    console.groupEnd();
  }
};
