import { useSubscription } from '@apollo/client';
import { AiStreamDocument, ConversationMessageFragmentDoc } from '@cycle-app/graphql-codegen';
import scrollIntoView from 'scroll-into-view-if-needed';

import { useWorkspaceContext } from '../../contexts/workspaceContext';
import { askParameters, askVar } from '../../reactives/ask.reactive';

export const useConversationSubscription = () => {
  const productId = useWorkspaceContext(ctx => ctx.productId);

  return useSubscription(AiStreamDocument, {
    variables: {
      productId,
    },
    onData: ({
      data, client,
    }) => {
      if (data.data?.aiStream) {
        const {
          stream, mutationId, progress, parameters, docIds, conversationId,
        } = data.data.aiStream;

        // When we ask a new question we write in the cache a Conversation with
        // the id btoa(`Conversation_${mutationId}`) and _isPending=true.
        const botAnswerId = btoa(`Conversation_${mutationId}`);
        client.cache.updateFragment({
          fragment: ConversationMessageFragmentDoc,
          fragmentName: 'ConversationMessage',
          id: botAnswerId,
        }, answer => {
          if (answer) {
            return {
              ...answer,
              content: `${answer.content}${stream}`,
              // Remove "Thinking..." state.
              _isPending: false,
            };
          }
          return answer;
        });
        const element = document.querySelector<HTMLLIElement>(`[data-id="${botAnswerId}"]`);
        if (element) {
          // Ensures it snaps at the bottom of the element as the scroll can happen before the render of the stream.
          element.style.scrollPaddingBlockEnd = '0';
          scrollIntoView(element, { block: 'end' });
          requestAnimationFrame(() => {
            if (element) {
              element.style.scrollPaddingBlock = '';
            }
          });
        }

        if (conversationId) {
          askParameters.set(prev => ({
            ...prev,
            [conversationId]: {
              ...prev[conversationId],
              ...parameters && {
                parameters: {
                  feature: parameters.feature,
                  fromDate: parameters.fromDate,
                  toDate: parameters.toDate,
                  companies: parameters.companies?.map(companyAnswer => ({
                    name: companyAnswer.name,
                    company: companyAnswer.company?.__typename === 'Company' ? {
                      id: companyAnswer.company.id,
                      logo: companyAnswer.company.logo,
                    } : null,
                  })) || [],
                },
              },
              ...docIds && { docs: docIds },
            },
          }));
        }

        askVar.set(prev => ({
          ...prev,
          progress,
        }));
      }
    },
  });
};
