import { ThemeType, UpdateProductOnboardingDocument } from '@cycle-app/graphql-codegen';
import { Button } from '@cycle-app/ui';
import { MoreHorizCircleIcon, NotebookIcon, UserSearchIcon, ReloadIcon, ListSparkleIcon } from '@cycle-app/ui/icons';
import { motion } from 'framer-motion';
import { useState } from 'react';
import { twJoin } from 'tailwind-merge';

import { useSafeMutation } from 'src/hooks';
import { setOnboarding, useGetOnboarding } from 'src/reactives/lightOnboarding.reactive';
import { LightOnboardingScreen } from 'src/types/onboarding.types';

import { OnboardingLayout } from '../OnboardingLayout/OnboardingLayout';
import changelogDarkIllustration from './assets/changelog.dark.webp';
import changelogLightIllustration from './assets/changelog.light.webp';
import linearDarkIllustration from './assets/linear.dark.webp';
import linearLightIllustration from './assets/linear.light.webp';
import otherDarkIllustration from './assets/other.dark.webp';
import otherLightIllustration from './assets/other.light.webp';
import sourceDarkIllustration from './assets/source.dark.webp';
import sourceLigthIllustration from './assets/source.light.webp';
import userResearchDarkIllustration from './assets/userResearch.dark.webp';
import userResearchLightIllustration from './assets/userResearch.light.webp';

const asideIllustrations = {
  [LightOnboardingScreen.Sources]: {
    light: (
      <img
        src={sourceLigthIllustration}
        className="size-full object-contain"
        loading="lazy"
      />
    ),
    dark: (
      <img
        src={sourceDarkIllustration}
        className="size-full object-contain"
        loading="lazy"
      />
    ),
  },
  [LightOnboardingScreen.Linear]: {
    light: (
      <img
        src={linearLightIllustration}
        className="size-full object-contain"
        loading="lazy"
      />
    ),
    dark: (
      <img
        src={linearDarkIllustration}
        className="size-full object-contain"
        loading="lazy"
      />
    ),
  },
  [LightOnboardingScreen.UserResearch]: {
    light: (
      <img
        src={userResearchLightIllustration}
        className="size-full object-contain"
        loading="lazy"
      />
    ),
    dark: (
      <img
        src={userResearchDarkIllustration}
        className="size-full object-contain"
        loading="lazy"
      />
    ),
  },
  [LightOnboardingScreen.Changelog]: {
    light: (
      <img
        src={changelogLightIllustration}
        className="size-full object-contain"
        loading="lazy"
      />
    ),
    dark: (
      <img
        src={changelogDarkIllustration}
        className="size-full object-contain"
        loading="lazy"
      />
    ),
  },
  [LightOnboardingScreen.Done]: {
    light: (
      <img
        src={otherLightIllustration}
        className="size-full object-cover object-left-top"
        loading="lazy"
      />
    ),
    dark: (
      <img
        src={otherDarkIllustration}
        className="size-full object-cover object-left-top"
        loading="lazy"
      />
    ),
  },
} as const;

const selectOptions = [
  {
    value: LightOnboardingScreen.Sources,
    label: 'Process large volumes of product feedback',
    icon: <ListSparkleIcon />,
  },
  {
    value: LightOnboardingScreen.Linear,
    label: 'Sync customer feedback with your issue tracker',
    icon: <ReloadIcon />,
  },
  {
    value: LightOnboardingScreen.UserResearch,
    label: 'Centralize and automate your user research',
    icon: <UserSearchIcon />,
  },
  {
    value: LightOnboardingScreen.Changelog,
    label: 'Communicate your release notes with customers',
    icon: <NotebookIcon />,
  },
  {
    value: LightOnboardingScreen.Done,
    label: 'Other',
    icon: <MoreHorizCircleIcon />,
  },
] as const;

type SelectOptionsValue = typeof selectOptions[number]['value'];

export const OnboardingStepFlowSelection = ({ productId }: { productId: string }) => {
  const { theme } = useGetOnboarding();
  const [updateProduct, { loading }] = useSafeMutation(UpdateProductOnboardingDocument);
  const [hoveredOption, setHoveredOption] = useState<SelectOptionsValue>();
  const [selectedOption, setSelectedOption] = useState<SelectOptionsValue>();
  const [customOption, setCustomOption] = useState('');

  const displayedOption = hoveredOption || selectedOption;
  const illustrationTheme = theme === ThemeType.Sunrise ? 'light' : 'dark';

  const handleSubmit = async () => {
    if (!selectedOption) return;

    if (selectedOption === LightOnboardingScreen.Done && !customOption.trim()) return;

    await updateProduct({
      variables: {
        productId,
        start: selectedOption === LightOnboardingScreen.Done ? customOption.trim() : selectedOption,
      },
    });

    setOnboarding({ screen: selectedOption });
  };

  const isSubmitValid = !!selectedOption && (selectedOption !== LightOnboardingScreen.Done || customOption);

  return (
    <OnboardingLayout
      progressAbsolute
      title="What do you want to start in Cycle with?"
      aside={(
        <motion.div
          key={displayedOption}
          className="absolute inset-0 z-0 opacity-0"
          animate={{ opacity: 1 }}
        >
          {asideIllustrations[displayedOption ?? LightOnboardingScreen.Done][illustrationTheme]}
        </motion.div>
      )}
      main={(
        <form onSubmit={async e => {
          e.preventDefault();
          await handleSubmit();
        }}
        >
          <div
            className="mt-8 flex flex-col gap-4"
            onMouseLeave={() => setHoveredOption(undefined)}
          >
            {selectOptions.map((selectOption) => {
              const isSelected = selectOption.value === selectedOption;
              return (
                <label
                  key={selectOption.value}
                  className={twJoin(
                    'cursor-pointer rounded-lg has-[[type="radio"]:focus-visible]:ring',
                    isSelected ? 'border-2 border-cycle' : 'border border-grey-200 p-px dark:border-grey-850',
                    (selectOption.value !== LightOnboardingScreen.Done || selectedOption !== LightOnboardingScreen.Done) &&
                      'hover:bg-grey-150 dark:hover:bg-grey-850',
                  )}
                  onMouseEnter={() => setHoveredOption(selectOption.value)}
                >
                  <input
                    name="flowSelection"
                    className="sr-only"
                    type="radio"
                    onChange={() => setSelectedOption(selectOption.value)}
                  />

                  {(selectedOption === LightOnboardingScreen.Done && isSelected) ? (
                    <div className="px-3.5 py-3">
                      <input
                        autoFocus
                        className="w-full rounded-md bg-grey-150 px-3 py-1.5 text-body focus:outline-hidden dark:bg-grey-850"
                        placeholder="Tell us more..."
                        value={customOption}
                        onChange={e => setCustomOption(e.target.value)}
                      />
                    </div>
                  ) : (
                    <div className="flex items-center gap-2.5 px-3.5 py-3 text-body font-medium text-primary">
                      {selectOption.icon}
                      {selectOption.label}
                    </div>
                  )}
                </label>
              );
            })}
          </div>

          <div className="mt-8 flex justify-end">
            <Button
              disabled={!isSubmitValid}
              type="submit"
              isLoading={loading}
              size="M"
            >
              Next
            </Button>
          </div>
        </form>
      )}
    />
  );
};
