import { Variants } from 'framer-motion';

export const variantsContent: Variants = {
  enter: {
    opacity: 1,
    scale: 1,
    translateY: 0,
    transition: {
      duration: 0.2,
    },
  },
  exit: {
    opacity: 0,
    scale: 0.98,
    translateY: 15,
    transition: {
      duration: 0.2,
    },
  },
};

export const variantsContentFromLeft: Variants = {
  enter: {
    opacity: 1,
    scale: 1,
    translateX: 0,
    transition: {
      duration: 0.2,
    },
  },
  exit: {
    opacity: 0,
    scale: 0.98,
    translateX: 15,
    transition: {
      duration: 0.2,
    },
  },
};
