import { Color, ViewType } from '@cycle-app/graphql-codegen';
import {
  MovingBorderLoader, boxShadowZ1, typo, truncate, ViewCard, colorUtils,
} from '@cycle-app/ui';
import styled, { css } from 'styled-components';

import { DOC_ITEM_KABAN_WIDTH } from 'src/constants/board.constant';

function getRotate(direction = 'right'): number {
  return direction === 'right' ? 2 : -2;
}

interface ContainerProps {
  grabbing?: boolean;
  locked?: boolean;
}

const getLinkCursor = ({
  grabbing, locked,
}: ContainerProps): string => {
  if (grabbing) return 'grabbing';
  if (locked) return 'default';
  return 'pointer';
};

export const PlaceholderMulti = styled.div<{ direction?: 'left' | 'right' }>`
  position: absolute;
  top: 4px;
  left: 4px;

  width: 100%;
  height: 100%;
  border-radius: 4px;
  border: 1px solid hsla(var(--cycle), 0.15);
  background-color: hsla(var(--cycle), 0.1);

  transform: translate3d(10px, 0, 0) rotate(${p => getRotate(p.direction)}deg);
`;

export const CoverLoader = styled(MovingBorderLoader)`
  position: absolute;
  z-index: 10;
  top: -1px;
  left: -1px;
`;

export const DropzoneContainer = styled.div`
  position: relative;
`;

export const Viewers = styled.div`
  z-index: 1;
  position: absolute;
  right: 2px;
  top: -5px;

  display: flex;
  align-items: center;
  gap: 3px;
`;

export const Viewer = styled.div<{ bgColor: Color }>`
  border-radius: 50%;
  width: 8px;
  height: 8px;
  background-color: ${p => colorUtils.transparentize(p.theme.nuances[p.bgColor].main, 0.5)};

  transition: transform .2s ease-in-out;
  &:hover {
    transform: scale(1.5);
  }
`;

export const BulkContainer = styled.div<{ $viewType: ViewType }>`
  position: absolute;
  z-index: 1;
  top: ${p => (p.$viewType === ViewType.List ? '50%' : '1px')};
  left: -19px;
`;

interface BulkCheckboxProps {
  viewType?: ViewType;
  isSelected?: boolean;
}
export const BulkCheckbox = styled.button<BulkCheckboxProps>`
  padding: 3px;

  ${p => p.viewType === 'LIST' && css`
    top: 50%;
    transform: translateY(-50%);
  `}

  display: flex;
  align-items: center;
  justify-content: center;

  width: 16px;
  height: 16px;
  border-radius: 4px;
  cursor: pointer;


  svg {
    width: 8px;
    height: 8px;
  }
  div {
    display: flex;
  }

  &:hover {
    opacity: 1;
  }

  ${p => p.isSelected && css`
    opacity: 1;
    background-color: hsl(var(--cycle));
    color: ${p.theme.colors.text.white};
  `};

  ${p => !p.isSelected && css`
    background-color: ${p.theme.colors.card.checkbox.bg};
    color: ${p.theme.colors.card.checkbox.color};
    border: 1px solid ${p.theme.colors.card.checkbox.border};
    &:hover {
      color: ${p.theme.colors.text.secondary};
    }
  `};

  ${boxShadowZ1};
`;

export const Container = styled.div<ContainerProps>`
  position: relative;
  a {
    cursor: ${(props) => getLinkCursor(props)};
  }
`;

export const TitleContainer = styled.div`
  overflow: hidden;
  width: 100%;
  max-width: 1000px;
`;

export const TitleContent = styled.span`
  ${truncate}
`;

export const Title = styled.span`
  ${typo.body500}
`;

export const Description = styled.span`
  margin-left: 8px;
  color: ${p => p.theme.colors.text.secondary};
`;

export const TooltipContent = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  ${typo.caption500}
`;

export const ParentContainer = styled.div<{ viewType: ViewType }>`
  ${p => p.viewType === ViewType.Kanban && css`
    max-width: 100%;
  `}

  > div {
    overflow: hidden;
  }
`;

export const CardContainer = styled.div<{
  $disableActions: boolean;
  $viewType: ViewType;
  $isDocOptionsVisible: boolean;
}>`
  ${p => p.$viewType === ViewType.Kanban && `
    width: ${DOC_ITEM_KABAN_WIDTH}px;
  `}

  ${p => p.$disableActions && `
    > * {
      pointer-events: none;
    }
    ${BulkContainer} {
      pointer-events: all;
    }
  `}

  --max-width: calc(100% - 26px);

  ${p => p.$isDocOptionsVisible && p.$viewType === ViewType.Kanban && `
    ${ParentContainer} {
      max-width: var(--max-width);
    }
  `};

  :hover {
    ${ParentContainer} {
      ${p => p.$viewType === ViewType.Kanban && css`
        max-width: var(--max-width);
      `};
    }
  }
`;

export const StyledViewCard = styled(ViewCard)<{
  $isNewInbox?: boolean;
}>`
  ${p => p.$isNewInbox && css`
    background: ${p.theme.colors.background.primary};
    border-radius: 8px;
    box-shadow: none;
    min-height: 44px;
    display: flex;
    justify-content: center;
    flex-direction: column;
  `};

  ${p => p.$isNewInbox && (p.asPlaceholder || p.isDragging) && css`
    background-color: ${p.theme.colors.background.secondary};
  `};
`;
