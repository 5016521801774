import { Icon } from '@cycle-app/ui';
import { useController } from 'react-hook-form';

import { ToggleDropdown } from 'src/components/DropdownLayer';

import { ColorInput } from '../shared/ColorInput';
import { useChangelogBuilderForm } from '../useChangelogBuilderForm';

export function ChangelogBuilderHeaderColors() {
  const { control } = useChangelogBuilderForm();

  const backgroundColorField = useController({
    control,
    name: 'backgroundColor',
  });

  const headersColorField = useController({
    control,
    name: 'headersColor',
  });

  const bodyColorField = useController({
    control,
    name: 'bodyColor',
  });

  const linkColorField = useController({
    control,
    name: 'linkColor',
  });

  const dividerColorField = useController({
    control,
    name: 'dividerColor',
  });

  return (
    <ToggleDropdown
      withWrapper={false}
      content={(
        <div className="grid w-80 grid-cols-2 items-center gap-x-2 gap-y-3 p-3">
          <div>
            Background
          </div>
          <ColorInput
            value={backgroundColorField.field.value}
            onChange={backgroundColorField.field.onChange}
          />

          <div>
            Headers
          </div>
          <ColorInput
            value={headersColorField.field.value}
            onChange={headersColorField.field.onChange}
          />

          <div>
            Body
          </div>
          <ColorInput
            value={bodyColorField.field.value}
            onChange={bodyColorField.field.onChange}
          />

          <div>
            Links
          </div>
          <ColorInput
            value={linkColorField.field.value}
            onChange={linkColorField.field.onChange}
          />

          <div>
            Divider
          </div>
          <ColorInput
            value={dividerColorField.field.value}
            onChange={dividerColorField.field.onChange}
          />
        </div>
      )}
      button={({ onClick }) => (
        <button
          type="button"
          className="btn-tertiary aria-expanded:btn-hover"
          onClick={onClick}
        >
          <Icon
            name="colors-fill"
            className="size-4"
          />
          Colors
        </button>
      )}
    />
  );
}
