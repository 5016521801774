import { truncate, AvatarLegacy } from '@cycle-app/ui';
import styled from 'styled-components';

import { Slot } from 'src/utils/customers.util';

export const StyledSlot = styled(Slot)`
  color: ${p => p.theme.colors.text.primary};
  width: 100%;
  overflow: hidden;
`;

export const Label = styled.div`
  ${truncate}
`;

export const StyledAvatar = styled(AvatarLegacy)`
  margin-right: 6px;
`;
