import { Popover, PopoverProps, Skeleton } from '@cycle-app/ui';
import { PropsWithChildren } from 'react';

import { DocAttributes } from 'src/components/DocAttributes';
import { useDocTitleFragment } from 'src/hooks/api/fragments/doc';
import { useDocBase } from 'src/hooks/api/useDoc';

type Props = Omit<PopoverProps, 'content'> & {
  feedbackDocId: string;
};

export const FeedbackPopover = ({
  feedbackDocId,
  children,
  ...props
}: PropsWithChildren<Props>) => {
  return (
    <Popover
      placement="top"
      withWrapper={false}
      interactive
      animation
      delay={[200, 0]}
      popperOptions={{
        strategy: 'fixed',
      }}
      {...props}
      content={<Content feedbackDocId={feedbackDocId} />}
    >
      {children}
    </Popover>
  );
};

const Content = ({ feedbackDocId }: { feedbackDocId: string }) => {
  const docTitle = useDocTitleFragment(feedbackDocId);
  return (
    <div
      className="relative min-w-80 max-w-96 cursor-auto rounded-[10px] border border-grey-200 bg-white p-3 text-primary shadow-z2 dark:border-grey-800 dark:bg-grey-850"
      onClick={e => e.stopPropagation()}
      onWheel={e => e.stopPropagation()}
    >
      <div className="text-body">
        {docTitle?.title ?? 'Untitled feedback doc'}
      </div>

      <FeedbackDocAttributes feedbackDocId={feedbackDocId} />
    </div>
  );
};

const FeedbackDocAttributes = ({ feedbackDocId }: { feedbackDocId: string }) => {
  const query = useDocBase(feedbackDocId);

  if (query.isLoading) return (
    <div className="flex h-5 items-center gap-1.5">
      <Skeleton className="h-4! w-20!" />
      <Skeleton className="h-4! w-20!" />
    </div>
  );

  if (!query.doc) return null;

  return (
    <DocAttributes
      className="mt-2"
      doc={query.doc}
      showDocId={false}
      showDocType={false}
      showStatus={true}
      readOnlyStatus
      hideStatusLabel
      showLinear={false}
      showAiState={false}
      showRelease={false}
      showSource={false}
      showProductAreas={true}
      context="doc-panel"
      appendTo="parent"
    />
  );
};
