import {
  Icon, Tag, HoverCardProps, HoverCard, HoverCardGroup, HoverCardButtonGroup, useCloseHoverCard,
} from '@cycle-app/ui';
import { MouseEvent, PropsWithChildren } from 'react';

import { PageId } from 'src/constants/routing.constant';
import { useDocProductAreas } from 'src/hooks/api/fragments/doc';
import { useProductAreaCategoryFragment } from 'src/hooks/api/fragments/productArea';
import { useProductAreaAttribute } from 'src/hooks/api/useAttributes';
import { openProductAreaModal } from 'src/hooks/modals/useProductAreaModal';
import { useNavigateToSettings } from 'src/hooks/useNavigateToSettings';

type Props = Omit<HoverCardProps, 'content'> & {
  docId: string;
  onClickChange?: (e: MouseEvent) => void;
};

export const ProductAreasHoverCard = ({
  docId,
  onClickChange,
  children,
  ...props
}: PropsWithChildren<Props>) => {
  return (
    <HoverCard
      {...props}
      content={(
        <Content
          docId={docId}
          onClickChange={onClickChange}
        />
      )}
    >
      {children}
    </HoverCard>
  );
};

const Content = ({
  docId, onClickChange,
}: {
  docId: string;
  onClickChange?: (e: MouseEvent) => void;
}) => {
  const close = useCloseHoverCard();
  const { navigate: navigateToSettings } = useNavigateToSettings();
  const docProductAreas = useDocProductAreas(docId);
  const { attributeNamePlural } = useProductAreaAttribute();
  if (!docProductAreas) return null;
  const firstProductArea = docProductAreas[0];
  return (
    <>
      <HoverCardGroup className="flex flex-col gap-2 shy-scrollbar max-h-48 pb-2">
        {docProductAreas.length === 0 && (
          <span className="text-disabled">
            {`No selected ${attributeNamePlural}`}
          </span>
        )}

        {docProductAreas.map(productArea => (
          <div key={productArea.id}>
            <div>
              <Icon
                name="tri-shapes"
                className="text-disabled !inline align-sub"
              />
              <span className="font-medium ml-1.5 mr-2">
                {productArea.value}
              </span>
              <CategoryTag id={productArea.category?.id} />
            </div>

            {productArea.description && (
              <div className="text-secondary text-[13px]">
                {productArea.description}
              </div>
            )}
          </div>
        ))}
      </HoverCardGroup>

      <HoverCardButtonGroup>
        {docProductAreas.length === 1 && firstProductArea && (
          <button
            className="btn-tertiary"
            onClick={() => {
              close();
              openProductAreaModal(firstProductArea.id);
            }}
          >
            <Icon
              name="arrow-right-up"
              className="size-3.5"
            />
            Open
          </button>
        )}

        {onClickChange && (
          <button
            className="btn-tertiary"
            onClick={e => {
              close();
              onClickChange(e);
            }}
          >
            <Icon
              name="edit"
              className="size-3.5"
            />
            Change
          </button>
        )}

        <button
          className="btn-tertiary"
          onClick={() => navigateToSettings(PageId.SettingsProductHierarchy)}
        >
          <Icon
            name="settings"
            className="size-3.5"
          />
          Settings
        </button>
      </HoverCardButtonGroup>
    </>
  );
};

const CategoryTag = ({ id }: { id: string | undefined }) => {
  const category = useProductAreaCategoryFragment(id);
  const { categoryName } = useProductAreaAttribute();
  const value = category ? category.value : `No ${categoryName}`;
  return (
    <Tag
      className="!max-w-none !inline-flex"
      limitSize={false}
    >
      {value}
    </Tag>
  );
};
