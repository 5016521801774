import { TooltipLegacy, Shortcut, Icon } from '@cycle-app/ui';

import { ShortcutDocPanel, shortcuts } from 'src/constants/shortcuts.constants';
import useAppHotkeys from 'src/hooks/useAppHotkeys';

export const QuotePrevNext = ({
  isPrevDisabled, isNextDisabled, openPrev, openNext,
}: {
  isPrevDisabled: boolean;
  isNextDisabled: boolean;
  openPrev: () => void;
  openNext: () => void;
}) => {
  useAppHotkeys('k', openPrev, { enabled: () => !isPrevDisabled });
  useAppHotkeys('j', openNext, { enabled: () => !isNextDisabled });

  return (
    <>
      <TooltipLegacy
        content={(
          <Shortcut
            keys={shortcuts[ShortcutDocPanel.PrevDoc]}
            label="Previous"
          />
        )}
        withWrapper={false}
        withPortal
      >
        <button
          className="btn-tertiary btn-square"
          disabled={isPrevDisabled}
          onClick={openPrev}
        >
          <Icon name="up" />
        </button>
      </TooltipLegacy>

      <TooltipLegacy
        content={(
          <Shortcut
            keys={shortcuts[ShortcutDocPanel.NextDoc]}
            label="Next"
          />
        )}
        withWrapper={false}
        withPortal
      >
        <button
          className="btn-tertiary btn-square"
          disabled={isNextDisabled}
          onClick={openNext}
        >
          <Icon name="down" />
        </button>
      </TooltipLegacy>
    </>
  );
};
