import { Icon, IconName, Menu, DropdownMenu, ToggleInput } from '@cycle-app/ui';
import { ComponentProps, Fragment } from 'react';

import { useSectionFilter, setSectionFilter, SectionFilter } from 'src/reactives/commandbar.reactive';

import { FilterButton, ArrowIcon } from './CommandSectionFilter.styles';

type Option = {
  label: string;
  value: SectionFilter;
  iconName: IconName;
  selected: boolean;
  disabled: boolean;
};

export const CommandSectionFilter = (props: Pick<ComponentProps<typeof Menu>, 'open' | 'onOpenChange'>) => {
  const sectionFilter = useSectionFilter();

  const isSingle = Object.values(sectionFilter).filter(Boolean).length < 2;

  const options: Option[] = [
    {
      label: 'Feedback',
      value: 'feedback',
      iconName: 'feedback',
      selected: sectionFilter.feedback,
      disabled: sectionFilter.feedback && isSingle,
    },
    {
      label: 'Quotes',
      value: 'quotes',
      iconName: 'quote',
      selected: sectionFilter.quotes,
      disabled: sectionFilter.quotes && isSingle,
    },
    {
      label: 'Requests',
      value: 'features',
      iconName: 'feature',
      selected: sectionFilter.features,
      disabled: sectionFilter.features && isSingle,
    },
  ];

  return (
    <Menu
      {...props}
      side="bottom"
      align="start"
      className="w-50 z-10"
      onCloseAutoFocus={e => e.preventDefault()}
      trigger={(
        <FilterButton>
          {options.filter(option => option.selected).map(option => (
            <Fragment key={option.value}>
              <Icon
                name={option.iconName}
                className="size-3.5"
              />
              {isSingle && option.label}
            </Fragment>
          ))}
          <ArrowIcon size={16} />
        </FilterButton>
      )}
    >
      {options.map(option => (
        <DropdownMenu.Item
          key={option.value}
          disabled={option.disabled}
          onSelect={e => {
            e.preventDefault();
            setSectionFilter({ [option.value]: !sectionFilter[option.value] });
          }}
        >
          <Icon name={option.iconName} />
          {option.label}
          <ToggleInput
            id={option.value}
            checked={sectionFilter[option.value]}
            onChange={() => {}}
            className="ml-auto pointer-events-none"
          />
        </DropdownMenu.Item>
      ))}
    </Menu>
  );
};
