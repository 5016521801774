import { UpdateDocDoctypeDocument } from '@cycle-app/graphql-codegen';
import { Menu, DropdownMenu, Icon, Emoji } from '@cycle-app/ui';
import { useAtom, useAtomValue } from 'jotai';
import { useState } from 'react';

import { useDoctypeFragment } from 'src/hooks/api/fragments/doctype';
import { useProductDoctypesFragment } from 'src/hooks/api/fragments/product';
import useSafeMutation from 'src/hooks/useSafeMutation';
import { isFeature } from 'src/utils/docType.util';

import { draftRequestIdAtom, draftRequestTypeIdAtom } from './newRequestModal.atoms';

export const SelectRequestType = ({ disabled }: { disabled: boolean }) => {
  const draftRequestId = useAtomValue(draftRequestIdAtom);
  const [draftRequestTypeId, setDraftRequestTypeId] = useAtom(draftRequestTypeIdAtom);
  const [open, onOpenChange] = useState(false);
  const doctypes = useProductDoctypesFragment();
  const requestTypes = doctypes.filter(isFeature);
  const selected = useDoctypeFragment(draftRequestTypeId);
  const [updateDoctype] = useSafeMutation(UpdateDocDoctypeDocument);
  return (
    <Menu
      className="z-10 min-w-48"
      open={open}
      onOpenChange={onOpenChange}
      side="bottom"
      align="start"
      trigger={(
        <button
          className="btn-secondary btn-lg data-[state=open]:btn-hover group font-normal"
          disabled={disabled}
        >
          {selected && (
            <Emoji
              emoji={selected.emoji}
              size={14}
            />
          )}
          {selected?.name ?? 'Select request type...'}
          <Icon
            name="down"
            className="text-secondary size-3.5"
          />
        </button>
      )}
    >
      {requestTypes.map(doctype => (
        <DropdownMenu.Item
          key={doctype.id}
          onSelect={() => {
            setDraftRequestTypeId(doctype.id);
            if (!draftRequestId) return;
            void updateDoctype({
              variables: {
                docId: draftRequestId,
                doctypeId: doctype.id,
              },
            });
          }}
        >
          <Emoji
            emoji={doctype.emoji}
            size={14}
          />
          {doctype.name}
        </DropdownMenu.Item>
      ))}
    </Menu>
  );
};
