import { useLayoutEffect, useRef, useState } from 'react';

import { AnswerDoc } from './AnswerDoc';
import { Content, AnswerDocsContainer, AnswerDocsScrollable } from './Ask.styles';
import { DocsButton } from './DocsButton';
import { askParameters } from '../../reactives/ask.reactive';

export const AnswerDocs = ({
  conversationId, hide,
}: {
  conversationId: string;
  hide: VoidFunction;
}) => {
  const parameters = askParameters.hook();
  const docs = parameters[conversationId]?.docs || [];
  const scrollableRef = useRef<HTMLDivElement>(null);
  const [withGradientTop, setWithGradientTop] = useState(false);
  const [withBorderBottom, setWithBorderBottom] = useState(false);

  const checkGradientTop = () => {
    const el = scrollableRef.current as HTMLDivElement;
    if (el.scrollTop > 0 && !withGradientTop) setWithGradientTop(true);
    if (el.scrollTop === 0 && withGradientTop) setWithGradientTop(false);
  };

  const checkBorderBottom = () => {
    const el = scrollableRef.current as HTMLDivElement;
    const isOverflow = el.scrollHeight - el.scrollTop > el.clientHeight;
    if (isOverflow && !withBorderBottom) setWithBorderBottom(true);
    if (!isOverflow && withBorderBottom) setWithBorderBottom(false);
  };

  useLayoutEffect(() => {
    checkBorderBottom();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AnswerDocsContainer>
      <DocsButton
        docCount={docs.length}
        onClick={hide}
      />
      <Content
        $withBorder={withBorderBottom}
        $withGradient={withGradientTop}
      >
        <AnswerDocsScrollable
          ref={scrollableRef}
          onScroll={() => {
            checkGradientTop();
            checkBorderBottom();
          }}
          className="mb-4"
        >
          {docs.map(docId => (
            <AnswerDoc
              key={docId}
              docId={docId}
              rootRef={scrollableRef}
            />
          ))}
        </AnswerDocsScrollable>
      </Content>

    </AnswerDocsContainer>
  );
};
