import { TooltipLegacy } from '@cycle-app/ui';
import { motion } from 'framer-motion';
import { ReactNode } from 'react';
import { twJoin } from 'tailwind-merge';

export function PreviewTypeRadioGroup <T extends string>({
  items, value, onChange,
}: { items: { label: string; value: T; icon: ReactNode }[]; value: T; onChange: (value: T) => void }) {
  return (
    <div className="flex gap-0.5 rounded-lg bg-grey-150 p-0.5 dark:bg-grey-950">
      {items.map((item) => (
        <TooltipLegacy
          key={item.value}
          content={item.label}
          delay={[500, 0]}
          placement="bottom"
        >
          <label
            className={twJoin('group relative flex cursor-pointer rounded-md px-3 py-1.5 transition-colors',
              item.value !== value && 'text-grey-500 hover:bg-grey-100 dark:text-grey-600 dark:hover:bg-grey-850',
            )}
          >
            {item.value === value && (
              <motion.div
                className="absolute inset-0 rounded-inherit bg-white group-has-[:focus-visible]:ring dark:bg-grey-800"
                layout
                layoutId="bg"
                transition={{ duration: 0.2 }}
              />
            )}

            <div className="isolate leading-[0]">
              {item.icon}
            </div>

            <input
              type="radio"
              className="sr-only"
              checked={value === item.value}
              onChange={() => onChange(item.value)}
              name="preview-radio-group"
            />
          </label>
        </TooltipLegacy>
      ))}
    </div>
  );
}
