import { atom } from 'jotai';

import { atomWithDebounce } from 'src/utils/jotai/atomWithDebounce';

export const customersTabAtom = atom<'people' | 'companies'>('companies');

export const customersModalAtom = atom<'customer' | 'company' | null>(null);

export const [customersInputAtom, customersSearchAtom] = atomWithDebounce('');
customersInputAtom.debugLabel = 'customersInput';
customersSearchAtom.debugLabel = 'customersSearch';
customersSearchAtom.onMount = (set) => () => set('');
