import { motion } from 'framer-motion';
import { memo, useRef } from 'react';

import { FeatureDocPanelContent } from 'src/app/Main/Board/DocPanel/FeatureDocPanelContent';
import CoverImageInputFile from 'src/components/CoverImageInputFile/CoverImageInputFile';
import { ErrorBoundary } from 'src/components/ErrorBoundary';
import { ErrorPage } from 'src/components/ErrorPage';
import { Size } from 'src/components/ErrorPage/ErrorPage.types';
import { ExportQuotesFromDoc } from 'src/components/ExportQuotesModal';
import { BoardConfigContextProvider } from 'src/contexts/boardConfigContext';
import { DocPanelProvider } from 'src/contexts/docPanelContext';
import { useDoc } from 'src/hooks/api/useDoc';
import { useDocSubscription } from 'src/hooks/api/useDocSubscription';
import { useDocsPreview } from 'src/hooks/doc/useDocsPreview';
import { useParams } from 'src/hooks/router/useParams';
import { useDocPanelProps } from 'src/hooks/useDocPanelProps';
import { getDocId } from 'src/utils/slug.util';

export const ReleaseNoteDocPanel = memo(() => {
  const docId = getDocId(useParams().docSlug);

  const {
    doc: docBase, loading: isLoadindDocBase,
  } = useDoc(docId);

  const {
    doc,
    updateDocCover,
    setDocViewCoverInput,
    getDropzonePropsCover,
    isDraggingCover,
    isUploadingCover,
    onOpenCoverInputFile,
    onTitleUpdated,
  } = useDocPanelProps();

  useDocsPreview();
  useDocSubscription(docId);

  const containerRef = useRef<HTMLDivElement>(null);

  if (!docId) return null;

  const noDoc = !docBase && ! isLoadindDocBase;

  return (
    <motion.div
      className="absolute inset-0 z-10"
      initial="hidden"
      animate="visible"
      exit="hidden"
      variants={{
        visible: { x: 0 },
        hidden: { x: '100%' },
      }}
      transition={{
        type: 'spring',
        duration: 0.5,
        bounce: 0.2,
      }}
    >
      <BoardConfigContextProvider>
        <DocPanelProvider
          docId={docId}
          doc={doc}
        >
          <div
            ref={containerRef}
            className="relative flex h-full bg-white dark:bg-grey-950"
          >
            {noDoc ? (
              <ErrorPage
                size={Size.SMALL}
                message="Oops, this doc no longer exists"
              />
            ) : (
              <ErrorBoundary>
                <FeatureDocPanelContent
                  docId={docId}
                  containerRef={containerRef}
                  getDropzonePropsCover={getDropzonePropsCover}
                  isDraggingCover={isDraggingCover}
                  isUploadingCover={isUploadingCover}
                  onTitleUpdated={onTitleUpdated}
                  onOpenCoverInputFile={onOpenCoverInputFile}
                  focusOnMount={false}
                />
              </ErrorBoundary>
            )}
          </div>

          <CoverImageInputFile
            ref={(r) => r && setDocViewCoverInput(r)}
            onCoverChanged={updateDocCover}
          />
        </DocPanelProvider>
        <ExportQuotesFromDoc docId={docId} />
      </BoardConfigContextProvider>
    </motion.div>
  );
});
