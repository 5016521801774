import { Icon } from '@cycle-app/ui';
import { Link, useLocation } from 'react-router-dom';

import { PageId } from 'src/constants/routing.constant';
import { getUrl } from 'src/utils/routing.utils';

import { ChangelogPreview } from '../preview';

export function ChangelogBuilderPreviewButton() {
  const { search } = useLocation();

  const isPreviewVisible = new URLSearchParams(search).has('preview');

  return (
    <>
      <Link
        to={{
          pathname: getUrl(PageId.Changelog),
          search: 'preview',
        }}
        className="btn-tertiary btn-lg"
      >
        <Icon name="play-fill" />
        Preview
      </Link>

      {isPreviewVisible && (
        <ChangelogPreview />
      )}
    </>
  );
}
