import { DropdownMenu } from 'radix-ui';
import { forwardRef } from 'react';
import { twJoin, twMerge } from 'tailwind-merge';

import { Icon } from '../Icon';

type Props = Omit<DropdownMenu.DropdownMenuSubTriggerProps, 'asChild'> & {
  open: boolean;
};

export const SubTrigger = forwardRef<HTMLDivElement, Props>(
  function SubTrigger(props, ref) {
    const {
      children, open, className,
    } = props;
    return (
      <DropdownMenu.SubTrigger
        ref={ref}
        {...props}
        asChild
        className={twMerge(
          'group flex items-center justify-between gap-2 pl-1.5 rounded h-8 data-[highlighted]:bg-grey-100 cursor-pointer dark:data-[highlighted]:bg-grey-800 flex-none',
          className,
        )}
      >
        <div>
          {children}
          <Icon
            name="chevron-left"
            direction="left"
            className={twJoin('size-5 rotate-y-180 ', open ? 'text-body' : 'text-disabled')}
          />
        </div>
      </DropdownMenu.SubTrigger>
    );
  },
);
