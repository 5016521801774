import { typo, boxShadowZ3, colorUtils } from '@cycle-app/ui';
import { Color } from '@cycle-app/ui/theme/baseColors';
import styled from 'styled-components';

import { RIGHT_PANEL_WIDTH } from 'src/constants/docPanel.constants';

export const Container = styled.div`
  width: ${RIGHT_PANEL_WIDTH}px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 32px;
`;

export const List = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 20px;
`;

export const Item = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  ${typo.body500};
`;

export const Label = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  color: ${p => p.theme.colors.text.secondary};
  user-select: text;
`;

export const Value = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  color: ${p => p.theme.colors.text.primary};
  user-select: text;
  overflow: hidden;
  > span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export const ActivityContainer = styled.div`
  height: 270px;
  padding: 36px 24px 32px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${p => (p.theme.isDark ? colorUtils.lighten(Color.Grey900, 0.02) : p.theme.colors.background.secondary)};
  color: ${p => p.theme.colors.text.secondary};
  ${typo.body400};
  strong {
    ${typo.body500};
    color: ${p => p.theme.colors.text.primary};
  }
`;

export const ActivityTitle = styled.p`
  margin-top: 15px;
  ${typo.body500};
  color: ${p => p.theme.colors.text.primary};
`;

export const ActivityItem = styled.div`
  display: flex;
  align-items: center;
  ${typo.body400};

  svg {
    margin-right: 12px;
    color: ${p => p.theme.colors.text.secondary};
  }

  & + & {
    margin-top: 16px;
  }
`;

export const FakeActivities = styled.div`
  position: relative;
  margin-top: 27px;
`;

export const Blur = styled.div`
  filter: blur(7px);

  > * {
    opacity: 0.6;
  }
`;

export const ComingSoon = styled.div`
  ${boxShadowZ3}
  background-color: ${p => (p.theme.isDark ? p.theme.baseColors.Grey800 : p.theme.colors.background.primary)};
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 4px;
  padding: 4px 14px;
  ${typo.body500}

  color: ${p => p.theme.colors.text.primary};
  transform: translate(-50%, -50%);
  white-space: nowrap;
`;
