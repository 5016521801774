import { Role, ThemeType } from '@cycle-app/graphql-codegen';
import { Icon, DropdownMenu, Tooltip, ProductLogo } from '@cycle-app/ui';
import { useRef, useState } from 'react';
import { twJoin } from 'tailwind-merge';

import MyAvatar from 'src/components/MyAvatar/MyAvatar';
import { PageId } from 'src/constants/routing.constant';
import { Shortcut, shortcuts } from 'src/constants/shortcuts.constants';
import { useMaybeMeV2 } from 'src/hooks/api/useMe';
import { useProductBase } from 'src/hooks/api/useProduct';
import { openCommandBar } from 'src/hooks/modals/useCommandBarModal';
import { useRouteAccess } from 'src/hooks/router/useRouteAccess';
import { useLogout } from 'src/hooks/useLogout';
import { useNavigateToSettings } from 'src/hooks/useNavigateToSettings';
import { useProductAddOn } from 'src/hooks/useProductAddOns';
import { useSidebarCollapsed } from 'src/hooks/useSidebarCollapsed';
import { setLimitationsModal } from 'src/reactives/limitationsModal.reactive';
import { getPermission, useGetPermission } from 'src/reactives/permission.reactive';
import { useIsMobile } from 'src/reactives/responsive.reactive';
import { toggleSettingsModal } from 'src/reactives/settingsModal.reactive';
import { toggleTheme, useGetThemeConfig } from 'src/reactives/theme.reactive';

import { WorkspaceList } from './WorkspaceList';

export const MainMenu = () => {
  const collapsed = useSidebarCollapsed();
  const [open, onOpenChange] = useState(false);
  return (
    <DropdownMenu.Root
      open={open}
      onOpenChange={onOpenChange}
    >
      <MenuTrigger />
      <DropdownMenu.Portal>
        <DropdownMenu.Content
          open={open}
          side={collapsed ? 'right' : 'bottom'}
          className="w-3xs z-199"
        >
          <MenuHeader />
          <DropdownMenu.Separator />
          <ItemAccountSettings />
          <ItemSwitchTheme />
          <DropdownMenu.Separator />
          <ItemWorkspaceSettings />
          <ItemSwitchWorkspace />
          <ItemAddWorkSpace />
          <DropdownMenu.Separator />
          <ItemLogout />
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
};

const MenuTrigger = () => {
  const btnRef = useRef<HTMLButtonElement>(null);
  const collapsed = useSidebarCollapsed();
  const product = useProductBase();
  return (
    <Tooltip
      content={product?.name}
      side="right"
      disabled={!collapsed || !product}
    >
      <DropdownMenu.Trigger
        ref={btnRef}
        className={twJoin(
          'btn-tertiary h-7 max-w-full gap-2 group data-[state=open]:btn-hover transition-none px-1',
          collapsed && 'btn-square size-7',
        )}
      >
        <ProductLogo
          url={product?.logo?.url}
          alt={product?.name}
          className="size-5"
        />

        {!collapsed && (
          <>
            <h1 className="select-none truncate text-body font-medium leading-tight text-primary">
              {product?.name}
            </h1>
            <Icon
              name="down"
              className="text-secondary size-3.5"
            />
          </>
        )}
      </DropdownMenu.Trigger>
    </Tooltip>
  );
};

const MenuHeader = () => {
  const { me } = useMaybeMeV2();
  if (!me) return null;
  return (
    <div className="flex items-center gap-2 p-1.5">
      <MyAvatar />
      <div className="flex flex-col gap-0.5 overflow-hidden">
        <div className="select-text truncate font-medium leading-tight">
          {`${me.firstName} ${me.lastName}`}
        </div>
        <div className="select-text truncate text-caption leading-tight text-secondary">
          {me.email}
        </div>
      </div>
    </div>
  );
};

const ShortcutKeys = ({ keys }: { keys: string[] }) => (
  <span className="text-[13px] text-disabled">
    {keys.map(key => key === ',' ? 'then' : key.toUpperCase()).join(' ')}
  </span>
);

const ItemAccountSettings = () => {
  const isMobile = useIsMobile();
  if (isMobile) return null;
  return (
    <DropdownMenu.Item onSelect={toggleSettingsModal}>
      Account settings
    </DropdownMenu.Item>
  );
};

const ItemSwitchTheme = () => {
  const { colorTheme } = useGetThemeConfig();
  return (
    <DropdownMenu.Item
      className="justify-between"
      onSelect={e => {
        e.preventDefault();
        toggleTheme();
      }}
    >
      {colorTheme === ThemeType.Nightfall
        ? 'Switch to light mode'
        : 'Switch to dark mode'}
      <ShortcutKeys keys={shortcuts[Shortcut.SwitchTheme]} />
    </DropdownMenu.Item>
  );
};

const ItemWorkspaceSettings = () => {
  const isMobile = useIsMobile();
  const { navigate } = useNavigateToSettings();
  const hasBillingAccess = useRouteAccess(PageId.SettingsBilling);
  const { isPermissionInit } = useGetPermission();
  if (isMobile || !isPermissionInit) return null;
  return (
    <DropdownMenu.Item
      className="justify-between"
      onSelect={() => {
        if (getPermission().canReadSettings) {
          navigate(PageId.Settings);
          return;
        }
        if (hasBillingAccess) {
          navigate(PageId.SettingsBilling);
          return;
        }
        setLimitationsModal({ action: 'SETTINGS_READ' });
      }}
    >
      Workspace settings
      <ShortcutKeys keys={shortcuts[Shortcut.GoToSettings]} />
    </DropdownMenu.Item>
  );
};

const ItemSwitchWorkspace = () => {
  const { me } = useMaybeMeV2();
  const sideOffset = useRef(Math.min(window.innerWidth - 600, 8));
  if (!me || me.products.edges.length < 2) return null;
  return (
    <DropdownMenu.SubMenu
      trigger="Switch workspace"
      className="w-sm p-0"
      sideOffset={sideOffset.current}
    >
      <WorkspaceList />
    </DropdownMenu.SubMenu>
  );
};

const ItemAddWorkSpace = () => {
  const { me } = useMaybeMeV2();
  const unlimitedWorkspacesAddon = useProductAddOn('unlimited-workspaces');
  return (
    <DropdownMenu.Item
      onSelect={() => {
        if (!unlimitedWorkspacesAddon.isEnabled && me?.role !== Role.SuperAdmin) {
          setLimitationsModal({
            action: 'USE_ADD_ON',
            brand: 'unlimited-workspaces',
          });
          return;
        }
        openCommandBar('create-workspace');
      }}
    >
      Add workspace
    </DropdownMenu.Item>
  );
};

const ItemLogout = () => {
  const logout = useLogout();
  return (
    <DropdownMenu.Item
      className="data-[highlighted]:bg-red-100 data-[highlighted]:text-red-500 dark:data-[highlighted]:bg-red-700/25 dark:data-[highlighted]:text-primary"
      onSelect={logout}
    >
      Log out
    </DropdownMenu.Item>
  );
};
