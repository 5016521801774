import { MateBaseFragment, ReleasePublicStatus, ReleaseNoteMakerBaseFragment } from '@cycle-app/graphql-codegen';
import { Icon, SelectPanel, SelectOption, Avatar, Tooltip } from '@cycle-app/ui';
import { useMemo, useState } from 'react';
import { twJoin } from 'tailwind-merge';

import { useActiveUsers } from 'src/hooks/api/useUsers';
import { useReleaseNote } from 'src/hooks/releases/useReleaseNote';
import { useReleaseNoteMakers } from 'src/hooks/releases/useReleaseNoteMakers';
import { useReleasePublicStatusUpdate } from 'src/hooks/releases/useReleasePublicStatusUpdate';
import { useUpdateReleaseNoteMakers } from 'src/hooks/releases/useUpdateReleaseNoteMakers';
import { Layer } from 'src/types/layers.types';

import { ReorderableMakers } from './ReorderableMakers';
import { ToggleDropdown } from '../DropdownLayer';

export function ReleaseNoteMakers({ releaseNoteId }: { releaseNoteId: string }) {
  const { updateReleaseNoteMakers } = useUpdateReleaseNoteMakers();
  const { releaseNote } = useReleaseNote(releaseNoteId);
  const { makers } = useReleaseNoteMakers(releaseNoteId);
  const { editPublished } = useReleasePublicStatusUpdate(releaseNote?.release.id);

  if (!releaseNote) return null;

  const hasMakers = makers.length > 0;

  const handleUpdateMakers = async (makerIds: string[]) => {
    if (releaseNote?.release.publicStatus === ReleasePublicStatus.Published) {
      await editPublished();
    }

    await updateReleaseNoteMakers(releaseNoteId, makerIds);
  };

  return (
    <div className="flex items-center gap-4">
      <ToggleDropdown
        withWrapper={false}
        placement="bottom-start"
        layer={Layer.DropdownModalZ1}
        content={(
          <ReleaseNoteMakersDropdownContent
            makers={makers}
            onUpdateMakers={handleUpdateMakers}
          />
        )}
        button={({
          ref, onClick,
        }) => (
          <div>
            <Tooltip content={hasMakers ? 'Add makers' : 'Who cooked this hot release note?'}>
              <button
                ref={ref}
                onClick={onClick}
                className={twJoin(
                  'rounded-full bg-grey-100 dark:bg-grey-800 hover:bg-grey-150 dark:hover:bg-grey-600 transition flex items-center justify-center cursor-pointer group gap-1 py-1.5 leading-0',
                  hasMakers ? 'px-1.5' : 'px-3',
                )}
              >
                {hasMakers ? (
                  <Icon
                    name="plus"
                    className="size-4"
                  />
                ) : (
                  <>
                    <Icon
                      name="emoji"
                      className="group-hover:-rotate-6 transition size-4 group-hover:scale-110"
                    />
                    Highlight makers
                  </>
                )}
              </button>
            </Tooltip>
          </div>
        )}
      />

      {hasMakers && (
        <ReorderableMakers
          makers={makers}
          onChange={(updatedMakers) => {
            void handleUpdateMakers(updatedMakers.map(m => m.maker.id));
          }}
        />
      )}
    </div>
  );
}

export function ReleaseNoteMakersDropdownContent({
  makers, onUpdateMakers,
}: { makers: ReleaseNoteMakerBaseFragment[]; onUpdateMakers: (makerIds: string[]) => Promise<void> }) {
  const [search, setSearch] = useState('');

  const {
    users, loading, hasNextPage, loadMore, loadingMore,
  } = useActiveUsers(search);

  const options: SelectOption[] = useMemo(() => users.map(user => ({
    value: user.id,
    label: getMakerLabel(user),
    icon: (
      <Avatar
        name={user.firstName || user.email}
        src={user.avatar?.url}
        accentColor={user.color}
        className="size-6 shrink-0"
      />
    ),
    selected: makers.some(maker => maker.maker.id === user.id) || false,
  })), [users, makers]);

  const handleSelectOption = async (option: SelectOption) => {
    try {
      // Get current makers and append the new one
      await onUpdateMakers([...makers.map(m => m.maker.id), option.value]);
    } catch (error) {
      console.error('Failed to add maker:', error);
    }
  };

  const handleUnselectOption = async (option: SelectOption) => {
    try {
      // Get current makers and remove the unselected one
      const currentMakerIds = makers.map(m => m.maker.id);
      await onUpdateMakers(currentMakerIds.filter(id => id !== option.value));
    } catch (error) {
      console.error('Failed to remove maker:', error);
    }
  };

  return (
    <SelectPanel
      className="w-64"
      isMulti
      options={options}
      onSelectOption={handleSelectOption}
      onUnselectOption={handleUnselectOption}
      searchPlaceholder="Search users..."
      hideSearch={options.length <= 5}
      onSearchChange={setSearch}
      isLoading={loading}
      infiniteScroll={{
        isLoading: loadingMore,
        hasMoreData: hasNextPage,
        loadMore,
      }}
    />
  );
}

export function getMakerLabel(maker?: MateBaseFragment) {
  if (!maker) return '';

  if (maker.firstName) {
    return `${maker.firstName} ${maker.lastName}`;
  }

  return maker.email;
}
