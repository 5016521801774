import { Icon } from '@cycle-app/ui';

export const integrations = [
  {
    id: 'linear',
    icon: (
      <Icon
        name="brand/linear"
        className="size-4"
      />
    ),
    label: 'Linear',
  },
  {
    id: 'jira',
    icon: (
      <Icon
        name="brand/jira"
        className="size-4"
      />
    ),
    label: 'Jira',
  },
  {
    id: 'jira-product-discovery',
    icon: (
      <Icon
        name="brand/jira-product-discovery"
        className="size-4"
      />
    ),
    label: 'Jira Product Discovery',
  },
  {
    id: 'github',
    icon: (
      <Icon
        name="brand/github"
        className="size-4"
      />
    ),
    label: 'Github',
  },
  {
    id: 'notion',
    icon: (
      <Icon
        name="brand/notion"
        className="size-4"
      />
    ),
    label: 'Notion',
  },
  {
    id: 'gitlab',
    icon: (
      <Icon
        name="brand/gitlab"
        className="size-4"
      />
    ),
    label: 'Gitlab',
  },
  {
    id: 'shortcut',
    icon: (
      <Icon
        name="brand/shortcut"
        className="size-4"
      />
    ),
    label: 'Shortcut',
  },
  {
    id: 'asana',
    icon: (
      <Icon
        name="brand/asana"
        className="size-4"
      />
    ),
    label: 'Asana',
  },
  {
    id: 'clickup',
    icon: (
      <Icon
        name="brand/click-up"
        className="size-4"
      />
    ),
    label: 'ClickUp',
  },
  {
    id: 'monday',
    icon: (
      <Icon
        name="brand/monday"
        className="size-4"
      />
    ),
    label: 'Monday',
  },
  {
    id: 'airtable',
    icon: (
      <Icon
        name="brand/airtable"
        className="size-4"
      />
    ),
    label: 'Airtable',
  },
];

export const others = integrations.slice(5);

export const sliderItems = [
  {
    id: 'github',
    icon: (
      <Icon
        name="brand/github"
        className="size-4"
      />
    ),
    label: 'Github',
  },
  {
    id: 'jira',
    icon: (
      <Icon
        name="brand/jira"
        className="size-4"
      />
    ),
    label: 'Jira',
  },
  {
    id: 'linear',
    icon: (
      <Icon
        name="brand/linear"
        className="size-4"
      />
    ),
    label: 'Linear',
  },
  {
    id: 'notion',
    icon: (
      <Icon
        name="brand/notion"
        className="size-4"
      />
    ),
    label: 'Notion',
  },
  {
    id: 'jira-product-discovery',
    icon: (
      <Icon
        name="brand/jira-product-discovery"
        className="size-4"
      />
    ),
    label: 'Jira Product Discovery',
  },
  {
    id: 'gitlab',
    icon: (
      <Icon
        name="brand/gitlab"
        className="size-4"
      />
    ),
    label: 'Gitlab',
  },
  {
    id: 'shortcut',
    icon: (
      <Icon
        name="brand/shortcut"
        className="size-4"
      />
    ),
    label: 'Shortcut',
  },
];
