import { RemoveStatusDoctypeDocument, StatusFragment, ProductBySlugDocument } from '@cycle-app/graphql-codegen';
import { nodeToArray } from '@cycle-app/utilities';
import { produce } from 'immer';
import remove from 'lodash/remove';
import { useCallback } from 'react';

import { useProductBase } from 'src/hooks';
import useSafeMutation from 'src/hooks/useSafeMutation';

export const useRemoveStatusDocType = (status: StatusFragment, docTypeId: string) => {
  const product = useProductBase();

  const [mutate, result] = useSafeMutation(RemoveStatusDoctypeDocument);

  const removeStatusDocType = useCallback(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    mutate({
      variables: {
        statusId: status.id,
        doctypeId: docTypeId,
      },
      optimisticResponse: {
        removeStatusDoctype: produce(status, draft => {
          remove(draft.doctypes.edges, e => e.node.id === docTypeId);
        }),
      },
      update: (cache, { data }) => {
        if (!product?.slug) return;
        // Unlink the status to the doc type
        cache.updateQuery({
          query: ProductBySlugDocument,
          variables: {
            slug: product.slug,
          },
        }, prev => produce(prev, draft => {
          if (!draft) return;
          const docTypes = nodeToArray(draft.product?.doctypes);
          const docType = docTypes.find(d => d.id === docTypeId);
          if (!docType) return;
          docType.status.edges =
            docType.status.edges.filter(e => e.node.id !== data?.removeStatusDoctype?.id);
        }));
      },
    });
  }, [docTypeId, mutate, product?.slug, status]);

  return {
    removeStatusDocType,
    ...result,
  };
};
