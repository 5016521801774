import { TooltipLegacy } from '@cycle-app/ui';
import { PenFilledIcon } from '@cycle-app/ui/icons';
import { useState } from 'react';

import { useCompany } from 'src/hooks/api/queries/customers/useCompany';

import { CompanyNameForm } from './CompanyNameForm';
import { ProfileEditButton } from './Profile.styles';

type Props = {
  companyId: string;
};

export const CompanyName = ({ companyId }: Props) => {
  const [isEditing, setIsEditing] = useState(false);
  const { company } = useCompany(companyId);

  if (!company) return null;

  if (isEditing) {
    return (
      <CompanyNameForm
        companyId={companyId}
        name={company.name ?? ''}
        hide={() => setIsEditing(false)}
      />
    );
  }

  return (
    <>
      {company.name}
      {!company.isDefault && (
        <TooltipLegacy
          content="Edit name"
          placement="top"
          withPortal
          withWrapper={false}
        >
          <ProfileEditButton
            onClick={(e) => {
              e.preventDefault();
              setIsEditing(true);
            }}
          >
            <PenFilledIcon />
          </ProfileEditButton>
        </TooltipLegacy>
      )}
    </>
  );
};
