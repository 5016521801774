import { TooltipLegacy } from '@cycle-app/ui';

import { useOverflow } from 'src/hooks/useOverflow';

export const ViewName = ({ name }: { name: string }) => {
  const [ref, overflow] = useOverflow<HTMLDivElement>([name]);
  return (
    <TooltipLegacy
      content={name}
      placement="top"
      disabled={!overflow}
      withPortal
      withWrapper={false}
    >
      <div
        ref={ref}
        className="truncate"
      >
        {name}
      </div>
    </TooltipLegacy>
  );
};
