import { useCreateProductChannel, useProductBase } from 'src/hooks';
import { useAllNotificationReadSubscription } from 'src/hooks/api/useAllNotificationReadSubscription';
import { useCreatedDocSubscription } from 'src/hooks/api/useCreatedDocSubscription';
import { useCustomersImportedSubscription } from 'src/hooks/api/useCustomersImportedSubscription';
import { useDeletedDocSubscription } from 'src/hooks/api/useDeletedDocSubscription';
import { useDocCreatedfromFileSubscription } from 'src/hooks/api/useDocCreatedfromFileSubscription';
import { useMutationProgressSubscription } from 'src/hooks/api/useMutationProgressSubscription';
import { useNbActiveInsightsUpdatedSubscription } from 'src/hooks/api/useNbActiveInsightsUpdatedSubscription';
import { useNbAiQueriesUpdatedSubscription } from 'src/hooks/api/useNbAiQueriesUpdatedSubscription';
import { useNbMonthlyDocsUpdatedSubscription } from 'src/hooks/api/useNbMonthlyDocsUpdatedSubscription';
import { useNewNotificationSubscription } from 'src/hooks/api/useNewNotificationSubscription';
import { useProductSubscription } from 'src/hooks/api/useProductSubscription';
import { useConversationSubscription } from 'src/hooks/ask/useConversationSubscription';
import { usePrefetchMeetingsEvents } from 'src/hooks/integration/useMeetingsEvents';
import { useInitProductReactives } from 'src/hooks/useInitProductReactives';
import { useIntercom } from 'src/hooks/useIntercom';
import { useNewFeaturesToaster } from 'src/hooks/useNewFeaturesToaster';
import { useUpdatePlainCustomer, useUpdatePlainTheme } from 'src/hooks/useUpdatePlain';

export const MainLayoutListeners = () => {
  const product = useProductBase();
  // TODO: Check where the subscription should be
  useProductSubscription(product?.id);
  useNbMonthlyDocsUpdatedSubscription(product?.id);
  useNbActiveInsightsUpdatedSubscription(product?.id);
  useNbAiQueriesUpdatedSubscription(product?.id);
  useCustomersImportedSubscription(product?.id);
  useMutationProgressSubscription();
  useCreatedDocSubscription(product?.id);
  useDeletedDocSubscription(product?.id);
  useNewNotificationSubscription();
  useAllNotificationReadSubscription(product?.id);
  useDocCreatedfromFileSubscription();
  useNewFeaturesToaster();
  useInitProductReactives();
  useCreateProductChannel();
  usePrefetchMeetingsEvents();
  useConversationSubscription();

  return (
    <>
      {import.meta.env.VITE_INTERCOM_KEY && <IntercomListeners />}
      {import.meta.env.VITE_PLAIN_KEY && <PlainListeners />}
    </>
  );
};

const IntercomListeners = () => {
  useIntercom();
  return null;
};

const PlainListeners = () => {
  useUpdatePlainTheme();
  useUpdatePlainCustomer();
  return null;
};
