import { Icon } from '@cycle-app/ui';
import { Controller } from 'react-hook-form';

import { ACCEPTED_LOGO_FILE_FORMATS } from '../constants/imageFileFormats';
import { ChangelogDropdown, ChangelogDropdownContent } from '../shared/ChangelogDropdown';
import { ChangelogInputImage, getImageUrl } from '../shared/ChangelogInputImage';
import { useChangelogBuilderForm } from '../useChangelogBuilderForm';

export function ChangelogBuilderHeaderFavicon() {
  const { watch } = useChangelogBuilderForm();
  const faviconUrl = getImageUrl(watch('favicon'));

  return (
    <ChangelogDropdown
      hoverStateClassName="-mx-3 -my-2.5 px-3 py-2.5 rounded-xl"
      content={({ hide }) => (
        <ChangelogDropdownContent
          title="Favicon"
          hide={hide}
        >
          <FaviconPopoverContent hide={hide} />
        </ChangelogDropdownContent>

      )}
      empty={!faviconUrl}
    >
      <div className="flex size-6 items-center justify-center text-(--changelog-body-color)">
        {faviconUrl
          ? (
            <img
              className="size-full object-contain"
              src={faviconUrl}
              loading="lazy"
            />
          )
          : (
            <Icon
              name="plus"
              className="size-4"
            />
          )}
      </div>
    </ChangelogDropdown>
  );
}

function FaviconPopoverContent({ hide }: { hide: VoidFunction }) {
  const {
    control, setValue,
  } = useChangelogBuilderForm();

  const handleRemoveLogo = () => {
    setValue('favicon', null, { shouldDirty: true });
    hide();
  };

  return (
    <div>
      <Controller
        control={control}
        name="favicon"
        render={({ field }) => (
          <ChangelogInputImage
            value={field.value}
            onChange={field.onChange}
            accept={ACCEPTED_LOGO_FILE_FORMATS}
            className="h-24"
          />
        )}
      />
      <button
        onClick={handleRemoveLogo}
        className="btn-tertiary btn-lg mt-4 w-full"
      >
        Remove favicon
      </button>
    </div>
  );
}
