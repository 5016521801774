import { Icon, Menu, DropdownMenu } from '@cycle-app/ui';
import { useState } from 'react';
import { twJoin } from 'tailwind-merge';

import { CustomersAddCustomerModal } from 'src/components/CustomersList/CustomersAddCustomerModal';
import { NewRequestModal } from 'src/components/NewRequestModal/NewRequestModal';
import { useNewRequestModal } from 'src/components/NewRequestModal/useNewRequestModal';
import { NewReleaseModal } from 'src/components/ReleaseModals';
import { shortcuts } from 'src/constants/shortcuts.constants';
import { useHotkeyListener } from 'src/hooks/useHotkeyListener';
import { useSidebarCollapsed } from 'src/hooks/useSidebarCollapsed';
import { getBoardGroupState } from 'src/reactives/boardGroup.reactive';
import { getBoardNewDocPositionState } from 'src/reactives/boardNewDoc/newDocPosition.reactive';
import { setCreateDoc, getCreateDoc } from 'src/reactives/createDoc.reactive';
import { getFeedback } from 'src/reactives/docTypes.reactive';
import { getLayer } from 'src/reactives/layer.reactive';
import { setLimitationsModal } from 'src/reactives/limitationsModal.reactive';
import { getPermission } from 'src/reactives/permission.reactive';
import { Layer } from 'src/types/layers.types';
import { Shortcut, ShortcutBoard, AnyShortcut } from 'src/types/shortcuts.types';

export const  NewButton = () => {
  const collapsed = useSidebarCollapsed();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isReleaseModalOpen, setIsReleaseModalOpen] = useState(false);
  const [isCustomerModalOpen, setIsCustomerModalOpen] = useState(false);

  const {
    isRequestModalOpen, setIsRequestModalOpen, openRequestModal,
  } = useNewRequestModal();

  const createDoc = () => {
    const feedbackId = getFeedback()?.id;
    if (!feedbackId) return;
    setCreateDoc({
      modalVisible: true,
      doctypeId: feedbackId,
    });
  };

  const createRelease = () => {
    if (getPermission().canCreateRelease) {
      setIsReleaseModalOpen(true);
    } else {
      setLimitationsModal({ action: 'RELEASE_UPDATE' });
    }
  };

  const createCustomer = () => {
    setIsCustomerModalOpen(true);
  };

  useHotkeyListener({
    callbacks: {
      [ShortcutBoard.CreateDoc]: () => {
        if (getBoardGroupState().hoverGroupId) return;
        createDoc();
        setIsMenuOpen(false);
      },
      [Shortcut.CreateRequest]: () => {
        void openRequestModal();
        setIsMenuOpen(false);
      },
      [Shortcut.CreateRelease]: () => {
        createRelease();
        setIsMenuOpen(false);
      },
      [Shortcut.CreateCustomer]: () => {
        createCustomer();
        setIsMenuOpen(false);
      },
    },
    shortcuts: [
      ShortcutBoard.CreateDoc,
      Shortcut.CreateRequest,
      Shortcut.CreateRelease,
      Shortcut.CreateCustomer,
    ],
    disableOnLayers: [
      Layer.DocPanel,
      Layer.Dropdown,
      Layer.Modal,
      Layer.ModalZ2,
      Layer.ModalZ3,
      Layer.DialogModal,
      Layer.MaskCommandbar,
      Layer.Commandbar,
    ],
    preventCallback: () => (
      getBoardNewDocPositionState().draftPosition !== null ||
        getCreateDoc().modalVisible ||
        isRequestModalOpen ||
        isReleaseModalOpen
    ),
  });

  return (
    <>
      <Menu
        open={isMenuOpen}
        onOpenChange={setIsMenuOpen}
        align="start"
        side="right"
        className="w-56 font-medium"
        onCloseAutoFocus={e => {
          const layer = getLayer();
          if (layer[Layer.Modal] || layer[Layer.ModalZ2] || layer[Layer.DocPanel]) {
            e.preventDefault();
          }
        }}
        trigger={(
          <button
            className={twJoin(
              'btn btn-tertiary btn-square data-[state=open]:btn-hover',
              !collapsed && 'text-disabled hover:text-secondary',
            )}
          >
            <Icon
              name="plus"
              className="size-5.5"
            />
          </button>
        )}
      >
        <DropdownMenu.Item onSelect={createDoc}>
          <Icon
            name="feedback"
            className="size-4.5"
          />
          New feedback
          <ShortcutKeys shortcut={ShortcutBoard.CreateDoc} />
        </DropdownMenu.Item>

        <DropdownMenu.Item onSelect={openRequestModal}>
          <Icon
            name="feature"
            className="size-4.5"
          />
          New request
          <ShortcutKeys shortcut={Shortcut.CreateRequest} />
        </DropdownMenu.Item>

        <DropdownMenu.Item onSelect={createRelease}>
          <Icon
            name="release"
            className="size-4.5"
          />
          New release
          <ShortcutKeys shortcut={Shortcut.CreateRelease} />
        </DropdownMenu.Item>

        <DropdownMenu.Item onSelect={createCustomer}>
          <Icon
            name="user"
            className="size-4.5"
          />
          New customer
          <ShortcutKeys shortcut={Shortcut.CreateCustomer} />
        </DropdownMenu.Item>
      </Menu>

      <NewRequestModal
        open={isRequestModalOpen}
        onOpenChange={setIsRequestModalOpen}
      />

      <NewReleaseModal
        open={isReleaseModalOpen}
        onOpenChange={setIsReleaseModalOpen}
      />

      {isCustomerModalOpen && (
        <CustomersAddCustomerModal
          onClose={() => setIsCustomerModalOpen(false)}
          showToaster
        />
      )}
    </>
  );
};

const ShortcutKeys = ({ shortcut }: { shortcut: AnyShortcut }) => {
  return (
    <span className="ml-auto inline-flex gap-1.5 items-center">
      {shortcuts[shortcut].map((key, i) => (
        <span
          key={i}
          className="size-5 rounded-sm inline-flex items-center justify-center uppercase bg-grey-150 text-grey-550 font-semibold text-caption group-data-[highlighted]:bg-grey-200 dark:bg-grey-800 dark:text-grey-500 dark:group-data-[highlighted]:bg-grey-750"
        >
          {key}
        </span>
      ))}
    </span>
  );
};
