import { TooltipLegacy } from '@cycle-app/ui';
import { InfoIconOutline } from '@cycle-app/ui/icons';
import { forwardRef, CSSProperties } from 'react';

import {
  ArrowContainer, Container, Title, StyledArrow, Root, Label,
} from './ArrowTooltip.styles';

interface Props {
  // is right to left
  isRTL?: boolean;
  label: string;
  tooltip: string;
  style: CSSProperties;
}

export const ArrowTooltip = forwardRef<HTMLDivElement, Props>(({
  isRTL, label, tooltip, style,
}, ref) => {
  return (
    <Root
      initial={{
        opacity: 0,
      }}
      animate={{
        opacity: 1,
      }}
      transition={{
        delay: 0.2,
      }}
      style={style}
    >
      <Container $isRTL={isRTL}>
        <Title ref={ref}>
          <TooltipLegacy
            placement="top"
            withPortal
            content={tooltip}
          >
            <Label>
              {label}
            </Label>
            {' '}
            <InfoIconOutline />
          </TooltipLegacy>
        </Title>
        <ArrowContainer>
          <StyledArrow $isRTL={isRTL} />
        </ArrowContainer>
      </Container>
    </Root>
  );
});
