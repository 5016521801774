import { Controller } from 'react-hook-form';

import { ChangelogDropdown, ChangelogDropdownContent } from '../shared/ChangelogDropdown';
import { ColorInput } from '../shared/ColorInput';
import { useChangelogBuilderForm } from '../useChangelogBuilderForm';

export function ChangelogBuilderContentDivider() {
  const { control } = useChangelogBuilderForm();

  return (
    <ChangelogDropdown
      hoverStateClassName="-m-3 p-3 rounded-lg"
      content={({ hide }) => (
        <ChangelogDropdownContent
          title="Divider"
          hide={hide}
        >
          <div className="grid grid-cols-2 items-center gap-2">
            <span className="text-primary">
              Color
            </span>
            <Controller
              control={control}
              name="dividerColor"
              render={({ field }) => (
                <ColorInput
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  value={field.value}
                  name={field.name}
                />
              )}
            />
          </div>
        </ChangelogDropdownContent>
      )}
    >
      <div className="border-t border-(--changelog-divider-color)" />
    </ChangelogDropdown>
  );
}
