import { z } from 'zod';

import {  makeVar } from 'src/utils/makeVar.utils';

import { AskContent } from './docRightPanel.reactive';
import { AskItemParameters } from '../components/Ask/Ask.types';
import { LocalKey } from '../types/localStorage.types';

type AskPanelState = {
  isOpen: boolean;
  isExpand: boolean;
  content: AskContent;
  progress: number;
  conversationId: string;
};

const initialState: AskPanelState = {
  isOpen: false,
  isExpand: false,
  conversationId: '',
  content: AskContent.Conversation,
  progress: 100,
};

export const askVar = makeVar<AskPanelState>(
  initialState,
);

export const openAsk = () => askVar.set(prev => ({
  ...prev,
  isOpen: true,
}));

export const closeAsk = () => askVar.set(prev => ({
  ...prev,
  isOpen: false,
}));

export const toggleExpandAsk = () => askVar.set(prev => ({
  ...prev,
  isExpand: !prev.isExpand,
}));

export const askParameters = makeVar<Record<string, { parameters?: AskItemParameters | null; docs?: string[] | null }>>(
  {},
  {
    crossTabSync: true,
    schema: z.record(z.string(), z.object({
      parameters: z.object({
        feature: z.string().nullable(),
        fromDate: z.string().nullable(),
        toDate: z.string().nullable(),
        companies: z.array(z.object({
          name: z.string(),
          company: z.object({
            id: z.string(),
            logo: z.string().nullable(),
          }),
        })),
      }).nullable(),
      docs: z.array(z.string()).nullable(),
    })),
    key: LocalKey.AskParameters,
  },
);
