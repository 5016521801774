import { OfflineIcon } from '../../icons';

export const OfflineBadge = () => (
  <span
    // eslint-disable-next-line max-len
    className="inline-flex items-center gap-1 rounded-full bg-grey-150 px-1.5 py-0.5 text-caption font-medium text-grey-600 dark:bg-grey-200 dark:text-black"
  >
    <OfflineIcon />
    Offline
  </span>
);
