import { KeywordFragment, UpdateKeywordDocument } from '@cycle-app/graphql-codegen';

import useSafeMutation from 'src/hooks/useSafeMutation';

export const useUpdateKeyword = () => {
  const [mutate, result] = useSafeMutation(UpdateKeywordDocument);

  const updateKeyword = (
    keyword: KeywordFragment,
    misspellings: string[],
    { optimistic } = { optimistic: true },
  ) => mutate({
    variables: {
      id: keyword.id,
      misspellings,
    },
    optimisticResponse: optimistic ? {
      updateKeyword: {
        ...keyword,
        misspellings,
      },
    } : undefined,
  });

  return [updateKeyword, result] as const;
};
