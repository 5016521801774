import { Input, ToggleInput, Dialog } from '@cycle-app/ui';
import { CloseIcon } from '@cycle-app/ui/icons';
import { toShortLocaleDateString } from '@cycle-app/utilities';
import { ComponentProps, useState } from 'react';

import DialogModal from 'src/components/DialogModal/DialogModal';
import { Header, Title, CloseButtonStyled as CloseButton } from 'src/components/DialogModal/DialogModal.styles';
import { ReleaseForm } from 'src/components/ReleaseForm';
import { PageId, routing } from 'src/constants/routing.constant';
import { useReleasesContext } from 'src/contexts/releasesContext';
import { useProductBase, useRouteMatch } from 'src/hooks';
import { useChangelog } from 'src/hooks/releases/useChangelog';
import { useChangelogUpdate } from 'src/hooks/releases/useChangelogUpdate';
import { useReleaseNotes } from 'src/hooks/releases/useReleaseNotes';
import { useReleasePublicStatusUpdate } from 'src/hooks/releases/useReleasePublicStatusUpdate';
import { useRemoveRelease } from 'src/hooks/releases/useRemoveRelease';
import { useRemoveReleaseNote } from 'src/hooks/releases/useRemoveReleaseNote';
import { navigateToRelease, useNavigate } from 'src/hooks/useNavigate';
import { resetReleasesAction, useGetReleasesAction } from 'src/reactives/releases.reactive';
import { Layer } from 'src/types/layers.types';
import { changelogUrl } from 'src/utils/changelog.utils';
import { getParentPage } from 'src/utils/routing.utils';

import { Modal, ConfirmOption } from './ReleaseModals.styles';

export const ReleaseEditModal = ({ onReleaseCreated }: {
  onReleaseCreated?: (releaseId: string) => void;
}) => {
  const action = useGetReleasesAction();
  if (action.type !== 'createRelease' && action.type !== 'updateRelease') return null;
  return (
    <Modal
      hide={resetReleasesAction}
      layer={Layer.ModalZ2}
    >
      <Header>
        <Title>
          {action.type === 'updateRelease' ? 'Edit release' : 'New release'}
        </Title>
        <CloseButton onClick={resetReleasesAction}>
          <CloseIcon />
        </CloseButton>
      </Header>
      <ReleaseForm
        hide={resetReleasesAction}
        releaseId={action.id}
        onReleaseCreated={(releaseId) => {
          onReleaseCreated?.(releaseId);
          if (action.type === 'createRelease') action.onCompleted?.(releaseId);
        }}
      />
    </Modal>
  );
};

export const NewReleaseModal = (props: ComponentProps<typeof Dialog.Modal>) => (
  <Dialog.Modal
    {...props}
    className="w-md"
    aria-describedby={undefined}
  >
    <Dialog.Title className="mb-4">
      New release
    </Dialog.Title>

    <ReleaseForm
      hide={() => props.onOpenChange?.(false)}
      showToaster
      onReleaseCreated={releaseId => {
        props.onOpenChange?.(false);
        if (getParentPage() === 'releases') {
          navigateToRelease(releaseId);
        }
      }}
    />
  </Dialog.Modal>
);

export const ReleaseCreateModalBulk = ({ onReleaseCreated }: {
  onReleaseCreated?: (releaseId: string) => void;
}) => {
  const action = useGetReleasesAction();
  if (action.type !== 'createReleaseBulk') return null;
  return (
    <Modal
      hide={resetReleasesAction}
      layer={Layer.ModalZ2}
    >
      <Header>
        <Title>
          New release
        </Title>
        <CloseButton onClick={resetReleasesAction}>
          <CloseIcon />
        </CloseButton>
      </Header>

      <ReleaseForm
        hide={resetReleasesAction}
        onReleaseCreated={onReleaseCreated}
      />
    </Modal>
  );
};

type ReleaseRemoveModalProps = {
  navigateAfterDelete?: boolean;
  releaseId: string;
};

export const ReleaseRemoveModal = ({
  releaseId, navigateAfterDelete,
}: ReleaseRemoveModalProps) => {
  const action = useGetReleasesAction();
  const releaseNoValue = useReleasesContext(ctx => ctx.releaseNoValue);

  if (
    action.type !== 'removeRelease' ||
    /**
     * There are a modal for each releaseItem so we do not open it only
     * based on the action type anymore
     */
    releaseId !== action.id ||
    !releaseNoValue?.id
  ) return null;

  return (
    <ReleaseRemoveModalContent
      releaseId={action.id}
      releaseNoValueId={releaseNoValue.id}
      navigateAfterDelete={navigateAfterDelete}
    />
  );
};

type ReleaseRemoveModalContentProps = ReleaseRemoveModalProps & {
  releaseId: string;
  releaseNoValueId: string;
};

const ReleaseRemoveModalContent = ({
  releaseId, releaseNoValueId, navigateAfterDelete,
}: ReleaseRemoveModalContentProps) => {
  const { navigateToReleases } = useNavigate();
  const { removeRelease } = useRemoveRelease();
  const action = useGetReleasesAction();
  const releases = useReleasesContext(ctx => ctx.releases);
  const mainToMove = useReleaseNotes(releaseId, false);
  const otherToMove = useReleaseNotes(releaseId, true);
  const mainNoValue = useReleaseNotes(releaseNoValueId, false);
  const otherNoValue = useReleaseNotes(releaseNoValueId, true);
  if (action.type !== 'removeRelease') return null;
  const releaseName = releases[action.id]?.title || (releases[action.id]?.date && toShortLocaleDateString(releases[action.id]?.date));
  return (
    <DialogModal
      title="Delete release"
      confirmLabel="Delete"
      hide={resetReleasesAction}
      onConfirm={() => {
        if (navigateAfterDelete) navigateToReleases();
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        removeRelease(releaseId, {
          releasesNoteToMove: {
            main: mainToMove,
            other: otherToMove,
          },
          releasesNoteNoValue: {
            main: mainNoValue,
            other: otherNoValue,
          },
        });
      }}
      info={(
        <p>
          Are you sure you want to delete the release
          {' '}
          <strong>
            {releaseName}
          </strong>
          ? All related release notes will loose their release date.
          <br />
          This action can’t be undone.
        </p>
      )}
    />
  );
};

export const ReleaseNoteRemoveModal = () => {
  const matchReleaseNote = useRouteMatch(routing[PageId.ReleaseNote]);
  const releaseId = matchReleaseNote?.params.releaseId;
  const noteId = matchReleaseNote?.params.noteId;
  const { removeReleaseNote } = useRemoveReleaseNote(releaseId);
  const action = useGetReleasesAction();
  if (action.type !== 'removeReleaseNote') return null;
  return (
    <DialogModal
      title="Delete release note"
      confirmLabel="Delete"
      hide={resetReleasesAction}
      onConfirm={() => {
        if (releaseId && noteId === action.id) navigateToRelease(releaseId);
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        removeReleaseNote(action.id);
      }}
      info={(
        <div>
          Are you sure you want to delete this release note?
          <br />
          This can’t be undone.
        </div>
      )}
    />
  );
};

export const ReleasePublishModal = ({ releaseId }: { releaseId: string }) => {
  const product = useProductBase();
  const { publish } = useReleasePublicStatusUpdate(releaseId);
  const { changelogUpdate } = useChangelogUpdate();
  const { changelog } = useChangelog();
  const action = useGetReleasesAction();
  if (action.type !== 'publishRelease' || releaseId !== action.id || !product) return null;
  return (
    <DialogModal
      type="default"
      variantCancelButton="secondary"
      variantSubmitButton="primary"
      title="Publish release"
      confirmLabel="Publish"
      hide={resetReleasesAction}
      onConfirm={async () => {
        await publish();
        if (!changelog) return null;
        return changelogUpdate({
          id: changelog.id,
          isPublished: true,
        });
      }}
      info={(
        <div className="flex flex-col items-stretch gap-2">
          <div>
            By publishing your first release, your changelog will become public at the address:
          </div>
          <Input
            disabled
            value={changelogUrl(product.slug, changelog?.domain)}
          />
        </div>
      )}
    />
  );
};

export const ReleaseUnpublishModal = ({ releaseId }: { releaseId: string }) => {
  const action = useGetReleasesAction();
  if (action.type !== 'unpublishRelease' || releaseId !== action.id) {
    return null;
  }
  return (
    <ReleaseUnpublishModalVisible releaseId={releaseId} />
  );
};

export const ReleaseUnpublishModalVisible = ({ releaseId }: { releaseId: string }) => {
  const {
    unpublish, isUnpublishLoading,
  } = useReleasePublicStatusUpdate(releaseId);
  const { changelogUpdate } = useChangelogUpdate();
  const { changelog } = useChangelog();
  const [keepChangelogPublic, setKeepChangelogPublic] = useState(true);
  return (
    <DialogModal
      type="default"
      variantCancelButton="secondary"
      variantSubmitButton="primary"
      title="Unpublish release"
      confirmLabel="Unpublish"
      loading={isUnpublishLoading}
      hide={resetReleasesAction}
      autoHide={false}
      onConfirm={async () => {
        await unpublish();
        resetReleasesAction();
        if (!changelog || keepChangelogPublic) return null;
        return changelogUpdate({
          id: changelog.id,
          isPublished: false,
        });
      }}
      info={(
        <div className="flex flex-col items-stretch gap-4">
          <div>
            You won’t have any published release in your changelog anymore.
          </div>
          <ConfirmOption>
            <div className="flex flex-col items-stretch">
              <div className="select-text text-body font-medium">
                Keep changelog public
              </div>
              <div className="-mt-0.5 select-text text-secondary">
                You changelog will still be visible but empty
              </div>
            </div>
            <ToggleInput
              id="keep-changelog-public"
              checked={keepChangelogPublic}
              onChange={e => setKeepChangelogPublic(e.target.checked)}
            />
          </ConfirmOption>
        </div>
      )}
    />
  );
};
