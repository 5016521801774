import { ViewType } from '@cycle-app/graphql-codegen';
import { typo, ActionButton, colorUtils } from '@cycle-app/ui';
import { CornerDownRightIcon } from '@cycle-app/ui/icons';
import styled, { css } from 'styled-components';

export const ParentIcon = styled(CornerDownRightIcon)`
  transform: rotateX(-180deg) rotateZ(90deg);
  color: ${p => p.theme.colors.card.parent.color};
`;

export const PublicId = styled.div`
  ${typo.caption400};
`;

export const Title = styled.span<{ $viewType?: ViewType }>`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: ${p => (p.$viewType ? 'unset' : '400px')};
  ${typo.caption400};
`;

const buttonCss = css`
  color: ${p => p.theme.colors.card.parent.colorAlt};
  :hover, :focus {
    color: ${p => p.theme.colors.card.parent.colorHover};
    background: ${p => p.theme.colors.card.parent.bgHoverAlt};
  }
  :active {
    background: ${p => p.theme.colors.card.parent.bgActive};
  }
`;

export const MainButton = styled.div<{
  $minimal?: boolean;
  readonly: boolean;
  size: 'XS' | 'S' | 'M';
  $context?: 'doc-panel' | 'doc-item';
  $forceFocus?: boolean;
}>`
  display: flex;
  gap: 6px;
  align-items: center;
  white-space: nowrap;
  color: ${p => p.theme.colors.text.secondary};

  svg {
    flex: none;
    color: ${p => p.theme.colors.text.disabled};
  }

  &, > [data-popover] {
    overflow: hidden;
  }

  > [data-popover] {
    align-items: inherit;
    display: inherit;
    gap: inherit;
    ${p => p.$context !== 'doc-item' && css`
      flex: none;
    `};
  }

  ${p => !p.readonly && css`
    cursor: pointer;
    &:hover {
      color: ${p.theme.colors.text.primary};
      background-color: ${p.theme.colors.background.hoverSoft};
    }
  `}

  ${p => p.$minimal && css`
    border: none;

    ${!p.readonly && css`
      &:hover {
        background-color: ${colorUtils.transparentize(p.theme.colors.background.hover, 0.5)};
        ${PublicId} {
          color: ${p.theme.colors.text.primary};
        }
      }
      ${PublicId} {
        color: ${p.theme.colors.text.secondary};
        ${typo.caption500};
      }
    `}
  `};

  ${p => p.size === 'XS' && css`
      ${PublicId}, ${Title} {
        ${typo.caption400}
      }
  `}

  ${p => p.size === 'M' && css`
      ${PublicId}, ${Title} {
        ${typo.body400}
      }
  `}

  padding: 0 6px;
  height: 24px;
  ${typo.caption400}
  width: fit-content;
  border-radius: 6px;

  ${p => p.$context === 'doc-item' && css`
    border-radius: 0;
    ${buttonCss}
  `}

  ${p => p.$forceFocus && `
    background-color: ${colorUtils.transparentize(p.theme.colors.background.hover, 0.5)};
  `}
`;

export const EditButton = styled(ActionButton)<{
  $forceFocus?: boolean;
}>`
  ${buttonCss}
  border-radius: 0;
  height: 100%;
  display: none;
  ${p => p.$forceFocus && `
    display: block;
    background-color: ${colorUtils.transparentize(p.theme.colors.background.hover, 0.5)};
  `}
`;

export const Container = styled.div<{
  $context?: 'doc-panel' | 'doc-item';
  viewType?: ViewType;
  readonly: boolean;
}>`
  background: ${p => p.theme.colors.card.bg};
  :hover {
    background: ${p => (p.readonly ? p.theme.colors.card.bg : p.theme.colors.card.parent.bgHover)};
    ${EditButton} {
      display: block;
    }
  }

  display: flex;
  border-radius: 6px;
  ${p => p.$context === 'doc-item' && css`
    height: 24px;
    overflow: hidden;
    background: transparent;
  `}

  ${p => p.$context === 'doc-item' && p.viewType === ViewType.List && `
    margin-right: 32px;
  `}
`;
