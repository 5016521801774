import { TooltipLegacy } from '@cycle-app/ui';
import { CheckIcon } from '@cycle-app/ui/icons';
import { twJoin } from 'tailwind-merge';

type Props = {
  checked: boolean;
  toggle: () => void;
};

export const QuoteCheckbox = ({
  checked = false,
  toggle,
}: Props) => {
  return (
    <TooltipLegacy
      content={checked ? 'Unselect' : 'Select'}
      placement="top"
      withPortal
      withWrapper={false}
    >
      <button
        className={twJoin(
          'absolute -left-1.5 top-1/2 -translate-y-1/2 shadow-z1 group-hover/quote:opacity-100',
          'flex size-3.5 cursor-pointer items-center justify-center rounded-[4px] opacity-0 outline-hidden',
          checked
            ? 'bg-cycle text-white opacity-100'
            : 'border border-grey-150 bg-white text-grey-350 hover:text-secondary dark:border-grey-600/25 dark:bg-grey-800 dark:text-grey-550',
        )}
        onClick={e => {
          e.stopPropagation();
          toggle();
        }}
      >
        <CheckIcon size={8} />
      </button>
    </TooltipLegacy>
  );
};
