import { Icon } from '@cycle-app/ui';

import { AskContent } from '../../reactives/docRightPanel.reactive';

type Props = {
  onNavigate: (to: 'history' | 'new' | 'back') => void;
  onClose: VoidFunction;
  onExpand?: VoidFunction;
  content: AskContent;
  isExpanded?: boolean;
};

export const AskHeader = ({
  onNavigate, onClose, content, onExpand,
}: Props) => {
  return (
    <div className="flex-none py-3 px-3">
      <div className="flex items-center h-6 justify-between">
        <div>
          {content === AskContent.History && (
            <button
              type="button"
              className="btn-tertiary text-primary font-medium"
              onClick={() => onNavigate('back')}
            >
              <Icon
                name="chevron-left"
                className="size-5 flex-none"
              />
              Back
            </button>
          )}
        </div>
        <div className="flex gap-1.5 items-center">
          {onExpand && (
            <button
              type="button"
              className="btn-tertiary flex-none btn-square btn-sm"
              onClick={onExpand}
            >
              <Icon
                name="expand"
                className="size-3.5"
              />
            </button>
          )}
          <button
            type="button"
            className="btn-tertiary flex-none btn-square btn-sm"
            onClick={() => onNavigate('new')}
          >
            <Icon
              name="plus"
              className="size-4"
            />
          </button>
          <button
            type="button"
            className="btn-tertiary flex-none btn-square btn-sm"
            onClick={() => onNavigate('history')}
          >
            <Icon
              name="history"
              className="size-4"
            />
          </button>
          <button
            type="button"
            className="btn-tertiary btn-sm btn-square flex-none"
            onClick={onClose}
          >
            <Icon name="close" />
          </button>
        </div>
      </div>
    </div>
  );
};
