import { Icon } from '@cycle-app/ui';

import {
  Container, Title, StyledFeatures, RightBox, RightBoxHeader, RightBoxBody, ContentBox, Description,
} from './UpgradeBox.styles';
import { UpgradeBoxAddons } from './UpgradeBoxAddons';
import { UpgradeBoxForm } from './UpgradeBoxForm';
import { UpgradeBoxFormMakers } from './UpgradeBoxFormMakers';

type Props = {
  className?: string;
  description?: string;
  isYC: boolean;
  title?: string;
  subtitle?: string;
};

export const UpgradeBox = ({
  className, description, title, isYC, subtitle,
}: Props) => {
  return (
    <div className={className}>
      {isYC && (
        <Icon
          name="brand/yc"
          className="size-10 block mx-auto mb-2"
        />
      )}
      {title && (
        <Title>
          {title}
        </Title>
      )}
      <Container>
        <ContentBox>
          {subtitle && (
            <Title>
              {subtitle}
            </Title>
          )}
          {description && (
            <Description>
              {description}
            </Description>
          )}
          <StyledFeatures
            isYC={isYC}
            tooltip
          />
        </ContentBox>
        <RightBox>
          <RightBoxHeader>
            <div>
              <UpgradeBoxAddons isYC={isYC} />
            </div>
            <div>
              <UpgradeBoxFormMakers />
            </div>
          </RightBoxHeader>
          <RightBoxBody>
            <UpgradeBoxForm isYC={isYC} />
          </RightBoxBody>
        </RightBox>
      </Container>
    </div>
  );
};
