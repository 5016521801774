import { ConversationRemoveDocument, DocConversationsFragment, ProductConversationsFragment } from '@cycle-app/graphql-codegen';
import { produce } from 'immer';

import { useWorkspaceContext } from '../../contexts/workspaceContext';
import { askParameters } from '../../reactives/ask.reactive';
import useSafeMutation from '../useSafeMutation';

export const useConversationRemove = () => {
  const [removeMutation] = useSafeMutation(ConversationRemoveDocument);
  const productId = useWorkspaceContext(ctx => ctx.productId);

  const remove = ({
    id, docId,
  }:{ id: string; docId?: string }) => {
    return removeMutation({
      variables: { id },
      optimisticResponse: {
        removeConversation: { id },
      },
      update: (cache, { data }) => {
        if (data?.removeConversation) {
          cache.modify<DocConversationsFragment | ProductConversationsFragment>({
            id: docId || productId,
            fields: {
              conversations: (current, {
                readField, isReference,
              }) => {
                if (isReference(current)) return current;
                return produce(current, draft => {
                  if (draft) {
                    draft.edges = draft.edges.filter(edge => {
                      return readField('id', edge.node) !== id;
                    });
                  }
                });
              },
            },
          });
          askParameters.produce(prev => {
            delete prev[id];
          });
          cache.evict({ id });
          cache.gc();
        }
      },
    });
  };

  return { remove };
};
