import { AvatarLegacy } from '@cycle-app/ui';

import { useDocBase } from 'src/hooks/api/useDoc';
import { getUserLabel } from 'src/utils/users.util';

import { useQuoteId } from './QuoteContext';

export const QuotePanelCreator = () => {
  const quoteId = useQuoteId();
  const { doc } = useDocBase(quoteId);
  const creator = doc?.creator;
  if (!creator) return null;
  return (
    <div className="flex items-center gap-2 px-2 font-medium text-primary">
      <div className="flex size-4 items-center justify-center">
        <AvatarLegacy
          user={creator}
          size={16}
          className="scale-90"
        />
      </div>
      {getUserLabel(creator)}
    </div>
  );
};
