import { Skeleton, LinearProjectIcon, Icon, Spinner } from '@cycle-app/ui';
import { ArrowRightUpIcon } from '@cycle-app/ui/icons';
import { useEffect } from 'react';

import { useDocLinearContext } from 'src/contexts/docLinearContext';
import { useLinearProject } from 'src/hooks/api/queries/integrations/useLinearProject';
import { usePrefetchLinearProjectIssues } from 'src/hooks/api/queries/integrations/useLinearProjectIssues';
import { useDocSyncAutomationContent } from 'src/hooks/doc/useDocSyncAutomationContent';
import { projectInfos } from 'src/utils/linear.utils';

import {
  PreviewHeader, HeaderLeft, HeaderRight, ProjectName, ProjectLink, Infos,
} from './DocLinear.styles';
import { PreviewAlert } from './PreviewAlert';
import { PreviewProjectIssues } from './PreviewProjectIssues';

export const PreviewProject = ({
  onClickChange, onUnlink, hide,
}: {
  onClickChange: VoidFunction;
  onUnlink: VoidFunction;
  hide: VoidFunction;
}) => {
  const projectId = useDocLinearContext(ctx => ctx.automationId);
  const integrationId = useDocLinearContext(ctx => ctx.integrationId);
  const query = useLinearProject(projectId, integrationId);
  const prefetchProjectIssues = usePrefetchLinearProjectIssues();
  const automationUrl = useDocLinearContext(ctx => ctx.automationUrl);

  const {
    syncAutomationContent,
    isLoading: isSyncAutomationContentLoading,
  } = useDocSyncAutomationContent();

  useEffect(() => {
    if (projectId) prefetchProjectIssues(projectId, integrationId);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (query.isLoading && !query.project) {
    return (
      <div className="p-3">
        <Skeleton height={20} />
      </div>
    );
  }

  const footer = (
    <div className="border-t border-grey-150 flex items-center gap-1 p-1 dark:border-grey-800">
      {query.project && automationUrl && (
        <button
          className="btn-tertiary"
          onClick={() => {
            hide();
            window.open(automationUrl, '_blank');
          }}
        >
          <Icon
            name="arrow-right-up"
            className="size-3.5"
          />
          Open in Linear
        </button>
      )}

      <button
        className="btn-tertiary"
        onClick={() => {
          hide();
          onClickChange();
        }}
      >
        <Icon name="edit" />
        Change project
      </button>

      <button
        className="btn-tertiary"
        onClick={() => {
          hide();
          onUnlink();
        }}
      >
        <Icon name="unlink" />
        Unlink project
      </button>

      {query.project && automationUrl && (
        <button
          className="btn-tertiary"
          onClick={syncAutomationContent}
        >
          {isSyncAutomationContentLoading
            ? <Spinner />
            : <Icon name="sync-data" />}
          Sync data
        </button>
      )}
    </div>
  );

  if (!query.project) {
    return (
      <>
        <div className="p-3 flex flex-col gap-2">
          <PreviewHeader>
            <PreviewAlert message="It seems that this project no longer exists." />
          </PreviewHeader>
        </div>
        {footer}
      </>
    );
  }

  return (
    <>
      <div className="p-3 flex flex-col gap-2 shy-scrollbar">
        <PreviewHeader>
          <HeaderLeft>
            <LinearProjectIcon
              icon={query.project.icon}
              color={query.project.color}
              size={16}
            />

            <ProjectName>
              {query.project.name}
            </ProjectName>
          </HeaderLeft>

          <HeaderRight>
            <Infos>
              {projectInfos(query.project.progress, query.project.issues.length)}
            </Infos>
          </HeaderRight>
        </PreviewHeader>

        <PreviewProjectIssues />
        <ProjectLink
          href={query.project.url}
          target="_blank"
          rel="noreferrer"
        >
          <ArrowRightUpIcon size={12} />
          View more in Linear
        </ProjectLink>
      </div>
      {footer}
    </>
  );
};

