import { Icon } from '@cycle-app/ui';
import { AddIcon } from '@cycle-app/ui/icons';

import { PageId } from 'src/constants/routing.constant';
import { useFeedbackDocType, useIsRoadmapsEnabled } from 'src/hooks';
import { openCommandBar } from 'src/hooks/modals/useCommandBarModal';
import { useNavigate } from 'src/hooks/useNavigate';
import { useParentPage, usePageId } from 'src/hooks/usePageId';
import { setCreateDoc } from 'src/reactives/createDoc.reactive';
import { useMobileAsideMenu } from 'src/reactives/mobileAsideMenu.reactive';
import { useIsMobile } from 'src/reactives/responsive.reactive';

import { MobileFooterContainer, ButtonLink, Button, ButtonContainer } from './MobileFooter.styles';

type ActiveElement = 'home' | 'inbox' | 'search' | 'views' | 'roadmap';

export const MobileFooter = () => {
  const feedback = useFeedbackDocType();
  const isMobile = useIsMobile();
  const { getUrl } = useNavigate();
  const [{ isVisible }, setMobileAsideMenu] = useMobileAsideMenu();
  const parentPage = useParentPage();
  const pageId = usePageId();
  const isRoadmapsEnabled = useIsRoadmapsEnabled();

  if (!isMobile || pageId === PageId.DocFullPage) return null;

  const closeAsideNav = () => setMobileAsideMenu({ isVisible: false });

  const getActiveElement = (): ActiveElement | null => {
    if (isVisible || parentPage === 'board') return 'views';
    if (parentPage === 'home') return 'home';
    if (parentPage === 'inbox') return 'inbox';
    if (parentPage === 'roadmap') return 'roadmap';

    return null;
  };

  return (
    <MobileFooterContainer>
      <ButtonContainer>
        <ButtonLink
          to={getUrl(PageId.Main)}
          onClick={closeAsideNav}
          $isActive={getActiveElement() === 'home'}
        >
          <Icon
            name="home"
            className="size-[18px]"
          />
          Home
        </ButtonLink>
      </ButtonContainer>
      <ButtonContainer>
        <ButtonLink
          to={getUrl(PageId.Inbox)}
          onClick={closeAsideNav}
          $isActive={getActiveElement() === 'inbox'}
        >
          <Icon
            name="feedback"
            className="size-[18px]"
          />
          Feedback
        </ButtonLink>
      </ButtonContainer>
      <ButtonContainer>
        <Button
          $isHighlight
          onClick={() => {
            if (!feedback?.id) return;
            setCreateDoc({
              modalVisible: true,
              doctypeId: feedback.id,
            });
            closeAsideNav();
          }}
        >
          <AddIcon />
        </Button>
      </ButtonContainer>
      {isRoadmapsEnabled ? (
        <ButtonContainer>
          <ButtonLink
            to={getUrl(PageId.Roadmap)}
            onClick={closeAsideNav}
            $isActive={getActiveElement() === 'roadmap'}
          >
            <Icon
              name="feature"
              className="size-[18px]"
            />
            Requests
          </ButtonLink>
        </ButtonContainer>
      ) : (
        <ButtonContainer>
          <Button
            onClick={() => {
              openCommandBar();
            }}
          >
            <Icon
              name="search"
              className="size-[18px]"
            />
            Search
          </Button>
        </ButtonContainer>
      )}
    </MobileFooterContainer>
  );
};
