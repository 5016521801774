import { TooltipLegacy, ActionButtonProps } from '@cycle-app/ui';
import { ComponentProps, FC, ReactNode } from 'react';
import { Placement } from 'tippy.js';

import { ActionButtonStyled } from './DocAction.styles';

export type DocActionProps = Omit<ActionButtonProps, 'onClick'> & ComponentProps<typeof ActionButtonStyled> & {
  buttonIcon?: ReactNode;
  label?: string;
  counter?: number;
  tooltipPlacement?: Placement;
  forceFocus?: boolean;
  size?: 'S' | 'M' | 'L';
  context?: 'doc-item' | 'doc-panel';
};

const DocAction: FC<React.PropsWithChildren<DocActionProps>> = ({
  className,
  buttonIcon,
  label,
  counter,
  forceFocus,
  children,
  tooltipPlacement = 'top',
  size = 'M',
  context,
  ...props
}) => {
  return label
    ? (
      <TooltipLegacy
        placement={tooltipPlacement}
        content={label}
        withPortal
        disabled={props.disabled || false}
      >
        {renderActionButton()}
      </TooltipLegacy>
    )
    : renderActionButton();

  function renderActionButton() {
    return (
      <ActionButtonStyled
        counter={counter}
        className={forceFocus ? `${className} force-focus` : className}
        size={size}
        context={context}
        {...props}
      >
        {buttonIcon}
        {children}
      </ActionButtonStyled>
    );
  }
};

export default DocAction;
