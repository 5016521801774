import { useFragment } from '@apollo/client';
import {
  ProductAreaCategoryFragment,
  ProductAreaFragment,
  ProductAreasCategoriesFragmentDoc,
  ProductAreasFragmentDoc,
} from '@cycle-app/graphql-codegen';
import { nodeToArray } from '@cycle-app/utilities';

import { useWorkspaceContext } from '../../contexts/workspaceContext';
import { useProductAreaAttribute } from '../api/useAttributes';

type Categories = (Pick<ProductAreaCategoryFragment, '__typename' | 'value'> &
{ id: string | null; productAreas: ProductAreaFragment[] })[];

export const useProductAreasCategories = () => {
  const productId = useWorkspaceContext(ctx => ctx.productId);
  const {
    categoryName, attribute, categoryAttribute,
  } = useProductAreaAttribute();
  const areasCategories = useFragment({
    fragment: ProductAreasCategoriesFragmentDoc,
    fragmentName: 'ProductAreasCategories',
    from: productId,
  });
  const areas = useFragment({
    fragment: ProductAreasFragmentDoc,
    fragmentName: 'ProductAreas',
    from: productId,
  });
  const categories: Categories = [
    ...nodeToArray(areasCategories.complete ? areasCategories.data.productAreaCategories : null)
      .map(category => {
        return {
          __typename: category.__typename,
          id: category.id,
          value: category.value,
          productAreas: nodeToArray(category.productAreas).sort((a, b) => a.value.localeCompare(b.value)),
        };
      }),
    {
      __typename: 'ProductAreaCategory',
      id: null,
      value: `No ${categoryName}`,
      productAreas: nodeToArray(areas.complete ? areas.data.productAreas : null)
        .filter(a => !a.category?.id)
        .sort((a, b) => a.value.localeCompare(b.value)),
    },
  ];
  const productAreas = categories
    .flatMap(category => category.productAreas)
    .sort((a, b) => a.value.localeCompare(b.value));
  // "No category" excluded.
  const hasCategories = categories.some(category => category.id);
  return {
    hasCategories,
    areas: productAreas,
    categories,
    // Acts as feature flag. enabled only for few ws were product areas were set up.
    isEnabled: !!attribute?.id && !!categoryAttribute?.id,
  };
};
