import { useFragment } from '@apollo/client';
import {
  DocInitialBaseFragmentDoc,
  DocParentFragmentDoc,
  DocStatusFragmentDoc,
  DocCreatorFragmentDoc,
  DocSourceFragmentDoc,
  DocDocSourceFragmentDoc,
  DocTitleFragmentDoc,
  DocProductAreasFragmentDoc,
  DocBaseFragmentDoc,
} from '@cycle-app/graphql-codegen';
import { nodeToArray } from '@cycle-app/utilities';

export const useDocInitialBaseFragment = (docId: string | null | undefined) => {
  const {
    data, complete,
  } = useFragment({
    fragment: DocInitialBaseFragmentDoc,
    fragmentName: 'DocInitialBase',
    from: docId ?? '',
  });
  return docId && complete ? data : null;
};

export const useDocBaseFragment = (docId: string | null | undefined) => {
  const {
    data, complete,
  } = useFragment({
    fragment: DocBaseFragmentDoc,
    fragmentName: 'DocBase',
    from: docId ?? '',
  });
  return docId && complete ? data : null;
};

export const useDocParentFragment = (parentDocId: string) => {
  const {
    data, complete,
  } = useFragment({
    fragment: DocParentFragmentDoc,
    fragmentName: 'DocParent',
    from: parentDocId,
  });
  return complete ? data : null;
};

export const useDocStatusFragment = (docId: string | null | undefined) => {
  const {
    data, complete,
  } = useFragment({
    fragment: DocStatusFragmentDoc,
    fragmentName: 'DocStatus',
    from: docId ?? '',
  });
  return complete ? data : null;
};

export const useDocCreatorFragment = (docId: string) => {
  const {
    data, complete,
  } = useFragment({
    fragment: DocCreatorFragmentDoc,
    fragmentName: 'DocCreator',
    from: docId,
  });
  return complete ? data : null;
};

export const useDocSourceFragment = (docId: string) => {
  const {
    data, complete,
  } = useFragment({
    fragment: DocSourceFragmentDoc,
    fragmentName: 'DocSource',
    from: docId,
  });
  return complete ? data : null;
};

export const useDocDocSourceFragment = (feedbackDocId: string) => {
  const {
    data, complete,
  } = useFragment({
    fragment: DocDocSourceFragmentDoc,
    fragmentName: 'DocDocSource',
    from: feedbackDocId,
  });
  return complete ? data : null;
};

export const useDocTitleFragment = (docId: string) => {
  const {
    data, complete,
  } = useFragment({
    fragment: DocTitleFragmentDoc,
    fragmentName: 'DocTitle',
    from: docId,
  });
  return complete ? data : null;
};

export const useDocProductAreas = (docId: string) => {
  const {
    data, complete,
  } = useFragment({
    fragment: DocProductAreasFragmentDoc,
    fragmentName: 'DocProductAreas',
    from: docId,
  });
  return complete ? nodeToArray(data.productAreas) : null;
};
