import { motion } from 'framer-motion';

import { AskConversation } from './AskConversation';
import { AskHeader } from './AskHeader';
import { AskNewConversation } from './AskNewConversation';
import { AskProductHistory } from './AskProductHistory';
import { askVar, closeAsk, toggleExpandAsk } from '../../reactives/ask.reactive';
import { AskContent } from '../../reactives/docRightPanel.reactive';

export const AskProduct = ({ examples }: { examples: { id: string; content: string }[] }) => {
  const {
    isExpand, content, conversationId, progress,
  } = askVar.hook();
  return (
    <motion.div
      initial={false}
      animate={{ height: isExpand ? 'calc(100vh - 60px)' : 450 }}
      style={{ maxHeight: 'calc(100vh - 60px)' }}
    >
      <div className="h-full flex flex-col">
        <AskHeader
          content={content}
          onNavigate={to => {
            askVar.produce(prev => {
              if (to === 'new') prev.conversationId = '';
              prev.content = (to === 'new' || to === 'back') ? AskContent.Conversation : AskContent.History;
            });
          }}
          onClose={closeAsk}
          onExpand={toggleExpandAsk}
        />
        {content === AskContent.History && (
          <AskProductHistory
            onSelect={(id) => {
              askVar.produce(prev => {
                prev.conversationId = id;
                prev.content = AskContent.Conversation;
              });
            }}
            onAskNew={() => {
              askVar.produce(prev => {
                prev.conversationId = '';
                prev.content = AskContent.Conversation;
              });
            }}
          />
        )}
        {content === AskContent.Conversation && (
          <>
            {conversationId && (
              <AskConversation
                conversationId={conversationId}
                onAskNew={() => {
                  askVar.produce(prev => {
                    prev.conversationId = '';
                    prev.content = AskContent.Conversation;
                  });
                }}
                pending={progress !== 100}
              />
            )}
            {!conversationId && (
              <AskNewConversation
                examples={examples}
                onAsk={(id) => {
                  askVar.produce(prev => {
                    prev.conversationId = id;
                    prev.content = AskContent.Conversation;
                  });
                }}
                pending={progress !== 100}
              />
            )}
          </>
        )}
      </div>
    </motion.div>
  );
};
