import { ComponentType, lazy, LazyExoticComponent } from 'react';

type PreloadableComponent<T extends ComponentType> = LazyExoticComponent<T> & {
  preload: VoidFunction;
};

export const lazyWithPreload = <T extends ComponentType>(factory: () => Promise<{ default: T }>) => {
  const Component = lazy(factory) as PreloadableComponent<T>;

  Component.preload = () => {
    void factory();
  };

  return Component;
};
