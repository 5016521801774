import { useAtomValue } from 'jotai';

import DocPanelDocAttributes from 'src/app/Main/Board/DocPanel/DocPanelDocAttributes/DocPanelDocAttributes';
import { BoardConfigContextProvider } from 'src/contexts/boardConfigContext';
import { useDocBaseFragment } from 'src/hooks/api/fragments/doc';
import { Layer } from 'src/types/layers.types';

import { draftRequestIdAtom } from './newRequestModal.atoms';

export const NewRequestAttributes = () => {
  const draftRequestId = useAtomValue(draftRequestIdAtom);
  const docBase = useDocBaseFragment(draftRequestId);
  if (!docBase) return null;
  return (
    <BoardConfigContextProvider>
      <DocPanelDocAttributes
        doc={docBase}
        layer={Layer.DropdownModal}
        showAssignee
        showDoctype={false}
        className="mx-0"
      />
    </BoardConfigContextProvider>
  );
};
