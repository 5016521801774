import { TooltipLegacy, Shortcut } from '@cycle-app/ui';
import { MouseEventHandler, FC, ReactNode } from 'react';

import { shortcuts } from 'src/constants/shortcuts.constants';
import { useIsSmallScreen } from 'src/reactives';
import { Action as ActionType } from 'src/services/editor/editorActions';

import { ButtonAction, ButtonText, TooltipLabelContainer } from './Action.styles';

type ActionButtonProps = {
  action: ActionType;
  onClick?: MouseEventHandler;
  active?: boolean;
  customIcon?: ReactNode;
  customIconInTooltipLabel?: ReactNode;
  labelComplement?: string;
  isDocMention?: boolean;
  isDisabled?: boolean;
  disabledTooltip?: string;
  isTooltipDisabled?: boolean;
  hasGradient?: boolean;
};

export const ActionButton: FC<React.PropsWithChildren<ActionButtonProps>> = ({
  onClick, active, action, disabledTooltip, customIcon, isDocMention, labelComplement, isDisabled, isTooltipDisabled, hasGradient,
}) => {
  const isSmallScreen = useIsSmallScreen();

  const tooltipLabel = (
    <TooltipLabelContainer>
      {disabledTooltip ?? (
        <>
          {action.label}
          {customIcon}
          {labelComplement}
        </>
      )}
    </TooltipLabelContainer>
  );

  return (
    <TooltipLegacy
      content={action.shortcut && !disabledTooltip
        ? (
          <Shortcut
            keys={shortcuts[action.shortcut]}
            label={tooltipLabel}
          />
        )
        : tooltipLabel}
      placement="top"
      disabled={isTooltipDisabled || !action.label}
      withWrapper={false}
    >
      {action.text && !isSmallScreen ? (
        <ButtonText
          onClick={onClick}
          active={active}
          disabled={isDisabled}
        >
          {customIcon || action.icon}
          {action.text}
        </ButtonText>
      ) : (
        <ButtonAction
          type="button"
          onClick={onClick}
          active={active}
          $hasSmallIcon={isDocMention}
          disabled={isDisabled}
          hasGradient={hasGradient}
        >
          {customIcon || action.icon}
        </ButtonAction>
      )}
    </TooltipLegacy>
  );
};
