import { ReleasePublicStatus } from '@cycle-app/graphql-codegen';
import { TooltipLegacy } from '@cycle-app/ui';
import { twJoin } from 'tailwind-merge';

function getStatusTooltipContent(status: ReleasePublicStatus) {
  switch (status) {
    case ReleasePublicStatus.Unpublished:
      return 'Draft';
    case ReleasePublicStatus.Editing:
      return 'Published with pending changes';
    case ReleasePublicStatus.Published:
      return 'Published';
  }
}

export function ReleaseStatusIndicator({
  status, className,
}: {
  status: ReleasePublicStatus;
  className?: string;
}) {
  return (
    <TooltipLegacy
      withWrapper={false}
      placement="top"
      content={getStatusTooltipContent(status)}
    >
      <div
        className={twJoin(
          'pointer-events-auto relative size-2 rounded-full flex-none',
          status === ReleasePublicStatus.Unpublished ? 'bg-yellow-500' : 'bg-green-700',
          className,
        )}
      >
        {status === ReleasePublicStatus.Editing && (
          <div className="absolute -right-px -top-px size-1.5 rounded-full border border-white bg-yellow-500 dark:border-grey-950" />
        )}
      </div>
    </TooltipLegacy>
  );
}
