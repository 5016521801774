import { Icon, Spinner } from '@cycle-app/ui';
import { CloseIcon } from '@cycle-app/ui/icons';
import { FC, FormEvent, useEffect, useRef } from 'react';

import searchPreview from 'src/components/CommandBar/searchPreview';
import { commandSections } from 'src/constants/commandSections.constants';
import { useEnterCallback } from 'src/hooks/commandPanel/useEnterCallback';
import { useCommandbar } from 'src/reactives/commandbar.reactive';
import { useIsMobile } from 'src/reactives/responsive.reactive';

import {
  Container,
  LogoContainer,
  InputContainer,
  SearchInput,
  Section,
  Loading,
  TagStyled,
  ShortcutStyled,
  MobileInput,
} from './CommandInput.styles';

const DEFAULT_PLACEHOLDER = 'Type a command or search anything';

interface Props {
  search: string;
  onSearchUpdate: (e: FormEvent<HTMLInputElement>) => void;
  isLoading?: boolean;
}

const CommandInput: FC<React.PropsWithChildren<Props>> = ({
  search,
  onSearchUpdate,
  isLoading,
}) => {
  const [enterCallback, loadingEnterCallback] = useEnterCallback(search);
  const inputRef = useRef<HTMLInputElement>(null);
  const [{ section }, setCommandbar] = useCommandbar();
  const isMobile = useIsMobile();

  useEffect(() => {
    inputRef.current?.focus();
  }, [section]);

  const loadingInput = isLoading || loadingEnterCallback;

  if (isMobile) {
    return (
      <MobileInput
        ref={inputRef}
        value={search}
        onChange={onSearchUpdate}
        placeholder={section ? commandSections[section].placeholder : DEFAULT_PLACEHOLDER}
        autoFocus
        spellCheck={false}
        onKeyDown={(e) => {
          if (e.code === 'Enter' && !isLoading) enterCallback?.();
          if (e.code === 'Backspace' && !search.length) backToSummary();
        }}
      />
    );
  }

  return (
    <Container $withSection={!!section}>
      <InputContainer>
        <LogoContainer>
          <Icon name="search"  />
        </LogoContainer>
        <div className="flex flex-row-reverse items-center w-full gap-4">
          <SearchInput
            ref={inputRef}
            value={search}
            onChange={onSearchUpdate}
            placeholder={section ? commandSections[section].placeholder : DEFAULT_PLACEHOLDER}
            autoFocus
            spellCheck={false}
            onKeyDown={(e) => {
              if (e.code === 'Enter' && !isLoading) enterCallback?.();
              if (e.code === 'Backspace' && !search.length) backToSummary();
              searchPreview.onKeyDown(e);
            }}
            onKeyUp={searchPreview.onKeyUp}
          />
          {section && (
            <Section>
              <TagStyled
                size="L"
                onClick={backToSummary}
                tabIndex={2}
              >
                {commandSections[section].tagLabel ?? commandSections[section].label}
                <CloseIcon />
              </TagStyled>
            </Section>
          )}
        </div>
      </InputContainer>
      {loadingInput && (
        <Loading>
          <Spinner />
        </Loading>
      )}
      {!loadingInput && enterCallback && (
        <ShortcutStyled keys={['enter']} />
      )}
    </Container>
  );

  function backToSummary() {
    setCommandbar({ section: null });
  }
};

export default CommandInput;
