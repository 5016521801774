import { TextHighlighter, Tooltip } from '@cycle-app/ui';
import { useLayoutEffect, useRef, useState } from 'react';

type Props = {
  title: string;
  search?: string;
};

export const DocSearchTitle = ({
  title,
  search,
}: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  const [overflow, setOverflow] = useState(false);

  // Enable tooltip only if title overflows
  useLayoutEffect(() => {
    const el = ref.current;
    if (!el) return;
    queueMicrotask(() => {
      setOverflow(el.scrollWidth > el.clientWidth);
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Tooltip
      disabled={!overflow}
      content={search ? (
        <TextHighlighter
          className="highlight-tooltip"
          searchWords={[search]}
          textToHighlight={title}
        />
      ) : title}
    >
      <div
        ref={ref}
        className="truncate"
      >
        {search ? (
          <TextHighlighter
            className="highlight"
            searchWords={[search]}
            textToHighlight={title}
          />
        ) : title}
      </div>
    </Tooltip>
  );
};
