import { atom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';

import { LocalKey } from 'src/types/localStorage.types';

// Last doctype used in the modal
export const draftRequestTypeIdAtom = atomWithStorage<string | null>(LocalKey.DraftRequestTypeId, null);

// Draft doc to load on modal open, if not discarded on close
export const draftRequestIdAtom = atomWithStorage<string | null>(LocalKey.DraftRequestId, null);

// State of the load status of the editor, used to determine when to display it
export const editorLoadedAtom = atom(false);
editorLoadedAtom.onMount = set => () => set(false);
