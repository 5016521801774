export const getNetworkState = () => {
  const connection = navigator?.connection;
  return {
    online: navigator.onLine,
    ...connection && {
      downlink: connection?.downlink,
      effectiveType: connection?.effectiveType,
      rtt: connection?.rtt,
      saveData: connection?.saveData,
      type: connection?.type,
    },
  };
};
