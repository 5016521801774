import { useQuery } from '@apollo/client';
import { CompanyLogoInput, MeFragment, ProductGetSuggestionDocument } from '@cycle-app/graphql-codegen';
import { AvatarLegacy, Input, Spinner, TextArea, TooltipLegacy } from '@cycle-app/ui';
import { slugify, readFileSrc, ERROR_CODE, capitalize } from '@cycle-app/utilities';
import { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { useDebouncedCallback } from 'use-debounce';

import defaultLogo from 'src/assets/workspace-default-logo.png';
import { FieldLabel } from 'src/components/Form/Form.styles';
import { ErrorMessage } from 'src/constants/errors.constants';
import { useFetchCompanyLogo } from 'src/hooks';
import { useFetchCompanyDescription } from 'src/hooks/company/useFetchCompanyDescription';
import { useEnhancedForm, ErrorMap } from 'src/hooks/form/useEnhancedForm';
import { useCreateWorkspace } from 'src/hooks/user/useCreateWorkspace';
import { setOnboarding } from 'src/reactives/lightOnboarding.reactive';
import { LightOnboardingScreen } from 'src/types/onboarding.types';
import { addErrorToaster } from 'src/utils/errorToasters.utils';

import {
  Domain, LogoInput, LogoInputPreview, StyledImageInput, UploadButton, SpinnerContainer, LogoSpinner, Helper, StyledAiIcon,
} from './OnboardingStepWorkspace.styles';
import { WorkspaceJoin } from './WorkspaceJoin';
import { Loading } from '../Onboarding/Onboarding.styles';
import { AsideApp } from '../OnboardingLayout/AsideApp';
import { Logo } from '../OnboardingLayout/Logo';
import { OnboardingLayout } from '../OnboardingLayout/OnboardingLayout';
import {
  ErrorHelper, Footer, Form, FormGrid, NextButton, BackButton,
} from '../OnboardingLayout/OnboardingLayout.styles';

interface OnboardingStepWorkspaceProps {
  me: MeFragment;
}

interface FormData {
  name: string;
  logoString: string;
  logoFile: File | null;
  slug: string;
  description: string;
}

const Loader = () => (
  <Loading>
    <Spinner />
  </Loading>
);

const mutationErrorsMap: ErrorMap<FormData>[] = [
  {
    code: ERROR_CODE.PRODUCT_WITH_SLUG_ALREADY_EXISTS,
    fieldName: 'slug',
    renderMessage: () => ErrorMessage[ERROR_CODE.PRODUCT_WITH_SLUG_ALREADY_EXISTS],
  },
];

export const OnboardingStepWorkspace = ({ me }: OnboardingStepWorkspaceProps) => {
  const [showCreate, setShowCreate] = useState(false);
  const {
    data, loading: isSuggestLoading,
  } = useQuery(ProductGetSuggestionDocument, { fetchPolicy: 'cache-and-network' });

  if (isSuggestLoading) return <Loader />;

  if (!showCreate && data?.getSuggestedProduct?.id) {
    return (
      <WorkspaceJoin
        me={me}
        product={data.getSuggestedProduct}
        onShowCreate={() => setShowCreate(true)}
      />
    );
  }

  return (
    <WorkspaceCreate
      me={me}
      onBack={() => {
        if (data?.getSuggestedProduct?.id) {
          setShowCreate(false);
        } else {
          setOnboarding({ screen: LightOnboardingScreen.AccountPreferences });
        }
      }}
    />
  );
};

interface WorkspaceCreateProps {
  me: MeFragment;
  onBack: VoidFunction;
}

const WorkspaceCreate = ({
  me, onBack,
}: WorkspaceCreateProps) => {
  const domain = me.email.split('@')[1];
  const defaultSlug = domain?.split('.')[0];
  const defaultName = defaultSlug ? capitalize(defaultSlug) : undefined;
  const [shouldFetchLogo, setShouldFetchLogo] = useState(true);

  const {
    handleSubmit,
    setValue,
    watch,
    register,
    control,
    getFieldState,
    formState: { errors },
    displayFieldsErrors,
    clearErrors,
    setError,
  } = useEnhancedForm<FormData>({
    defaultValues: {
      name: defaultName,
      slug: defaultSlug,
      logoFile: null,
      logoString: defaultLogo,
      description: '',
    },
  });

  const {
    fetch: fetchLogo,
    isLoading: isFetchingLogo,
  } = useFetchCompanyLogo();
  const {
    fetch: fetchDescription, isLoading: isDescriptionLoading,
  } = useFetchCompanyDescription();

  const fetchLogoDebounced = useDebouncedCallback(async (data: CompanyLogoInput) => {
    if (!shouldFetchLogo) return;
    const response = await fetchLogo(data);
    const logoString = response?.companyLogo;
    if (logoString) setValue('logoString', logoString);
  }, 800);

  useEffect(() => {
    if (domain) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      fetchLogoDebounced({ domain });
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      fetchDescription(domain).then(result => {
        if (result?.companyDescription) setValue('description', result.companyDescription);
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    logoString, name, slug,
  } = watch();

  const {
    createWorkspace, isLoading,
  } = useCreateWorkspace(me, {
    onError: (apolloError) => {
      if (apolloError.message.match('code 413')) {
        setError('logoFile', {
          type: 'manual',
          message: 'This image was not accepted. Please try another one.',
        });
      } else {
        addErrorToaster({
          message: ErrorMessage._GENERIC,
        });
      }
    },
  });

  const onSubmit = async (data: FormData) => {
    const logoInput = data.logoFile
      ? { avatar: data.logoFile }
      : { avatarUrl: data.logoString };
    const result = await createWorkspace({
      variables: {
        description: data.description,
        name: data.name,
        slug: data.slug,
        logo: logoInput,
        inviteMe: true,
      },
    });
    if (result.errors) {
      displayFieldsErrors(result.errors, mutationErrorsMap);
      return;
    }
    setOnboarding({ screen: LightOnboardingScreen.FlowSelection });
  };

  return (
    <OnboardingLayout
      title="Create your workspace"
      aside={(
        <AsideApp
          color={me.color}
          logo={(
            <Logo
              src={logoString}
              name={name}
            />
          )}
          avatar={(
            <AvatarLegacy
              user={me}
              size={18}
              userColor={me.color}
              src={me.avatar?.url}
              pending={false}
            />
          )}
        />
      )}
      main={(
        <Form onSubmit={handleSubmit(onSubmit)}>
          <FormGrid>
            <div>
              <FieldLabel>
                Company logo
              </FieldLabel>
              <StyledImageInput
                previewModalTitle="Confirm your logo"
                previewModalSubmitLabel="Set new logo"
                previewBorderColor={me.color}
                onChange={async (newLogoFile) => {
                  clearErrors('logoFile');
                  setValue('logoFile', newLogoFile);
                  const logoSrc = await readFileSrc(newLogoFile);
                  setValue('logoString', logoSrc);
                  setShouldFetchLogo(false);
                }}
              >
                {(inputRef) => (
                  <LogoInput>
                    {isFetchingLogo
                      ? (
                        <SpinnerContainer>
                          <LogoSpinner />
                        </SpinnerContainer>
                      )
                      : (
                        <LogoInputPreview
                          onClick={() => inputRef.current?.click()}
                          src={logoString}
                        />
                      )}

                    <UploadButton
                      variant="ternary"
                      size="L"
                      onClick={(e) => {
                        e.preventDefault();
                        inputRef.current?.click();
                      }}
                    >
                      Upload image
                    </UploadButton>
                  </LogoInput>
                )}
              </StyledImageInput>
              {errors.logoFile?.message && (
                <ErrorHelper
                  $hasError
                  $size="S"
                >
                  {errors.logoFile.message}
                </ErrorHelper>
              )}
            </div>
            <div>
              <Input
                autoFocus
                label="Company name"
                placeholder="Example"
                error={errors.name?.message}
                {...register('name', {
                  required: true,
                  onChange: (e) => {
                    if (!getFieldState('slug').isDirty || !slug) {
                      clearErrors('slug');
                      setValue('slug', slugify(e.target.value));
                      // eslint-disable-next-line @typescript-eslint/no-floating-promises
                      fetchLogoDebounced({ name: e.target.value });
                    }
                  },
                })}
              />
              <Helper>
                Your company or organization name
              </Helper>
            </div>
            <div>
              <FieldLabel>
                Company description
                <TooltipLegacy
                  withWrapper={false}
                  placement="top"
                  content="This helps with AI calibration"
                >
                  <StyledAiIcon size={12} />
                </TooltipLegacy>
                {isDescriptionLoading && (
                  <Spinner
                    size={12}
                    style={{
                      marginLeft: '6px',
                      opacity: 0.5,
                    }}
                  />
                )}
              </FieldLabel>
              <TextArea
                disabled={isDescriptionLoading}
                style={{ minHeight: '120px' }}
                {...register('description')}
              />
            </div>
            <div>
              <Controller
                control={control}
                rules={{
                  required: true,
                }}
                name="slug"
                render={({
                  field: {
                    value, onChange,
                  },
                }) => {
                  return (
                    <Input
                      iconBefore={(
                        <Domain>
                          cycle.app/
                        </Domain>
                      )}
                      iconPadding={80}
                      value={slugify(value, false)}
                      onChange={e => {
                        onChange(e);
                        // eslint-disable-next-line @typescript-eslint/no-floating-promises
                        fetchLogoDebounced({ name: e.target.value });
                      }}
                      label="Domain"
                      error={errors.slug?.message}
                      helperSize="S"
                    />
                  );
                }}
              />
            </div>
          </FormGrid>
          <Footer>
            <BackButton
              size="M"
              onClick={onBack}
            >
              Back
            </BackButton>
            <NextButton
              size="M"
              isLoading={isLoading}
              disabled={!name.trim() || !slug.trim() || !!Object.keys(errors).length}
              type="submit"
            >
              Next
            </NextButton>
          </Footer>
        </Form>
      )}
    />
  );
};
