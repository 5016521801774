import { Color } from '@cycle-app/graphql-codegen';
import { FC } from 'react';

import { Label, EmojiPickerStyled } from './EmojiInput.styles';
import { Emoji } from '../../Emoji/Emoji';
import { EmojiData } from '../../EmojiPicker/EmojiPicker';

export interface EmojiInputProps {
  emoji: string;
  label?: string;
  onSelect: (emoji: EmojiData) => void;
  className?: string;
  color?: Color;
}

export const EmojiInput: FC<EmojiInputProps> = ({
  emoji, label, onSelect, className,
}) => (
  <div>
    {label && (
      <Label>
        Icon
      </Label>
    )}
    <EmojiPickerStyled
      onSelect={onSelect}
      className={className}
    >
      <Emoji
        emoji={emoji}
        size={24}
      />
    </EmojiPickerStyled>
  </div>
);
