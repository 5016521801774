import { SelectPanel, Spinner, AvatarLegacy, SelectOption } from '@cycle-app/ui';
import { FC, useMemo } from 'react';
import { useController, Control } from 'react-hook-form';

import DropdownLayer from 'src/components/DropdownLayer/DropdownLayer';
import { useGithubAssignees } from 'src/hooks/api/queries/integrations/useGithubAssignees';
import useOptimizedBooleanState from 'src/hooks/useOptimizedBooleanState';
import { setLastGitHubAssigneesUsed } from 'src/reactives/githubRepository.reactive';
import { CreateGithubIssueFormValues } from 'src/types/integrations.types';
import { Layer } from 'src/types/layers.types';

import { Container, Label, Caret, Select } from './AssigneesField.styles';
import { SelectedAssignees } from './SelectedAssignees/SelectedAssignees';

type Props = {
  control: Control<CreateGithubIssueFormValues>;
};

export const AssigneesField: FC<React.PropsWithChildren<Props>> = ({ control }) => {
  const {
    assignees, isLoading,
  } = useGithubAssignees();
  const [isAssigneesVisible, { toggleCallback: toggleIsAssigneesVisible }] = useOptimizedBooleanState(false);
  const {
    field: {
      onChange, onBlur, name, ref, value,
    },
  } = useController({
    name: 'assignees',
    control,
    defaultValue: [],
  });

  const options: SelectOption[] = useMemo(() => assignees.map(a => ({
    label: a.login,
    value: a.id,
    icon: a.avatarUrl ? <AvatarLegacy src={a.avatarUrl} /> : undefined,
    selected: value?.some(({ id }) => id === a.id) ?? false,
  })) || [], [assignees, value]);

  return (
    <Container>
      <Label>
        Assignees
      </Label>
      <DropdownLayer
        layer={Layer.DropdownModal}
        visible={isAssigneesVisible}
        hide={() => {
          onBlur();
          toggleIsAssigneesVisible();
        }}
        placement="bottom-start"
        content={(
          <SelectPanel
            title="GitHub assignees"
            options={options}
            isMulti
            onOptionsChange={(newOptions) => {
              const newSelectedAssigneesIds = newOptions.map(newOption => newOption.value);
              const newAssignees = assignees.filter(a => newSelectedAssigneesIds.includes(a.id));
              onChange(newAssignees);
              setLastGitHubAssigneesUsed(newSelectedAssigneesIds);
            }}
            selectType="checkbox"
          />
        )}
      >
        <Select
          name={name}
          ref={ref}
          onClick={toggleIsAssigneesVisible}
          type="button"
          forceFocus={isAssigneesVisible}
          disabled={!assignees.length}
        >
          {isLoading
            ? <Spinner />
            : <SelectedAssignees assignees={value} />}
          <Caret />
        </Select>
      </DropdownLayer>
    </Container>
  );
};
