import { ChangelogBaseFragment } from '@cycle-app/graphql-codegen';
import { Helmet } from 'react-helmet-async';
import { FormProvider, useForm, useFormState } from 'react-hook-form';
import { Prompt, matchPath } from 'react-router-dom';

import { ChangelogTitle } from 'src/components/PageTitle';
import { routing, PageId } from 'src/constants/routing.constant';

import { ChangelogBuilderContent } from './ChangelogBuilderContent';
import { ChangelogBuilderFooter } from './ChangelogBuilderFooter';
import { ChangelogBuilderFormSubmit } from './ChangelogBuilderFormSubmit';
import { ChangelogBuilderHeader } from './ChangelogBuilderHeader';
import { ChangelogBuilderThemeProvider } from './ChangelogBuilderThemeProvider';
import { generateChangelogDefaultValues, useChangelogBuilderForm } from './useChangelogBuilderForm';

export function ChangelogBuilder({ changelog }: { changelog: ChangelogBaseFragment }) {
  const form = useForm({ defaultValues: generateChangelogDefaultValues(changelog) });

  return (
    <FormProvider {...form}>
      <Helmet>
        <link
          rel="preconnect"
          href="https://fonts.googleapis.com"
        />
        <link
          rel="preconnect"
          href="https://fonts.gstatic.com"
          crossOrigin=""
        />
        <link
          // eslint-disable-next-line max-len
          href="https://fonts.googleapis.com/css2?family=Asap:ital,wght@0,100..900;1,100..900&family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&family=Hind:wght@300;400;500;600;700&family=JetBrains+Mono:ital,wght@0,100..800;1,100..800&family=Libre+Baskerville:ital,wght@0,400;0,700;1,400&family=Lora:ital,wght@0,400..700;1,400..700&family=Manrope:wght@200..800&family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Mulish:ital,wght@0,200..1000;1,200..1000&family=Playfair+Display:ital,wght@0,400..900;1,400..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Sora:wght@100..800&family=Source+Sans+3:ital,wght@0,200..900;1,200..900&family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <ChangelogBuilderFormExitPrompt />

      <ChangelogTitle />

      <ChangelogBuilderFormSubmit>
        <ChangelogBuilderThemeProvider className="flex h-full flex-col">
          <ChangelogBuilderHeader />
          <ChangelogBuilderContent />
          <ChangelogBuilderFooter />
        </ChangelogBuilderThemeProvider>
      </ChangelogBuilderFormSubmit>
    </FormProvider>
  );
}

function ChangelogBuilderFormExitPrompt() {
  const { control } = useChangelogBuilderForm();
  const { isDirty } = useFormState({ control });
  return (
    <Prompt
      when={isDirty}
      message={(location) => {
        if (matchPath(location.pathname, routing[PageId.Changelog])) {
          return true;
        }

        return 'Are you sure you want to leave this page? You have unsaved changes that will be lost.';
      }}
    />
  );
}
