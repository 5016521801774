import { Icon } from '@cycle-app/ui';
import { BulbLight, CustomViewsIcon } from '@cycle-app/ui/icons';

import { useIsRoadmapsEnabled } from 'src/hooks';
import { useFeedbackTour } from 'src/hooks/useFeedbackTour';
import { useInsightTour } from 'src/hooks/useInsightTour';
import { useRoadmapTour } from 'src/hooks/useRoadmapTour';
import { setMobileAsideMenu } from 'src/reactives/mobileAsideMenu.reactive';

const cardClasses = 'flex h-[120px] flex-col items-center justify-center gap-2 rounded-xl bg-white text-body font-medium dark:bg-grey-950';

export const HomeViewsSection = () => {
  const { open: openFeedbackTour } = useFeedbackTour();
  const { open: openInsightTour } = useInsightTour();
  const { open: openRoadmapTour } = useRoadmapTour();
  const isRoadmapsEnabled = useIsRoadmapsEnabled();

  return (
    <div className="mx-4 flex flex-col gap-4">
      <div className="grid grid-cols-2 gap-4">
        <button
          className={cardClasses}
          onClick={openFeedbackTour}
        >
          <Icon name="feedback" />
          Feedback
        </button>

        <button
          className={cardClasses}
          onClick={openInsightTour}
        >
          <BulbLight size={17} />
          Insights
        </button>

        {isRoadmapsEnabled && (
          <button
            className={cardClasses}
            onClick={openRoadmapTour}
          >
            <Icon
              name="feature"
              className="size-[18px]"
            />
            Requests
          </button>
        )}
      </div>

      <button
        className="flex h-14 items-center justify-start gap-4 rounded-xl bg-white px-5 text-body font-medium dark:bg-grey-950"
        onClick={() => setMobileAsideMenu({ isVisible: true })}
      >
        <CustomViewsIcon />
        Custom views
      </button>
    </div>
  );
};
