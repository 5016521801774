import { Typography } from '@cycle-app/graphql-codegen';
import { SelectPanel, Icon } from '@cycle-app/ui';
import { useWatch } from 'react-hook-form';

import { ToggleDropdown } from 'src/components/DropdownLayer';

import { CHANGELOG_TYPOGRAPHY } from '../constants/typography';
import { useChangelogBuilderForm } from '../useChangelogBuilderForm';

export function ChangelogBuilderHeaderTypography() {
  const {
    setValue, control,
  } = useChangelogBuilderForm();

  const selectedTypography = useWatch({
    control,
    name: 'typography',
  });

  const handleSelect = (value: Typography) => {
    setValue('typography', value, { shouldDirty: true });
  };

  return (
    <ToggleDropdown
      withWrapper={false}
      content={({ hide }) => (
        <SelectPanel
          hideSearch
          selectedValue={selectedTypography}
          options={CHANGELOG_TYPOGRAPHY}
          onOptionChange={(payload) => {
            handleSelect(payload.value as Typography);
            hide();
          }}
        />
      )}
      button={({ onClick }) => (
        <button
          type="button"
          className="btn-tertiary aria-expanded:btn-hover"
          onClick={onClick}
        >
          <Icon
            name="type"
            className="size-4"
          />
          Typography
        </button>
      )}
    />
  );
}
