/* eslint-disable no-nested-ternary */
import {
  truncate, typo, Button, BOX_SHADOW_COLOR_Z5, boxShadowZ3, boxShadowZ2, ShyScrollbarCss, colorUtils,
} from '@cycle-app/ui';
import { Helper } from '@cycle-app/ui/components/Inputs/Input/Input.styles';
import { ColorTheme } from '@cycle-app/utilities';
import { motion } from 'framer-motion';
import styled, { css, keyframes } from 'styled-components';

import { PlayerContainer as AudioPlayerContainer } from '../AudioPlayer/AudioPlayer.styles';
import { AudioRecorder } from '../AudioRecorder';
import {
  RecordButton, Left, Title as RecordTitle, Time, PlayerContainer as RecorderPlayerContainer, Middle,
} from '../AudioRecorder/AudioRecorder.styles';
import { FileCard } from '../Dropzone/FileCard';
import { LinkInput } from '../Dropzone/LinkInput';

const opacityAnimation = keyframes`
  0% { opacity: 0 }
  100% { opacity: 1 }
`;

export const Hover = styled(motion.div)`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  padding: 6px 24px 0;
  z-index: 1;
`;

export const Container = styled(motion.div)<{
  $isHover: boolean;
  $isLoading: boolean;
}>`
  min-width: 180px;
  height: 6px;
  border-radius: 9999px;
  overflow: hidden;
  cursor: pointer;
  position: relative;
  background-color: ${p => (p.theme.isDark ? ColorTheme.Grey850 : ColorTheme.Grey300)};
  z-index: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.8s;

  ${p => p.$isHover && `
    background-color: ${p.theme.colors.background.secondary};
    box-shadow: ${(p.theme.isDark ? 'unset' : `0px 0px 20px ${BOX_SHADOW_COLOR_Z5}, 0px 25px 30px ${BOX_SHADOW_COLOR_Z5}`)};
  `}

  ${p => !p.$isHover && p.$isLoading && css`
    ${boxShadowZ3}
  `}
`;

export const Loader = styled.div`
  position: absolute;
  width: 0;
  height: 100%;
  border-radius: 9999px;
  background: ${p => (p.theme.isDark ? ColorTheme.Grey700 : ColorTheme.Grey400)};
  left: 0;
  opacity: 0;
  animation: ${opacityAnimation} 0.4s linear 0.2s forwards;
`;

export const Content = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 20px 15px 30px;
  width: 500px;
  height: 74px;
  flex: none;
`;

export const ActiveState = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  gap: 2px;
  width: 100%;
  height: 100%;
  svg {
    color: ${p => p.theme.colors.text.primary};
  }
`;

export const EmptyState = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  width: 100%;
`;

export const Title = styled.div`
  user-select: none;
  ${typo.body500}
  ${truncate}
`;

export const Description = styled.div`
  user-select: none;
  color: ${p => p.theme.colors.text.secondary};
  ${typo.caption400}
  ${truncate}
`;

export const Infos = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Buttons = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;

export const PrimaryButton = styled(Button)`
  border-radius: 9999px;
  flex: none;
  width: 32px;
  height: 32px;
  :disabled {
    --bg: hsl(var(--cycle));
    --color: ${p => p.theme.colors.text.white};
    opacity: 0.5;
    pointer-events: initial; /* click should not upload file */
    cursor: default;
  }
`;

const mainButtonCss = css`
  padding: 0;

  &, :disabled {
    --bg: ${p => (p.theme.isDark ? ColorTheme.Grey850 : ColorTheme.Grey200)};
    --color: ${p => (p.theme.isDark ? ColorTheme.Grey500 : ColorTheme.Grey700)};
  }

  :hover {
    --bg: ${p => (p.theme.isDark ? ColorTheme.Grey800 : colorUtils.darken(ColorTheme.Grey200, 0.04))};
    --color: ${p => (p.theme.isDark ? ColorTheme.Grey500 : ColorTheme.Grey700)};
  }

  :disabled {
    opacity: 1;
    pointer-events: initial; /* click should not upload file */
    cursor: default;
  }
`;

export const MainButton = styled(PrimaryButton).attrs({
  variant: 'light',
})`
  ${mainButtonCss}
`;

export const Label = styled.label`
  ${mainButtonCss}
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--bg);
  color: var(--color);
  cursor: pointer;
  padding: 1px 8px;
  border-radius: 9999px;
  flex: none;
  width: 32px;
  height: 32px;
`;

export const SecondaryButton = styled(PrimaryButton).attrs({
  variant: 'light',
})`
  --bg: ${p => (p.theme.isDark
    ? p.theme.colors.background.secondaryElevated
    : p.theme.colors.background.tertiary)};
  --color: ${p => p.theme.colors.text.secondary};
`;

export const RecorderContainer = styled.div<{ $isVisible: boolean }>`
  position: absolute;
  top: 0;
  inset: 0;
  background: ${p => p.theme.colors.background.secondary};
  padding: 15px 20px 15px 30px;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: default;
  ${p => !p.$isVisible && `
    opacity: 0;
  `}
`;

export const ClipboardContainer = styled(RecorderContainer)``;

export const StyledAudioRecorder = styled(AudioRecorder)`
  border: none;
  margin: 0;
  padding: 0;
  ${Left} {
    gap: 0;
  }
  ${Middle} {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  ${RecorderPlayerContainer}, ${AudioPlayerContainer} {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 32px;
  }
  ${RecordTitle} {
    ${typo.body500}
  }
  ${Time} {
    ${typo.caption400}
  }
  ${RecordButton} {
    width: 32px;
    height: 32px;
    svg {
      width: 16px;
      height: 16px;
    }
  }
`;

export const ImagePreview = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  gap: 16px;
  overflow: hidden;
  padding: 5px;
  margin: -5px;
`;

export const Thumbnail = styled.img`
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 6px;
  ${boxShadowZ2}
  cursor: zoom-in;
`;

export const StyledLinkInput = styled(LinkInput)`
  flex: 1;
  input {
    padding: 0;
    --bgColor: transparent;
    --borderColorFocus: transparent;
    :hover {
      --bgColor: transparent;
    }
    border-color: transparent
  }
  ${Helper} {
    position: absolute;
    top: 45px;
  }
`;

export const ListContainer = styled(motion.div)`
  padding-top: 12px;
`;

export const ListContent = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% - 48px);
  border-radius: 16px;
  border: 1px solid;
  background: ${p => p.theme.colors.background.secondary};
  box-shadow: ${(p => (p.theme.isDark ? 'unset' : `0px 0px 20px ${BOX_SHADOW_COLOR_Z5}, 0px 25px 30px ${BOX_SHADOW_COLOR_Z5}`))};
  border-color: ${(p => (p.theme.isDark ? ColorTheme.Grey850 : 'transparent'))};
  padding: 0 8px;
  overflow: hidden;
`;

export const Scrollable = styled.div`
  ${ShyScrollbarCss};
  max-height: 280px;
  margin-right: -8px;
  padding: 8px 0;
  scrollbar-gutter: stable;
`;

export const StyledFileCard = styled(FileCard)`
  /* using fixed width to fix cross-browser issue */
  width: 482px;
  max-width: 100%;
`;

const bgOpacity1 = keyframes`
  0% { opacity: 0; }
  6%, 20% { opacity: 1; }
  30%, 100% { opacity: 0; }
`;

const bgOpacity2 = keyframes`
  0% { opacity: 0; }
  6%, 20% { opacity: 0.6; }
  30%, 100% { opacity: 0; }
`;

const bgSpin = keyframes`
  to {
    --border-angle1: 1turn;
    --border-angle2: 1.5turn;
  }
`;

export const Border = styled.div<{ $discover: boolean }>`
  position: absolute;
  inset: 0;
  border-radius: inherit;
  border: 1px solid ${p => (p.theme.isDark ? ColorTheme.Grey850 : 'transparent')};
  z-index: -1;

  ${p => p.$discover && css`
    --bg: ${p.theme.isDark ? ColorTheme.Grey900 : '#f9f9f9'};
    --color1: #A990FF;
    --color2: #57CFEF;
    --color3: #7FEA96;
    background: var(--bg);

    @property --border-angle1 {
      syntax: "<angle>";
      inherits: true;
      initial-value: 0turn;
    }

    @property --border-angle2 {
      syntax: "<angle>";
      inherits: true;
      initial-value: .5turn;
    }

    :before {
      content: "";
      position: absolute;
      inset: -1px;
      border: solid ${p.theme.isDark ? '1px' : '2px'} transparent;
      border-radius: inherit;
      opacity: 1;

      --border-angle1: 0turn;
      --border-angle2: .5turn;

      background:
        conic-gradient(var(--bg), var(--bg)) padding-box,
        conic-gradient(from var(--border-angle1), transparent 70%, var(--color1), var(--color2) 95%, var(--color3) 100%) border-box,
        conic-gradient(from var(--border-angle2), transparent 70%, var(--color1), var(--color2) 95%, var(--color3) 100%) border-box;

      background-position: center center;

      animation:
        ${bgSpin} 5s linear infinite,
        ${bgOpacity1} 5s linear infinite;
    }
  `}

  ${p => p.$discover && p.theme.isDark && css`
    :after {
      content: "";
      position: absolute;
      inset: -1px;
      border: solid 1px transparent;
      border-radius: inherit;
      filter: blur(5px);
      opacity: 0.7;

      --border-angle1: 0turn;
      --border-angle2: .5turn;

      background:
        conic-gradient(var(--bg), var(--bg)) padding-box,
        conic-gradient(from var(--border-angle1), transparent 70%, var(--color1), var(--color2) 95%, var(--color3) 100%) border-box,
        conic-gradient(from var(--border-angle2), transparent 70%, var(--color1), var(--color2) 95%, var(--color3) 100%) border-box;

      background-position: center center;

      animation:
        ${bgSpin} 5s linear infinite,
        ${bgOpacity2} 5s linear infinite;
    }
  `}
`;
