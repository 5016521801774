import { TooltipLegacy } from '@cycle-app/ui';
import { FileType } from '@cycle-app/utilities';
import { ReactNode } from 'react';

import { Action } from 'src/components/Editor/DiscoverTooltip/DiscoverTooltip.styles';
import { useEditorContext } from 'src/contexts/editorContext';
import { removeTranscriptTooltip, useTranscriptTooltip } from 'src/reactives/transcript.reactive';

type TranscriptTooltipProps = {
  dataId?: string;
  transcript: VoidFunction;
  children: ReactNode;
};

export const TranscriptTooltip = ({
  dataId, transcript, children,
}: TranscriptTooltipProps) => {
  const hide = () => removeTranscriptTooltip(dataId);
  const dataToTranscript = useTranscriptTooltip(dataId);
  const fileType = dataToTranscript?.type;
  const disabled = useEditorContext(ctx => !!ctx.disabledFeatures?.includes('transcript'));
  return (
    <TooltipLegacy
      variant="shiny"
      withPortal
      withWrapper={false}
      interactive
      controlled
      visible={!!dataToTranscript}
      hide={hide}
      placement="bottom-start"
      disabled={disabled}
      title={getTitle(fileType)}
      content={getContent(fileType)}
      footer={(
        <>
          <Action
            onClick={() => {
              transcript();
              hide();
            }}
            autoFocus
          >
            {getTranscriptLabel(fileType)}
          </Action>
          <Action
            onClick={hide}
          >
            Ignore
          </Action>
        </>
      )}
    >
      {children}
    </TooltipLegacy>
  );
};

const getTitle = (fileType?: FileType) => {
  if (fileType === 'image') return 'You uploaded an image';
  if (fileType === 'video') return 'You uploaded a video';
  if (fileType === 'audio') return 'You uploaded an audio';
  return 'You uploaded a file';
};

const getContent = (fileType?: FileType) => {
  if (fileType === 'image') return 'Run OCR to extract characters on the image';
  return "Get the file's transcript";
};

const getTranscriptLabel = (fileType?: FileType) => {
  if (fileType === 'image') return 'Run OCR';
  return 'Get transcript';
};
