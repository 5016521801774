import { Node } from "@tiptap/core";

export const sectionExtension = Node.create({
  name: "section",
  group: "block",
  content: "block+",

  addAttributes() {
    return {
      'data-type': {
        default: 'summary',
        parseHTML: el => el.getAttribute('data-type'),
        renderHTML: attrs => ({ 'data-type': attrs['data-type'] }),
      },
    };
  },

  parseHTML() {
    return [{
      tag: "section[data-type='summary']",
    }];
  },

  renderHTML({ HTMLAttributes }) {
    return ['section', HTMLAttributes, ['div', 0]]
  },
});