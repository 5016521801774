import styled, { keyframes, css } from 'styled-components';

import { colorUtils } from '../../utils/cssFunctions.util';

const shine = keyframes`
  100% {
    transform: translateX(100%);
  }
`;

const commonCss = css`
  position: relative;
  color: ${p => p.theme.colors.skeleton.bg};
  background-color: ${p => colorUtils.rgba(p.theme.colors.skeleton.bg, 0.3)};
  overflow: hidden;
  pointer-events: none;
  isolation: isolate;

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
      90deg,
      ${p => colorUtils.rgba(p.theme.colors.skeleton.bg, 0)} 0,
      ${p => colorUtils.rgba(p.theme.colors.skeleton.bg, 0.2)} 20%,
      ${p => colorUtils.rgba(p.theme.colors.skeleton.bg, 0.4)} 60%,
      ${p => colorUtils.rgba(p.theme.colors.skeleton.bg, 0)} 100%
    );
    animation: ${shine} infinite;
    animation-duration: 1.2s;
    content: '';
  }
`;

export const Skeleton = styled.div<{
  width?: number;
  height?: number;
  $inline?: boolean;
}>`
  ${commonCss}
  border-radius: 4px;
  width: 100%;
  height: 6px;
  ${p => p.width && css`
    width: ${p.width}px;
  `}
  ${p => p.height && css`
    height: ${p.height}px;
  `}
  ${p => p.$inline && css`
    display: inline-block;
  `}
`;

export const CircleSkeleton = styled.div<{
  width?: number;
  height?: number;
}>`
  ${commonCss}
  border-radius: 50%;
  width: 100%;
  height: 100%;
  ${p => p.width && css`
    width: ${p.width}px;
  `}
  ${p => p.height && css`
    height: ${p.height}px;
  `}
`;
