import { useAtomsDevtools } from 'jotai-devtools';

/**
 * Provider that manages ReduxDevTools extension for a all Jotai atoms.
 * It only works in a non-production environment.
 * @see https://jotai.org/docs/tools/devtools#useatomsdevtools
 */
export const AtomsDevtools = ({ children }: { children: JSX.Element }) => {
  useAtomsDevtools('Cycle Atoms');
  return children;
};
