import { TooltipLegacy, Icon, Input } from '@cycle-app/ui';
import { CSSProperties, useRef, useState } from 'react';

import DialogModal from 'src/components/DialogModal/DialogModal';
import { DropdownLayer } from 'src/components/DropdownLayer';
import { useDocPanelContext } from 'src/contexts/docPanelContext';
import { Layer } from 'src/types/layers.types';

import { BlockButton } from './DocPanelBody.styles';

export const SpeakerButton = ({
  speaker, style,
}: {
  speaker: string;
  style?: CSSProperties;
}) => {
  const editor = useDocPanelContext(ctx => ctx.editor);
  const ref = useRef<HTMLButtonElement>(null);
  const [editSpeaker, setEditSpeaker] = useState(false);
  const [inputValue, setInputValue] = useState(speaker);
  const [modalOpen, setModalOpen] = useState(false);
  if (!editor) return null;
  return (
    <>
      <TooltipLegacy
        content="Edit speaker"
        placement="top"
        withWrapper={false}
        withPortal
      >
        <BlockButton
          style={style}
          onClick={() => setEditSpeaker(true)}
          forceFocus={modalOpen}
          ref={ref}
        >
          <Icon
            name="user-edit"
            className="size-3.5!"
          />
        </BlockButton>
      </TooltipLegacy>

      <DropdownLayer
        withPortal
        visible={editSpeaker}
        hide={() => setEditSpeaker(false)}
        layer={Layer.DropdownModalZ3}
        placement="bottom-start"
        reference={ref}
        content={(
          <form
            className="m-2"
            onSubmit={e => {
              e.preventDefault();
              if (inputValue.trim()) {
                setModalOpen(true);
              }
            }}
          >
            <label>
              <div className="mb-1 text-body font-medium">
                Speaker name
              </div>
              <Input
                name="speaker"
                autoFocus
                inputSize="S"
                value={inputValue}
                onChange={e => setInputValue(e.target.value)}
                className="min-w-64"
              />
            </label>
          </form>
        )}
      />

      {modalOpen && (
        <DialogModal
          title="Want to replace all?"
          info={(
            <span>
              <span className="font-medium">
                {speaker}
              </span>
              {' will be replaced by '}
              <span className="font-medium">
                {inputValue.trim()}
              </span>
              {' everywhere in the doc'}
            </span>
          )}
          hide={() => setModalOpen(false)}
          confirmLabel="Replace all"
          onConfirm={() => {
            const content = editor.getHTML();
            const newContent = content.replaceAll(speaker, inputValue.trim());
            editor.commands.setContent(newContent);
            setEditSpeaker(false);
          }}
        />
      )}
    </>
  );
};
