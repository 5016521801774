import {
  forwardRef,
  InputHTMLAttributes,
  ReactNode,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';

import {
  Container,
  Label,
  StyledInput,
  InputContainer,
  IconBeforeContainer,
  Helper,
  ActionIcon,
  Required,
  EndContainer,
  IconContainer,
  Prefix,
  Suffix,
} from './Input.styles';
import { InfoIconOutline } from '../../../icons';
import { TooltipLegacy, TooltipLegacyProps } from '../../TooltipLegacy/Tooltip';

export type InputProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'prefix'> & {
  label?: string | ReactNode;
  tooltipLabel?: string;
  tooltipProps?: Omit<TooltipLegacyProps, 'content'>;
  tooltipIcon?: ReactNode;
  iconBefore?: ReactNode;
  iconAfter?: ReactNode;
  iconPadding?: number;
  inputHidden?: boolean;
  onClickIcon?: () => void;
  isRequired?: boolean;
  error?: ReactNode | string;
  helper?: string | ReactNode;
  readOnly?: boolean;
  endInput?: ReactNode;
  helperSize?: 'S' | 'M';
  prefix?: ReactNode;
  suffix?: ReactNode;
  inputSize?: 'S' | 'M';
};

export const Input = forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  const {
    className,
    label,
    tooltipLabel,
    tooltipProps = {},
    tooltipIcon = <InfoIconOutline />,
    iconBefore,
    iconAfter,
    iconPadding,
    inputHidden,
    onClickIcon,
    isRequired,
    error,
    helper,
    endInput,
    helperSize,
    prefix,
    suffix,
    inputSize,
    ...inputProps
  } = props;

  const prefixRef = useRef<HTMLDivElement>(null);
  const suffixRef = useRef<HTMLDivElement>(null);
  const [paddingLeft, setPaddingLeft] = useState<number>();
  const [paddingRight, setPaddingRight] = useState<number>();

  useLayoutEffect(() => {
    if (prefixRef.current) {
      setPaddingLeft(prefixRef.current.offsetWidth);
    }
    if (suffixRef.current) {
      setPaddingRight(suffixRef.current.offsetWidth);
    }
  }, []);

  return (
    <Container
      className={className}
      onKeyDown={e => {
        if (['ArrowLeft', 'ArrowRight'].includes(e.code)) e.stopPropagation();
      }}
      style={{
        ...inputHidden && { display: 'none' },
      }}
    >
      {label && (
        <Label
          {...inputProps.id ? {
            as: 'label',
            htmlFor: inputProps.id,
          } : {
            as: 'div',
          }}
        >
          {label}

          {isRequired && (
            <Required>
              (required)
            </Required>
          )}

          {tooltipLabel && (
            <TooltipLegacy
              content={tooltipLabel}
              {...tooltipProps}
            >
              <IconContainer>
                {tooltipIcon}
              </IconContainer>
            </TooltipLegacy>
          )}
        </Label>
      )}

      <InputContainer
        $hasIconBefore={!!iconBefore}
        $hasIconAfter={!!iconAfter || !!endInput}
        $iconPadding={iconPadding}
      >
        {prefix && (
          <Prefix ref={prefixRef}>
            {prefix}
          </Prefix>
        )}

        <StyledInput
          ref={ref}
          {...inputProps}
          $hasError={!!error}
          $paddingLeft={paddingLeft}
          $paddingRight={paddingRight}
          $size={inputSize}
        />

        {iconBefore && (
          <IconBeforeContainer>
            {iconBefore}
          </IconBeforeContainer>
        )}

        {iconAfter && (
          <ActionIcon onClick={onClickIcon}>
            {iconAfter}
          </ActionIcon>
        )}

        {endInput && (
          <EndContainer>
            {endInput}
          </EndContainer>
        )}

        {suffix && (
          <Suffix ref={suffixRef}>
            {suffix}
          </Suffix>
        )}
      </InputContainer>

      {helper && !error && (
        <Helper
          role="alert"
          $size={helperSize}
        >
          {helper}
        </Helper>
      )}

      {error && (
        <Helper
          role="alert"
          $hasError
          $size={helperSize}
        >
          {error}
        </Helper>
      )}
    </Container>
  );
});

export const InputComponents = {
  ActionIcon,
  EndContainer,
  Helper,
  Required,
  IconBeforeContainer,
};
