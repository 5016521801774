import { ConversationUpdateDocument } from '@cycle-app/graphql-codegen';

import useSafeMutation from '../useSafeMutation';

export const useConversationUpdate = () => {
  const [updateMutation] = useSafeMutation(ConversationUpdateDocument);

  const update = ({
    id, title,
  }: { id: string; title: string }) => {
    return updateMutation({
      variables: {
        id,
        title,
      },
      optimisticResponse: {
        updateConversation: {
          id,
          title,
        },
      },
    });
  };

  return { update };
};
