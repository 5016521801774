import { IntegrationType } from '@cycle-app/graphql-codegen';
import { TooltipLegacy } from '@cycle-app/ui';
import {
  TrashIcon, OpenIcon, DuplicateIcon, WheelIcon, SyncIcon, SyncStrikeIcon, InfoIconOutline,
} from '@cycle-app/ui/icons';
import { useRef } from 'react';

import DialogModal from 'src/components/DialogModal/DialogModal';
import DotsMenuLayer from 'src/components/DotsMenuLayer/DotsMenuLayer';
import { HELP_CYCLE_API } from 'src/constants/help.constants';
import { CHROME_STORE_CYCLE_APP_URL, CUSTOMERS_INTEGRATIONS, integrationsDataMap, ZAPIER_CYCLE_APP_URL } from 'src/constants/integrations.constants';
import { PageId } from 'src/constants/routing.constant';
import { useIntegration, useIsFreePlan, useOptimizedBooleanState, useSlackTour } from 'src/hooks';
import { useIntegrationSync } from 'src/hooks/integration/useIntegrationSync';
import { useClickOutside } from 'src/hooks/useClickOutside';
import { useNavigateToSettings } from 'src/hooks/useNavigateToSettings';
import { setLimitationsModal, useGetPermission } from 'src/reactives';
import { useGetIntegrationSync } from 'src/reactives/integrationSync.reactive';
import { setIntegrationSyncModal } from 'src/reactives/integrationSyncModal.reactive';
import { setTours, useTours } from 'src/reactives/productTours.reactive';
import { setUserPreferences, useUserPreferencesValue } from 'src/reactives/userPreferences.reactive';
import { FrontEndIntegration, Integration } from 'src/types/integrations.types';
import { TourName } from 'src/types/productTour.types';
import { copyToClipboard } from 'src/utils/clipboard.utils';
import { integrationNameTitles } from 'src/utils/integrations.utils';

import { UninstallIntegrationModal } from '../UninstallIntegrationModal';

interface Props {
  email?: string | null;
  integrationId?: string;
  integrationType: Integration;
  settingsLabel?: string;
}

export const IntegrationItemMenu = ({
  email,
  integrationId,
  integrationType,
  settingsLabel,
}: Props) => {
  const [isUninstallModalVisible, {
    setTrueCallback: showUninstallModal, setFalseCallback: hideUninstallModal,
  }] = useOptimizedBooleanState(false);
  const { navigate } = useNavigateToSettings();
  const { canReadSettings } = useGetPermission();
  const { isCustomerSynced } = useIntegration({ type: integrationType });
  const integrationSync = useIntegrationSync();
  const [isCustomerSyncWarningVisible, {
    setTrueCallback: showCustomerSyncWarning, setFalseCallback: hideCustomerSyncWarning,
  }] = useOptimizedBooleanState(false);
  const integrations = useGetIntegrationSync();
  const { open: openSlackLearnMoreTour } = useSlackTour();
  const isFreePlan = useIsFreePlan();

  const { endedTour } = useTours();
  const { discoverSlack } = useUserPreferencesValue();
  const discoverTooltipRef = useRef<HTMLDivElement>(null);

  useClickOutside(discoverTooltipRef, () => {
    if (endedTour === TourName.SLACK) {
      setTours({ endedTour: undefined });
      setUserPreferences({ discoverSlack: false });
    }
  });

  const isSlack = integrationType === IntegrationType.Slack;
  const isChrome = integrationType === IntegrationType.Extension;
  const isZappier = integrationType === IntegrationType.Zapier;
  const isCycleApi = integrationType === FrontEndIntegration.CYCLE_API;
  const isCustomerIntegration = CUSTOMERS_INTEGRATIONS.includes(integrationType);
  const isCallRecording = integrationType === IntegrationType.Meeting;

  const isSyncing = integrations[integrationType]?.isSyncing;

  // eslint-disable-next-line no-nested-ternary
  const syncLabel = isSyncing
    ? 'Customer sync in progress'
    : isCustomerSynced
      ? 'Disable customer sync'
      : 'Enable customer sync';

  const showUninstall = !isChrome && !isZappier && !isCycleApi && !isCallRecording;

  const title = integrationNameTitles[integrationType];

  const { learnUrl } = integrationsDataMap[integrationType];

  let url = '';
  if ((isZappier || isChrome || isCycleApi) && !isFreePlan) {
    // eslint-disable-next-line no-nested-ternary
    url = isZappier
      ? ZAPIER_CYCLE_APP_URL
      : isCycleApi
        ? HELP_CYCLE_API
        : CHROME_STORE_CYCLE_APP_URL;
  }

  const discover = isSlack && discoverSlack && endedTour === TourName.SLACK;

  return (
    <>
      <div style={{ position: 'relative' }}>
        {discover && learnUrl &&
          (
            <TooltipLegacy
              visible
              variant="shiny"
              content={title ? `You can learn more about ${title} anytime here` : 'You can learn more anytime here'}
              withPortal
              style={{
                alignItems: 'center',
                display: 'flex',
                height: '100%',
                justifyContent: 'center',
                pointerEvents: 'none',
                position: 'absolute',
                width: '100%',
              }}
            >
              <span ref={discoverTooltipRef} />
            </TooltipLegacy>
          )}
        <DotsMenuLayer
          placement="bottom-end"
          options={[
            ...isCycleApi ? [{
              value: 'settings',
              icon: <WheelIcon />,
              label: settingsLabel || 'Open settings',
              onSelect: () => {
                if (!canReadSettings) {
                  setLimitationsModal({ action: 'SETTINGS_READ' });
                  return;
                }
                navigate(PageId.SettingsAPI);
              },
            }] : [],
            ...isSlack && learnUrl ? [{
              value: 'more',
              icon: <InfoIconOutline />,
              label: 'Learn more',
              onSelect: () => {
                openSlackLearnMoreTour();
              },
            }] : [],
            ...isCustomerIntegration ? [{
              value: 'sync',
              icon: isCustomerSynced ? <SyncStrikeIcon /> : <SyncIcon />,
              label: syncLabel,
              disabled: isSyncing,
              onSelect: () => {
                if (!canReadSettings) {
                  setLimitationsModal({ action: 'SETTINGS_READ' });
                  return;
                }
                if (isCustomerSynced) {
                  showCustomerSyncWarning();
                } else {
                  setIntegrationSyncModal({ integrationType });
                }
              },
            }] : [],
            ...email ? [{
              value: 'copy',
              icon: <DuplicateIcon />,
              label: 'Copy',
              onSelect: () => copyToClipboard({
                text: email,
                notification: 'Email copied to clipboard',
              }),
            }] : [],
            ...url ? [{
              value: 'open',
              icon: (
                <OpenIcon
                  width="16"
                  height="16"
                />
              ),
              label: 'Open',
              onSelect: () => window.open(url, '_blank'),
            }] : [],
            ...showUninstall ? [{
              value: 'delete',
              icon: <TrashIcon />,
              label: 'Uninstall',
              onSelect: () => {
                if (!canReadSettings) {
                  setLimitationsModal({ action: 'SETTINGS_READ' });
                  return;
                }
                showUninstallModal();
              },
            }] : [],
          ]}
        />
      </div>
      {integrationId && (
        <UninstallIntegrationModal
          hide={hideUninstallModal}
          id={integrationId}
          isVisible={isUninstallModalVisible}
          type={integrationType}
        />
      )}
      {isCustomerSyncWarningVisible && (
        <DialogModal
          autoHide={false}
          title="Disable customer sync"
          info={`Are you sure you want to disable ${title} customer sync? All the ${title} customers without feedback will be removed from Cycle.`}
          onCancel={hideCustomerSyncWarning}
          onConfirm={async () => {
            await integrationSync.customerUnsync(integrationType);
            hideCustomerSyncWarning();
          }}
          loading={integrationSync.isLoading}
          hide={hideCustomerSyncWarning}
          confirmLabel="Disable"
        />
      )}
    </>
  );
};
