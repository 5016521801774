import { IntegrationType } from '@cycle-app/graphql-codegen';
import { Button, ButtonSize, SelectPanel, Icon } from '@cycle-app/ui';

import { PRIVACY_POLICY } from 'src/constants/policies.constants';
import { useInstallIntegration, useOptimizedBooleanState } from 'src/hooks';
import { useMeetingsIntegration } from 'src/hooks/integration/useMeetingsIntegration';
import { Layer } from 'src/types/layers.types';

import { ToggleDropdown, ToggleDropdownProps } from '../DropdownLayer';
import { SoonBadge } from '../SoonBadge';
import { Link, ModalHeader, StyledModal, Actions } from './MeetingButtonInstall.styles';

type Props = {
  button?: ToggleDropdownProps['button'];
  size?: ButtonSize;
};

export const MeetingButtonInstall = ({
  button, size = 'M',
}: Props) => {
  const install = useInstallIntegration();
  const { integration } = useMeetingsIntegration();
  const [isConsentModalOpened, {
    setTrueCallback: openConsentModal, setFalseCallback: closeConsentModal,
  }] = useOptimizedBooleanState(false);
  return (
    <>
      <ToggleDropdown
        placement="bottom-end"
        width={286}
        layer={Layer.DropdownModal}
        content={contentProps => (
          <SelectPanel
            hideSearch
            options={[{
              icon: (
                <Icon
                  name="brand/google-calendar"
                  className="size-3.5"
                />
              ),
              value: 'google',
              label: 'Connect Google Calendar',
            }, {
              icon: (
                <Icon
                  name="brand/outlook"
                  className="size-4"
                />
              ),
              value: 'outlook',
              label: 'Connect Outlook Calendar',
              end: <SoonBadge />,
              disabled: true,
            }]}
            onOptionChange={() => {
              contentProps?.hide();
              openConsentModal();
            }}
          />
        )}
        button={button || (buttonProps => (
          <Button
            full
            size={size}
            onClick={buttonProps.onClick}
          >
            Enable
          </Button>
        ))}
      />
      {isConsentModalOpened && (
        <StyledModal hide={closeConsentModal}>
          <div>
            <ModalHeader>
              Call recording privacy policy
            </ModalHeader>
            <p className="mt-3 text-secondary">
              By using the meeting integration, you agree to the&nbsp;
              <Link
                target="_blank"
                rel="noreferrer"
                href={PRIVACY_POLICY}
              >
                terms and conditions
              </Link>
              &nbsp;and more specifically, how we process the data with Deepgram to generate the transcript.
            </p>
          </div>
          <Actions>
            <Button
              size="M"
              variant="outlined-alt"
              onClick={closeConsentModal}
            >
              Decline
            </Button>
            <Button
              size="M"
              onClick={async () => {
                closeConsentModal();
                await install(IntegrationType.Meeting, integration);
              }}
            >
              Accept
            </Button>
          </Actions>
        </StyledModal>
      )}
    </>
  );
};
