import { useFragment } from '@apollo/client';
import { ReleaseBaseFragmentDoc } from '@cycle-app/graphql-codegen';

export const useReleaseBaseFragment = (releaseId: string | null | undefined) => {
  const {
    data, complete,
  } = useFragment({
    fragment: ReleaseBaseFragmentDoc,
    fragmentName: 'ReleaseBase',
    from: releaseId ?? '',
  });
  return complete ? data : null;
};
