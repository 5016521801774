import { Separator as RadixSeparator } from 'radix-ui';
import { ComponentPropsWithoutRef, forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';

interface SeparatorProps extends Omit<ComponentPropsWithoutRef<typeof RadixSeparator.Root>, 'asChild' | 'orientation'> {}

export const Separator = forwardRef<HTMLDivElement, SeparatorProps>(
  function Separator({
    className,
    ...props
  }, ref) {
    return (
      <RadixSeparator.Root
        {...props}
        ref={ref}
        orientation="horizontal"
        className={twMerge(
          'bg-grey-200 dark:bg-grey-850 w-full h-px my-4',
          className,
        )}
      />
    );
  },
);
