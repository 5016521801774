import { AiIcon } from '@cycle-app/ui/icons';
import { memo } from 'react';
import { twJoin } from 'tailwind-merge';

import { useFeatureFlag } from 'src/hooks/useFeatureFlag';
import { openAskPanel, useGetDocPanel } from 'src/reactives/docRightPanel.reactive';

type Props = {
  docId: string;
  className?: string;
};

export const DocAsk = memo<Props>(({ className }) => {
  const isAskEnabled = useFeatureFlag('ask').isEnabled;
  const docPanel = useGetDocPanel();
  if (!isAskEnabled || docPanel.isExpanded && docPanel.askContent) return null;

  return (
    <button
      onClick={() => openAskPanel()}
      className={twJoin(
        'flex size-9 cursor-pointer items-center justify-center rounded-full outline-hidden focus-visible:ring-2',
        'bg-white hover:bg-grey-100 dark:bg-grey-950 dark:hover:bg-grey-850',
        'border border-black/[0.08] dark:border-white/[0.08]',
        className,
      )}
      style={{
        boxShadow: '0px 2px 4px 0px rgba(32, 33, 36, 0.04), 0px 4px 6px -3px rgba(32, 33, 36, 0.04)',
      }}
    >
      <AiIcon
        hasGradient
        size={16}
      />
    </button>
  );
});
