import { TooltipLegacy } from '@cycle-app/ui';
import { CloseIcon }  from '@cycle-app/ui/icons';

import { PageId } from 'src/constants/routing.constant';
import { navigate, useLocation } from 'src/hooks';
import { useNavigateToSettings } from 'src/hooks/useNavigateToSettings';

export function ChangelogBuilderHeaderBackButton() {
  const previousPageId = useLocation().state?.changelogBuilder?.previousPageId;
  const { navigate: navigateToSettings } = useNavigateToSettings();

  return (
    <TooltipLegacy
      content="Exit masterpiece"
      placement="bottom"
      withWrapper={false}
    >
      <button
        type="button"
        className="btn-tertiary btn-sm btn-square"
        onClick={() => {
          if (previousPageId === PageId.SettingsReleases) {
            navigateToSettings(previousPageId);
            return;
          }
          navigate(PageId.Releases);
        }}
      >
        <CloseIcon className="size-3" />
      </button>
    </TooltipLegacy>
  );
}
