import { useEffect, useRef } from 'react';

import { useRouteAccess } from 'src/hooks/router/useRouteAccess';
import { useFastConnection } from 'src/hooks/useNavigatorConnection';
import { PageId } from 'src/hooks/usePageId';
import { useGetPermission } from 'src/reactives/permission.reactive';
import { useIsMobile } from 'src/reactives/responsive.reactive';

export const usePreloadSettings = (preload: VoidFunction) => {
  const loaded = useRef(false);

  const isMobile = useIsMobile();
  const canReadSettings = useGetPermission().canReadSettings;
  const hasBillingAccess = useRouteAccess(PageId.SettingsBilling);
  const isFastConnection = useFastConnection();

  useEffect(() => {
    if (loaded.current) return;
    if (isMobile) return;
    if (!canReadSettings && !hasBillingAccess) return;
    if (!isFastConnection) return;
    loaded.current = true;
    preload();
  }, [preload, isMobile, canReadSettings, hasBillingAccess, isFastConnection]);
};

