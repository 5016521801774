import { Language } from '@cycle-app/graphql-codegen';
import { SelectPanel, Icon } from '@cycle-app/ui';
import { useWatch } from 'react-hook-form';

import { ToggleDropdown } from 'src/components/DropdownLayer';

import { CHANGELOG_LANGUAGES } from '../constants/languages';
import { useChangelogBuilderForm } from '../useChangelogBuilderForm';

export function ChangelogBuilderHeaderLanguage() {
  const {
    setValue, control,
  } = useChangelogBuilderForm();

  const selectedLanguage = useWatch({
    control,
    name: 'language',
  });

  const handleSelect = (value: Language) => {
    setValue('language', value, { shouldDirty: true });
  };

  return (
    <ToggleDropdown
      withWrapper={false}
      content={({ hide }) => (
        <SelectPanel
          hideSearch
          selectedValue={selectedLanguage}
          options={CHANGELOG_LANGUAGES}
          onOptionChange={(payload) => {
            handleSelect(payload.value as Language);
            hide();
          }}
        />
      )}
      button={({ onClick }) => (
        <button
          type="button"
          className="btn-tertiary aria-expanded:btn-hover"
          onClick={onClick}
        >
          <Icon
            name="language-fill"
            className="size-4"
          />
          Language
        </button>
      )}
    />
  );
}
