import { CommandSection } from 'src/types/commandbar.types';

interface Section {
  label: string;
  tagLabel?: string;
  placeholder: string;
  disableSearch?: boolean;
}

export const commandSections: Record<CommandSection, Section> = {
  'go-to-settings': {
    label: 'Go to settings',
    tagLabel: 'Settings',
    placeholder: 'Select a page…',
  },
  'create-workspace': {
    label: 'Create workspace',
    placeholder: 'Workspace name',
    disableSearch: true,
  },
  workspace: {
    label: 'Go to workspace',
    placeholder: 'Select a workspace…',
  },
  customers: {
    label: 'Customers',
    placeholder: '',
  },
  'toggle-addon': {
    label: 'Toggle addon',
    placeholder: 'Addon name',
  },
};
