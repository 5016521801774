import { Command, useCommandState } from 'cmdk';
import { fuzzy } from 'fast-fuzzy';
import { ComponentPropsWithoutRef, forwardRef } from 'react';
import { twJoin, twMerge } from 'tailwind-merge';

import { Icon } from '../Icon';
import { Input as InputText } from '../Inputs';
import { TextHighlighter } from '../TextHighlighter/TextHighlighter';

// Fast fuzzy search with normalization
const defaultFilter = (value: string, search: string) => fuzzy(search, value) < 1 ? 0 : 1;

interface ComboboxProps extends ComponentPropsWithoutRef<typeof Command> {}

const Root = forwardRef<HTMLDivElement, ComboboxProps>(
  function Root({
    className, ...props
  }, ref) {
    return (
      <Command
        ref={ref}
        loop
        filter={defaultFilter}
        className={twJoin('flex flex-col', className)}
        {...props}
      />
    );
  },
);

interface InputProps extends ComponentPropsWithoutRef<typeof Command.Input> {}

const Input = forwardRef<HTMLInputElement, InputProps>(
  function Input({
    className,
    ...props
  }, ref) {
    return (
      <Command.Input
        ref={ref}
        asChild
        {...props}
      >
        <InputText
          autoFocus
          inputSize="S"
          iconBefore={<Icon name="search" />}
          placeholder="Search..."
          className={twJoin('p-1', className)}
        />
      </Command.Input>
    );
  },
);

interface ListProps extends ComponentPropsWithoutRef<typeof Command.List> {}

const List = forwardRef<HTMLDivElement, ListProps>(
  function List(props, ref) {
    return (
      <Command.List
        ref={ref}
        {...props}
        className={twMerge(
          'max-h-[324px] shy-scrollbar scroll-py-1 px-1 pb-1',
          props.className,
        )}
      />
    );
  },
);

interface EmptyProps extends ComponentPropsWithoutRef<typeof Command.Empty> {}

const Empty = forwardRef<HTMLDivElement, EmptyProps>(
  function Empty(props, ref) {
    return (
      <Command.Empty
        ref={ref}
        {...props}
        className={twMerge(
          'text-secondary px-1.5 h-8 flex items-center justify-start',
          props.className,
        )}
      />
    );
  },
);

interface ItemProps extends ComponentPropsWithoutRef<typeof Command.Item> { }

const Item = forwardRef<HTMLDivElement, ItemProps>(
  function Item(props, ref) {
    return (
      <Command.Item
        ref={ref}
        {...props}
        className={twMerge(
          'flex items-center justify-start gap-2 py-1 px-1.5 rounded min-h-8 data-[selected=true]:bg-grey-100 cursor-pointer dark:data-[selected=true]:bg-grey-800',
          props.className,
        )}
      />
    );
  },
);

type HighlightProps = {
  className?: string;
  text: string;
};

const Highlight = ({
  text, className,
}: HighlightProps) => {
  const search = useCommandState(state => state.search);
  return (
    <TextHighlighter
      className={twJoin('highlight', className)}
      searchWords={[search]}
      textToHighlight={text}
    />
  );
};

export const Combobox = {
  Root,
  Input,
  List,
  Empty,
  Item,
  Group: Command.Group,
  Separator: Command.Separator,
  Highlight,
};
