import { BoardWithDraftConfigFragment } from '@cycle-app/graphql-codegen';
import { useState } from 'react';

import { InboxCreateViewModal } from 'src/components/InboxCreateView/InboxCreateViewModal';
import { RoadmapViewCreateModal } from 'src/components/RoadmapViewCreate/RoadmapViewCreateModal';
import { BoardConfigContextProvider } from 'src/contexts/boardConfigContext';
import { SectionParent } from 'src/utils/section.utils';

import { getPermission, setLimitationsModal } from '../reactives';
import useProductMutations from './api/mutations/useProductMutations';

type SectionModal = {
  sectionParent: SectionParent;
  name?: string;
};

type Props = {
  sectionParent: SectionParent;
  sectionId: string;
  onHide?: VoidFunction;
};

export const useCreateViewModal = ({
  sectionParent,
  sectionId,
  onHide,
}: Props) => {
  const [modalOpen, setModalOpen] = useState<SectionModal | null>(null);
  const [draftBoard, setDraftBoard] = useState<BoardWithDraftConfigFragment | null>(null);
  const {
    addSectionDraftBoard, abortSectionDraftBoard,
  } = useProductMutations();

  const openModal = (name?: string) => {
    if (!sectionId) return;

    if (!getPermission().canCreateView) {
      setLimitationsModal({ action: 'VIEW_CREATE' });
      return;
    }

    setModalOpen({
      sectionParent,
      name,
    });
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    addSectionDraftBoard({ sectionId }, true)
      .then(response => {
        // If the mutation fails, close the modal
        if (!response.data?.createDraftBoard) {
          setModalOpen(null);
          return;
        }
        setDraftBoard(response.data?.createDraftBoard ?? null);
      });
  };

  const modalProps = {
    draftBoard,
    defaultName: modalOpen?.name,
    hide: () => {
      abortSectionDraftBoard();
      setModalOpen(null);
      setDraftBoard(null);
      onHide?.();
    },
  };

  const modal = !modalOpen ? null : (
    <BoardConfigContextProvider>
      {sectionParent === 'inbox' && <InboxCreateViewModal {...modalProps} />}
      {sectionParent === 'roadmap' && <RoadmapViewCreateModal {...modalProps} />}
    </BoardConfigContextProvider>
  );

  return {
    openModal,
    modal,
  };
};
