import { AddStatusDoctypeDocument, StatusFragment, ProductBySlugDocument, DoctypeType } from '@cycle-app/graphql-codegen';
import { nodeToArray } from '@cycle-app/utilities';
import { produce } from 'immer';
import pick from 'lodash/pick';
import { useCallback } from 'react';

import { useProductBase } from 'src/hooks';
import useSafeMutation from 'src/hooks/useSafeMutation';

export const useAddStatusDocType = () => {
  const product = useProductBase();

  const [mutate, result] = useSafeMutation(AddStatusDoctypeDocument);

  const addStatusDocType = useCallback((status: StatusFragment, docTypeId: string) => mutate({
    variables: {
      statusId: status.id,
      doctypeId: docTypeId,
    },
    optimisticResponse: {
      addStatusDoctype: produce(status, draft => {
        draft?.doctypes.edges.push({
          __typename: 'DoctypeEdge',
          node: {
            __typename: 'Doctype',
            id: docTypeId,
            type: DoctypeType.Custom,
          },
        });
      }),
    },
    update: (cache, { data }) => {
      const addedStatus = data?.addStatusDoctype;
      if (!addedStatus || !product?.slug) return;
      // Link the status to the doc type
      cache.updateQuery({
        query: ProductBySlugDocument,
        variables: {
          slug: product.slug,
        },
      }, prev => produce(prev, draft => {
        if (!draft) return;
        const docTypes = nodeToArray(draft.product?.doctypes);
        const index = docTypes.findIndex(d => d.id === docTypeId);
        docTypes[index]?.status.edges.push({
          __typename: 'StatusEdge',
          node: pick(addedStatus, ['__typename', 'id', 'category']),
        });
      }));
    },
  }), [mutate, product?.slug]);

  return {
    addStatusDocType,
    ...result,
  };
};
