import { Dialog } from '@cycle-app/ui';
import { VisuallyHidden } from 'radix-ui';
import { useEffect } from 'react';
import { twJoin } from 'tailwind-merge';

import { OfflineState } from 'src/components/OfflineState';
import { mappingZindex } from 'src/constants/zIndex.constant';
import { useIsOffline } from 'src/hooks';
import { closeCommandBar, openCommandBar, useIsCommandBarOpen } from 'src/hooks/modals/useCommandBarModal';
import { setLayer } from 'src/reactives/layer.reactive';
import { Layer } from 'src/types/layers.types';

import CommandK from './CommandK/CommandK';

export const CommandBarModal = () => {
  const isCommandbarOpen = useIsCommandBarOpen();
  const isOffline = useIsOffline();

  useEffect(() => {
    setLayer(Layer.Commandbar, isCommandbarOpen);
  }, [isCommandbarOpen]);

  return (
    <Dialog.Modal
      close={false}
      overlay={false}
      zIndex={mappingZindex[Layer.Commandbar]}
      open={isCommandbarOpen}
      onOpenChange={open => open ? openCommandBar(null) : closeCommandBar()}
      aria-describedby={undefined}
      className={twJoin(
        'w-[680px] h-[510px] p-0 rounded-xl',
        // Full screen on mobile
        'pointer-coarse:max-md:max-w-full pointer-coarse:max-md:max-h-full pointer-coarse:max-md:size-full pointer-coarse:max-md:border-0 pointer-coarse:max-md:outline-0 pointer-coarse:max-md:rounded-none',
        // Darker bg on mobile
        'pointer-coarse:max-md:dark:bg-grey-950',
        // Shadow: max 32px for blur radius due to perf issue in chrome v134
        'shadow-[0_0_24px_rgba(43,43,43,0.2)] dark:shadow-[0_0_24px_rgba(0,0,0,0.4)]',
      )}
    >
      <VisuallyHidden.Root asChild>
        <Dialog.Title>
          Command Bar
        </Dialog.Title>
      </VisuallyHidden.Root>

      {isOffline ? <OfflineState hideRetry /> : <CommandK />}
    </Dialog.Modal>
  );
};
