import { AddNewDocDocument, DocNodeDocument, Role, UpdateDocDoctypeDocument } from '@cycle-app/graphql-codegen';
import { useAtom } from 'jotai';
import { useState } from 'react';

import { getProductDoctypesFragment } from 'src/hooks/api/fragments/product';
import { useMaybeMeV2 } from 'src/hooks/api/useMe';
import { getProductId } from 'src/hooks/useProductId';
import useSafeMutation from 'src/hooks/useSafeMutation';
import client from 'src/services/apollo/client';
import { extract } from 'src/types/graphql.types';
import { isFeature } from 'src/utils/docType.util';

import { draftRequestIdAtom, draftRequestTypeIdAtom } from './newRequestModal.atoms';

/**
 * Hook to manage the new request modal
 * - Opens and closes the modal
 * - Loads the draft if stored in the local storage, or creates a new draft
 * - Updates the doctype if it does not match the current product doctypes
 */
export const useNewRequestModal = () => {
  const [isRequestModalOpen, setIsRequestModalOpen] = useState(false);
  const [draftRequestTypeId, setDraftRequestTypeId] = useAtom(draftRequestTypeIdAtom);
  const [draftRequestId, setDraftRequestId] = useAtom(draftRequestIdAtom);
  const [createDoc] = useSafeMutation(AddNewDocDocument);
  const [updateDoctype] = useSafeMutation(UpdateDocDoctypeDocument);
  const { me } = useMaybeMeV2();

  const openRequestModal = async () => {
    setIsRequestModalOpen(true);

    const productId = getProductId();
    if (!productId) return;

    let requestTypeId = draftRequestTypeId;

    // List of product request types
    const productRequestTypeIds = getProductDoctypesFragment().filter(isFeature).map(doctype => doctype.id);
    const defaultRequestTypeId = productRequestTypeIds[0] ?? null;
    if (!defaultRequestTypeId) return;

    // Get and validate draftRequestTypeId
    if (!requestTypeId || !productRequestTypeIds.includes(requestTypeId)) {
      requestTypeId = defaultRequestTypeId;
      setDraftRequestTypeId(defaultRequestTypeId);
    }

    // Query draft doc if draft id
    if (draftRequestId) {
      const result = await client.query({
        query: DocNodeDocument,
        variables: {
          id: draftRequestId,
        },
      });
      const doc = extract('Doc', result.data?.node);

      if (doc && doc.doctype.id !== requestTypeId) {
        console.warn('Draft doc doctype mismatch');
        void updateDoctype({
          variables: {
            docId: doc.id,
            doctypeId: defaultRequestTypeId,
          },
        });
      }
      if (!doc) {
        console.warn('Draft doc not found');
        setDraftRequestId(null);
      }
    }

    // Create a new draft doc if no draft id
    if (!draftRequestId) {
      const result = await createDoc({
        variables: {
          productId,
          doctypeId: requestTypeId,
          isDraft: true,
          groupId: '',
          title: '',
          assignee: me && me.role !== Role.SuperAdmin ? me.id : null,
        },
      });
      const doc = result.data?.addNewDoc;
      if (doc) setDraftRequestId(doc.id);
    }
  };

  return {
    isRequestModalOpen,
    setIsRequestModalOpen,
    openRequestModal,
  };
};
