import { Icon } from '@cycle-app/ui';
import { Controller } from 'react-hook-form';
import { twMerge } from 'tailwind-merge';

import { ACCEPTED_LOGO_FILE_FORMATS } from '../constants/imageFileFormats';
import { ChangelogDropdown, ChangelogDropdownContent } from '../shared/ChangelogDropdown';
import { ChangelogInputImage, getImageUrl } from '../shared/ChangelogInputImage';
import { useChangelogBuilderForm } from '../useChangelogBuilderForm';

export function ChangelogBuilderContentLogo({ className }: { className?: string }) {
  const { watch } = useChangelogBuilderForm();
  const logoUrl = getImageUrl(watch('logo'));

  return (
    <ChangelogDropdown
      hoverStateClassName="-mx-2 px-3 py-1 -my-1 rounded-2xl"
      content={({ hide } ) => (
        <ChangelogDropdownContent
          title="Logo"
          hide={hide}
        >
          <LogoPopoverContent hide={hide} />
        </ChangelogDropdownContent>
      )}
      empty={!logoUrl}
    >
      <div className={twMerge('flex h-10 w-24 items-center justify-center', className)}>
        {logoUrl
          ? (
            <img
              className="h-full w-auto object-contain"
              src={logoUrl}
              loading="lazy"
            />
          )
          : (
            <div className="flex items-center gap-2 text-(--changelog-body-color)">
              <Icon
                name="plus"
                className="size-4"
              />
              {' '}
              Logo
            </div>
          )
        }
      </div>

    </ChangelogDropdown>
  );
}

function LogoPopoverContent({ hide }: { hide: VoidFunction }) {
  const {
    control, setValue,
  } = useChangelogBuilderForm();
  const handleRemoveLogo = () => {
    setValue('logo', null, { shouldDirty: true });
    hide();
  };
  return (
    <div>
      <Controller
        control={control}
        name="logo"
        render={({ field }) => (
          <ChangelogInputImage
            value={field.value}
            onChange={field.onChange}
            accept={ACCEPTED_LOGO_FILE_FORMATS}
            className="h-24"
          />
        )}
      />
      <button
        onClick={handleRemoveLogo}
        className="btn-tertiary btn-lg mt-4 w-full"
      >
        Remove logo
      </button>
    </div>
  );
}
