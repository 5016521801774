import { Icon } from '@cycle-app/ui';
import { getDocSlug } from '@cycle-app/utilities';
import { Link } from 'react-router-dom';

import { FeedbackPopover } from 'src/components/FeedbackPopover';
import { PageId } from 'src/constants/routing.constant';
import { useDocTitleFragment } from 'src/hooks/api/fragments/doc';
import { getUrl } from 'src/utils/routing.utils';

export const QuoteFeedback = ({ feedbackDocId }: { feedbackDocId: string }) => {
  const docTitle = useDocTitleFragment(feedbackDocId);
  if (!docTitle) return null;
  return (
    <FeedbackPopover feedbackDocId={feedbackDocId}>
      <Link
        to={getUrl(PageId.DocFullPage, { docSlug: getDocSlug(docTitle) })}
        onClick={e => e.stopPropagation()}
        // eslint-disable-next-line max-len
        className="flex size-5 items-center justify-center rounded-[4px] text-disabled outline-hidden hover:bg-grey-150 hover:text-primary focus-visible:ring-2 dark:hover:bg-grey-800"
      >
        <Icon
          name="feedback"
          className="size-3.5"
        />
      </Link>
    </FeedbackPopover>
  );
};
