import { Icon } from '@cycle-app/ui';

export const items = [
  {
    id: 'zoom',
    icon: (
      <Icon
        name="brand/zoom"
        className="size-4"
      />
    ),
    label: 'Zoom',
  },
  {
    id: 'meet',
    icon: (
      <Icon
        name="brand/google-meet"
        className="size-4"
      />
    ),
    label: 'Meet',
  },
  {
    id: 'teams',
    icon: (
      <Icon
        name="brand/microsoft-teams"
        className="size-4"
      />
    ),
    label: 'Teams',
  },
  {
    id: 'slack',
    icon: (
      <Icon
        name="brand/slack"
        className="size-4"
      />
    ),
    label: 'Slack',
  },
  {
    id: 'notion',
    icon: (
      <Icon
        name="brand/notion"
        className="size-4"
      />
    ),
    label: 'Notion',
  },
  {
    id: 'google-docs',
    icon: (
      <Icon
        name="brand/google-doc"
        className="size-4"
      />
    ),
    label: 'Google docs',
  },
  {
    id: 'typeform',
    icon: (
      <Icon
        name="brand/typeform"
        className="size-4"
      />
    ),
    label: 'Typeform',
  },
  {
    id: 'google-forms',
    icon: (
      <Icon
        name="brand/google-form"
        className="size-4"
      />
    ),
    label: 'Google Forms',
  },
  {
    id: 'dovetail',
    icon: (
      <Icon
        name="brand/dovetail"
        className="size-4"
      />
    ),
    label: 'Dovetail',
  },
  {
    id: 'fillout',
    icon: (
      <Icon
        name="brand/fillout"
        className="size-4"
      />
    ),
    label: 'Fillout',
  },
  {
    id: 'tally',
    icon: (
      <Icon
        name="brand/tally"
        className="size-4"
      />
    ),
    label: 'Tally',
  },
  {
    id: 'airtable',
    icon: (
      <Icon
        name="brand/airtable"
        className="size-4"
      />
    ),
    label: 'Airtable',
  },
  {
    id: 'google-sheet',
    icon: (
      <Icon
        name="brand/google-sheet"
        className="size-4"
      />
    ),
    label: 'Google Sheet',
  },
  {
    id: 'confluence',
    icon: (
      <Icon
        name="brand/confluence"
        className="size-4"
      />
    ),
    label: 'Confluence',
  },
  {
    id: 'slite',
    icon: (
      <Icon
        name="brand/slite"
        className="size-4"
      />
    ),
    label: 'Slite',
  },
  {
    id: 'onedrive',
    icon: (
      <Icon
        name="brand/onedrive"
        className="size-4"
      />
    ),
    label: 'OneDrive',
  },
  {
    id: 'sharepoint',
    icon: (
      <Icon
        name="brand/sharepoint"
        className="size-4"
      />
    ),
    label: 'SharePoint',
  },
  {
    id: 'google-drive',
    icon: (
      <Icon
        name="brand/google-drive"
        className="size-4"
      />
    ),
    label: 'Google Drive',
  },
  {
    id: 'grain',
    icon: (
      <Icon
        name="brand/grain"
        className="size-4"
      />
    ),
    label: 'Grain',
  },
  {
    id: 'enjoyhq',
    icon: (
      <Icon
        name="brand/enjoyhq"
        className="size-4"
      />
    ),
    label: 'EnjoyHQ',
  },
  {
    id: 'uservoice',
    icon: (
      <Icon
        name="brand/uservoice"
        className="size-4"
      />
    ),
    label: 'Uservoice',
  },
];
