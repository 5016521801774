import { twJoin } from 'tailwind-merge';

import { getImageUrl } from '../../shared/ChangelogInputImage';
import { useChangelogBuilderForm } from '../../useChangelogBuilderForm';

export function ChangelogBuilderHeroImage({ className }: { className?: string }) {
  const { watch } = useChangelogBuilderForm();

  const heroImageUrl = getImageUrl(watch('heroImage'));

  return (
    <div className={twJoin('aspect-[2.5/1]', className)}>
      {heroImageUrl && (
        <img
          src={heroImageUrl}
          alt="Hero image"
          className="block size-full object-contain"
        />
      )}
    </div>
  );
}
