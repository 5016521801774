import { useQuery } from '@apollo/client';
import { ConversationNodeDocument } from '@cycle-app/graphql-codegen';
import { useState } from 'react';

import { extract } from '../../types/graphql.types';

export const CONVERSATION_PAGINATION = {
  size: 100,
  cursor: '',
};

export const useConversation = (id: string) => {
  const {
    data, fetchMore, loading,
  } = useQuery(ConversationNodeDocument, {
    variables: {
      id,
      ...CONVERSATION_PAGINATION,
    },
  });
  const conversation = extract('Conversation', data?.node);
  const pageInfo = conversation?.messages?.pageInfo;
  const [isLoadingMore, setIsLoadingMore] = useState(false);

  const loadMore = async () => {
    if (pageInfo?.hasNextPage) {
      setIsLoadingMore(true);
      await fetchMore({
        variables: {
          cursor: pageInfo.endCursor,
        },
      });
      setIsLoadingMore(false);
    }
  };

  return {
    conversation,
    hasNextPage: !!pageInfo?.hasNextPage,
    loadMore,
    isLoading: loading,
    isLoadingMore,
  };
};
