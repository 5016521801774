import { DocsCountByStatusFragment } from '@cycle-app/graphql-codegen';
import { TooltipLegacy } from '@cycle-app/ui';
import { PropsWithChildren } from 'react';

import { statusColors } from './Profile.constants';
import { TooltipCountContent, StatusLine, Dot } from './Profile.styles';

type Props = {
  count: DocsCountByStatusFragment;
};

export const TooltipCount = ({
  children, count,
}: PropsWithChildren<Props>) => {
  return (
    <TooltipLegacy
      withPortal
      withWrapper={false}
      placement="top"
      content={(
        <TooltipCountContent>
          <StatusLine>
            <Dot $color={statusColors.notStarted} />
            {`${count.notStarted} Not started`}
          </StatusLine>
          <StatusLine>
            <Dot $color={statusColors.started} />
            {`${count.started} In progress`}
          </StatusLine>
          <StatusLine>
            <Dot $color={statusColors.completed} />
            {`${count.completed} Completed`}
          </StatusLine>
          <StatusLine>
            <Dot $color={statusColors.canceled} />
            {`${count.canceled} Canceled`}
          </StatusLine>
        </TooltipCountContent>
      )}
    >
      {children}
    </TooltipLegacy>
  );
};
