import { AvatarLegacy, AvatarLegacyProps } from '@cycle-app/ui';
import { FC } from 'react';

import { useMe } from 'src/hooks/api/useMe';

const MyAvatar: FC<React.PropsWithChildren<AvatarLegacyProps>> = (props) => {
  const { me } = useMe();

  return (
    <AvatarLegacy
      {...props}
      user={me}
    />
  );
};

export default MyAvatar;
