import { DoctypeType } from '@cycle-app/graphql-codegen';
import { Spinner, ActionButton } from '@cycle-app/ui';
import { ImageIcon, CommentAltIcon, ReleaseNoteIcon, CloseIcon } from '@cycle-app/ui/icons';
import { Editor } from '@tiptap/core';
import { FC } from 'react';

import { AddQuote } from 'src/components/AddQuote';
import { DocPanelComments } from 'src/components/DocComments/DocComments';
import { DocCompanyCustomer } from 'src/components/DocCompanyCustomer';
import { DocReleaseNoteCreateButton } from 'src/components/DocReleaseNoteCreateButton';
import { useDoc } from 'src/hooks';
import { useGetDocType } from 'src/reactives/docTypes.reactive';
import { useIsMobile } from 'src/reactives/responsive.reactive';
import { getCommentsCount } from 'src/utils/comments.utils';
import { isFeedback, isCustom } from 'src/utils/docType.util';

import { AddCoverAction, AddReleaseAction, Container, CustomerContainer } from './DocPanelActions.styles';
import { Summarize } from './Summarize';

interface Props {
  docId: string;
  className?: string;
  onAddCoverClicked: VoidFunction;
  isUploadingCover: boolean;
  isDraggingCover: boolean;
  closeDocPanel?: VoidFunction;
  editor?: Editor;
}

const DocPanelActions: FC<React.PropsWithChildren<Props>> = ({
  docId,
  className,
  onAddCoverClicked,
  isUploadingCover,
  isDraggingCover,
  closeDocPanel,
  editor,
}) => {
  const { doc: docBase } = useDoc(docId);
  const isMobile = useIsMobile();
  const coverActionLabel = getCoverActionButtonLabel();
  const isCoverActionEmphasized = isUploadingCover || isDraggingCover;
  const doctype = useGetDocType(docBase?.doctype.id);

  return (
    <Container className={className}>
      {closeDocPanel && (
        <ActionButton
          className="mr-2 size-6"
          onClick={closeDocPanel}
        >
          <CloseIcon size={12} />
        </ActionButton>
      )}

      {docBase && isFeedback(docBase.doctype) && (
        <CustomerContainer>
          <DocCompanyCustomer
            doc={docBase}
            canRemoveCustomer={docBase.doctype.type === DoctypeType.Custom}
          />
        </CustomerContainer>
      )}

      {!isMobile && (
        <>
          {docBase && (
            <DocPanelComments
              docId={docBase.id}
              nbComments={getCommentsCount(docBase)}
              tooltipPlacement="bottom"
              size="L"
              showLabel={!isMobile}
              buttonIcon={<CommentAltIcon />}
              maxHeight="70vh"
              className="doc-panel-comments"
            />
          )}

          {!isFeedback(docBase?.doctype) && (
            <AddCoverAction
              onClick={onAddCoverClicked}
              userColor={isCoverActionEmphasized}
              disabled={isUploadingCover}
              size="L"
            >
              <ImageIcon />
              <span>
                {coverActionLabel}
              </span>
            </AddCoverAction>
          )}

          {docBase && isCustom(doctype) &&
          !!doctype?.release &&
          !docBase.releaseNote && (
            <DocReleaseNoteCreateButton
              releaseNote={docBase?.releaseNote}
              docId={docBase.id}
            >
              {(toggleProps, isLoading) => (
                <AddReleaseAction
                  onClick={toggleProps.onClick}
                  userColor={false}
                  disabled={isLoading}
                  size="L"
                >
                  {isLoading ? <Spinner /> : <ReleaseNoteIcon />}
                  <span>
                    Add release
                  </span>
                </AddReleaseAction>
              )}
            </DocReleaseNoteCreateButton>
          )}

          {docBase && isFeedback(docBase.doctype) && (
            <>
              <AddQuote
                feedbackDoc={docBase}
                editor={editor}
              />
              <Summarize />
            </>
          )}
        </>
      )}
    </Container>
  );

  function getCoverActionButtonLabel(): string {
    if (isUploadingCover) return 'Uploading…';
    return docBase?.cover?.url
      ? 'Update cover'
      : 'Add cover';
  }
};

export default DocPanelActions;
