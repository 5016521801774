import { AvatarLegacy } from '@cycle-app/ui';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: inline-flex;
  gap: 4px;

  > :not(div[data-tippy-root]) {
    display: flex;
    align-items: center;
  }
`;

export const StyledAvatar = styled(AvatarLegacy)<{ unassigned?: boolean }>`
  --color: ${p => (p.src ? 'transparent' : (p.theme.userColors.main))};
  --border-size: 0px;
  --shadow-size: 0px;
  --padding: 0px;
  --shadow-size-hover: 0px;
  ${p => p.unassigned && css`
    background: var(--color);
    border-radius: 50%;
    opacity: .5;
  `}
  cursor: pointer;
`;
