export enum LocalKey {
  AiCounter = 'ai-counter',
  Auth = 'auth',
  CustomerDashboardRefresh = 'customer-dashboard-refresh-multi',
  DocDraft = 'doc-draft',
  DocProcessModalChecked = 'doc-process-modal-checked',
  DraftComments = 'draft-comments',
  EmptySections = 'empty-sections',
  FeatureCollapsedSections = 'feature-collapsed-sections',
  Features = 'features',
  FeatureToggler = 'feature-toggler',
  Gamification = 'gamification',
  Groups = 'groups',
  Language = 'language',
  LastInboxBoard = 'last-inbox-board',
  LastInsightBoard = 'last-insight-board',
  LastRoadmapBoard = 'last-roadmap-board',
  LastUsedCustomField = 'last-used-custom-field',
  LastUsedGitHubRepository = 'last-used-github-repository',
  LastView = 'last-view',
  LightOnboarding = 'light-onboarding',
  Linear = 'linear',
  LinearImportDocTypes = 'linear-import-doctypes',
  /**
   * @deprecated use `OnboardingSlackNew`
   */
  OnboardingSlack = 'onboarding-slack',
  OnboardingSlackNew = 'onboarding-slack-new',
  RequestMakerAccess = 'request-maker-access',
  SectionFilter = 'section-filter',
  Sections = 'sections',
  SidebarCollapsed = 'sidebar-collapsed',
  SidebarWidth = 'sidebar-width',
  SlackNotifBannerHidden = 'slack-notif-banner-hidden',
  StarredSection = 'starred-section',
  WorkspaceSection = 'workspace-section',
  StatusFilter = 'status-filter',
  Theme = 'theme',
  UserPreferences = 'user-preferences',
  VideoPlaybackRate = 'video-playback-rate',
  ColorPaletteSavedColors = 'color-palette-saved-colors',
  ChangelogBuilder = 'changelog-builder',
  ChangelogDiscover = 'changelog-discover',
  CompanyTabId = 'company-tab-id',
  CustomerTabId = 'customer-tab-id',
  CompanyStatusCategory = 'company-status-category',
  ProductAreaStatusCategory = 'product-area-status-category',
  CustomerStatusCategory = 'customer-status-category',
  DraftRequestTypeId = 'draft-request-type-id',
  DraftRequestId = 'draft-request-id',
  AskParameters = 'ask-parameters',
}
