import { useQuery } from '@apollo/client';
import { UserWithProductRoleDocument } from '@cycle-app/graphql-codegen';
import { TooltipLegacy } from '@cycle-app/ui';
import { Link } from 'react-router-dom';

import { PageId } from 'src/constants/routing.constant';
import { useProductId } from 'src/hooks/useProductId';
import { extract } from 'src/types/graphql.types';
import { getUrl } from 'src/utils/routing.utils';

export const RoleLink = ({ assigneeId }: { assigneeId: string }) => {
  const productId = useProductId();

  const { data } = useQuery(UserWithProductRoleDocument, {
    skip: !productId,
    variables: {
      id: assigneeId,
      productId: productId ?? '',
    },
  });

  const user = extract('OtherUser', data?.node) ?? extract('Me', data?.node);

  if (!user?.productRole) return null;

  return (
    <TooltipLegacy
      content="Explore maker features"
      placement="top"
      withPortal
      withWrapper={false}
    >
      <Link
        className="btn-secondary btn-sm h-5 px-1 py-0.5"
        to={getUrl(PageId.RequestMakerAccess)}
      >
        <span className="lowercase text-secondary first-letter:uppercase">
          {user.productRole}
        </span>
      </Link>
    </TooltipLegacy>
  );
};
