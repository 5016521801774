import { SourceFragment } from '@cycle-app/graphql-codegen';
import { Tag, TagProps, CycleLogo, Icon, type IconName } from '@cycle-app/ui';
import { EmailIcon } from '@cycle-app/ui/icons';
import { forwardRef } from 'react';

import { isCycleFileSource } from 'src/utils/doc.util';

import { CycleLogoContainer, StyledImportFileIcon } from './DocSource.styles';
import { getLabel } from './DocSource.utils';

export type DocSourceProps = Pick<TagProps, 'color'> & {
  source?: SourceFragment | null;
  showName?: boolean;
};

export const DocSource = forwardRef<HTMLDivElement, DocSourceProps>(({
  source, showName = true, color = 'grey',
}, ref) => {
  if (!source) return null;
  return (
    <Tag
      ref={ref}
      color={color}
      icon={(
        <SourceIcon
          source={source}
          size={12}
        />
      )}
    >
      {showName && getLabel(source)}
    </Tag>
  );
});

export const getIconBasedOnUrl = (url: string, size: number, defaultIcon: JSX.Element) => {
  const iconName = getIconNameFromUrl(url);
  if (iconName) {
    return (
      <Icon
        name={iconName}
        style={{
          width: size,
          height: size,
        }}
      />
    );
  }
  return defaultIcon;
};

const UrlIconMap = {
  'brand/click-up': ['clickup.com'],
  'brand/claap': ['claap.io'], // e.g. https://app.claap.io/cycle/share-your-claaps-c-aZ6f2FkC_j-RZJEfe8crKKx
  'brand/confluence': ['atlassian.net'], // e.g. https://cycle-team.atlassian.net/l/cp/mBniDYfb
  'brand/descript': ['descript.com'], // e.g. https://share.descript.com/view/lcEZpDJoXPa
  'brand/discord': ['discord.com'],
  'brand/dovetail': ['dovetail.com'], // e.g. https://cycle-yyv4.dovetail.com/data/3jPeauRlJ2IihgVGy2LxID
  'brand/dropbox': ['dropbox.com'], // e.g. https://www.dropbox.com/s/luyfzjww5uokmsf/Enregistrement%202024-03-05%20%C3%A0%2013.13.40.webm?dl=0
  'brand/freshdesk': ['freshdesk.com'], // e.g. https://cycle.freshdesk.com/a/tickets/2
  'brand/front': ['front.com'],
  'brand/fullstory': ['fullstory.com', 'fsty.io'],
  'brand/gainsight': ['gainsight.com'],
  'brand/gitlab': ['gitlab.com'],
  'brand/gong': ['gong.io'],
  'brand/grain': ['grain.com'],
  'brand/hubspot': ['hubspot.com'],
  'brand/make': ['make.com'],
  'brand/microsoft-teams': ['microsoft.com'],
  'brand/miro': ['miro.com'], // e.g.https://miro.com/welcomeonboard/NlpVV0FrNkdJb0hFc0pYMW1DR1ZzVlNNSW5DYmdLU3B0Nk1abnFlam1tZmlVQnZ1TTdPaVh3eldPS0hKdDAyOXwzNDU4NzY0NTI2MTQ5Mzc0MjIyfDI=?share_link_id=935171778387
  'brand/modjo': ['modjo.com', 'modjo.ai'],
  'brand/monday': ['monday.com'],
  'brand/outlook': ['outlook.com'],
  'brand/productboard': ['productboard.com'],
  'brand/pitch': ['pitch.com'],
  'brand/pinterest': ['pinterest.com'],
  'brand/shortcut': ['shortcut.com'],
  'brand/skype': ['skype.com'],
  'brand/slack': ['slack.com'],
  'brand/slite': ['slite.com'],
  'brand/snowflake': ['snowflake.com'],
  'brand/zoom': ['zoom.com'],
  'brand/salesforce': ['salesforce.com'],
  'brand/zendesk': ['zendesk.com'],
  'brand/asana': ['asana.com'],
  'brand/canny': ['canny.io'],
  'brand/reddit': ['reddit.com'],
  'brand/vitally': ['vitally.io'],
  'brand/twitter': ['twitter.com', 'https://x.com'],
  'brand/g2': ['g2.com'],
  'brand/trustpilot': ['trustpilot.com'],
  'brand/capterra': ['capterra'],
  'brand/notion': ['notion.com', 'notion.so'],
  'brand/attio': ['attio.com'],
  'brand/github': ['github.com'],
  'brand/airtable': ['airtable.com'],
  'brand/intercom': ['intercom.com'],
  'brand/zapier': ['zapier.com'],
  'brand/google-doc': ['docs.google.com/document'],
  'brand/google-sheet': ['docs.google.com/spreadsheets'],
  'brand/hotjar': ['hotjar.com'],
} satisfies Partial<Record<IconName, string[]>>;

function getIconNameFromUrl(url: string) {
  for (const [iconName, urls] of Object.entries(UrlIconMap)) {
    if (urls.some((urlPattern) => url.includes(urlPattern))) {
      return iconName as keyof typeof UrlIconMap;
    }
  }
  return null;
}

export const SourceIcon = ({
  size, source, defaultIcon,
}: {
  source: Partial<SourceFragment> | null;
  size?: number;
  defaultIcon?: JSX.Element;
}) => {
  switch (source?.__typename) {
    case 'SourceSlack':
      return (
        <Icon
          name="brand/slack"
          style={{
            width: size,
            height: size,
          }}
        />
      );
    case 'SourceIntercom':
      return (
        <Icon
          name="brand/intercom"
          style={{
            width: size,
            height: size,
          }}
        />
      );
    case 'SourceMail':
      return <EmailIcon size={size} />;
    case 'SourceZapier':
      return (
        <Icon
          name="brand/zapier"
          style={{
            width: size,
            height: size,
          }}
        />
      );
    case 'SourceHubspot':
      return (
        <Icon
          name="brand/hubspot"
          style={{
            width: size,
            height: size,
          }}
        />
      );
    case 'SourceZoom':
      return (
        <Icon
          name="brand/zoom"
          style={{
            width: size,
            height: size,
          }}
        />
      );
    case 'SourceMicrosoftTeams':
      return (
        <Icon
          name="brand/microsoft-teams"
          style={{
            width: size,
            height: size,
          }}
        />
      );
    case 'SourceGoogleMeet':
      return (
        <Icon
          name="brand/google-meet"
          style={{
            width: size,
            height: size,
          }}
        />
      );
    case 'SourceLoom':
      return (
        <Icon
          name="brand/loom"
          style={{
            width: size,
            height: size,
          }}
        />
      );
    case 'SourceNotion':
      return (
        <Icon
          name="brand/notion"
          style={{
            width: size,
            height: size,
          }}
        />
      );
    case 'SourceZendesk':
      return (
        <Icon
          name="brand/zendesk"
          style={{
            width: size,
            height: size,
          }}
        />
      );
    case 'SourceSalesforce':
      return (
        <Icon
          name="brand/salesforce"
          style={{
            width: size,
            height: size,
          }}
        />
      );
    case 'SourceModjo':
      return (
        <Icon
          name="brand/modjo"
          style={{
            width: size,
            height: size,
          }}
        />
      );
    case 'SourceWeb':
      return getIconBasedOnUrl(
        source?.url || '',
        size ?? 12,
        <Icon
          name="brand/chrome"
          style={{
            width: size,
            height: size,
          }}
        />,
      );
    case 'SourceGong':
      return (
        <Icon
          name="brand/gong"
          style={{
            width: size,
            height: size,
          }}
        />
      );
    case 'SourceCycle':
    default:
      if (isCycleFileSource(source)) {
        const extension = source?.url?.split('.').at(-1);
        return (
          <StyledImportFileIcon
            extension={extension}
            size="S"
          />
        );
      }
      return getIconBasedOnUrl(
        source?.url || '',
        size ?? 12,
        defaultIcon ?? (
          <CycleLogoContainer>
            <CycleLogo size={8} />
          </CycleLogoContainer>
        ),
      );
  }
};
