import { Icon } from '@cycle-app/ui';
import { CycleStandardIcon } from '@cycle-app/ui/icons';

import { ChangelogBuilderHeaderBackButton } from './header/ChangelogBuilderHeaderBackButton';
import { ChangelogBuilderHeaderColors } from './header/ChangelogBuilderHeaderColors';
import { ChangelogBuilderHeaderFavicon } from './header/ChangelogBuilderHeaderFavicon';
import { ChangelogBuilderHeaderLanguage } from './header/ChangelogBuilderHeaderLanguage';
import { ChangelogBuilderHeaderTemplates } from './header/ChangelogBuilderHeaderTemplates';
import { ChangelogBuilderHeaderTypography } from './header/ChangelogBuilderHeaderTypography';
import { ChangelogBuilderPreviewButton } from './header/ChangelogBuilderPreviewButton';

export function ChangelogBuilderHeader() {
  return (
    <div className="flex items-center gap-4">
      <div className="flex flex-1 pt-5">
        <div className="flex h-14 w-16 items-center justify-center rounded-br-2xl">
          <Icon
            name="brand/google"
            className="size-6"
          />
        </div>
        <div
          // eslint-disable-next-line max-len
          className="relative z-10 flex h-14 w-16 translate-y-px items-center justify-center rounded-t-2xl border-x border-t border-grey-200 bg-(--changelog-background-color) dark:border-grey-950"
        >
          <div className="absolute -inset-x-3 bottom-0 h-3 bg-inherit" />
          <div
            // eslint-disable-next-line max-len
            className="absolute bottom-0 left-full size-3 rounded-bl-2xl border-b border-l border-grey-200 bg-grey-100 dark:border-grey-950 dark:bg-black"
          />
          <div
            // eslint-disable-next-line max-len
            className="absolute bottom-0 right-full size-3 rounded-br-2xl border-b border-r border-grey-200 bg-grey-100 dark:border-grey-950 dark:bg-black"
          />
          <ChangelogBuilderHeaderFavicon />
        </div>
        <div className="flex h-14 w-16 items-center justify-center rounded-bl-2xl">
          <CycleStandardIcon
            variant="primary"
            className="size-6"
          />
        </div>
      </div>

      <ChangelogBuilderPreviewButton />

      <div className="flex flex-1 items-center justify-end gap-4 pr-12">
        <ChangelogBuilderHeaderLanguage />
        <ChangelogBuilderHeaderColors />
        <ChangelogBuilderHeaderTypography />
        <ChangelogBuilderHeaderTemplates />
        <ChangelogBuilderHeaderBackButton />
      </div>
    </div>
  );
}

