import { memo } from 'react';

import { MainMenu } from 'src/components/MainMenu';
import { Notifications } from 'src/components/Notifications';
import { useIsChangelogOnly } from 'src/contexts/workspaceContext';
import { useSidebarCollapsed } from 'src/hooks/useSidebarCollapsed';

import { NewButton } from './NewButton';
import { NewReleaseButton } from './NewReleaseButton';

export const SidebarHeader = memo(() => {
  const collapsed = useSidebarCollapsed();
  const isChangelogOnly = useIsChangelogOnly();

  if (collapsed) {
    return (
      <div className="mb-0.5 mt-1 flex w-7 flex-none flex-col items-center justify-between gap-4">
        <div className="relative -m-0.5 box-content flex w-7 grow items-center overflow-hidden p-0.5">
          <MainMenu />
        </div>
        <div className="flex flex-col items-center gap-0.5">
          <Notifications />
          {isChangelogOnly ? <NewReleaseButton /> : <NewButton />}
        </div>
      </div>
    );
  }

  return (
    <div className="mb-4 mt-1 mr-1 flex h-7 flex-none items-center justify-between gap-1">
      <div className="relative -m-0.5 box-content flex h-7 grow items-center overflow-hidden p-0.5">
        <MainMenu />
      </div>
      <div className="flex items-center gap-1">
        <Notifications />
        {isChangelogOnly ? <NewReleaseButton /> : <NewButton />}
      </div>
    </div>
  );
});
