import {
  Menu, DropdownMenu, ActionButton, Emoji, Icon, InfiniteScroll, Skeleton,
} from '@cycle-app/ui';
import { AddIcon } from '@cycle-app/ui/icons';
import { useMemo, useState } from 'react';

import { useIsRoadmapsEnabled } from 'src/hooks';
import { useBoardSections } from 'src/hooks/api/useBoardSections';
import { useBoardCreateFromSection } from 'src/hooks/boards/useBoardCreateFromSection';
import { useGetPermission } from 'src/reactives';

import { useBoardLink } from '../../hooks/boards/useBoardLink';
import { useBoards } from '../../hooks/boards/useBoards';
import { BoardDefaultIcon } from '../BoardDefaultIcon';

interface Props {
  sidebarSectionId: string;
  onMount?: VoidFunction;
  onHide?: VoidFunction;
}

type Submenu = 'inbox' | 'roadmaps';

export const SectionViewOptions = ({
  onMount, onHide, sidebarSectionId,
}: Props) => {
  const {
    createBoard, createBoardModal,
  } = useBoardCreateFromSection();
  const { isPermissionInit } = useGetPermission();
  const [open, setOpen] = useState(false);
  const isRoadmapsEnabled = useIsRoadmapsEnabled();
  if (!isPermissionInit) return null;
  return (
    <>
      <Menu
        open={open}
        onOpenChange={(isOpen) => {
          if (isOpen) {
            onMount?.();
          } else {
            onHide?.();
          }
          setOpen(isOpen);
        }}
        side="bottom"
        align="start"
        sideOffset={5}
        trigger={(
          <ActionButton
            tooltip="Add view"
            tooltipPlacement="top"
            forceFocus={open}
          >
            <AddIcon />
          </ActionButton>
        )}
        portal
        className="z-20"
      >
        <DropdownMenu.SubMenu
          sideOffset={-1}
          trigger={(
            <>
              <Icon name="feedback" />
              Feedback
            </>
          )}
          className="overflow-hidden max-h-96"
        >
          <SubContent
            hide={() => {
              onHide?.();
              setOpen(false);
            }}
            sidebarSectionId={sidebarSectionId}
            submenu="inbox"
            createBoard={createBoard}

          />
        </DropdownMenu.SubMenu>
        {isRoadmapsEnabled && (
          <DropdownMenu.SubMenu
            sideOffset={-1}
            trigger={(
              <>
                <Icon name="feature" />
                Requests
              </>
            )}
            className="overflow-hidden max-h-96"
          >
            <SubContent
              hide={() => {
                onHide?.();
                setOpen(false);
              }}
              sidebarSectionId={sidebarSectionId}
              submenu="roadmaps"
              createBoard={createBoard}
            />
          </DropdownMenu.SubMenu>
        )}
      </Menu>
      {createBoardModal}
    </>
  );
};

const SubContent = ({
  sidebarSectionId, submenu, createBoard, hide,
}: {
  hide: VoidFunction;
  sidebarSectionId: string;
  submenu: Submenu;
  createBoard: ReturnType<typeof useBoardCreateFromSection>['createBoard'];
}) => {
  const {
    mappingBoardLinks, feedbackSectionId, roadmapSectionId,
  } = useBoardSections();
  const boardLinks = Object.values(mappingBoardLinks);
  const sectionId = submenu === 'inbox' ? feedbackSectionId : roadmapSectionId;
  const {
    boards, isLoading, isLoadingMore, hasNextPage, loadMore,
  } = useBoards(sectionId);
  const options = useMemo(() => boards
    // Omit linked views.
    .filter(board => !boardLinks.some(link => link.board.id === board.id))
    .map(board => ({
      value: board.id,
      icon: board.emoji
        ? (
          <Emoji
            color={board.color}
            emoji={board.emoji}
          />
        )
        : (
          <BoardDefaultIcon
            sectionType={board.section?.type}
            color={board.color}
          />
        ),
      label: board.name,
    })), [boards, boardLinks]);
  const { createBoardLink } = useBoardLink();
  return (
    <InfiniteScroll
      hasMoreData={hasNextPage}
      loadMore={loadMore}
      isLoading={isLoadingMore}
      className="shy-scrollbar grow"
    >
      {isLoading && (
        <div className="px-1.5 h-8 flex items-center">
          <Skeleton />
        </div>
      )}
      {options.map(o => (
        <DropdownMenu.Item
          key={o.value}
          onSelect={async () => {
            hide();
            await createBoardLink({
              boardId: o.value,
              sectionId: sidebarSectionId,
            });
          }}
        >
          {o.icon}
          {o.label}
        </DropdownMenu.Item>
      ))}
      {isLoadingMore && (
        <div className="px-1.5 h-8 flex items-center">
          <Skeleton />
        </div>
      )}
      <DropdownMenu.Item
        onSelect={async () => {
          hide();
          if (sectionId) {
            await createBoard({
              sectionId,
              sidebarSectionId,
            });
          }
        }}
        className="text-secondary font-normal"
      >
        <Icon name="plus" />
        Create view
      </DropdownMenu.Item>
    </InfiniteScroll>
  );
};
