import { AiIcon } from '@cycle-app/ui/icons';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';

import { askParameters } from '../../reactives/ask.reactive';
import { AnswerParameters } from '../Ask/AnswerParameters';
import { DocsButton } from '../Ask/DocsButton';

type Props = {
  pending: boolean;
  content: string;
  conversationId: string;
  openDocs: VoidFunction;
  loader?: string;
};

export const AskAnswer = ({
  conversationId,
  content,
  pending,
  openDocs,
  loader,
}: Props) => {
  const values = askParameters.hook();
  const {
    parameters, docs,
  } = values[conversationId] || {};
  const docCount = docs?.length ?? 0;
  return (
    <div className="p-2">
      {(
        // Check if at least one parameters is present to avoid an useless sentence.
        parameters?.feature || !!parameters?.companies.length || parameters?.fromDate || parameters?.toDate
      ) && (
        <AnswerParameters parameters={parameters} />
      )}

      {pending ? (
        <div className="text-disabled">
          <AiIcon
            isAnimated
            className="align-middle mr-1"
          />
          {loader || 'Thinking…'}
        </div>
      ) : (
        <ReactMarkdown className="[&>ul]:list-none [&>ul]:pl-4 [&>ol]:list-none [&>ol]:pl-4">
          {content}
        </ReactMarkdown>
      )}

      {docCount > 0 && (
        <DocsButton
          docCount={docCount}
          onClick={openDocs}
        />
      )}
    </div>
  );
};
