import { HeroSection, Color, ChangelogHeroStyle } from '@cycle-app/graphql-codegen';
import { Input, SelectPanel, Icon } from '@cycle-app/ui';
import { DownIcon } from '@cycle-app/ui/icons';
import { ChangeEvent } from 'react';
import { Controller } from 'react-hook-form';
import { twJoin } from 'tailwind-merge';

import { ToggleDropdown } from 'src/components/DropdownLayer';
import { Layer } from 'src/types/layers.types';

import { getSubtitleData } from './helpers';
import { heroImageSuggestions } from './heroImages';
import { ACCEPTED_LOGO_FILE_FORMATS } from '../../constants/imageFileFormats';
import { SCROLLBAR_VISIBLE_CLASS } from '../../constants/layout';
import { shipOptions } from '../../constants/shipOptions';
import { ChangelogDropdownDivider } from '../../shared/ChangelogDropdown';
import { getImageUrl } from '../../shared/ChangelogInputImage';
import { ChangelogRadioInput } from '../../shared/ChangelogRadioInput';
import { useChangelogBuilderForm } from '../../useChangelogBuilderForm';

const FIGMA_COMMUNITY_URL = 'https://www.figma.com/community/file/1464288408141237154';
// eslint-disable-next-line max-len
const heroImageButtonClasses = twJoin('absolute right-2 top-2 flex size-6 cursor-pointer items-center justify-center rounded-md bg-grey-950/60 text-white opacity-0 backdrop-blur-sm transition hover:bg-grey-950/80 group-hover:opacity-100');

const heroOptions = [
  {
    value: HeroSection.S,
    label: 'Small',
  },
  {
    value: HeroSection.M,
    label: 'Medium',
  },
  {
    value: HeroSection.L,
    label: 'Large',
  },
  {
    value: HeroSection.Xl,
    label: 'Extra large',
  },
];

const shipItems = [
  Color.A,
  Color.B,
  Color.C,
  Color.D,
  Color.E,
  Color.F,
  Color.G,
  Color.H,
  Color.I,
  Color.J,
  Color.K,
].map((colorCode) => ({
  value: colorCode,
  color: shipOptions[colorCode].color,
}));

export function ChangelogBuilderHeroPopoverContent() {
  const { watch } = useChangelogBuilderForm();

  const heroStyle = watch('heroStyle');

  return (
    <>
      <HeroStyle />

      {[ChangelogHeroStyle.Text, ChangelogHeroStyle.TextImage].includes(heroStyle) && (
        <>
          <ChangelogDropdownDivider className="my-4" />
          <HeroTextContent />
        </>
      )}

      {[ChangelogHeroStyle.Image, ChangelogHeroStyle.TextImage].includes(heroStyle) && (
        <>
          <ChangelogDropdownDivider className="my-4" />
          <HeroImageContent />
        </>
      )}

      {ChangelogHeroStyle.Text === heroStyle && (
        <>
          <ChangelogDropdownDivider className="my-4" />
          <HeroTextStyleContent />
        </>
      )}
    </>
  );
}

function HeroStyle() {
  const { register } = useChangelogBuilderForm();

  return (
    <>
      <div className="mb-2 font-medium">
        Style
      </div>

      <ChangelogRadioInput
        label="Titles"
        support={(
          <svg
            width="30"
            height="30"
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              width="30"
              height="30"
              rx="4"
            />
            <rect
              x="4"
              y="16"
              width="21"
              height="3"
              rx="1.5"
              fill="#D9D9D9"
            />
            <rect
              x="4"
              y="11"
              width="21"
              height="3"
              rx="1.5"
              fill="#D9D9D9"
            />
          </svg>
        )}
        value={ChangelogHeroStyle.Text}
        {...register('heroStyle')}
      />
      <ChangelogRadioInput
        label="Titles and image"
        support={(
          <svg
            width="30"
            height="30"
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              x="14"
              y="11"
              width="11"
              height="8"
              rx="2"
              fill="#D9D9D9"
            />
            <rect
              x="4"
              y="14"
              width="8"
              height="3"
              rx="1.5"
              fill="#D9D9D9"
            />
          </svg>
        )}
        value={ChangelogHeroStyle.TextImage}
        {...register('heroStyle')}
      />
      <ChangelogRadioInput
        label="Image"
        support={(
          <svg
            width="30"
            height="30"
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              width="30"
              height="30"
              rx="4"
            />
            <rect
              x="5"
              y="12"
              width="20"
              height="8"
              rx="3"
              fill="#D9D9D9"
            />
          </svg>
        )}
        value={ChangelogHeroStyle.Image}
        {...register('heroStyle')}
      />
      <ChangelogRadioInput
        label="Hidden"
        value={ChangelogHeroStyle.Hidden}
        {...register('heroStyle')}
      />
    </>
  );
}

function HeroTextContent() {
  const { register } = useChangelogBuilderForm();

  return (
    <>
      <Input
        label="Title"
        {...register('title')}
      />

      <Input
        className="mt-4"
        label="Subtitle"
        {...register('subtitle')}
      />
    </>
  );
}

function HeroImageContent() {
  const {
    watch, setValue,
  } = useChangelogBuilderForm();

  const availableHeroImages = watch('availableHeroImages');
  const heroImage = watch('heroImage');

  const selectedImageUrl = getImageUrl(heroImage);

  const handleHeroChange = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setValue('heroImage', file, { shouldDirty: true });
    }
    e.target.value = '';
  };

  const handleRemoveImage = (url: string) => {
    setValue('availableHeroImages', availableHeroImages.filter((image) => image !== url), { shouldDirty: true });
  };

  return (
    <>
      <div className="flex gap-1">
        <label className="btn-tertiary btn-lg">
          Upload
          <input
            type="file"
            className="sr-only"
            accept={ACCEPTED_LOGO_FILE_FORMATS}
            onChange={handleHeroChange}
          />
        </label>
        <a
          className="btn-tertiary btn-lg"
          href={FIGMA_COMMUNITY_URL}
          target="_blank"
          rel="noreferrer"
        >
          Make my own
        </a>
      </div>

      <p className="mt-2 flex items-center gap-1 text-secondary">
        <Icon
          name="info-circle"
          className="size-3.5"
        />
        Best result: 2000px / 800px
      </p>

      <ChangelogDropdownDivider className="mt-4" />

      <div className={twJoin('-mx-3 max-h-56 px-3', SCROLLBAR_VISIBLE_CLASS)}>
        {availableHeroImages.length > 0 && (
          <>
            <p className="sticky top-0 z-10 mt-2 bg-white py-2 text-xs text-secondary dark:bg-grey-850">
              My cover images
            </p>

            <ul className="flex flex-col gap-1">
              {heroImage instanceof File && selectedImageUrl && (
                <img
                  src={selectedImageUrl}
                  loading="lazy"
                  className="group relative aspect-[2.5/1] w-full cursor-pointer overflow-clip rounded-lg border border-cycle object-contain p-0"
                />
              )}

              {availableHeroImages.map((heroImageOption) => (
                <li
                  key={heroImageOption}
                  className={twJoin(
                    'group relative flex aspect-[2.5/1] cursor-pointer overflow-clip rounded-lg border',
                    selectedImageUrl === heroImageOption ? 'border-cycle' : 'border-transparent',
                  )}
                  onClick={() => setValue('heroImage', heroImageOption, { shouldDirty: true })}
                >
                  <img
                    src={heroImageOption}
                    loading="lazy"
                    className="size-full object-cover"
                  />

                  <button
                    className={heroImageButtonClasses}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleRemoveImage(heroImageOption);
                    }}
                  >
                    <Icon name="delete-fill" />
                  </button>
                </li>
              ))}
            </ul>
          </>
        )}

        <p className="sticky top-0 z-10 mt-2 bg-white py-2 text-xs text-secondary dark:bg-grey-850">
          Inspiration/Templates
        </p>

        <ul className="flex flex-col gap-1">
          {heroImageSuggestions.map((heroImageOption) => (
            <li
              key={heroImageOption}
              className={twJoin(
                'group relative flex aspect-[2.5/1] cursor-pointer overflow-clip rounded-lg border',
                selectedImageUrl === heroImageOption ? 'border-cycle' : 'border-transparent',
              )}
              onClick={() => setValue('heroImage', heroImageOption, { shouldDirty: true })}
            >

              <img
                src={heroImageOption}
                alt="Hero image"
                loading="lazy"
                className="size-full object-cover"
              />

              <a
                className={heroImageButtonClasses}
                href={FIGMA_COMMUNITY_URL}
                target="_blank"
                rel="noreferrer"
                onClick={e => e.stopPropagation()}
              >
                <Icon name="edit-fill" />
              </a>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
}

function HeroTextStyleContent() {
  const {
    control, watch,
  } = useChangelogBuilderForm();
  const { hasShip } = getSubtitleData(watch('subtitle'));

  return (
    <>
      <div className="mb-2 font-medium">
        Size
      </div>
      <Controller
        control={control}
        name="heroSection"
        render={({ field }) => (
          <ToggleDropdown
            withWrapper={false}
            placement="bottom-start"
            layer={Layer.DropdownModal}
            content={(contentProps) => (
              <SelectPanel
                hideSearch
                selectedValue={field.value}
                options={heroOptions}
                onOptionChange={(payload) => {
                  field.onChange(payload.value);
                  contentProps.hide();
                }}
                style={{ width: `${contentProps.buttonRect?.width}px` }}
              />
            )}
            button={(buttonProps) => (
              <button
                ref={buttonProps.ref}
                type="button"
                  // eslint-disable-next-line max-len
                className="group flex w-full cursor-pointer items-center justify-between rounded-lg border border-primary px-3 py-2 text-left hover:bg-grey-100 dark:hover:bg-grey-800"
                onClick={buttonProps.onClick}
              >
                {heroOptions.find((option) => option.value === field.value)?.label ?? 'Select'}
                <DownIcon className="text-secondary group-aria-expanded:rotate-180" />
              </button>
            )}
          />
        )}
      />
      {hasShip && (
        <>
          <ChangelogDropdownDivider className="my-4" />
          <div className="mb-2 mt-4 font-medium">
            Ship badge color
          </div>
          <Controller
            control={control}
            name="shipBadgeColor"
            render={({ field }) => (
              <div className="grid grid-cols-11 place-items-center">
                {shipItems.map((shipOption, shipOptionIndex) => (
                  <label key={shipOptionIndex}>
                    <div
                      className="cursor-pointer rounded-full border border-transparent p-1 hover:border-grey-200 has-[:checked]:border-grey-500"
                    >
                      <input
                        type="radio"
                        value={shipOption.value}
                        name="shipBadgeColor"
                        checked={field.value === shipOption.value}
                        onChange={field.onChange}
                        className="sr-only"
                      />
                      <div
                        className="size-3 rounded-full border border-grey-550/10 shadow-xs"
                        style={{ backgroundColor: shipOption.color }}
                      />
                    </div>
                  </label>
                ))}
              </div>
            )}
          />
        </>
      )}
    </>
  );
}
