import { SelectPanel, Icon } from '@cycle-app/ui';

import { ToggleDropdown } from 'src/components/DropdownLayer';

import { CHANGELOG_TEMPLATES, ChangelogTemplate } from '../constants/templates';
import { useChangelogBuilderForm } from '../useChangelogBuilderForm';

export function ChangelogBuilderHeaderTemplates() {
  const {
    setValue, getValues,
  } = useChangelogBuilderForm();

  const handleTemplateClick = ({ theme }: ChangelogTemplate) => {
    setValue('heroSection', theme.heroSection, { shouldDirty: true });
    setValue('contentSection', theme.contentSection, { shouldDirty: true });
    setValue('backgroundColor', theme.backgroundColor, { shouldDirty: true });
    setValue('headersColor', theme.headersColor, { shouldDirty: true });
    setValue('linkColor', theme.linkColor, { shouldDirty: true });
    setValue('bodyColor', theme.bodyColor, { shouldDirty: true });
    setValue('shipBadgeColor', theme.shipBadgeColor, { shouldDirty: true });
    setValue('releaseTagStyle', theme.releaseTagStyle, { shouldDirty: true });
    setValue('releaseTagColor', theme.releaseTagColor, { shouldDirty: true });
    setValue('dividerColor', theme.dividerColor, { shouldDirty: true });

    const updatedTags = getValues('tags').map((tag, tagIndex) => ({
      ...tag,
      color: theme.tagColors[tagIndex % theme.tagColors.length] || tag.color,
    }));

    setValue('tags', updatedTags, { shouldDirty: true });
  };

  return (
    <ToggleDropdown
      withWrapper={false}
      content={({ hide }) => (
        <SelectPanel
          hideSearch
          options={CHANGELOG_TEMPLATES}
          onOptionChange={(payload) => {
            const foundOption = CHANGELOG_TEMPLATES.find((template) => template.value === payload.value);
            if (foundOption) {
              handleTemplateClick(foundOption);
            }
            hide();
          }}
        />
      )}
      button={({ onClick }) => (
        <button
          type="button"
          className="btn-tertiary aria-expanded:btn-hover"
          onClick={onClick}
        >
          <Icon
            name="table-fill"
            className="size-4"
          />
          Templates
        </button>
      )}
    />
  );
}
