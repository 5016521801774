import { ProductFragment, DoctypeFragment } from '@cycle-app/graphql-codegen';
import { nodeToArray } from '@cycle-app/utilities';
import isEqual from 'lodash/isEqual';
import keyBy from 'lodash/keyBy';
import pickBy from 'lodash/pickBy';

import { findFeedback, findInsight, isCustom, isParentOfInsight } from 'src/utils/docType.util';
import { make } from 'src/utils/reactives.util';

const {
  hookValue,
  hookWithSelector,
  getValue,
  setValue,
} = make<{
  docTypes: Record<string, DoctypeFragment>;
}>({
  defaultState: {
    docTypes: {},
  },
});

export const setDocTypes = (doctypes?: ProductFragment['doctypes']) => {
  const newDoctypes = keyBy(nodeToArray(doctypes), 'id');
  if (isEqual(newDoctypes, getValue().docTypes)) return;
  setValue({ docTypes: newDoctypes });
};

export const useGetDocTypes = hookValue;

export const getDocType = (id?: string | null) => {
  if (!id) return undefined;
  return getValue().docTypes[id];
};

export const getFeedback = () => findFeedback(Object.values(getValue().docTypes));

export const useGetDocType = (id?: string | null) => hookWithSelector(state => (!id ? undefined : state.docTypes[id]));

export const useGetDocTypeEmoji = (id?: string | null) => hookWithSelector(state => (!id ? undefined : state.docTypes[id]?.emoji));
export const useGetDocTypeName = (id?: string | null) => hookWithSelector(state => (!id ? undefined : state.docTypes[id]?.name));

export const useGetDocTypesWithReleaseProperty = () => hookWithSelector(state => pickBy(state.docTypes, 'release'));

export const useGetCustomDocTypes = () => hookWithSelector(state => pickBy(state.docTypes, isCustom));

export const getInsightDocType = () => findInsight(Object.values(getValue().docTypes));

export const useInsightDocType = () => hookWithSelector(state => findInsight(Object.values(state.docTypes)));
export const useFeedbackDocType = () => hookWithSelector(state => findFeedback(Object.values(state.docTypes)));

export const useInsightParentDocTypeIds = () => hookWithSelector(
  state => Object.values(state.docTypes).filter(isParentOfInsight).map(d => d.id),
);

export const useInsightParentDocTypes = () => hookWithSelector(
  state => Object.values(state.docTypes).filter(isParentOfInsight),
);

export const useFeatureChildrenDocTypes = (id?: string | null) => hookWithSelector(
  state => !id ? [] : nodeToArray(state.docTypes[id]?.children).filter(isCustom),
);
