import {
  FilterPropertyRuleEdgesFragment,
  GroupByConfigBoardQueryWithGroupByFragment,
  PropertiesFragment,
  SwimlaneByDoctypeFragment,
} from '@cycle-app/graphql-codegen';
import { SelectPanel, SelectOption, StatusIcon } from '@cycle-app/ui';
import { EyeClosedIcon, AddIcon, InfoIconOutline } from '@cycle-app/ui/icons';
import { nodeToArray } from '@cycle-app/utilities';
import { FC, useCallback, useMemo, useState, ReactNode } from 'react';
import { isPresent } from 'ts-is-present';

import DropdownLayer from 'src/components/DropdownLayer/DropdownLayer';
import { SoonBadge } from 'src/components/SoonBadge';
import { SourceCategoryIcon } from 'src/components/SourceCategoryIcon';
import { useIsRoadmapsEnabled } from 'src/hooks';
import useManageGroupBy from 'src/hooks/api/mutations/boardConfig/useManageGroupby';
import { GROUP_BY_RULES_WITH_DISABLED_CROSS_GROUP, GROUP_BY_RULES_WITH_INSIGHT_NO_EDIT_ALLOW } from 'src/hooks/dnd/useCrossGroupStrategy';
import { useDefaultNotStartedStatus } from 'src/reactives/productStatus.reactive';
import { isStatus } from 'src/types/graphql.types';
import { Layer } from 'src/types/layers.types';
import { ATTRIBUTE_ICON_MAPPING, getAttributeName } from 'src/utils/attributes.util';
import { isFilterDoctypePropertyRuleByInsight, getFilterAttributeOption } from 'src/utils/boardConfig/boardConfig.util';
import { getGroupName } from 'src/utils/groups.util';
import { getOptionFromDoctype } from 'src/utils/selectOptions.util';

import {
  Label,
  LightButton,
  GroupByButtons,
  GroupByButton,
  Caret,
  PreventDnd,
} from './BoardConfigForm.styles';

type VisibleDropdownState = 'groupBy' | 'hiddenGroups' | null;

interface Props {
  boardConfigId: string;
  groupableProperties: PropertiesFragment;
  groupByConfig: GroupByConfigBoardQueryWithGroupByFragment['groupbyConfig'] | null;
  boardID?: string;
  hideHiddenGroups?: boolean;
  isDisabled?: boolean;
  label?: ReactNode;
  filterProperties?: FilterPropertyRuleEdgesFragment;
  filterableProperties: PropertiesFragment;
  availableSwimlaneByDoctypes: SwimlaneByDoctypeFragment['availableSwimlaneByDoctypes'] | null;
  isFeedbackView?: boolean;
}
const BoardConfigFormGroupby: FC<React.PropsWithChildren<Props>> = ({
  boardConfigId,
  groupableProperties,
  groupByConfig,
  boardID,
  hideHiddenGroups,
  label,
  filterProperties,
  isDisabled = false,
  filterableProperties,
  availableSwimlaneByDoctypes,
  isFeedbackView = false,
}) => {
  const {
    changeGroupByProperty,
    removeGroupByProperty,
    hideBoardConfigGroupValue,
    showBoardConfigGroupValue,
    hideAllGroups,
    showAllGroups,
  } = useManageGroupBy(boardConfigId, boardID);
  const [visibleDropdown, setVisibleDropdown] = useState<VisibleDropdownState>(null);
  const isRoadmapsEnabled = useIsRoadmapsEnabled();
  const groupByOptions = useMemo(
    () => {
      let properties = nodeToArray(groupableProperties);
      if (!isRoadmapsEnabled && !isFeedbackView) properties = properties.filter(p => p.__typename !== 'StatusDefinition');
      const availableOptions = properties.map(property => getFilterAttributeOption(property, isFeedbackView));

      const comingSoonOptions = nodeToArray(filterableProperties)
        .filter(property => {
          // Filter out parent property
          if (property.__typename === 'ParentDefinition') return false;
          // Filter out the groupBy property
          if (groupByConfig?.property.id === property.id) return false;
          // Filter out non-groupable properties
          if (['AttributeUrlDefinition', 'AttributeTextDefinition', 'AttributeNumberDefinition'].includes(property.__typename)) return false;
          // Filter out already available options
          if (availableOptions.some(o => o.value === property.id)) return false;
          if (!isFeedbackView && !isRoadmapsEnabled && property.__typename === 'StatusDefinition') return false;
          return true;
        })
        .map(property => ({
          ...getFilterAttributeOption(property, isFeedbackView),
          disabled: true,
          end: <SoonBadge />,
        }));

      const parentOptions = nodeToArray(availableSwimlaneByDoctypes).map(d => ({
        ...getOptionFromDoctype(d),
        disabled: true,
        end: <SoonBadge />,
      }));

      return [
        ...availableOptions,
        ...comingSoonOptions,
        ...parentOptions,
      ];
    },
    [
      availableSwimlaneByDoctypes, filterableProperties, groupByConfig?.property.id,
      groupableProperties, isRoadmapsEnabled, isFeedbackView,
    ],
  );

  const selectedGroupBy = useMemo(() => (groupByConfig
    ? {
      __typename: groupByConfig.property.__typename,
      ...getFilterAttributeOption(groupByConfig.property, isFeedbackView),
    }
    : null), [groupByConfig, isFeedbackView]);

  const defaultNotStartedStatus = useDefaultNotStartedStatus();

  const hiddenGroupOptions = useMemo<SelectOption[]>(
    () => {
      if (groupByConfig && groupByConfig.property.__typename === 'StatusDefinition') {
        return groupByConfig.values.edges.reduce<SelectOption[]>((acc, { node }) => {
          if (!isStatus(node.propertyValue)) return acc;
          const disabled = node.propertyValue.id === defaultNotStartedStatus?.id;
          acc.push({
            value: node.id,
            label: node.propertyValue.value,
            selected: !node.hidden,
            icon: (
              <StatusIcon
                category={node.propertyValue.category}
                withBackground
              />
            ),
            disabled,
            tooltipContent: disabled ? `${node.propertyValue.value} can’t be hidden` : null,
            tooltipPlacement: 'top-end',
            tooltipOffset: [60, 5],
          });
          return acc;
        }, []);
      }
      return nodeToArray(groupByConfig?.values).map((group) => {
        const attributeName = groupByConfig ? getAttributeName(groupByConfig?.property, isFeedbackView) : undefined;
        return {
          value: group.id,
          label: getGroupName(group, attributeName),
          selected: !group.hidden,
          icon: group.propertyValue?.__typename === 'SourceCategory'
            ? <SourceCategoryIcon value={group.propertyValue.value} />
            : null,
        };
      }).filter(isPresent);
    },
    [defaultNotStartedStatus?.id, groupByConfig, isFeedbackView],
  );
  const nbHiddenGroups = useMemo(() => hiddenGroupOptions.filter(o => !o.selected)?.length, [hiddenGroupOptions]);

  const onGroupByChange = useCallback(async (option: SelectOption) => {
    setVisibleDropdown(null);
    const property = groupableProperties.edges.find(({ node }) => node.id === option.value)?.node;
    if (property) {
      await changeGroupByProperty(property);
    }
  }, [groupableProperties, changeGroupByProperty]);
  const onRemoveGroupby = useCallback(() => {
    setVisibleDropdown(null);
    return removeGroupByProperty();
  }, [removeGroupByProperty]);

  const onSelectOption = useCallback((o: SelectOption) => showBoardConfigGroupValue(o.value), [showBoardConfigGroupValue]);
  const onUnselectOption = useCallback((o: SelectOption) => hideBoardConfigGroupValue(o.value), [hideBoardConfigGroupValue]);
  const insightDoctypeFilter = isFilterDoctypePropertyRuleByInsight(filterProperties);
  const groupLabel = selectedGroupBy?.label;

  return (
    <>
      {Boolean(label) && (
        <Label>
          {label}
        </Label>
      )}
      <GroupByButtons $isFullWidth={hideHiddenGroups && !!selectedGroupBy}>
        <DropdownLayer
          layer={Layer.DropdownModalZ1}
          visible={visibleDropdown === 'groupBy'}
          hide={() => setVisibleDropdown(null)}
          placement="bottom-start"
          content={(
            <SelectPanel
              onClearValue={onRemoveGroupby}
              options={groupByOptions}
              onOptionChange={onGroupByChange}
            />
          )}
          disabled={isDisabled}
        >
          {!selectedGroupBy && (
            <LightButton
              onClick={() => !isDisabled && setVisibleDropdown('groupBy')}
              forceFocus={visibleDropdown === 'groupBy'}
              iconStart={<AddIcon size={11} />}
            >
              Add group
            </LightButton>
          )}
          {selectedGroupBy && (
            <GroupByButton
              type="button"
              onClick={() => !isDisabled && setVisibleDropdown('groupBy')}
              forceFocus={visibleDropdown === 'groupBy'}
              {...!hideHiddenGroups && { position: 'left' }}
            >
              {ATTRIBUTE_ICON_MAPPING[selectedGroupBy.__typename]}
              {selectedGroupBy.label}
              <Caret direction={visibleDropdown === 'groupBy' ? 'top' : 'bottom'} />
            </GroupByButton>
          )}
        </DropdownLayer>

        {!hideHiddenGroups && selectedGroupBy && (
          <DropdownLayer
            layer={Layer.DropdownModalZ1}
            visible={visibleDropdown === 'hiddenGroups'}
            hide={() => setVisibleDropdown(null)}
            placement="bottom-start"
            content={(
              <SelectPanel
                isMulti
                options={hiddenGroupOptions}
                onSelectOption={onSelectOption}
                onUnselectOption={onUnselectOption}
                onUnselectAll={hideAllGroups}
                unselectAllLabel="Hide all"
                onSelectAll={showAllGroups}
                selectAllLabel="Show all"
              />
            )}
            disabled={isDisabled}
          >
            <GroupByButton
              type="button"
              onClick={() => !isDisabled && setVisibleDropdown('hiddenGroups')}
              forceFocus={visibleDropdown === 'hiddenGroups'}
              position="right"
            >
              <EyeClosedIcon />
              <div>
                {`${nbHiddenGroups} hidden group${nbHiddenGroups > 1 ? 's' : ''}`}
              </div>
            </GroupByButton>
          </DropdownLayer>
        )}
      </GroupByButtons>
      {groupLabel &&
        [
          ...(insightDoctypeFilter?.isInsightSelected ? GROUP_BY_RULES_WITH_INSIGHT_NO_EDIT_ALLOW : []),
          ...GROUP_BY_RULES_WITH_DISABLED_CROSS_GROUP,
        ].includes(selectedGroupBy.__typename) &&
        (
          <PreventDnd>
            <InfoIconOutline />
            {`Drag & drop from ${groupLabel.toLocaleLowerCase()} to ${groupLabel.toLocaleLowerCase()} is disabled`}
            {(
              insightDoctypeFilter?.isOnlyInsightSelected ||
              GROUP_BY_RULES_WITH_INSIGHT_NO_EDIT_ALLOW.includes(selectedGroupBy.__typename)
            ) && ' for insights'}
            .
          </PreventDnd>
        )}
    </>
  );
};

export default BoardConfigFormGroupby;
