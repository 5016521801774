import { DoctypeFragment } from '@cycle-app/graphql-codegen';
import { useHotkeys } from '@cycle-app/utilities';
import { useEditor, EditorContent } from '@tiptap/react';
import { forwardRef, useImperativeHandle } from 'react';
import { useTheme } from 'styled-components';

import Bubble from 'src/components/Editor/Bubble/Bubble';
import { Content } from 'src/components/Editor/Editors/Editor.styles';
import { EditorContextProvider } from 'src/contexts/editorContext';
import { fullEditorExtensions } from 'src/editorExtensions/editorExtensions';
import { useProduct } from 'src/hooks';
import useUploadFile from 'src/hooks/useUploadFile';
import { ActionId, templateDisabledActions } from 'src/services/editor/editorActions';
import { insertFile, reorderProsemirrorPlugins } from 'src/utils/editor/editor.utils';
import { clearColors } from 'src/utils/html.utils';

import { EditorBubbleContainer } from './EditorBubbleContainer';
import { EditorQuickActions } from './EditorQuickActions';
import { useEditorTemplate } from './useEditorTemplate';

const disabledActions = [
  ...templateDisabledActions,
  ActionId.TurnTextIntoGithubIssueMention,
  ActionId.TurnTextIntoLinearMention,
  ActionId.Notion,
  ActionId.Linear,
  ActionId.LinearProject,
  ActionId.GithubIssue,
  ActionId.Transcript,
  ActionId.Embed,
  ActionId.Comments,
  ActionId.Ai,
  ActionId.Jira,
  ActionId.Confluence,
  ActionId.Keywords,
];

export type BasicEditorProps = {
  className?: string;
  docType?: DoctypeFragment;
  onUpdate?: (p: { html?: string; json?: string; text?: string }) => void;
  emptyPlaceholder?: string;
  initialValue?: string;
  hideQuickActions?: boolean;
};

export type BasicEditorCallbacks = {
  focusEnd: VoidFunction;
};

/**
 * Editor with only text formatting and docType template features
 */
export const BasicEditor = forwardRef<BasicEditorCallbacks, BasicEditorProps>(({
  className,
  onUpdate,
  emptyPlaceholder,
  docType,
  initialValue,
  hideQuickActions,
}, ref) => {
  const theme = useTheme();

  const {
    isUploading, onUpload,
  } = useUploadFile();

  const handlePastedFiles = async (files: File[]) => {
    await Promise.all(files.map(async file => {
      const uploadedFile = await onUpload?.(file, 'paste');
      if (!uploadedFile) return;
      insertFile({
        editor,
        file: uploadedFile,
      });
    }));
  };

  const editor = useEditor({
    immediatelyRender: true,
    content: initialValue,
    onCreate: params => reorderProsemirrorPlugins(params.editor),
    onUpdate: params => {
      onUpdate?.({
        json: JSON.stringify(params.editor.getJSON()),
        html: params.editor.getHTML(),
      });
    },
    editorProps: {
      attributes: {
        spellcheck: 'false',
      },
      transformPastedHTML: clearColors,
    },
    extensions: fullEditorExtensions({
      disabledActions: [
        ...templateDisabledActions,
      ],
      userColor: theme.userColors.main,
      emptyPlaceholder,
      isDnDEnabled: true,
      onPastedFiles: (_, files) => handlePastedFiles(files),
    }),
  }, []);

  useHotkeys('tab', e => {
    if (editor?.isFocused) e.preventDefault();
  });

  const {
    applyTemplate,
    previewTemplateModal,
    openPreviewTemplateModal,
    closePreviewTemplateModal,
  } = useEditorTemplate({
    editor,
    docType,
    onUpdate,
  });

  const { product } = useProduct();

  useImperativeHandle(ref, () => ({
    focusEnd: () => editor?.chain().focus('end').createParagraphNear().run(),
  }), [editor]);

  if (!editor) return null;

  return (
    <EditorContextProvider
      editor={editor}
      disabledActions={disabledActions}
      nbAiQueries={product?.nbAiQueries}
      isUploading={isUploading}
      onUpload={onUpload}
      disabledFeatures={[
        'transcript',
        'cover',
      ]}
    >
      <EditorBubbleContainer className={className}>
        {onMouseDown => (
          <>
            <Bubble disabledActions={disabledActions} />
            <Content
              className="content-editor"
              onMouseDown={onMouseDown}
            >
              <EditorContent editor={editor} />
              {editor.isEmpty && !hideQuickActions && (
                <EditorQuickActions
                  applyTemplate={applyTemplate}
                  disabledActions={disabledActions}
                  docType={docType}
                  onHidePreviewTemplate={closePreviewTemplateModal}
                  onShowPreviewTemplate={openPreviewTemplateModal}
                />
              )}
            </Content>
          </>
        )}
      </EditorBubbleContainer>

      {previewTemplateModal}
    </EditorContextProvider>
  );
});
