import { ColorTheme } from '@cycle-app/utilities';
import styled from 'styled-components';

import * as typo from '../../theme/typo';
import { boxShadowZ1 } from '../../utils/styles.util';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  gap: 16px;
  width: 100%;
  --border: ${p => (p.theme.isDark ? ColorTheme.Grey850 : 'rgba(0,0,0,0.06)')};
  outline: 1px solid var(--border);
  background-color: ${p => p.theme.colors.modal.content.bg};
  border-radius: 8px;
  ${boxShadowZ1}
`;

export const Logo = styled.div`
  flex: none;
  > svg {
    width: 20px;
    height: 20px;
  }
`;

export const Actions = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
`;

export const Name = styled.h3`
  ${typo.body500}
  font-weight: 500;
  color: ${p => p.theme.colors.text.primary};
`;

export const Description = styled.p`
  ${typo.body400}
  color: ${p => p.theme.colors.text.secondary};
  display: flex;
  gap: 8px;
  flex: 1;
`;

export const Cta = styled.div`
  margin-top: 15px;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 8px;
  width: 100%;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1px;
  width: 100%;
  flex: 1;
`;
