import { AiState } from '@cycle-app/graphql-codegen';
import { TagProps, TooltipLegacy } from '@cycle-app/ui';
import { AiIcon, CheckAltIcon } from '@cycle-app/ui/icons';

import { AiStatePopover } from 'src/components/AiStatePopover';

import { StyledTag } from './AiStateTag.styles';

export type AiStateTagProps = TagProps & {
  docId?: string;
  docTypeId?: string;
  aiState: AiState | null | undefined;
  full?: boolean;
  isDisabled?: boolean;
};

export const AiStateTag = ({
  docId,
  docTypeId,
  aiState,
  full = false,
  isDisabled = false,
  ...props
}: AiStateTagProps) => {
  if (!aiState) return null;

  const isVerifiable = aiState === AiState.AiCreated && docId && docTypeId && !isDisabled;

  const tag = (
    <StyledTag
      color="grey"
      icon={(
        <>
          <AiIcon size={13} />
          {aiState === AiState.UserValidated && <CheckAltIcon size={8} />}
        </>
      )}
      {...props}
    >
      {full && getAiStateLabel(aiState)}
    </StyledTag>
  );

  if (docId && isVerifiable) {
    return (
      <AiStatePopover docId={docId}>
        {tag}
      </AiStatePopover>
    );
  }

  return (
    <TooltipLegacy
      placement="top"
      content="AI-generated, user-verified"
      withPortal
      withWrapper={false}
    >
      {tag}
    </TooltipLegacy>
  );
};

const getAiStateLabel = (aiState: AiState) => {
  if (aiState === AiState.UserValidated) return 'User-verified';
  return 'AI-generated';
};
