import { useFragment } from '@apollo/client';
import { DocCoverFragmentDoc } from '@cycle-app/graphql-codegen';
import { SelectPanel, Icon, VariantOption, SelectOption } from '@cycle-app/ui';
import { TrashIcon, MoreHorizIcon, ImageIcon, EditIcon } from '@cycle-app/ui/icons';
import { delay } from '@cycle-app/utilities/src/utils/async.utils';
import { ComponentProps, useCallback, useRef, useState } from 'react';
import { twJoin } from 'tailwind-merge';

import CoverImageInputFile from 'src/components/CoverImageInputFile/CoverImageInputFile';
import { DocDeleteWarningModal } from 'src/components/DocDeleteWarningModal';
import { DocOptionsEditProperty } from 'src/components/DocOptions/DocOptionsEditProperty';
import { ToggleDropdown } from 'src/components/DropdownLayer';
import { QuoteFeatureDropdownContent } from 'src/components/QuoteCard/QuoteFeatureDropdown';
import { PageId } from 'src/constants/routing.constant';
import useDocCoverMutations from 'src/hooks/api/mutations/useDocCoverMutations';
import { useDocBase } from 'src/hooks/api/useDoc';
import { closeQuoteModal, getQuoteIdParam } from 'src/hooks/modals/useQuoteModal';
import { navigate } from 'src/hooks/useNavigate';
import { getPermission, setLimitationsModal } from 'src/reactives';
import { useInsightDocType } from 'src/reactives/docTypes.reactive';
import { Layer } from 'src/types/layers.types';
import { getEditCoverAction } from 'src/utils/doc.util';
import { getPageId } from 'src/utils/routing.utils';

export const QuoteOptions = ({
  docId,
  className,
  showEditProperty = false,
  showChangeFeature = false,
}: {
  docId: string;
  showEditProperty?: boolean;
  showChangeFeature?: boolean;
  className?: string;
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [menu, setMenu] = useState<'main' | 'properties' | 'feature'>('main');
  const quoteDoctype = useInsightDocType();

  const { updateDocCover } = useDocCoverMutations(docId);
  const coverInputRef = useRef<HTMLInputElement | null>(null);

  const coverUrl = useFragment({
    fragment: DocCoverFragmentDoc,
    from: docId,
  }).data.cover?.url;

  const getOptions = useCallback((hide: () => void) => {
    const options: SelectOption[] = [];

    if (showEditProperty) {
      options.push({
        value: 'edit-property',
        label: 'Edit property',
        icon: <Icon name="edit" />,
        onSelect: () => setMenu('properties'),
      });
    }

    if (showChangeFeature) {
      options.push({
        value: 'change-feature',
        label: 'Change request',
        icon: <EditIcon />,
        onSelect: () => setMenu('feature'),
      });
    }

    options.push({
      value: 'edit-cover',
      label: getEditCoverAction(coverUrl),
      icon: <ImageIcon />,
      onSelect: () => {
        hide();
        coverInputRef.current?.click();
      },
    }, {
      value: 'delete',
      label: 'Delete quote',
      icon: <TrashIcon />,
      variant: 'danger' as VariantOption,
      onSelect: () => {
        hide();
        if (!getPermission().canDeleteInsight) {
          setLimitationsModal({ action: 'INSIGHT_DELETE' });
          return;
        }
        setModalOpen(true);
      },
    });

    return options;
  }, [coverUrl, showEditProperty, showChangeFeature]);

  if (!quoteDoctype) return null;

  return (
    <>
      <ToggleDropdown
        layer={Layer.DropdownModalZ3}
        placement="bottom-end"
        withWrapper={false}
        onHide={() => setMenu('main')}
        button={props => (
          <button
            className={twJoin(
              'btn-tertiary btn-square text-disabled hover:text-primary',
              props['data-active'] && 'btn-hover text-primary',
              className,
            )}
            ref={props.ref}
            onClick={props.onClick}
          >
            <MoreHorizIcon />
          </button>
        )}
        content={props => {
          if (menu === 'properties') {
            return (
              <QuoteEditProperty
                docId={docId}
                hide={props.hide}
                goBack={() => setMenu('main')}
                keepDropdownOnSelect
              />
            );
          }

          if (menu === 'feature') {
            return (
              <QuoteFeatureDropdownContent
                docId={docId}
                hide={props.hide}
              />
            );
          }

          return (
            <SelectPanel
              hideSearch
              onOptionChange={option => {
                option.onSelect?.();
              }}
              options={getOptions(props.hide)}
            />
          );
        }}
      />

      <CoverImageInputFile
        ref={coverInputRef}
        onCoverChanged={file => updateDocCover(file, { optimistic: true })}
      />

      {modalOpen && (
        <DocDeleteWarningModal
          docId={docId}
          onHide={() => setModalOpen(false)}
          onConfirm={async () => {
            if (getPageId() === PageId.Quote) {
              navigate(PageId.Main);
              return;
            }
            if (getQuoteIdParam()) {
              closeQuoteModal();
              await delay(200);
            }
          }}
        />
      )}
    </>
  );
};

type QuoteEditPropertyProps = Omit<ComponentProps<typeof DocOptionsEditProperty>, 'doc'> & {
  docId: string;
};

const QuoteEditProperty = ({
  docId, ...props
}: QuoteEditPropertyProps) => {
  const { doc } = useDocBase(docId);
  if (!doc) return null;
  return (
    <DocOptionsEditProperty
      doc={doc}
      {...props}
    />
  );
};
