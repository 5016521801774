import { TooltipLegacy, DraggableCard } from '@cycle-app/ui';
import { CheckIcon } from '@cycle-app/ui/icons';
import { memo, useState } from 'react';
import { twJoin } from 'tailwind-merge';

import DocAssignee from 'src/components/DocAssignee/DocAssignee';
import { DocAttributes } from 'src/components/DocAttributes';
import { useBoardConfig } from 'src/contexts/boardConfigContext';
import { useDocContext } from 'src/contexts/docContext';
import { useDocItemHoverIdSelector } from 'src/reactives/docItem.reactive';
import { useGetDocType } from 'src/reactives/docTypes.reactive';
import { useDocsSelectedLength, useIsDocSelected } from 'src/reactives/selection.reactive';
import { ViewType } from 'src/types/viewType.types';

import { CardContainer, BulkContainer, Viewers, PlaceholderMulti } from './DocItem.styles';
import { DocItemProps } from './DocItem.types';
import { DocItemCheckbox } from './DocItemCheckbox';
import { DocItemContextTitle } from './DocItemContextTitle';
import { DocItemOptions } from './DocItemOptions';
import { DocItemViewer } from './DocItemViewer';
import { QuoteAiState } from '../QuoteCard/QuoteAiState';
import { QuoteCustomer } from '../QuoteCard/QuoteCustomer';
import { QuoteFeature } from '../QuoteCard/QuoteFeature';
import { QuoteFeedback } from '../QuoteCard/QuoteFeedback';
import { QuoteSource } from '../QuoteCard/QuoteSource';

const LIMIT_DISPLAY_VIEWERS_LENGTH = 3;

export type DocItemCardProps = Omit<DocItemProps, 'isGroupInBoardView' | 'isLazy' | 'docIndex'> & {
  isFocused?: boolean;
  isReadOnly?: boolean;
  updateDocCover?: (file: File) => void;
};

export const DocItemCardQuote = ({
  isDragging = false,
  asPlaceholder = false,
  direction,
  viewType,
  showAssignee,
  showCover,
  showDocParent,
  showSource,
  showCustomer,
  showProperties = true,
  showAiState,
  showProductAreas,
  isSelectable = false,
  isFocused = false,
  isReadOnly = true,
  updateDocCover,
  isNewInbox,
}: DocItemCardProps) => {
  const docId = useDocContext(ctx => ctx.id);
  const docTypeId = useDocContext(ctx => ctx.doctype.id);
  const docType = useGetDocType(docTypeId);
  const docCoverUrl = useDocContext(ctx => ctx.cover?.url);
  const isCreating = useDocContext(ctx => ctx._creating);
  const docSourceId = useDocContext(ctx => ctx.docSource?.doc?.id);
  const assignee = useDocContext(ctx => ctx.assignee);
  const isSelected = useIsDocSelected(docId);
  const selectedLength = useDocsSelectedLength();
  const isKanban = viewType === ViewType.Kanban;
  const [isDocOptionsVisible, setDocOptionsVisibility] = useState(false);
  const docAssignee = showAssignee && docType && (
    <DocAssignee
      docId={docId}
      assignee={assignee}
      isDisabled
      isRemovable={false}
      context="doc-item"
      docTypeName={docType.name}
      docTypeType={docType.type}
    />
  );

  return (
    <CardContainer
      id={docId}
      className="doc-item"
      $disableActions={selectedLength > 0}
      $viewType={viewType}
      $isDocOptionsVisible={isDocOptionsVisible}
    >
      {isDragging && selectedLength > 1 && !isReadOnly && (
        <PlaceholderMulti direction={direction} />
      )}

      <DocItemViewers />

      <DraggableCard
        isCreating={!!isCreating}
        isDragging={isDragging}
        direction={direction}
        isHover={isFocused}
        isSelected={isSelectable && isSelected}
        asPlaceholder={asPlaceholder}
      >
        {docCoverUrl && isKanban && showCover && (
          <img
            className="mb-2 block h-32 w-full rounded object-cover"
            src={docCoverUrl}
          />
        )}
        <div className="flex justify-between">
          <div className="flex items-center gap-1 overflow-hidden">
            {showCustomer && <QuoteCustomer docId={docId} />}
            {showDocParent && <QuoteFeature docId={docId} />}
          </div>
          <div className="flex items-center gap-2">
            {!isKanban && (
              <>
                <DocItemAttributes
                  viewType={viewType}
                  showProperties={showProperties}
                  showProductAreas={showProductAreas}
                />
                <QuoteSource docId={docId} />
                {docAssignee}
                {docSourceId && <QuoteFeedback feedbackDocId={docSourceId} />}
              </>
            )}
            <div className={twJoin(isKanban && 'absolute right-0 top-0')}>
              <DocItemOptions
                updateDocCover={updateDocCover}
                viewType={viewType}
                onVisibilityChange={setDocOptionsVisibility}
                isNewInbox={isNewInbox}
              />
            </div>
          </div>
        </div>
        <DocItemContextTitle
          viewType={viewType}
          className="mt-2"
        />
        {isKanban && (
          <>
            <DocItemAttributes
              viewType={viewType}
              showProperties={showProperties}
              showProductAreas={showProductAreas}
              className="mt-2"
            />
            <div className="mt-2 flex justify-between">
              <div className="flex gap-2">
                {showSource && <QuoteSource docId={docId} />}
                {showAiState && <QuoteAiState docId={docId} />}
              </div>
              <div className="flex items-center gap-2">
                {docSourceId && <QuoteFeedback feedbackDocId={docSourceId} />}
                {docAssignee}
              </div>
            </div>
          </>
        )}
        {isSelectable && !isDragging && !asPlaceholder && !isReadOnly && <DocItemBulkCheckbox viewType={viewType} />}
      </DraggableCard>
    </CardContainer>
  );
};

const DocItemViewers = memo(() => {
  const viewers = useDocContext(ctx => ctx._viewers);

  if (!viewers?.length) return null;

  return (
    <Viewers>
      {viewers?.slice(0, LIMIT_DISPLAY_VIEWERS_LENGTH).map(viewerId => (
        <DocItemViewer
          key={viewerId}
          userId={viewerId}
        />
      ))}
    </Viewers>
  );
});

type DocItemAttributesProps = Pick<
DocItemCardProps,
| 'viewType'
| 'isDragging'
| 'showProperties'
| 'showProductAreas'
| 'className'
>;

const DocItemAttributes = memo(({
  className,
  viewType,
  isDragging,
  showProperties,
  showProductAreas,
}: DocItemAttributesProps) => {
  const doc = useDocContext();
  const displayedPropertiesIds = useBoardConfig(ctx => ctx.displayedPropertiesIds);
  return (
    <DocAttributes
      className={className}
      doc={doc}
      displayedPropertiesIds={showProperties ? displayedPropertiesIds : []}
      viewType={viewType}
      isDragging={isDragging}
      showDocId={false}
      showDocType={false}
      showSource={false}
      showStatus={false}
      showRelease={false}
      showLinear={false}
      showAiState={false}
      showProductAreas={showProductAreas}
      displayPrimaryAttributes={viewType === ViewType.Kanban}
      context="doc-item"
    />
  );
});

const DocItemBulkCheckbox = ({ viewType }: Pick<DocItemCardProps, 'viewType'>) => {
  const docId = useDocContext(ctx => ctx.id);
  const isSelected = useIsDocSelected(docId);
  const isHovered = useDocItemHoverIdSelector(id => id === docId);

  if (!isSelected && !isHovered) return null;

  return (
    <BulkContainer
      $viewType={viewType}
    >
      <TooltipLegacy
        content="Select"
        placement="top"
        withPortal
      >
        <DocItemCheckbox
          docId={docId}
          viewType={viewType}
        >
          <CheckIcon />
        </DocItemCheckbox>
      </TooltipLegacy>
    </BulkContainer>
  );
};
