import { TooltipLegacy, Shortcut } from '@cycle-app/ui';
import { AddIcon } from '@cycle-app/ui/icons';
import { useState } from 'react';
import { twJoin } from 'tailwind-merge';

import { NewReleaseModal } from 'src/components/ReleaseModals';
import { shortcuts, Shortcut as GlobalShortcut } from 'src/constants/shortcuts.constants';
import { useHotkeyListener } from 'src/hooks/useHotkeyListener';
import { useSidebarCollapsed } from 'src/hooks/useSidebarCollapsed';
import { setLimitationsModal } from 'src/reactives/limitationsModal.reactive';
import { getPermission } from 'src/reactives/permission.reactive';
import { Layer } from 'src/types/layers.types';

export const NewReleaseButton = () => {
  const collapsed = useSidebarCollapsed();
  const [modalOpen, setModalOpen] = useState(false);

  const createRelease = () => {
    if (getPermission().canCreateRelease) {
      setModalOpen(true);
    } else {
      setLimitationsModal({ action: 'RELEASE_UPDATE' });
    }
  };

  useHotkeyListener({
    callbacks: {
      [GlobalShortcut.CreateRelease]: createRelease,
    },
    shortcuts: [
      GlobalShortcut.CreateRelease,
    ],
    disableOnLayers: [
      Layer.DocPanel,
      Layer.Dropdown,
      Layer.Modal,
      Layer.ModalZ2,
      Layer.ModalZ3,
      Layer.DialogModal,
      Layer.MaskCommandbar,
      Layer.Commandbar,
    ],
  });

  return (
    <>
      <TooltipLegacy
        placement={collapsed ? 'right' : 'bottom'}
        withPortal
        withWrapper={false}
        content={(
          <Shortcut
            label="New release"
            keys={shortcuts[GlobalShortcut.CreateRelease]}
          />
        )}
      >
        <button
          className={twJoin(
            'btn btn-tertiary btn-square',
            !collapsed && 'text-disabled hover:text-secondary',
          )}
          onClick={createRelease}
        >
          <AddIcon size={14} />
        </button>
      </TooltipLegacy>

      <NewReleaseModal
        open={modalOpen}
        onOpenChange={setModalOpen}
      />
    </>
  );
};
