import { Button } from '@cycle-app/ui';
import orderBy from 'lodash/orderBy';
import { FC, useMemo } from 'react';

import { useCurrentBilling, useFeedbackDocType, useProductIntegrations } from 'src/hooks';
import { setLimitationsModal, useGetIntegrationPermission } from 'src/reactives';

import { integrationsDataMap } from '../../constants/integrations.constants';
import { ToggleDropdown } from '../DropdownLayer';
import { InboxZeroSources } from './InboxZeroSources';
import { SourcesPanel } from './SourcesPanel';
import union from './union.svg';
const openLimitationsModal = () => setLimitationsModal({ action: 'INTEGRATION_ADD' });

export const InboxZero: FC<React.PropsWithChildren<{ onClick: VoidFunction }>> = ({ onClick }) => {
  const { sourcesByStatus } = useProductIntegrations();
  const { canAddIntegration } = useGetIntegrationPermission();
  const billing = useCurrentBilling();
  const installed = useMemo(() => orderBy(
    sourcesByStatus.installed
      .filter(x => (!(billing?.plan === 'FREE' && ['ZAPIER', 'CHROME'].includes(x.integrationType))))
      .map(i => ({
        ...i,
        order: integrationsDataMap[i.integrationType].order,
      })),
    ['order'],
  ), [sourcesByStatus.installed, billing?.plan]);

  const feedback = useFeedbackDocType();
  const feedbackName = feedback?.name ?? 'Feedback';

  return (
    <div className="flex h-full items-center justify-center px-8">
      <div className="flex flex-col items-center">
        <div className="relative">
          <div className="absolute -left-4 top-3 h-20 w-28 rounded-lg bg-white opacity-80 shadow-[0px_3px_18px_0px_rgba(0,0,0,0.08),0px_1.5px_3px_0px_rgba(0,0,0,0.07)] dark:bg-grey-350" />
          <div className="relative z-40 h-[100px] w-20 rounded-xl bg-white p-3 shadow-[0px_4px_24px_0px_rgba(0,0,0,0.08),0px_2px_4px_0px_rgba(0,0,0,0.07)] dark:bg-grey-350">
            <img src={union} />
          </div>
        </div>
        <InboxZeroSources installed={installed} />
        <div className="mt-5 text-center font-medium">
          You don’t have any feedback yet
        </div>
        <div className="text-center text-secondary">
          Add a source to capture feedback from anywhere or directly create feedback from here
        </div>
        <div className="mt-6 flex flex-wrap justify-center gap-4">
          {sourcesByStatus.uninstalled.length && !(billing?.plan === 'FREE' && installed.length === 1) && (
            <ToggleDropdown
              button={(buttonProps) => (
                <Button
                  size="M"
                  variant="outlined-alt"
                  onClick={canAddIntegration ? buttonProps.onClick : openLimitationsModal}
                >
                  Add source
                </Button>
              )}
              content={contentProps => (
                <SourcesPanel hide={contentProps.hide} />
              )}
            />
          )}
          <Button
            size="M"
            onClick={onClick}
          >
            {`Create ${feedbackName}`}
          </Button>
        </div>
      </div>
    </div>
  );
};
