import { useQuery } from '@apollo/client';
import { MeetingCalendarEventsDocument } from '@cycle-app/graphql-codegen';
import { nodeToArray } from '@cycle-app/utilities';
import { useEffect, useMemo } from 'react';

import { useWorkspaceContext } from 'src/contexts/workspaceContext';
import { useMeetingsIntegration } from 'src/hooks/integration/useMeetingsIntegration';
import { useProductAddOn } from 'src/hooks/useProductAddOns';
import client from 'src/services/apollo/client';

export const useMeetingsEvents = () => {
  const productId = useWorkspaceContext(ctx => ctx.productId);
  const {
    data: upcomingData, loading: isUpcomingLoading,
  } = useQuery(MeetingCalendarEventsDocument, {
    // Always get fresh data.
    fetchPolicy: 'cache-and-network',
    variables: {
      productId,
      after: true,
    },
  });
  const {
    data: pastData, loading: isPastLoading,
  } = useQuery(MeetingCalendarEventsDocument, {
    fetchPolicy: 'cache-and-network',
    variables: {
      productId,
      after: false,
    },
  });
  const upcoming = useMemo(() => {
    return nodeToArray(upcomingData?.getCalendarEvents).filter(e => {
      // Keep only events that are recordable
      return e.url;
    });
  }, [upcomingData?.getCalendarEvents]);

  const {
    ongoing, passed,
  } = useMemo(() => {
    const now = new Date().getTime();
    return {
      ongoing: nodeToArray(pastData?.getCalendarEvents)
        .filter(e => {
          return (
            e.url && // Keep only events that are recordable
            new Date(e.endTime).getTime() >= now
          );
        })
        .sort((a, b) => new Date(b.startTime).getTime() - new Date(a.startTime).getTime()),
      passed: nodeToArray(pastData?.getCalendarEvents)
        .filter(e => {
          return (
            e.doc?.id && // Keep only events that were recorded.
            new Date(e.endTime).getTime() < now
          );
        })
        .sort((a, b) => new Date(b.startTime).getTime() - new Date(a.startTime).getTime()),
    };
  }, [pastData?.getCalendarEvents]);
  return {
    events: {
      ongoing,
      passed,
      upcoming,
    },
    isInitialLoading: (isPastLoading && !pastData) || (isUpcomingLoading && !upcomingData),
    isPastLoading,
    isUpcomingLoading,
  };
};

export const usePrefetchMeetingsEvents = () => {
  const productId = useWorkspaceContext(ctx => ctx.productId);
  const { isEnabled } = useProductAddOn('MEETINGS');
  const { isInstalled } = useMeetingsIntegration();
  useEffect(() => {
    if (!isEnabled || !isInstalled) return;
    for (const after of [true, false]) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      client.query({
        query: MeetingCalendarEventsDocument,
        variables: {
          productId,
          after,
        },
      });
    }
  }, [isEnabled, isInstalled, productId]);
};
