import { twJoin } from 'tailwind-merge';
import { Placement } from 'tippy.js';

import { useThemeVariant } from '../../shared/useThemeVariant';
import { useChangelogBuilderForm } from '../../useChangelogBuilderForm';
import { ChangelogBuilderContentLink } from '../ChangelogBuilderContentLink';
import { ChangelogBuilderContentSubscription } from '../ChangelogBuilderContentSubscription';

export function ChangelogBuilderHeroLinks({
  className, dropdownPlacement = 'bottom',
}: { className?: string; dropdownPlacement?: Placement }) {
  const { watch } = useChangelogBuilderForm();

  const contentClasses = useThemeVariant(watch('contentSection'));

  return (
    <div
      className={twJoin(
        'flex items-center justify-center gap-2 text-center',
        contentClasses({
          'UNIFORM': 'text-base',
          'SOBER': 'text-base',
          'SHOW_OFF': 'text-lg',
        }) ?? 'text-sm',
        className,
      )}
    >
      <ChangelogBuilderContentSubscription dropdownPlacement={dropdownPlacement} />
      <span className="size-1 rounded-full bg-(--changelog-divider-color)" />
      <ChangelogBuilderContentLink dropdownPlacement={dropdownPlacement} />
    </div>
  );
}
