/**
 * Low-level abstraction of Radix DropdownMenu primitives with custom styles and animations
 */
import { AnimatePresence, motion, MotionProps, Variants  } from 'framer-motion';
import { DropdownMenu } from 'radix-ui';
import { ComponentPropsWithoutRef, forwardRef, useRef } from 'react';
import { twMerge } from 'tailwind-merge';

import { SubContent } from './SubContent';
import { SubMenu } from './SubMenu';
import { SubTrigger } from './SubTrigger';

interface Props extends Omit<ComponentPropsWithoutRef<typeof DropdownMenu.Content>, 'forceMount' | 'asChild'> {
  open?: boolean;
  motionVariants?: MotionProps['variants'];
}

const Content = forwardRef<HTMLDivElement, Props>(
  function Content({
    open = false,
    motionVariants = defaultVariants,
    className,
    children,
    ...props
  }, forwardedRef) {
    const ref = useRef<HTMLDivElement>(null);
    return (
      <AnimatePresence>
        {open && (
          <DropdownMenu.Content
            ref={forwardedRef}
            forceMount
            asChild
            align="start"
            sideOffset={8}
            collisionPadding={8}
            loop
            {...props}
          >
            <motion.div
              ref={ref}
              className={twMerge(
                'bg-white flex flex-col p-1 items-stretch dark:bg-grey-850 rounded-md outline! outline-black/[6%]! dark:shadow-none dark:outline-grey-800! shadow-z3 text-primary shy-scrollbar',
                'max-h-[var(--radix-dropdown-menu-content-available-height)] max-w-[var(--radix-dropdown-menu-content-available-width)]',
                className,
              )}
              animate={open ? 'open' : 'closed'}
              initial="closed"
              exit="closed"
              variants={motionVariants}
              style={{
                transformOrigin: 'var(--radix-dropdown-menu-content-transform-origin)',
              }}
            >
              {children}
            </motion.div>
          </DropdownMenu.Content>
        )}
      </AnimatePresence>
    );
  },
);

const defaultVariants = {
  open: {
    opacity: 1,
    scale: 1,
    transition: {
      type: 'spring',
      duration: 0.2,
    },
  },
  closed: {
    opacity: 0,
    scale: 0.9,
    transition: {
      type: 'spring',
      duration: 0.2,
    },
  },
} satisfies Variants;

interface ItemProps extends React.ComponentPropsWithoutRef<typeof DropdownMenu.Item> {}

const Item = forwardRef<HTMLDivElement, ItemProps>(
  function Item(props, ref) {
    return (
      <DropdownMenu.Item
        ref={ref}
        {...props}
        className={twMerge(
          'group flex items-center justify-start gap-2 py-1 px-1.5 rounded min-h-8 data-[highlighted]:bg-grey-100 cursor-pointer dark:data-[highlighted]:bg-grey-800 flex-none leading-snug',
          props.className,
        )}
      />
    );
  },
);

interface SeparatorProps extends React.ComponentPropsWithoutRef<typeof DropdownMenu.Separator> {}

const Separator = forwardRef<HTMLDivElement, SeparatorProps>(
  function Separator(props, ref) {
    return (
      <DropdownMenu.Separator
        ref={ref}
        {...props}
        className={twMerge(
          'border-t border-grey-150 dark:border-grey-800 -mx-1 my-1.5',
          props.className,
        )}
      />
    );
  },
);

const CustomDropdownMenu = {
  Root: DropdownMenu.Root,
  Trigger: DropdownMenu.Trigger,
  Portal: DropdownMenu.Portal,
  Sub: DropdownMenu.Sub,
  Content,
  Item,
  Separator,
  SubMenu,
  SubContent,
  SubTrigger,
};

export { CustomDropdownMenu as DropdownMenu };
