import { ProductBaseFragment } from '@cycle-app/graphql-codegen';
import { TextHighlighter, ProductLogo } from '@cycle-app/ui';

export const CommandWorkspaceAction = ({
  product, searchText,
}: {
  product: ProductBaseFragment;
  searchText: string;
}) => {
  return (
    <div className="flex h-9 items-center gap-2 px-2.5">
      <ProductLogo
        url={product.logo?.url}
        alt={product.name}
        className="size-4"
      />

      <TextHighlighter
        searchWords={[searchText]}
        textToHighlight={product.name}
        className="highlight"
      />

      <span className="text-caption text-disabled">
        {product.slug}
      </span>
    </div>
  );
};
