import { BoardType } from '@cycle-app/graphql-codegen';
import { useEffect, FC, useState } from 'react';

import BoardContent from 'src/app/Main/Board/BoardContent/BoardContent';
import { MobileViewHeader } from 'src/app/Main/Board/BoardContent/BoardContent.styles';
import { BoardContentSkeleton } from 'src/app/Main/Board/BoardContent/BoardContentSkeleton';
import { CreateDocModal } from 'src/app/Main/Board/CreateDocModal/CreateDocModal';
import { ErrorPage, Size } from 'src/components/ErrorPage';
import { InboxZero } from 'src/components/InboxZero';
import { OfflineState } from 'src/components/OfflineState';
import { ViewBreadcrumb } from 'src/components/ViewBreadcrumb';
import { BULK_SELECTION_AREA_ID } from 'src/constants/bulkSelection.constants';
import { useBoardConfig } from 'src/contexts/boardConfigContext';
import { matchBoardConfig } from 'src/hoc/matchBoardConfig';
import { useBoard, useIsOffline } from 'src/hooks';
import { useInitBoard } from 'src/hooks/boards/useInitBoard';
import { useBoardSlug } from 'src/hooks/usePathParams';
import { useIsMobile } from 'src/reactives';
import { setLastInboxBoard, setLastView } from 'src/reactives/lastView.reactive';
import { isViewEmpty } from 'src/utils/boardConfig/isViewEmpty.util';

import { ViewContainer } from './Inbox.styles';
import { useBoardSections } from '../../../hooks/api/useBoardSections';
import AnalysesPage from '../../AnalysesPage';
import { InsightsEmpty } from '../../ReleasesEmpty/InsightsEmpty';

export const InboxView: FC<React.PropsWithChildren<unknown>> = matchBoardConfig(({ children }) => {
  const isMobile = useIsMobile();
  const hasError = useBoardConfig(ctx => ctx.hasError);
  const isAnalytics = useBoardConfig(ctx => ctx.isAnalytics);
  const isOffline = useIsOffline();
  const { feedbackSectionId } = useBoardSections();

  if (isOffline && hasError) return <OfflineState />;

  if (hasError) return <ErrorPage />;

  return (
    <ViewContainer
      id={BULK_SELECTION_AREA_ID}
      $isScrollable={isAnalytics}
    >
      {isMobile && (
        <MobileViewHeader>
          {feedbackSectionId && (
            <ViewBreadcrumb
              sectionId={feedbackSectionId}
              sectionParent="inbox"
            />
          )}
        </MobileViewHeader>
      )}
      <ViewContent />
      <CreateDocModal />
      {children}
    </ViewContainer>
  );
});

const ViewContent = () => {
  const boardSlug = useBoardSlug();
  const boardId = useBoardConfig(ctx => ctx.boardId);
  const board = useBoard(boardId);
  const loading = useBoardConfig(ctx => ctx.loading);
  const notFound = useBoardConfig(ctx => ctx.notFound);
  const boardConfig = useBoardConfig(ctx => ctx.boardConfig);
  const isFilteredByQuote = useBoardConfig(ctx => ctx.insightDocType.isInBoard);

  const boardConfigId = boardConfig?.id;
  const [isEmptyStateVisible, setIsEmptyStateVisible] = useState(isViewEmpty(boardConfig));

  // Show the empty state if the board is empty
  useEffect(() => {
    if (!isEmptyStateVisible && isViewEmpty(boardConfig)) {
      setIsEmptyStateVisible(true);
    } else if (isEmptyStateVisible && !isViewEmpty(boardConfig)) {
      setIsEmptyStateVisible(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [boardConfig]);

  // If the user clicks on "New doc" in the empty state,
  // we open the new doc form in the first group
  useEffect(() => {
    if (!isEmptyStateVisible && isViewEmpty(boardConfig)) {
      const button = document.querySelector(`#${BULK_SELECTION_AREA_ID} button[data-action="new-doc"]`);
      if (button instanceof HTMLButtonElement) button.click();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEmptyStateVisible]);

  useInitBoard({
    board,
    boardConfigId,
  });

  useEffect(() => {
    setLastView({ section: 'feedback' });
    setLastInboxBoard({
      boardSlug,
      boardId: board?.id,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [boardSlug]);

  if (notFound) return (
    <ErrorPage
      message="Oops, this view no longer exists"
      size={Size.SMALL}
    />
  );

  const viewType = board?.publishedBoardConfig?.viewType;
  if (board?.type === BoardType.Custom && !viewType) return null;

  if (board?.type === BoardType.Analytics) {
    return (
      <AnalysesPage
        isTransparent
        boardId={board.id}
        dashboardId={board?.dashboardId || null}
      />
    );
  }

  if (!boardConfig?.id || loading) return (viewType && <BoardContentSkeleton viewType={viewType} />) || null;

  if (isEmptyStateVisible) {
    return isFilteredByQuote
      ? <InsightsEmpty />
      : (
        <InboxZero
          onClick={() => {
            setIsEmptyStateVisible(false);
          }}
        />
      );
  }

  // Hide My Inbox for super admin users
  const isMyInbox = board?.isBuiltIn && board.name === 'My Inbox';
  if (isMyInbox && !boardConfigId) return null;

  return (viewType && (
    <BoardContent
      viewType={viewType}
      boardConfigId={boardConfigId}
    />
  )) || null;
};
