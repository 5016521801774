import { Skeleton, Icon, Spinner } from '@cycle-app/ui';
import { LinearIcon } from '@cycle-app/ui/icons';

import { IssueAssignee } from 'src/components/LinearEditorMentionView/IssueAssignee';
import { IssueStatus } from 'src/components/LinearEditorMentionView/IssueStatus';
import { LinearStatus } from 'src/components/LinearStatus/LinearStatus';
import { useDocLinearContext } from 'src/contexts/docLinearContext';
import { useLinearIssue } from 'src/hooks/api/queries/integrations/useLinearIssue';
import { useDocSyncAutomationContent } from 'src/hooks/doc/useDocSyncAutomationContent';
import { Layer } from 'src/types/layers.types';
import { issueInfos } from 'src/utils/linear.utils';

import {
  PreviewHeader, HeaderLeft, HeaderRight, IssueTitle, Infos, IssueDetails,
} from './DocLinear.styles';
import { IssueDescription } from './IssueDescription';
import { IssueEstimate } from './IssueEstimate';
import { IssuePriority } from './IssuePriority';
import { IssueProject } from './IssueProject';
import { PreviewAlert } from './PreviewAlert';

export const PreviewIssue = ({
  onClickChange, onUnlink, hide,
}: {
  onClickChange: VoidFunction;
  onUnlink: VoidFunction;
  hide: VoidFunction;
}) => {
  const query = useLinearIssue({
    issueId: useDocLinearContext(ctx => ctx.automationId),
    integrationId: useDocLinearContext(ctx => ctx.integrationId),
  });
  const automationUrl = useDocLinearContext(ctx => ctx.automationUrl);

  const {
    syncAutomationContent,
    isLoading: isSyncAutomationContentLoading,
  } = useDocSyncAutomationContent();

  if (query.isLoading && !query.issue) {
    return (
      <div className="p-3">
        <Skeleton height={20} />
      </div>
    );
  }

  const footer = (
    <div className="border-t border-grey-150 flex items-center gap-1 p-1 dark:border-grey-800">
      {query.issue && automationUrl && (
        <button
          className="btn-tertiary"
          onClick={() => {
            hide();
            window.open(automationUrl, '_blank');
          }}
        >
          <Icon
            name="arrow-right-up"
            className="size-3.5"
          />
          Open in Linear
        </button>
      )}

      <button
        className="btn-tertiary"
        onClick={() => {
          hide();
          onClickChange();
        }}
      >
        <Icon name="edit" />
        Change issue
      </button>

      <button
        className="btn-tertiary"
        onClick={() => {
          hide();
          onUnlink();
        }}
      >
        <Icon name="unlink" />
        Unlink issue
      </button>

      {query.issue && automationUrl && (
        <button
          className="btn-tertiary"
          onClick={syncAutomationContent}
        >
          {isSyncAutomationContentLoading
            ? <Spinner />
            : <Icon name="sync-data" />}
          Sync data
        </button>
      )}
    </div>
  );

  if (!query.issue) {
    return (
      <>
        <div className="p-3 flex flex-col gap-2">
          <PreviewHeader>
            <PreviewAlert message="It seems that this issue no longer exists." />
          </PreviewHeader>
        </div>
        {footer}
      </>
    );
  }

  return (
    <>
      <div className="p-3 flex flex-col gap-2 shy-scrollbar">
        <PreviewHeader>
          <HeaderLeft>
            <LinearIcon size={16} />
            <Infos>
              {issueInfos(query.issue.url, query.issue.team?.name)}
            </Infos>
            {query.issue.status && (
              <LinearStatus
                status={query.issue.status}
                size={14}
              />
            )}
            <IssueTitle>
              {query.issue.title}
            </IssueTitle>
          </HeaderLeft>

          <HeaderRight>
            <IssueAssignee
              issue={query.issue}
              layer={Layer.DropdownModalZ4}
            />
          </HeaderRight>
        </PreviewHeader>

        <IssueDescription value={query.issue.description} />

        <IssueDetails>
          <IssueStatus issue={query.issue} />
          <IssuePriority issue={query.issue} />
          <IssueEstimate issue={query.issue} />
          <IssueProject issue={query.issue} />
        </IssueDetails>
      </div>
      {footer}
    </>
  );
};
