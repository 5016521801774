import { SelectPanel, SelectOption } from '@cycle-app/ui';
import { ReactNode, MouseEventHandler } from 'react';

import { ToggleDropdown } from 'src/components/DropdownLayer';
import { useEditorContext } from 'src/contexts/editorContext';
import { Layer } from 'src/types/layers.types';

import { ColorIcon } from './TextColorDropdown.styles';

type TextColorDropdownProps = {
  onSelect: (color: string) => void;
  button: (onClick: MouseEventHandler<Element>) => ReactNode;
  onClear: VoidFunction;
};

export const editorColorOptions: SelectOption[] = [
  {
    label: 'Blue',
    value: '#486EFA', // var(--color-blue-500)
  },
  {
    label: 'Green',
    value: '#209756', // var(--color-green-700)
  },
  {
    label: 'Yellow',
    value: '#BA8C32', // var(--color-yellow-700)
  },
  {
    label: 'Orange',
    value: '#E57048', // var(--color-orange-600)
  },
  {
    label: 'Red',
    value: '#E54444', // var(--color-red-600)
  },
  {
    label: 'Pink',
    value: '#E54F9D', // var(--color-pink-600)
  },
  {
    label: 'Purple',
    value: '#CA41D3', // var(--color-purple-600)
  },
  {
    label: 'Violet',
    value: '#8368F9', // var(--color-violet-500)
  },
].map(item => ({
  ...item,
  icon: <ColorIcon $color={item.value} />,
}));

export const TextColorDropdown = ({
  onSelect, onClear, button,
}: TextColorDropdownProps) => {
  const editor = useEditorContext(ctx => ctx.editor);
  const colorValue = editor.getAttributes('textStyle').color as string | undefined;
  return (
    <ToggleDropdown
      placement="bottom"
      layer={Layer.DropdownModalZ3}
      offsetY={16}
      button={({ onClick }) => button(onClick)}
      content={() => (
        <SelectPanel
          options={editorColorOptions}
          onOptionChange={(option) => {
            onSelect(option.value);
          }}
          hideSearch
          clearLabel="Clear"
          onClearValue={colorValue ? onClear : undefined}
        />
      )}
    />
  );
};
