import { SourceType } from '@cycle-app/graphql-codegen';
import { Icon } from '@cycle-app/ui';
import { EmailIcon } from '@cycle-app/ui/icons';
import { FC } from 'react';

const sourceTypeIcons: Record<string, FC<{ size?: number }>> = ({
  [SourceType.Cycle]: ({ size }) => (
    <Icon
      name="brand/cycle"
      className="text-cycle"
      style={{
        width: size,
        height: size,
      }}
    />
  ),
  [SourceType.Gong]: ({ size }) => (
    <Icon
      name="brand/gong"
      style={{
        width: size,
        height: size,
      }}
    />
  ),
  [SourceType.GoogleMeet]: ({ size }) => (
    <Icon
      name="brand/google-meet"
      style={{
        width: size,
        height: size,
      }}
    />
  ),
  [SourceType.Hubspot]: ({ size }) => (
    <Icon
      name="brand/hubspot"
      style={{
        width: size,
        height: size,
      }}
    />
  ),
  [SourceType.Intercom]: ({ size }) => (
    <Icon
      name="brand/intercom"
      style={{
        width: size,
        height: size,
      }}
    />
  ),
  'LOOM': ({ size }) => (
    <Icon
      name="brand/loom"
      style={{
        width: size,
        height: size,
      }}
    />
  ),
  [SourceType.Mail]: EmailIcon,
  [SourceType.MicrosoftTeams]: ({ size }) => (
    <Icon
      name="brand/microsoft-teams"
      style={{
        width: size,
        height: size,
      }}
    />
  ),
  'MODJO': ({ size }) => (
    <Icon
      name="brand/modjo"
      style={{
        width: size,
        height: size,
      }}
    />
  ),
  'NOTION': ({ size }) => (
    <Icon
      name="brand/notion"
      style={{
        width: size,
        height: size,
      }}
    />
  ),
  [SourceType.Salesforce]: ({ size }) => (
    <Icon
      name="brand/salesforce"
      style={{
        width: size,
        height: size,
      }}
    />
  ),
  [SourceType.Slack]: ({ size }) => (
    <Icon
      name="brand/slack"
      style={{
        width: size,
        height: size,
      }}
    />
  ),
  [SourceType.Web]: ({ size }) => (
    <Icon
      name="brand/chrome"
      style={{
        width: size,
        height: size,
      }}
    />
  ),
  [SourceType.Zapier]: ({ size }) => (
    <Icon
      name="brand/zapier"
      style={{
        width: size,
        height: size,
      }}
    />
  ),
  [SourceType.Zendesk]: ({ size }) => (
    <Icon
      name="brand/zendesk"
      style={{
        width: size,
        height: size,
      }}
    />
  ),
  [SourceType.Zoom]: ({ size }) => (
    <Icon
      name="brand/zoom"
      style={{
        width: size,
        height: size,
      }}
    />
  ),
});

const sourceCategoryIcons: Record<string, FC<{ size?: number }>> = sourceTypeIcons;

export const SourceCategoryIcon = ({
  value, size,
}: {
  value: string;
  size?: number;
}) => {
  const IconComponent = sourceCategoryIcons[value];
  return IconComponent ? <IconComponent size={size} /> : null;
};
