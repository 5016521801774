/**
 * Menu and MenuItem components built on top of DropdownMenu primitives
 */

import { ComponentPropsWithoutRef, ReactNode } from 'react';

import { DropdownMenu } from './DropdownMenu';

interface Props extends
  Pick<ComponentPropsWithoutRef<typeof DropdownMenu.Root>, 'open' | 'onOpenChange' | 'dir'>,
  ComponentPropsWithoutRef<typeof DropdownMenu.Content> {
  trigger: ReactNode;
  portal?: boolean;
}

export const Menu = ({
  open,
  onOpenChange,
  dir,
  trigger,
  portal = false,
  ...props
}: Props) => {
  const content = (
    <DropdownMenu.Content
      open={open}
      {...props}
    />
  );
  return (
    <DropdownMenu.Root
      open={open}
      onOpenChange={onOpenChange}
      dir={dir}
    >
      <DropdownMenu.Trigger asChild>
        {trigger}
      </DropdownMenu.Trigger>

      {portal ? (
        <DropdownMenu.Portal>
          {content}
        </DropdownMenu.Portal>
      ) : content}
    </DropdownMenu.Root>
  );
};
