import { BoardFragment } from '@cycle-app/graphql-codegen';
import { Emoji, TextHighlighter } from '@cycle-app/ui';

import { useBoardSections } from 'src/hooks/api/useBoardSections';
import { getSectionName } from 'src/utils/viewSection.utils';

export const CommandViewAction = ({
  view, searchText,
}: {
  view: BoardFragment;
  searchText: string;
}) => {
  const {
    mappingBoardLinks, sectionsCount,
  } = useBoardSections();
  const boardLink = Object.values(mappingBoardLinks).find(link => link.board.id === view.id);
  const sectionName = boardLink ? getSectionName(boardLink.section, sectionsCount) : null;

  return (
    <div className="flex h-9 items-center gap-2 px-2.5">
      <Emoji
        emoji={view.emoji}
        color={view.color}
      />

      <TextHighlighter
        searchWords={[searchText]}
        textToHighlight={view.name}
        className="highlight"
      />

      {sectionName && (
        <span className="text-caption text-disabled">
          {sectionName}
        </span>
      )}
    </div>
  );
};
