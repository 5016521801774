import { SelectPanel, TextHighlighter, SelectOption, CompanyLogo } from '@cycle-app/ui';
import { AddIcon } from '@cycle-app/ui/icons';
import { isEmail, useMeasure } from '@cycle-app/utilities';
import { useState, FC } from 'react';

import { CustomersAddCustomerModal } from 'src/components/CustomersList/CustomersAddCustomerModal';
import { ToggleDropdown } from 'src/components/DropdownLayer';
import { useOptimizedBooleanState } from 'src/hooks';
import { useCustomer } from 'src/hooks/api/queries/customers/useCustomer';
import { useCustomers } from 'src/hooks/api/queries/customers/useCustomers';
import { Layer } from 'src/types/layers.types';
import { getCustomerOption, renderCustomerOptionLabel } from 'src/utils/customers.util';

import { FieldButton, SkeletonInput, CustomerAvatar, CreateCustomerLine, Caret } from '../Form.styles';
import { StyledSlot, StyledName, Label, Email } from './FieldCustomer.styles';

interface Props {
  layer?: Layer;
  onChange: (value: string) => void;
  value: string;
  autoOpen?: boolean;
  hasError?: boolean;
}

export const FieldCustomer: FC<React.PropsWithChildren<Props>> = ({
  onChange,
  value,
  autoOpen = false,
  hasError = false,
}) => {
  const {
    rect: itemRect,
    ref: itemRef,
  } = useMeasure<HTMLDivElement>();
  const [searchText, setSearchText] = useState('');
  const {
    customer: selectedCustomer, loading,
  } = useCustomer(value, {
    onCompleted: (data) => {
      if (!data) onChange('');
    },
  });

  const selectedCompany = selectedCustomer?.company;
  const isSingleCustomer = selectedCompany?.countCustomers === 1;
  const selectedName = (isSingleCustomer ? selectedCompany.name : selectedCustomer?.name)?.trim();
  const selectedIcon = selectedCustomer && (isSingleCustomer ? (
    <CompanyLogo
      company={selectedCompany}
      size="S"
    />
  ) : (
    <CustomerAvatar
      customer={selectedCustomer}
      size="S"
    />
  ));

  const {
    customers, fetchNextPage, hasNextPage, loading: isSearchLoading,
  } = useCustomers({
    defaultSearch: searchText,
  });
  const [isCreateCustomerVisible, { toggleCallback: toggleCreateCustomer }] = useOptimizedBooleanState(false);

  const options: SelectOption[] = customers
    .map(customer => ({
      ...getCustomerOption(customer),
      renderLabel: (filterText) => (
        renderCustomerOptionLabel({
          customer,
          filterText,
        })
      ),
      end: null,
    }));

  return (
    <div ref={itemRef}>
      <ToggleDropdown
        initialVisible={autoOpen}
        placement="bottom-start"
        layer={Layer.DropdownModalZ3}
        width={itemRect?.width}
        onHide={() => setSearchText('')}
        button={props => (loading || isSearchLoading ? <SkeletonInput /> : (
          <FieldButton
            iconStart={selectedIcon}
            $hasError={hasError}
            {...props}
          >
            <StyledSlot>
              {selectedCustomer
                ? (
                  <Label>
                    {selectedName && (
                      <StyledName>
                        {selectedName}
                      </StyledName>
                    )}
                    <Email>
                      {selectedCustomer.email}
                    </Email>
                  </Label>
                )
                : (
                  <span>
                    Choose from list
                  </span>
                )}
            </StyledSlot>
            <Caret direction={props['data-active'] ? 'top' : 'bottom'} />
          </FieldButton>
        ))}
        content={props => (
          <>
            <SelectPanel
              filterOptionsOnInputChange={false}
              options={options}
              selectedValue={value}
              onOptionChange={option => {
                onChange(option.value);
                props.hide();
              }}
              onSearchChange={setSearchText}
              debounceSearch
              infiniteScroll={{
                isLoading: loading,
                hasMoreData: hasNextPage,
                loadMore: fetchNextPage,
              }}
            >
              <CreateCustomerLine
                startSlot={<AddIcon />}
                label={(
                  <>
                    Create
                    {' '}
                    {searchText ? (
                      <TextHighlighter
                        searchWords={[searchText]}
                        textToHighlight={searchText}
                        className="highlight"
                      />
                    ) : 'new customer'}
                  </>
                )}
                onClick={toggleCreateCustomer}
              />
            </SelectPanel>
            {isCreateCustomerVisible && (
              <CustomersAddCustomerModal
                defaultValues={{
                  name: isEmail(searchText, false) ? '' : searchText,
                  email: isEmail(searchText, false) ? searchText : '',
                }}
                layer={Layer.ModalZ3}
                dropdownsLayer={Layer.DropdownModalZ4}
                onClose={toggleCreateCustomer}
                onCreated={async data => {
                  onChange(data.id);
                  setSearchText('');
                  props.hide();
                }}
              />
            )}
          </>
        )}
      />
    </div>
  );
};
