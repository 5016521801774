import { useQuery } from '@apollo/client';
import { ReleaseNoteMakersDocument } from '@cycle-app/graphql-codegen';

import { extract } from '../../types/graphql.types';

export function useReleaseNoteMakers(releaseNoteId: string) {
  const {
    data, loading: isLoading,
  } = useQuery(ReleaseNoteMakersDocument, {
    variables: {
      id: releaseNoteId,
    },
  });

  const releaseNote = extract('ReleaseNote', data?.node);

  return {
    isLoading,
    makers: releaseNote?.makers ?? [],
  };
}
