import { DocBaseFragment } from '@cycle-app/graphql-codegen';
import { twJoin } from 'tailwind-merge';

import { DocReleaseNote } from 'src/components/DocReleaseNote';
import { ToggleSlidePanelIcon } from 'src/components/ToggleSlidePanelIcon';
import { toggleFeatureReleaseNote, useFeatureReleaseNoteOpen } from 'src/reactives/docRightPanel.reactive';

type Props = {
  releaseNote: NonNullable<DocBaseFragment['releaseNote']>;
};

export const FeatureDocReleaseNote = ({ releaseNote }: Props) => {
  const isFeatureReleaseNoteOpen = useFeatureReleaseNoteOpen();
  const releaseTitle = releaseNote.release.title?.trim();
  return (
    <button
      className={twJoin(
        'group flex w-full cursor-pointer flex-col gap-4 overflow-hidden rounded-lg p-4 text-left text-body',
        // background
        'hover:bg-grey-100 active:bg-grey-150 dark:hover:bg-grey-850 dark:active:bg-grey-800',
        // border, shadow, outline
        'border border-grey-200 shadow-xs outline-hidden focus-visible:ring dark:border-grey-800 dark:shadow-none',
        isFeatureReleaseNoteOpen && 'bg-grey-100 dark:bg-grey-850',
      )}
      onClick={toggleFeatureReleaseNote}
    >
      <div className="flex items-start justify-between gap-6 self-stretch">
        <div>
          {releaseTitle || <DocReleaseNote releaseNote={releaseNote} />}
        </div>

        <div className="flex items-center gap-2">
          {releaseTitle && <DocReleaseNote releaseNote={releaseNote} />}
          <ToggleSlidePanelIcon
            direction={isFeatureReleaseNoteOpen ? 'right' : 'left'}
          />
        </div>
      </div>

      <div className="text-header-small font-medium">
        {releaseNote.title}
      </div>
    </button>
  );
};
