import { Skeleton } from '@cycle-app/ui';
import { PropsWithChildren, ReactNode, useRef } from 'react';
import { useInView } from 'react-intersection-observer';
import { twJoin } from 'tailwind-merge';

export type Column<T> = {
  id: string;
  header: ReactNode;
  cell: (item: T) => ReactNode;
  className?: string;
};

type TableProps<T> = {
  columns: Column<T>[];
  data: T[];
  loading?: boolean; // initial loading or loading more data
  fetching?: boolean; // fetching new data
  stickyRowHeader?: boolean;
};

export const Table = <T extends { id: string }>({
  columns,
  data,
  loading = false,
  fetching = false,
  stickyRowHeader = false,
}: TableProps<T>) => {
  const tableRef = useRef<HTMLTableElement>(null);

  const items = [
    ...data,
    ...Array.from({ length: loading ? 3 : 0 }, () => null), // skeletons
  ];

  return (
    <table
      ref={tableRef}
      className="w-full table-auto border-collapse overflow-x-auto bg-inherit"
    >
      <thead className="sticky top-0 z-20 bg-inherit">
        <tr className="bg-inherit">
          {columns.map(col => (
            <th
              key={col.id}
              className={twJoin(
                'h-12 truncate bg-inherit pl-1 pr-4 pt-2 text-left font-normal text-secondary last:pr-1',
                stickyRowHeader && 'first:sticky first:left-0 first:z-30',
                col.className,
              )}
            >
              {col.header}
            </th>
          ))}
        </tr>
      </thead>

      <tbody className={twJoin('bg-inherit', fetching && 'opacity-50')}>
        {items.map((item, index) => (
          <TableRow
            key={item?.id ?? `loading_${index}`}
            root={tableRef.current?.parentElement}
          >
            {columns.map(col => (
              <td
                key={col.id}
                className={twJoin(
                  'h-10 select-text truncate bg-inherit pl-1 pr-4 first:rounded-l-md last:rounded-r-md last:pr-1',
                  stickyRowHeader && 'first:sticky first:left-0 first:z-10 ',
                  item && 'group-hover:bg-grey-100 group-hover:dark:bg-grey-950',
                  col.className,
                )}
              >
                {item ? col.cell(item) : <Skeleton height={16} />}
              </td>
            ))}
          </TableRow>
        ))}
      </tbody>
    </table>
  );
};

const TableRow = ({
  children, root,
}: PropsWithChildren<{ root: Element | null | undefined }>) => {
  const [ref, isVisible] = useInView({
    root,
    rootMargin: '200px',
  });
  return (
    <tr
      ref={ref}
      className="group bg-inherit"
    >
      {isVisible ? children : <td className="h-10" />}
    </tr>
  );
};
