import { MateFragment, DoctypeType } from '@cycle-app/graphql-codegen';
import { Icon, AvatarLegacy } from '@cycle-app/ui';

import { assigneeLabel } from 'src/utils/users.util';

import { RoleLink } from './RoleLink';

type Props = {
  assignee: MateFragment;
  isReadOnly?: boolean;
  onUpdate?: VoidFunction;
  docTypeType?: DoctypeType;
};

export const AssigneeTooltip = ({
  assignee, isReadOnly, onUpdate, docTypeType,
}: Props) => {
  const isFeedback = docTypeType !== DoctypeType.Custom;
  const name = [assignee.firstName?.trim(), assignee.lastName?.trim()].filter(Boolean).join(' ');
  return (
    <div
      className="relative min-w-80 max-w-96 cursor-auto rounded-[10px] border border-grey-200 bg-white text-primary shadow-z2 dark:border-grey-800 dark:bg-grey-850"
      onClick={e => e.stopPropagation()}
      onWheel={e => e.stopPropagation()}
    >
      <div className="flex items-start justify-between gap-1.5 p-3 pb-2">
        <div className="flex items-center gap-2.5">
          <AvatarLegacy
            user={assignee}
            size={32}
          />
          <div className="space-y-0.5">
            {name && (
              <div className="text-body font-medium leading-snug text-primary">
                {name}
              </div>
            )}
            {assignee?.email && (
              <div className="overflow-anywhere text-body leading-snug text-secondary">
                {assignee.email}
              </div>
            )}
          </div>
        </div>

        <RoleLink assigneeId={assignee.id} />
      </div>

      {!isReadOnly && (
        <div className="flex items-center gap-1 border-t border-grey-150 p-1 dark:border-grey-800">
          <button
            className="btn-tertiary"
            onClick={e => {
              e.stopPropagation();
              onUpdate?.();
            }}
          >
            <Icon name="edit" />
            {`Change ${assigneeLabel({ isFeedback })}`}
          </button>
        </div>
      )}
    </div>
  );
};

