import { ThemeType } from '@cycle-app/graphql-codegen';
import {
  typo, truncate, Tag, boxShadowZ4, DotsMenu, colorUtils,
} from '@cycle-app/ui';
import { DocumentIcon } from '@cycle-app/ui/icons';
import styled, { css } from 'styled-components';

import { StatusTag } from '../DocStatus';

export const Card = styled.div<{
  $isActive?: boolean;
  $isPlaceholder?: boolean;
  $isOverlay?: boolean;
}>`
  position: relative;
  height: 38px;
  padding: 8px 8px 8px 8px;
  border-radius: 8px;
  overflow: hidden;

  display: flex;
  align-items: center;
  gap: 8px;

  background: ${p => p.theme.colors.releases.card.bg};
  box-shadow:
    0 0 0 1px rgba(0, 0, 0, 0.06),
    0px 1px 2px rgba(0, 0, 0, 0.03);

  ${p => p.$isActive && !p.$isPlaceholder && `
    :before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 3px;
      height: 100%;
      background: hsl(var(--cycle));
    }
  `}

  ${p => p.$isPlaceholder && css`
    background: ${p.theme.colors.releases.card.placeholder};
  `}

  ${p => p.$isOverlay && css`
    max-width: 792px;
    ${boxShadowZ4}
    cursor: grabbing;
  `}
`;

export const CardNew = styled.div<{
  $isActive?: boolean;
  $isPlaceholder?: boolean;
  $isOverlay?: boolean;
}>`
  position: relative;
  height: 40px;
  padding: 8px 12px;
  border-radius: 8px;
  overflow: hidden;

  display: flex;
  align-items: center;
  gap: 8px;

  background: ${p => (p.$isActive ? p.theme.colors.background.tertiary : p.theme.colors.background.hoverSoft)};

  :hover {
    background: ${p => p.theme.colors.background.tertiary};
  }

  ${p => p.$isPlaceholder && css`
    background: ${p.theme.colors.releases.card.placeholder};
  `}

  ${p => p.$isOverlay && css`
    max-width: 792px;
    ${boxShadowZ4}
    cursor: grabbing;
  `}
`;

export const ToggleNoteEditor = styled.div`
  position: absolute;
  inset: 0;
  padding: 0;
  cursor: pointer;
`;

export const Content = styled.div`
  position: absolute;
  inset: 0;
  padding: 0 8px 0 10px;

  pointer-events: none;
  button, input, [role=button] {
    pointer-events: auto;
  }

  display: flex;
  align-items: center;
  gap: 8px;
`;
export const Title = styled.div`
  flex: 1;
  ${truncate}
  ${typo.body400}
  color: ${p => p.theme.colors.text.primary};
  ${typo.body400}
  ${p => p.theme.themeType === ThemeType.Nightfall && css`
    ${typo.body500}
  `}
`;

export const DraftTag = styled(Tag).attrs({ color: 'grey' })<{ $isPublished?: boolean }>`
  --bg: ${p => p.theme.colors.releases.tag.bgDraft};
  --bgHover: ${p => colorUtils.darken(p.theme.colors.releases.tag.bgDraft, 0.08)};
  --bgActive: var(--bgHover);
  ${p => p.$isPublished && css`
    --bg: ${p.theme.colors.releases.tag.bgPublished};
    --bgHover: ${colorUtils.darken(p.theme.colors.releases.tag.bgPublished, 0.08)};
  `}
`;

export const SelectPanel = styled.div`
  width: 110px;
  padding: 4px 0;
`;

export const StyledDotsMenu = styled(DotsMenu)<{ $forceVisible: boolean }>`
  ${CardNew}:hover & {
    opacity: 1;
  }
`;

export const StyledStatusTag = styled(StatusTag)`
  pointer-events: all;
`;

export const StyledDocumentIcon = styled(DocumentIcon)`
  color: ${p => p.theme.colors.text.disabled};
`;
