import { DocBaseFragment, ReleasePublicStatus } from '@cycle-app/graphql-codegen';
import { ActionButton } from '@cycle-app/ui';
import { CloseIcon } from '@cycle-app/ui/icons';
import { useRef } from 'react';

import { DocReleaseNote } from 'src/components/DocReleaseNote';
import { ErrorBoundary } from 'src/components/ErrorBoundary';
import { ReleaseNoteAlert } from 'src/components/ReleaseNote';
import { EditorContainer, EditorHeader, StyledDocPanelRealtime } from 'src/components/ReleaseNote/ReleaseNote.styles';
import { ReleaseNoteDocOpen } from 'src/components/ReleaseNote/ReleaseNoteDocOpen';
import { ReleaseNoteEditor } from 'src/components/ReleaseNote/ReleaseNoteEditor';
import { ReleaseNoteEditorTitle } from 'src/components/ReleaseNote/ReleaseNoteEditorTitle';
import { ReleaseNoteMakers } from 'src/components/ReleaseNote/ReleaseNoteMakers';
import { ReleaseNotePublishedBanner } from 'src/components/ReleaseNote/ReleaseNotePublishedBanner';
import { ReleaseNoteTags } from 'src/components/ReleaseNote/ReleaseNoteTags';
import { useReleasePublicStatusUpdate } from 'src/hooks/releases/useReleasePublicStatusUpdate';
import { useGetPermission } from 'src/reactives';
import { closeFeatureReleaseNote } from 'src/reactives/docRightPanel.reactive';

type Props = {
  releaseNote: NonNullable<DocBaseFragment['releaseNote']>;
};

export const FeatureDocReleaseNotePanelContent = ({ releaseNote }: Props) => {
  const { canUpdateReleaseNote } = useGetPermission();
  const isPublished = releaseNote.release.publicStatus === ReleasePublicStatus.Published;
  const releaseNoteInitialContent = useRef<string>();
  const { editPublished } = useReleasePublicStatusUpdate(releaseNote.release.id);

  const handleReleaseNoteContentChange = async (html: string) => {
    if (!isPublished) return;
    if (!releaseNoteInitialContent.current) {
      releaseNoteInitialContent.current = html;
      return;
    }

    if (releaseNoteInitialContent.current === html) return;

    await editPublished();
  };

  const handleReleaseNoteTitleChange = () => {
    if (!isPublished) return;

    void editPublished();
  };

  return (
    <div className="flex h-full flex-col">
      <div className="flex h-14 items-center justify-between border-b border-grey-150 px-5 dark:border-grey-850">
        <div className="flex items-center gap-3">
          <ActionButton
            className="size-6"
            onClick={closeFeatureReleaseNote}
          >
            <CloseIcon />
          </ActionButton>

          <DocReleaseNote
            releaseNote={releaseNote}
          />
        </div>

        <div className="flex items-center gap-3">
          <StyledDocPanelRealtime />

          <ReleaseNoteDocOpen
            isReadOnly={isPublished}
            releaseId={releaseNote.release.id}
            noteId={releaseNote.id}
          />
        </div>
      </div>

      <ReleaseNotePublishedBanner releaseId={releaseNote.release.id} />

      <ErrorBoundary>
        <EditorContainer>
          <EditorHeader>
            <ReleaseNoteAlert releaseNoteId={releaseNote.id} />
            <ReleaseNoteTags releaseNoteId={releaseNote.id} />
            <ReleaseNoteEditorTitle
              releaseNoteId={releaseNote.id}
              isReadOnly={!canUpdateReleaseNote}
              onChange={handleReleaseNoteTitleChange}
            />
          </EditorHeader>

          <ReleaseNoteEditor
            releaseNoteId={releaseNote.id}
            isReadOnly={!canUpdateReleaseNote}
            onChange={(html) => handleReleaseNoteContentChange(html)}
          />
        </EditorContainer>
      </ErrorBoundary>

      <div className="flex flex-col gap-4 px-8 py-6">
        <ReleaseNoteMakers releaseNoteId={releaseNote.id} />
      </div>
    </div>
  );
};

