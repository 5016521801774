import { Spinner, typo, colorUtils } from '@cycle-app/ui';
import { VideoIcon, DocumentEmptyIcon, ImageIcon, VolumeFullIcon } from '@cycle-app/ui/icons';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 100%;
`;

const iconStyles = css`
  flex: none;
`;

export const StyledVideoIcon = styled(VideoIcon)`
  color: transparent;
  stroke: ${p => p.theme.colors.text.secondary};
  ${iconStyles}
`;

export const StyledDocumentEmptyIcon = styled(DocumentEmptyIcon)`
  color: transparent;
  stroke: ${p => p.theme.colors.text.secondary};
  ${iconStyles}
`;

export const StyledImageIcon = styled(ImageIcon)`
  ${iconStyles}
`;

export const StyledVolumeIcon = styled(VolumeFullIcon)`
  ${iconStyles}
`;

export const Button = styled.button`
  color: ${p => p.theme.colors.text.secondary};
  background-color: ${p => p.theme.colors.background.tertiary};
  display: flex;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  width: 100%;
  padding: 12px 16px;
  cursor: pointer;
  white-space: nowrap;
  ${typo.body400}
  line-height: 18px;

  &:hover {
    background-color: ${p => colorUtils.darken(p.theme.colors.background.tertiary, 0.02)};
  }
`;

export const Label = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const DropdownContent = styled.div`
  padding: 16px;
  width: 400px;
`;

export const StyledSpinner = styled(Spinner)`
  ${iconStyles}
`;
