import { Emoji } from '@cycle-app/ui';

import { PageId } from 'src/constants/routing.constant';
import { useIsChangelogOnly } from 'src/contexts/workspaceContext';
import { useNavigate } from 'src/hooks';
import { useUrl } from 'src/hooks/useUrl';
import { CommandActionCategory } from 'src/types/commandbar.types';

import { CommandViewAction } from '../../components/CommandBar/CommandK/CommandViewAction';
import { useBoardSearch } from '../boards/useBoardSearch';
import { closeCommandBar } from '../modals/useCommandBarModal';

export const CATEGORY_ID = 'boards';

export const useBoardsResult = (searchDebounced: string): Array<CommandActionCategory> => {
  const isChangelogOnly = useIsChangelogOnly();
  const { navigate } = useNavigate();
  const getUrl = useUrl();
  const { boards } = useBoardSearch(searchDebounced);

  if (isChangelogOnly) return [];

  return [{
    id: CATEGORY_ID,
    label: 'Views',
    actions: boards
      .map(board => ({
        id: board.id,
        label: board.name,
        renderLabel: props => (
          <CommandViewAction
            view={board}
            searchText={props.searchTextDebounced}
          />
        ),
        icon: board.emoji ? <Emoji emoji={board.emoji} /> : null,
        linkTo: getUrl(PageId.Board, { boardSlug: board.id }),
        onClick: (e) => {
          if (!e.metaKey) closeCommandBar();
        },
        onSelect: () => {
          closeCommandBar();
          navigate(PageId.Board, { boardSlug: board.id });
        },
      })),
  }];
};
