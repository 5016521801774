import { Separator as RadixSeparator } from 'radix-ui';
import { ComponentPropsWithoutRef, forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';

interface SeparatorProps extends Omit<ComponentPropsWithoutRef<typeof RadixSeparator.Root>, 'asChild' | 'orientation'> {}

export const SeparatorPoint = forwardRef<HTMLDivElement, SeparatorProps>(
  function SeparatorPoint({
    className,
    ...props
  }, ref) {
    return (
      <RadixSeparator.Root
        {...props}
        ref={ref}
        orientation="vertical"
        className={twMerge(
          'mx-1.5 align-middle inline-block',
          className,
        )}
      >
        ·
      </RadixSeparator.Root>
    );
  },
);
