import { BoardSectionFragment, BoardWithMinimalConfigFragment } from '@cycle-app/graphql-codegen';
import { CloseIcon, DownIcon } from '@cycle-app/ui/icons';
import { DraggableSyntheticListeners } from '@dnd-kit/core';
import { SortableContext } from '@dnd-kit/sortable';
import {
  FC, HTMLAttributes, useState, ChangeEvent, useCallback, useMemo,
} from 'react';

import SectionOptions from 'src/components/SectionOptions/SectionOptions';
import { SectionViewOptions } from 'src/components/SectionViewOptions';
import { useLocationSelector } from 'src/hooks';
import { useRemoveSection, useRenameSection } from 'src/hooks/api/mutations/useBoardSectionsMutations';
import useOptimizedBooleanState from 'src/hooks/useOptimizedBooleanState';
import { useSidebarCollapsed } from 'src/hooks/useSidebarCollapsed';
import { useGetPermission } from 'src/reactives/permission.reactive';
import { getPathParams } from 'src/utils/routing.utils';
import { getSectionName } from 'src/utils/viewSection.utils';

import { Container, BoardSectionNavigationItem, ActionsContainer } from './SidebarBoardSection.styles';
import { BoardSectionInput } from '../../SidebarBoard.styles';
import SidebarItemBoardSortable from './SidebarItemBoard/SidebarItemBoardSortable';

export interface Props extends HTMLAttributes<HTMLDivElement> {
  section: Pick<BoardSectionFragment, 'id' | 'name' | 'type'>;
  sections: Pick<BoardSectionFragment, 'id' | 'name' | 'type' | 'boardLinks'>[];
  toggleSection?: (id: string) => void;
  isOpen: boolean;
  asPlaceholder?: boolean;
  isDragging?: boolean;
  boardLinksId?: string[];
  getBoard?: (boardLinkId: string) => BoardWithMinimalConfigFragment | undefined;
  sortableListeners?: DraggableSyntheticListeners;
  setDraggableNodeRef?: (node: HTMLElement | null) => void;
  setDroppableNodeRef?: (node: HTMLElement | null) => void;
  sectionItemProps?: HTMLAttributes<HTMLDivElement>;
  hideTitle?: boolean;
  hasBoardLinks?: boolean;
}

const SidebarBoardSection: FC<React.PropsWithChildren<Props>> = ({
  section,
  sections,
  isOpen,
  asPlaceholder,
  toggleSection,
  isDragging,
  boardLinksId,
  getBoard,
  setDroppableNodeRef,
  setDraggableNodeRef,
  hideTitle,
  ...htmlProps
}) => {
  const { canReorderBoards } = useGetPermission();

  const collapsed = useSidebarCollapsed();
  const { removeSection } = useRemoveSection(sections);
  const { renameSection } = useRenameSection();

  const [isSectionMenuOpen, setIsSectionMenuOpen] = useState(false);
  const [isSectionViewMenuOpen, setIsSectionViewMenuOpen] = useState(false);
  const [isEditingName, {
    setTrueCallback: showEditingMode,
    setFalseCallback: hideEditingMode,
  }] = useOptimizedBooleanState(false);

  const handleSubmit = useCallback(
    (name: string) => {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      renameSection(section.id, name);
      hideEditingMode();
    },
    [renameSection, hideEditingMode, section.id],
  );

  const onClick = useCallback(() => {
    toggleSection?.(section.id);
  }, [section.id, toggleSection]);

  const contentMore = useMemo(() => (
    <ActionsContainer>
      <SectionViewOptions
        onMount={() => setIsSectionViewMenuOpen(true)}
        onHide={() => setIsSectionViewMenuOpen(false)}
        sidebarSectionId={section.id}
      />
      <SectionOptions
        section={section}
        onEditSectionName={showEditingMode}
        onRemoveSection={removeSection}
        onClick={() => setIsSectionMenuOpen(true)}
        onHide={() => setIsSectionMenuOpen(false)}
      />
    </ActionsContainer>
  ), [removeSection, section, showEditingMode]);

  const sectionName = getSectionName(section, sections.length);

  const activeBoardLinkId = useLocationSelector(location => {
    if (collapsed || isOpen || location.state?.fromStarredBoard) return undefined;
    const { boardId } = getPathParams(location.pathname);
    return boardLinksId?.find(linkId => boardId === getBoard?.(linkId)?.id);
  });
  const activeBoard = activeBoardLinkId ? getBoard?.(activeBoardLinkId) : null;

  const filteredBoardLinksdId = collapsed || isOpen ? boardLinksId ?? [] : [];

  if (isEditingName) {
    return (
      <SectionEdit
        defaultName={sectionName}
        onResetEditingMode={hideEditingMode}
        onSubmit={handleSubmit}
      />
    );
  }

  const isSectionOpen = isOpen || filteredBoardLinksdId.length > 0;

  return (
    <Container
      {...htmlProps}
      ref={setDroppableNodeRef}
      $collapsed={collapsed}
      $isOpen={isOpen}
    >
      {!hideTitle && !collapsed && (
        <BoardSectionNavigationItem
          setNodeRef={setDraggableNodeRef}
          className="item--group"
          onClick={onClick}
          label={sectionName}
          postName={(
            <DownIcon
              size={14}
              direction={isSectionOpen ? 'top' : 'bottom'}
            />
          )}
          isFocus={isSectionMenuOpen || isSectionViewMenuOpen}
          contentMore={contentMore}
          isDragging={isDragging}
          asPlaceholder={asPlaceholder}
          isSidebarColor
          $isOpen={isSectionOpen}
        />
      )}
      {!isDragging && boardLinksId && getBoard && (
        <SortableContext
          items={boardLinksId}
          disabled={!canReorderBoards}
        >
          {filteredBoardLinksdId
            .map((boardLinkId) => {
              const board = getBoard(boardLinkId);
              return board ? (
                <SidebarItemBoardSortable
                  key={boardLinkId}
                  boardLinkId={boardLinkId}
                  board={board}
                />
              ) : null;
            })}

          {activeBoardLinkId && activeBoard && (
            <SidebarItemBoardSortable
              key={activeBoardLinkId}
              boardLinkId={activeBoardLinkId}
              board={activeBoard}
            />
          )}
        </SortableContext>
      )}
    </Container>
  );
};

export default SidebarBoardSection;

type SectionEditProps = {
  defaultName: string;
  onResetEditingMode: VoidFunction;
  onSubmit: (name: string) => void;
};

const SectionEdit = ({
  defaultName, onResetEditingMode, onSubmit,
}: SectionEditProps) => {
  const [sectionName, setSectionName] = useState(defaultName);
  return (
    <BoardSectionInput
      value={sectionName}
      placeholder="Section name"
      onChange={(e: ChangeEvent<HTMLInputElement>) => setSectionName(e.target.value)}
      iconAfter={<CloseIcon />}
      onBlur={onResetEditingMode}
      onClickIcon={onResetEditingMode}
      autoFocus
      onKeyDown={e => {
        const { key } = e;
        if (key === 'Escape') {
          e.preventDefault();
          onResetEditingMode();
        }
        if (key === 'Enter') {
          e.preventDefault();
          onSubmit(sectionName);
        }
      }}
    />
  );
};
