import { useSubscription } from '@apollo/client';
import { RunCustomPromptsDocument, MutationProgressDocument } from '@cycle-app/graphql-codegen';
import { Popover, Icon } from '@cycle-app/ui';
import { AiIcon } from '@cycle-app/ui/icons';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { twJoin } from 'tailwind-merge';

import DocAction from 'src/components/DocAction/DocAction';
import { PageId } from 'src/constants/routing.constant';
import { useProductId } from 'src/hooks/useProductId';
import useSafeMutation from 'src/hooks/useSafeMutation';
import { getParams, getUrl } from 'src/utils/routing.utils';

export const Summarize = () => {
  const [runCustomPrompts, loading]  = useRunCustomPrompts();
  const disabled = loading;
  return (
    <Popover
      withPortal
      withWrapper={false}
      placement="top"
      interactive
      animation
      content={(
        <div className="p-1 rounded-lg border border-grey-200 dark:border-grey-850 shadow-z3 bg-primary">
          <Link
            to={getUrl(PageId.Settings, { hash: 'summary-prompts' })}
            className="btn btn-tertiary"
          >
            <Icon name="edit" />
            Edit prompt summary
          </Link>
        </div>
      )}
    >
      <div
        className={twJoin(disabled && 'cursor-not-allowed')}
      >
        <DocAction
          size="L"
          disabled={disabled}
          onClick={runCustomPrompts}
        >
          <AiIcon
            size={14}
            isAnimated={loading}
          />
          {loading ? 'Summarizing...' : 'Summarize'}
        </DocAction>
      </div>
    </Popover>
  );
};

const useRunCustomPrompts = () => {
  const productId = useProductId();
  const [mutate, mutationResult] = useSafeMutation(RunCustomPromptsDocument);
  const [mutationId, setMutationId] = useState<string | null>(null);

  useSubscription(MutationProgressDocument, {
    ignoreResults: true,
    skip: !productId || !mutationId,
    variables: {
      productId: productId ?? '',
    },
    onData: ({ data: { data } }) => {
      if (data?.mutationProgress?.mutationId !== mutationId) return;
      if (data.mutationProgress.progress < 100) return;
      setMutationId(null);
    },
  });

  const runCustomPrompts = async () => {
    const { docId } = getParams();
    if (!productId || !docId) return;
    const result = await mutate({
      variables: {
        docId,
        productId,
      },
    });
    setMutationId(result.data?.runCustomPrompts ?? null);
    return result;
  };

  const loading = mutationResult.loading || !!mutationId;

  return [runCustomPrompts, loading] as const;
};
