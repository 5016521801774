import { Menu, Icon } from '@cycle-app/ui';
import { ComponentProps } from 'react';

import { useProductAreaAttribute } from 'src/hooks/api/useAttributes';
import { commandProductAreaFilter } from 'src/reactives/commandbar.reactive';

import { FilterButton, ArrowIcon } from './CommandProductAreaFilter.styles';
import { useProductAreasCategories } from '../../../../hooks/product/useProductAreasCategories';
import { ProductAreasManager } from '../../../ProductAreasManager';

export const CommandProductAreaFilter = (props: Pick<ComponentProps<typeof Menu>, 'open' | 'onOpenChange'>) => {
  const filter = commandProductAreaFilter.hook();
  const { areas } = useProductAreasCategories();
  const { attributeNamePlural } = useProductAreaAttribute();
  const total = areas.length;
  const selectedValues = areas.filter(area => filter.ids.includes(area.id));
  const selectedCount = selectedValues.length;
  if (!total) return null;

  return (
    <Menu
      {...props}
      side="bottom"
      align="start"
      className="p-0"
      onCloseAutoFocus={e => e.preventDefault()}
      trigger={(
        <FilterButton>
          <Icon name="tri-shapes" />
          {!selectedCount || total === selectedCount ? 'All' : selectedCount}
          {' '}
          {attributeNamePlural}
          <ArrowIcon size={16} />
        </FilterButton>
      )}
    >
      <ProductAreasManager
        docTypeName={undefined}
        compatibleIds={undefined}
        hideIncompatibleValues={false}
        isRequired={false}
        isMulti
        selectedValues={selectedValues.map(a => a.id)}
        onSelect={option => commandProductAreaFilter.produce(current => {
          current.ids = Array.from(new Set([...current.ids, option.productAreaId]));
        })}
        onUnselect={option => commandProductAreaFilter.produce(current => {
          current.ids = current.ids.filter(id => id !== option.productAreaId);
        })}
      />
    </Menu>
  );
};
