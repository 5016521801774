import { twJoin } from 'tailwind-merge';

import { getSubtitleData, TEXT_TO_REPLACE } from './helpers';
import { useChangelogBuilderTheme } from '../../ChangelogBuilderThemeProvider';
import { shipOptions } from '../../constants/shipOptions';
import { useThemeVariant } from '../../shared/useThemeVariant';
import { useChangelogBuilderForm } from '../../useChangelogBuilderForm';
import { ChangelogBuilderContentLogo } from '../ChangelogBuilderContentLogo';
import { ChangelogBuilderHeroLinks } from './ChangelogBuilderHeroLinks';

export function ChangelogBuilderHeroText({ className }: { className?: string }) {
  const { watch } = useChangelogBuilderForm();

  const { isDarkTheme } = useChangelogBuilderTheme();

  const shipBadgeColor = watch('shipBadgeColor');
  const subtitle = watch('subtitle');
  const heroSectionClasses = useThemeVariant(watch('heroSection'));
  const {
    hasShip, shipIndex,
  } = getSubtitleData(subtitle);

  return (
    <div className={twJoin('flex flex-col items-center py-4 text-center', className)}>
      <ChangelogBuilderContentLogo />

      <h1 className={twJoin(
        'mt-8 font-bold text-(--changelog-headers-color)',
        heroSectionClasses({
          S: 'text-[32px] md:text-[40px]/[44px]',
          M: 'text-[36px] md:text-[48px]/[57.6px]',
          L: 'text-[41px] md:text-[62px]/[68px]',
          XL: 'text-[73px] md:text-[80px]/[91px]',
        }),
      )}
      >
        {watch('title')}
      </h1>

      <p className={twJoin(
        'mt-4 font-medium text-(--changelog-body-color)',
        heroSectionClasses({
          S: 'mt-3 text-base',
          M: 'mt-3 md:mt-2 text-base md:text-lg',
          L: 'mt-4 text-xl',
          XL: 'mt-4 text-xl md:text-2xl',
        }),
      )}
      >
        {hasShip ? (
          <>
            <span>
              {subtitle.substring(0, shipIndex)}
            </span>
            <img
              src={isDarkTheme ? shipOptions[shipBadgeColor].darkBadge : shipOptions[shipBadgeColor].lightBadge}
              alt="Ship badge"
              className="mx-1 inline-block h-[2.3em] align-middle drop-shadow-sm"
              loading="lazy"
            />
            <span>
              {subtitle.substring(shipIndex + TEXT_TO_REPLACE.length)}
            </span>
          </>
        ) : (
          subtitle
        )}
      </p>

      <ChangelogBuilderHeroLinks
        className="mt-6"
        dropdownPlacement="bottom"
      />
    </div>
  );
}

