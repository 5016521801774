import { Spinner, TooltipLegacy } from '@cycle-app/ui';
import { CalendarTimeIcon, SignalIcon } from '@cycle-app/ui/icons';
import { plural } from '@cycle-app/utilities';

import { useMeetingsEvents } from 'src/hooks/integration/useMeetingsEvents';
import { openMeetingsEventsRecording } from 'src/reactives/settingsModal.reactive';

import { OngoingCta, UpcomingCta } from './HomeViewMeetingsEnabled.styles';

type Props = {
  isCompact: boolean;
};

export const HomeViewMeetingsEnabled = ({ isCompact }: Props) => {
  const query = useMeetingsEvents();
  const ongoingCount = query.events.ongoing.length;
  const upcomingCount = query.events.upcoming.length;

  if (query.isInitialLoading) return <Spinner />;

  if (ongoingCount) {
    const content = `${ongoingCount} ${plural('ongoing meeting', ongoingCount)}`;
    return (
      <TooltipLegacy
        withPortal
        withWrapper={false}
        placement="top"
        content={content}
        disabled={!isCompact}
      >
        <OngoingCta
          size="M"
          variant="secondary"
          onClick={openMeetingsEventsRecording}
        >
          <SignalIcon />
          {isCompact ? ongoingCount : content}
        </OngoingCta>
      </TooltipLegacy>
    );
  }

  if (upcomingCount) {
    const content = `${upcomingCount} ${plural('upcoming meeting', upcomingCount)}`;
    return (
      <TooltipLegacy
        withPortal
        withWrapper={false}
        placement="top"
        content={content}
        disabled={!isCompact}
      >
        <UpcomingCta
          size="M"
          variant="ternary"
          onClick={openMeetingsEventsRecording}
        >
          <CalendarTimeIcon />
          {isCompact ? upcomingCount : content}
        </UpcomingCta>
      </TooltipLegacy>
    );
  }

  return null;
};
