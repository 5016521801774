import { GithubAssignee } from '@cycle-app/graphql-codegen';
import { SelectPanel, AvatarLegacy, SelectOption } from '@cycle-app/ui';
import { FC, useMemo } from 'react';

import DropdownLayer from 'src/components/DropdownLayer/DropdownLayer';
import { Layer } from 'src/types/layers.types';

type Props = {
  isVisible: boolean;
  isLoading: boolean;
  children: JSX.Element;
  onClose: () => void;
  assignees: GithubAssignee[];
  selectedIds: string[];
  onChange: (assignee: GithubAssignee, action: 'add' | 'remove') => void;
};

export const GithubAssigneesSelectDropdown: FC<React.PropsWithChildren<Props>> = ({
  isVisible, onClose, children, assignees, selectedIds, onChange, isLoading,
}) => {
  const options: SelectOption[] = useMemo(() => assignees.map(a => ({
    label: a.login,
    value: a.id,
    icon: a.avatarUrl ? <AvatarLegacy src={a.avatarUrl} /> : undefined,
    selected: selectedIds.includes(a.id),
  })) || [], [assignees, selectedIds]);

  return (
    <DropdownLayer
      layer={Layer.DropdownModal}
      visible={isVisible}
      hide={onClose}
      placement="bottom-start"
      content={(
        <SelectPanel
          title="GitHub assignees"
          options={options}
          isMulti
          isLoading={isLoading}
          onOptionsChange={(newOptions) => {
            if (isLoading) return;

            const assigneeIdToAdd = getNewId(newOptions, selectedIds);

            if (assigneeIdToAdd) {
              const assigneeToAdd = assigneeIdToAdd ? assignees.find(a => a.id === assigneeIdToAdd) : null;
              if (assigneeToAdd) {
                onChange(assigneeToAdd, 'add');
              }
            } else {
              const assigneeIdToRemove = getMissingId(newOptions, selectedIds);
              const assigneeToRemove = assigneeIdToRemove ? assignees.find(a => a.id === assigneeIdToRemove) : null;
              if (assigneeToRemove) {
                onChange(assigneeToRemove, 'remove');
              }
            }
          }}
          selectType="checkbox"
        />
      )}
    >
      {children}
    </DropdownLayer>
  );
};

function getNewId(newOptions: SelectOption[], selectedIds: string[]) {
  return newOptions.find(n => !selectedIds.includes(n.value))?.value;
}
function getMissingId(newOptions: SelectOption[], selectedIds: string[]) {
  const newIds = newOptions.map(o => o.value);
  const missingId = selectedIds.find(id => !newIds.includes(id));

  return missingId;
}
