import { ChangelogBaseFragment, Color, ContentSection, HeroSection, ReleaseTagStyle } from '@cycle-app/graphql-codegen';
import { Icon } from '@cycle-app/ui';
import { ReactNode } from 'react';

export type ChangelogTemplate = {
  label: string;
  value: string;
  icon: ReactNode;
  theme: {
    tagColors: string[];
  } & Pick<ChangelogBaseFragment,
  | 'heroSection'
  | 'contentSection'
  | 'backgroundColor'
  | 'headersColor'
  | 'linkColor'
  | 'bodyColor'
  | 'shipBadgeColor'
  | 'releaseTagStyle'
  | 'releaseTagColor'
  | 'dividerColor'
  >;
};

export const CHANGELOG_TEMPLATES: ChangelogTemplate[] = [
  {
    label: 'Cycle',
    value: 'cycle_light',
    icon: (
      <Icon
        name="brand/cycle"
        className="size-4 text-primary"
      />
    ),
    theme: {
      heroSection: HeroSection.L,
      contentSection: ContentSection.Unique,
      backgroundColor: '#FFFFFF',
      headersColor: '#161B22',
      linkColor: '#2B44E7',
      bodyColor: '#000000',
      shipBadgeColor: Color.A,
      releaseTagStyle: ReleaseTagStyle.Background,
      releaseTagColor: '#000000',
      dividerColor: '#E6E6E6',
      tagColors: [
        '#FDE2E2',
        '#F5F1FE',
        '#E2FDEE',
        '#E2E6FD',
        '#FDF8E2',
        '#F9E2FD',
        '#FDE2F2',
        '#FDE7E2',
      ],
    },
  },
  {
    value: 'fey',
    label: 'Fey',
    icon: (
      <Icon
        name="brand/fey"
        className="size-4"
      />
    ),
    theme: {
      heroSection: HeroSection.M,
      contentSection: ContentSection.Uniform,
      backgroundColor: '#0B0B0F',
      headersColor: '#FFFFFF',
      linkColor: '#479FFA',
      bodyColor: '#868F97',
      dividerColor: '#2c2f32',
      shipBadgeColor: Color.F,
      releaseTagStyle: ReleaseTagStyle.Background,
      releaseTagColor: '#E6E6E6',
      tagColors: [
        '#1C2F2D',
        '#381F28',
        '#383C2E',
        '#3C302E',
        '#2E3C30',
        '#2E373C',
        '#362E3C',
        '#51384B',
      ],
    },
  },
  {
    value: 'loops',
    label: 'Loops',
    icon: (
      <Icon
        name="brand/loops"
        className="size-4"
      />
    ),
    theme: {
      heroSection: HeroSection.S,
      contentSection: ContentSection.Discreet,
      backgroundColor: '#FFFFFF',
      headersColor: '#171717',
      linkColor: '#FC5200',
      bodyColor: '#000000',
      dividerColor: '#E6E6E6',
      shipBadgeColor: Color.F,
      releaseTagStyle: ReleaseTagStyle.Dot,
      releaseTagColor: '#000000',
      tagColors: [
        '#FD5D10',
        '#3F61D9',
        '#D93F3F',
        '#3FABD9',
        '#673FD9',
        '#BA3FD9',
        '#EEBF45',
        '#35CB71',
      ],
    },
  },
  {
    value: 'attio',
    label: 'Attio',
    icon: (
      <Icon
        name="brand/attio"
        className="size-4"
      />
    ),
    theme: {
      heroSection: HeroSection.L,
      contentSection: ContentSection.Classic,
      backgroundColor: '#FFFFFF',
      headersColor: '#161B22',
      linkColor: '#245BC2',
      bodyColor: '#505967',
      dividerColor: '#E6E6E6',
      shipBadgeColor: Color.K,
      releaseTagStyle: ReleaseTagStyle.Dot,
      releaseTagColor: '#505967',
      tagColors: [
        '#266df0',
        '#F7CB6D',
        '#F06F26',
        '#7861F6',
        '#61AEF6',
        '#C661F6',
        '#F66161',
        '#60CA45',
      ],
    },
  },
  {
    value: 'june',
    label: 'June',
    icon: (
      <Icon
        name="brand/june"
        className="size-4"
      />
    ),
    theme: {
      heroSection: HeroSection.S,
      contentSection: ContentSection.Discreet,
      backgroundColor: '#FFFFFF',
      headersColor: '#171717',
      linkColor: '#6868F7',
      bodyColor: '#495057',
      dividerColor: '#E6E6E6',
      shipBadgeColor: Color.I,
      releaseTagStyle: ReleaseTagStyle.Background,
      releaseTagColor: '#495057',
      tagColors: [
        '#C9F0FD',
        '#FCEDCA',
        '#D7FED8',
        '#F9DDF9',
        '#FBEBE9',
        '#CCF7EF',
        '#CCE0F7',
        '#F7CCDF',
      ],
    },
  },
  {
    value: 'linear',
    label: 'Linear',
    icon: (
      <Icon
        name="brand/linear"
        className="size-4"
      />
    ),
    theme: {
      heroSection: HeroSection.S,
      contentSection: ContentSection.Discreet,
      backgroundColor: '#161618',
      headersColor: '#FFFFFF',
      linkColor: '#7185F1',
      bodyColor: '#F7F8F8',
      dividerColor: '#2c2f32',
      shipBadgeColor: Color.J,
      releaseTagStyle: ReleaseTagStyle.Background,
      releaseTagColor: '#E6E6E6',
      tagColors: [
        '#292C46',
        '#49392A',
        '#4B442C',
        '#46474A',
        '#4B322C',
        '#2C4B45',
        '#472C4B',
        '#2C4B37',
      ],
    },
  },
  {
    value: 'gumroad',
    label: 'Gumroad',
    icon: (
      <Icon
        name="brand/gumroad"
        className="size-4"
      />
    ),
    theme: {
      heroSection: HeroSection.S,
      contentSection: ContentSection.Discreet,
      backgroundColor: '#FFFFFF',
      headersColor: '#171717',
      linkColor: '#000000',
      bodyColor: '#495057',
      dividerColor: '#E6E6E6',
      shipBadgeColor: Color.H,
      releaseTagStyle: ReleaseTagStyle.Background,
      releaseTagColor: '#FFFFFF',
      tagColors: [
        '#EE795B',
        '#59A7AC',
        '#F869BF',
        '#E68D4C',
        '#E64C4C',
        '#4CB8E6',
        '#4C65E6',
        '#AB4CE6',
      ],
    },
  },
  {
    value: 'framer',
    label: 'Framer',
    icon: (
      <Icon
        name="brand/framer"
        className="size-4"
      />
    ),
    theme: {
      heroSection: HeroSection.L,
      contentSection: ContentSection.Sober,
      backgroundColor: '#000000',
      headersColor: '#FFFFFF',
      linkColor: '#FFFFFF',
      bodyColor: '#CCCCCC',
      dividerColor: '#2c2f32',
      shipBadgeColor: Color.C,
      releaseTagStyle: ReleaseTagStyle.Dot,
      releaseTagColor: '#CCCCCC',
      tagColors: [
        '#00AAFF',
        '#0055FF',
        '#FF006B',
        '#FF8A00',
        '#FFE500',
        '#00F0FF',
        '#AD00FF',
        '#2BD565',
      ],
    },
  },
];
