import { ChangelogDateStyle, ContentSection } from '@cycle-app/graphql-codegen';
import { twJoin } from 'tailwind-merge';

import { useThemeVariant } from '../shared/useThemeVariant';
import { useChangelogBuilderForm } from '../useChangelogBuilderForm';
import { ChangelogBuilderContentReleaseDropdown } from './ChangelogBuilderContentReleaseDropdown';

export function ChangelogBuilderContentReleaseTitle() {
  const { watch } = useChangelogBuilderForm();

  const contentSectionClass = useThemeVariant(watch('contentSection'));

  if (watch('dateStyle') === ChangelogDateStyle.SingleTitle) return null;

  return (
    <ChangelogBuilderContentReleaseDropdown>
      <h2
        className={twJoin(
          'font-bold text-(--changelog-headers-color)',
          contentSectionClass({
            [ContentSection.Sober]: 'text-[22px]',
            [ContentSection.Classic]: 'text-[24px]',
            [ContentSection.Unique]: 'text-[28px]',
            [ContentSection.Uniform]: 'text-[28px]',
            [ContentSection.Discreet]: 'text-[32px]',
            [ContentSection.ShowOff]: 'text-[46px]',
          }),
        )}
      >
        Release title
      </h2>
    </ChangelogBuilderContentReleaseDropdown>
  );
}
