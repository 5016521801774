import { IntegrationType } from '@cycle-app/graphql-codegen';
import { Icon } from '@cycle-app/ui';
import { isUrl } from '@cycle-app/utilities';
import { ReactNode } from 'react';

import { isEmbedSupported } from 'src/utils/editor/iframely.util';

import { LinearDiscoverEmbedDescription } from '../components/LinearDiscoverEmbedDescription';
import { AutomationType } from '../types/automation.types';

export const LINEAR_ISSUE_REGEX = /https:\/\/linear\.app\/.+\/issue\/([^/]+)\/([^/#]+)/;
export const LINEAR_ISSUE_KEY_REGEX = /https:\/\/linear\.app\/.+\/issue\/([^/#]+)/;
export const LINEAR_PROJECT_REGEX = /https:\/\/linear\.app\/.+\/project\/([^/#]+)/;

export const GITHUB_ISSUE_REGEX = /https:\/\/github\.com\/.+\/issues\/([A-Za-z0-9]+)/;
export const NOTION_PAGE_REGEX = /https:\/\/www\.notion\.so.*\/(?:[^-]+-)*([A-Za-z0-9]+)\??(.*)/;

export const getAutomationType = (url?: string | null): AutomationType => {
  if (!url) return null;
  if (LINEAR_PROJECT_REGEX.test(url)) return 'project';
  if (LINEAR_ISSUE_KEY_REGEX.test(url)) return 'issue';
  return null;
};

export const isEmbeddable = (url?: string | null) => {
  if (!url || !isUrl(url)) return false;

  return isEmbedSupported(url) ||
    !!getAutomationType(url) ||
    GITHUB_ISSUE_REGEX.test(url) ||
    NOTION_PAGE_REGEX.test(url);
};

export const getLinearIssueId = (url: string) => url.match(LINEAR_ISSUE_REGEX)?.[1];
export const getLinearIssueSlug = (url: string) => {
  const encodedSlug = url.match(LINEAR_ISSUE_REGEX)?.[2];
  // decode the slug to handle emojis
  return encodedSlug ? decodeURIComponent(encodedSlug) : null;
};

export const getLinearProjectId = (url: string) => url.match(LINEAR_PROJECT_REGEX)?.[1];

export const getGithubIssueId = (url: string) => url.match(GITHUB_ISSUE_REGEX)?.[1];

export const getNotionPageId = (url: string) => {
  const match = url.match(NOTION_PAGE_REGEX);
  const pageId = match?.[1];
  const paramsString = match?.[2];
  const searchParams = new URLSearchParams(paramsString);
  const isDatabase = searchParams.has('v');
  return {
    pageId,
    isDatabase,
  };
};

type LinkType =
  | 'Claap'
  | 'Descript'
  | 'Figma'
  | 'GitHub'
  | 'GitHubIssue'
  | 'Gong'
  | 'Grain'
  | 'LinearIssue'
  | 'LinearProject'
  | 'Loom'
  | 'Miro'
  | 'NotionPage'
  | 'Pitch';

export const getLinkType = (url?: string | null): LinkType | null => {
  if (!url) return null;
  if (url.includes('figma.com')) return 'Figma';
  if (url.includes('miro.com')) return 'Miro';
  if (url.includes('loom.com')) return 'Loom';
  if (url.includes('pitch.com')) return 'Pitch';
  if (url.includes('linear.app')) return getAutomationType(url) === 'issue' ? 'LinearIssue' : 'LinearProject';
  if (GITHUB_ISSUE_REGEX.test(url)) return 'GitHubIssue';
  if (url.includes('github.com')) return 'GitHub';
  if (url.includes('notion.so')) return 'NotionPage';
  if (url.includes('grain.com')) return 'Grain';
  if (url.includes('claap.io')) return 'Claap';
  if (url.includes('descript.com')) return 'Descript';
  if (url.includes('app.gong.io')) return 'Gong';
  return null;
};

export const isMeetingRelated = (name: string | LinkType | null) => [
  'Claap',
  'Descript',
  'Grain',
  'Loom',
  'Gong',
].includes(name || '');

type Options = {
  isInstalled?: boolean;
  isTurnableIntoInsight?: boolean;
};

const getTitle = (name: string) => (options: Options = {}) => {
  if (options.isTurnableIntoInsight) {
    return `You embeded a ${name}`;
  }
  return `You pasted a ${name} link`;
};

const getEmbeddableDescription = (name: string) => () => {
  if (name === 'Gong') return 'Enable Call recording extension to record your calls and automatically fetch transcripts as Cycle feedback.';
  const baseCopy = `Embed this ${name} link to visualize it directly from Cycle`;
  if (isMeetingRelated(name)) {
    return `${baseCopy} or enable Call recording extension to record your calls and automatically fetch transcripts as Cycle feedback.`;
  }
  return `${baseCopy}.`;
};

const getInstallableDescription = (name: string, embedName?: string) => (options: Options = {}) => {
  if (!options.isInstalled) {
    return `Embed this link as ${embedName ?? name} if you want to access and update its details directly from Cycle.`;
  }

  if (options.isTurnableIntoInsight) {
    return (
      <div>
        {`Turn this ${embedName ?? name} into an insight to properly link it to Cycle`}
        <br />
        feedback.
      </div>
    );
  }

  if (name === 'Linear') {
    return <LinearDiscoverEmbedDescription embedName={embedName} />;
  }

  if (name === 'Notion') {
    return 'Enable Notion extension to display the page backlink on doc.';
  }

  return `Enable ${name} extension to display the issue within the content and change its properties.`;
};

type LinkData = {
  icon: ReactNode;
  title: (options?: Options) => string;
  description: (options?: Options) => ReactNode;
  type?: IntegrationType;
};

export const linkData: Record<LinkType, LinkData> = {
  Figma: {
    icon: (
      <Icon
        name="brand/figma"
        className="size-4"
      />
    ),
    title: getTitle('Figma'),
    description: getEmbeddableDescription('Figma'),
  },
  Miro: {
    icon: (
      <Icon
        name="brand/miro"
        className="size-4"
      />
    ),
    title: getTitle('Miro'),
    description: getEmbeddableDescription('Miro'),
  },
  Loom: {
    icon: (
      <Icon
        name="brand/loom"
        className="size-4"
      />
    ),
    title: getTitle('Loom'),
    description: getEmbeddableDescription('Loom'),
  },
  Pitch: {
    icon: (
      <Icon
        name="brand/pitch"
        className="size-4"
      />
    ),
    title: getTitle('Pitch'),
    description: getEmbeddableDescription('Pitch'),
  },
  GitHub: {
    icon: (
      <Icon
        name="brand/github"
        className="size-4"
      />
    ),
    title: getTitle('GitHub'),
    description: getEmbeddableDescription('GitHub'),
  },
  LinearIssue: {
    icon: (
      <Icon
        name="brand/linear"
        className="size-4"
      />
    ),
    title: getTitle('Linear issue'),
    description: getInstallableDescription('Linear', 'Linear issue'),
  },
  LinearProject: {
    icon: (
      <Icon
        name="brand/linear"
        className="size-4"
      />
    ),
    title: getTitle('Linear project'),
    description: getInstallableDescription('Linear', 'Linear project'),
  },
  GitHubIssue: {
    icon: (
      <Icon
        name="brand/github"
        className="size-4"
      />
    ),
    title: getTitle('GitHub issue'),
    description: getInstallableDescription('GitHub issue'),
  },
  NotionPage: {
    icon: (
      <Icon
        name="brand/notion"
        className="size-4"
      />
    ),
    title: getTitle('Notion'),
    description: getInstallableDescription('Notion', 'Notion page'),
  },
  Grain: {
    icon: (
      <Icon
        name="brand/grain"
        className="size-4"
      />
    ),
    title: getTitle('Grain'),
    description: getEmbeddableDescription('Grain'),
  },
  Claap: {
    icon: (
      <Icon
        name="brand/claap"
        className="size-4"
      />
    ),
    title: getTitle('Claap'),
    description: getEmbeddableDescription('Claap'),
  },
  Descript: {
    icon: (
      <Icon
        name="brand/descript"
        className="size-4"
      />
    ),
    title: getTitle('Descript'),
    description: getEmbeddableDescription('Descript'),
  },
  Gong: {
    icon: (
      <Icon
        name="brand/gong"
        className="size-4"
      />
    ),
    title: getTitle('Gong'),
    description: getEmbeddableDescription('Gong'),
  },
};

export const getLinkIntegrationType = (linkType: LinkType | null): IntegrationType | null => {
  if (linkType === 'LinearIssue' || linkType === 'LinearProject') {
    return IntegrationType.Linear;
  }

  if (linkType === 'GitHubIssue') {
    return IntegrationType.Github;
  }

  if (linkType === 'NotionPage') {
    return IntegrationType.Notion;
  }

  return null;
};
