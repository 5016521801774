export const colorUtils = {
  mix,
  darken,
  lighten,
  transparentize,
  rgba,
  opacify,
  setLightness,
};

/**
 * Sets a specific opacity for a color
 * @param color - Any valid CSS color
 * @param alpha - Value between 0 and 1
 */
function rgba(color: string, alpha: number): string {
  const normalizedAlpha = Math.max(0, Math.min(1, alpha));
  return `rgb(from ${color} r g b / ${normalizedAlpha})`;
}

/**
 * Decreases the opacity of a color (makes it more transparent)
 * @param color - Any valid CSS color
 * @param amount - Value between 0 and 1 to decrease opacity by
 */
function transparentize(color: string, amount: number): string {
  const normalizedAmount = Math.max(0, Math.min(1, amount));
  return `rgb(from ${color} r g b / calc(alpha - ${normalizedAmount}))`;
}

/**
 * Increases the opacity of a color (makes it less transparent)
 * @param color - Any valid CSS color
 * @param amount - Value between 0 and 1 to increase opacity by
 */
function opacify(color: string, amount: number): string {
  const normalizedAmount = Math.max(0, Math.min(1, amount));
  return `rgb(from ${color} r g b / calc(alpha + ${normalizedAmount}))`;
}

/**
 * Sets the lightness of a color
 * @param color - Any valid CSS color
 * @param lightness - Value between 0 and 1
 */
function setLightness(color: string, lightness: number): string {
  const normalizedLightness = Math.max(0, Math.min(1, lightness));
  return `hsl(from ${color} h s ${normalizedLightness * 100}% / alpha)`;
}

/**
 * Mixes two colors together with a specified weight
 * @param color1 - Any valid CSS color
 * @param color2 - Any valid CSS color
 * @param weight - Value between 0 and 1 representing the weight of color1
 */
function mix(color1: string, color2: string, weight: number = 0.5): string {
  const normalizedWeight = Math.max(0, Math.min(1, weight));
  return `color-mix(in srgb, ${color1} ${normalizedWeight * 100}%, ${color2})`;
}

/**
 * Increases the lightness of a color
 * @param color - Any valid CSS color
 * @param amount - Value between 0 and 1 to increase lightness by
 */
function lighten(color: string, amount: number): string {
  const normalizedAmount = Math.max(0, Math.min(1, amount));
  return `color-mix(in oklab, ${color}, white ${normalizedAmount * 100}%)`;
}

/**
 * Decreases the lightness of a color
 * @param color - Any valid CSS color
 * @param amount - Value between 0 and 1 to decrease lightness by
 */
function darken(color: string, amount: number): string {
  const normalizedAmount = Math.max(0, Math.min(1, amount));
  return `color-mix(in oklab, ${color}, black ${normalizedAmount * 100}%)`;
}
