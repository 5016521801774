import { Setter } from '@cycle-app/utilities';
import {
  MouseEvent, ReactNode, useEffect, useState, useMemo, CSSProperties,
} from 'react';
import { twJoin } from 'tailwind-merge';
import { Placement } from 'tippy.js';

import { Dropdown, DropdownProps } from '../Dropdown/Dropdown';
import { SelectOption, SelectPanel, SelectPanelProps } from '../Selects';
import { TriggerContainer } from './DropdownSelect.styles';
import { Icon } from '../../components/Icon';

export interface DropdownSelectProps extends
  Omit<DropdownProps, 'content' | 'children'>,
  Pick<SelectPanelProps,
  'onClearValue' |
  'searchPlaceholder' |
  'warningOnNoneValue' |
  'isMulti' |
  'onSelectOption' |
  'onOptionsChange' |
  'onSelectAll' |
  'isRequired' |
  'toggleAllValuesVariant' |
  'onSearchChange' |
  'debounceSearch' |
  'onMouseEnterItem' |
  'onMouseLeaveItem' |
  'infiniteScroll'
  > {

  className?: string;
  ariaLabel?: string;
  options: SelectOption[];
  placement?: Placement;
  onChange?: (selectedOption: SelectOption) => void;
  onMount?: DropdownProps['onMount'];
  onHide?: VoidFunction;
  onVisibilityChange?: (visible: boolean) => void;
  hideSearch?: boolean;
  selectedValue?: string | undefined;
  listNavDefaultIndex?: number;
  setVisible?: Setter<boolean>;
  contentDropdown?: ReactNode;
  listMaxHeight?: string;
  forceFocus?: boolean;
  panelStyles?: CSSProperties;
  triggerStyle?: CSSProperties;
  children?: ReactNode | ((isVisible: boolean) => ReactNode);
}

export const DropdownSelect = ({
  visible: visibleProps,
  setVisible: setVisibleProps,
  options,
  placement = 'bottom',
  onChange,
  onMount,
  onHide,
  children,
  disabled = false,
  onVisibilityChange,
  hideSearch,
  selectedValue,
  listNavDefaultIndex,
  onClearValue,
  contentDropdown,
  listMaxHeight,
  searchPlaceholder,
  warningOnNoneValue,
  forceFocus,
  isMulti,
  onSelectOption,
  onOptionsChange,
  onSelectAll,
  isRequired,
  toggleAllValuesVariant,
  onSearchChange,
  debounceSearch,
  infiniteScroll,
  panelStyles,
  onMouseEnterItem,
  onMouseLeaveItem,
  triggerStyle,
  ...dropdownProps
}: DropdownSelectProps) => {
  const [visibleState, setVisibleState] = useState(false);

  const visible = typeof visibleProps !== 'undefined' ? visibleProps : visibleState;
  const setVisible = setVisibleProps || setVisibleState;

  const selectedOption = useMemo(() => options.find(o => o.value === selectedValue), [options, selectedValue]);

  useEffect(() => {
    onVisibilityChange?.(visible);
  }, [visible, onVisibilityChange]);

  return (
    <Dropdown
      visible={visible}
      placement={placement}
      onMount={onMount}
      onHide={onHide}
      hide={hide}
      content={contentDropdown || (
        <SelectPanel
          searchPlaceholder={searchPlaceholder}
          hideSearch={hideSearch}
          options={options}
          listNavDefaultIndex={listNavDefaultIndex}
          onClearValue={onClearValue}
          listMaxHeight={listMaxHeight}
          warningOnNoneValue={warningOnNoneValue}
          onSearchChange={onSearchChange}
          onMouseEnterItem={onMouseEnterItem}
          onMouseLeaveItem={onMouseLeaveItem}
          debounceSearch={debounceSearch}
          infiniteScroll={infiniteScroll}
          {...isMulti ? {
            isMulti: true,
            onSelectOption,
            onOptionsChange,
            onSelectAll,
            isRequired,
            toggleAllValuesVariant,
          } : {
            onOptionChange,
            selectedValue,
          }}
          style={panelStyles}
        />
      )}
      {...dropdownProps}
    >
      <TriggerContainer
        onClick={!disabled ? toggleSelect : undefined}
        role="button"
        tabIndex={-1}
        $isDisabled={disabled}
        style={triggerStyle}
      >
        {typeof children === 'function' ? children(visible) : children}
        {!children && selectedValue && (
          <button
            className={twJoin(
              'btn-tertiary h-6 text-primary',
              forceFocus && 'btn-hover',
            )}
          >
            {selectedOption?.icon}
            {selectedOption?.label}
            <Icon
              name="caret"
              className="size-2"
            />
          </button>
        )}
      </TriggerContainer>
    </Dropdown>
  );

  function onOptionChange(option: SelectOption) {
    if (option.disabled) return;

    if (!option.keepDropdownOnSelect) {
      hide();
    }
    onChange?.(option);
    option.onSelect?.();
  }

  function toggleSelect(e: MouseEvent) {
    e.preventDefault();
    e.stopPropagation();
    setVisible(!visible);
  }

  function hide() {
    setVisible(false);
  }
};
