import { ThemeType } from '@cycle-app/graphql-codegen';
import { ColorTheme } from '@cycle-app/utilities';

import { getThemeConfig } from 'src/reactives/theme.reactive';
import { logError } from 'src/utils/errors.utils';

type PlainCustomerDetails = {
  fullName?: string | null;
  shortName?: string | null;
  chatAvatarUrl?: string | null;
};

type PlainOptions = {
  appId?: string;
  customerDetails?: PlainCustomerDetails;
  hideLauncher?: boolean;
  hideBranding?: boolean;
  theme?: 'light' | 'dark' | 'auto';
  style?: {
    brandColor?: string;
    brandBackgroundColor?: string;
  };
  logo?: {
    url: string;
    alt: string;
  };
  position?: {
    right: string;
    bottom: string;
  };
  links?: {
    icon: 'bell' | 'book' | 'bug' | 'bulb' | 'chat' | 'integration' | 'discord' | 'email' | 'slack' | 'link' | 'pencil' | 'send' | 'support' | 'error';
    text: string;
    url: string;
  }[];
};

type Plain = {
  init: (options: PlainOptions) => Promise<void>;
  update: (options: PlainOptions) => Promise<void>;
  setCustomerDetails: (customerDetails: PlainCustomerDetails) => Promise<void>;
  isInitialized: () => boolean;
  open: () => void;
};

declare global {
  interface Window {
    Plain?: Plain;
  }
}

const loadPlain = () => new Promise<Plain>((resolve, reject) => {
  if (window.Plain) return resolve(window.Plain);
  const onLoad = () => {
    if (window.Plain) {
      resolve(window.Plain);
    } else {
      const error = new Error('Error loading Plain script');
      logError(error);
      reject(error);
    }
  };
  document.querySelector('#plain-script')?.addEventListener('load', onLoad, { once: true });
});

const PLAIN_KEY = import.meta.env.VITE_PLAIN_KEY;

let plainInitialized: Promise<Plain> | null = null;

export const initPlain = () => {
  if (!PLAIN_KEY) return;
  plainInitialized = new Promise(async (resolve, reject) => {
    const plain = await loadPlain();

    await plain.init({
      appId: PLAIN_KEY,
      ...plainInitOptions,
    });

    if (plain.isInitialized()) {
      resolve(plain);
    } else {
      const error = new Error('Error initializing Plain');
      logError(error);
      reject(error);
    }
  });
};

export const updatePlainCustomerDetails = async (customerDetails: PlainCustomerDetails) => {
  const plain = await plainInitialized;
  if (!plain) return;
  await plain.setCustomerDetails(customerDetails);
};

export const updatePlainWidget = async (options: PlainOptions) => {
  const plain = await plainInitialized;
  if (!plain) return;
  await plain.update(options);
};

export const openPlain = () => {
  if (!window.Plain?.isInitialized()) {
    console.warn('Plain not initialized');
    return;
  }
  try {
    window.Plain.open();
  } catch (error) {
    logError(new Error('Error opening Plain widget', { cause: error }));
  }
};

const plainInitOptions: PlainOptions = {
  hideLauncher: true,
  hideBranding: true,
  theme: getThemeConfig().colorTheme === ThemeType.Nightfall ? 'dark' : 'light',
  style: {
    brandColor: ColorTheme.Cycle,
    brandBackgroundColor: ColorTheme.Cycle,
  },
  logo: {
    url: '/images/icon-192x192.png',
    alt: 'Cycle',
  },
  position: {
    right: '40px',
    bottom: '40px',
  },
  links: [{
    icon: 'book',
    text: 'FAQ & Help center',
    url: 'https://help.cycle.app',
  }],
};
