import { ToggleChangelogOnlyDocument } from '@cycle-app/graphql-codegen';

import { useWorkspaceContext } from '../../contexts/workspaceContext';
import useSafeMutation from '../useSafeMutation';

export const useToggleChangelogOnly = () => {
  const [toggleChangeloOnlyMutation, { loading: isLoading }] = useSafeMutation(ToggleChangelogOnlyDocument);
  const productId = useWorkspaceContext(ctx => ctx.productId);

  const toggleChangelogOnly = () => toggleChangeloOnlyMutation({
    variables: {
      productId,
    },
  });

  return {
    toggleChangelogOnly,
    isLoading,
  };
};
