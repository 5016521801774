import { UpdateDocTitleDocument } from '@cycle-app/graphql-codegen';
import { useAtomValue } from 'jotai';
import { useDebouncedCallback } from 'use-debounce';

import ContentEditable from 'src/components/ContentEditable/ContentEditable';
import { useEditorInstance } from 'src/components/Editor';
import { INPUT_ONCHANGE_DEBOUNCE } from 'src/constants/inputs.constant';
import { useDocInitialBaseFragment } from 'src/hooks/api/fragments/doc';
import useSafeMutation from 'src/hooks/useSafeMutation';

import { draftRequestIdAtom } from './newRequestModal.atoms';

export const NewRequestTitle = () => {
  const editorRef = useEditorInstance();
  const draftRequestId = useAtomValue(draftRequestIdAtom);
  const doc = useDocInitialBaseFragment(draftRequestId);

  const [mutate] = useSafeMutation(UpdateDocTitleDocument);
  const updateDocTitle = useDebouncedCallback(async (value: string) => {
    if (!doc) return;
    void mutate({
      variables: {
        docId: doc?.id,
        title: value.trim(),
      },
    });
  }, INPUT_ONCHANGE_DEBOUNCE);

  if (!doc) return null;

  return (
    <ContentEditable
      key={doc.id}
      className="outline-none text-header-large font-semibold"
      placeholder="Untitled"
      focusEndOnMount
      onEnter={() => editorRef.current?.commands.focus('start')}
      onNext={() => editorRef.current?.commands.focus('start')}
      initialValue={doc.title}
      onChange={updateDocTitle}
    />
  );
};
