import { Typography } from '@cycle-app/graphql-codegen';
import { colorUtils } from '@cycle-app/ui';
import { parseToRgb } from 'polished';
import { ReactNode, createContext, useContext, useMemo } from 'react';

import { useChangelogBuilderForm } from './useChangelogBuilderForm';

type ChangelogBuilderThemeContextProps = {
  isDarkTheme: boolean;
};

const ChangelogBuilderThemeContext = createContext<ChangelogBuilderThemeContextProps | null>(null);

export function ChangelogBuilderThemeProvider({
  children, className,
}: { children: ReactNode; className?: string }) {
  const { watch } = useChangelogBuilderForm();
  const formBgColor = watch('backgroundColor');
  const bodyColor = watch('bodyColor');
  const linkColor = watch('linkColor');
  const dividerColor = watch('dividerColor');
  const headersColor = watch('headersColor');
  const typography = watch('typography');
  const releaseTagColor = watch('releaseTagColor');

  const backgroundColor = isValidColor(formBgColor) ? formBgColor : '#FFFFFF';

  const providerValue = useMemo<ChangelogBuilderThemeContextProps>(() => ({
    isDarkTheme: isDark(backgroundColor),
  }), [backgroundColor]);

  const style = useMemo(() => {
    const interactiveLevel = providerValue.isDarkTheme ? {
      '--interaction-color-level-1': colorUtils.lighten(backgroundColor, 0.08),
      '--interaction-color-level-2': colorUtils.lighten(backgroundColor, 0.16),
    } : {
      '--interaction-color-level-1': colorUtils.darken(backgroundColor, 0.02),
      '--interaction-color-level-2': colorUtils.darken(backgroundColor, 0.1),
    };
    return {
      '--changelog-background-color': backgroundColor,
      '--changelog-body-color': bodyColor,
      '--changelog-link-color': linkColor,
      '--changelog-divider-color': dividerColor,
      '--changelog-headers-color': headersColor,
      '--changelog-tags-color': releaseTagColor,
      '--changelog-typography': getTypography(typography),
      ...interactiveLevel,
    };
  }, [backgroundColor, bodyColor, linkColor, dividerColor, headersColor, typography, providerValue.isDarkTheme, releaseTagColor]);

  return (
    <ChangelogBuilderThemeContext.Provider value={providerValue}>
      <div
        className={className ?? 'contents'}
        style={style}
      >
        {children}
      </div>
    </ChangelogBuilderThemeContext.Provider>
  );
}

export function useChangelogBuilderTheme() {
  const context = useContext(ChangelogBuilderThemeContext);
  if (!context) {
    throw new Error('useChangelogBuilderTheme must be used within a ChangelogBuilderThemeProvider');
  }
  return context;
}

function getTypography(typograyphy: Typography) {
  switch (typograyphy) {
    case Typography.Montserrat: return '"Montserrat", sans-serif';
    case Typography.SourceSans_3: return '"Source Sans 3", sans-serif';
    case Typography.Roboto: return '"Roboto", sans-serif';
    case Typography.WorkSans: return '"Work Sans", sans-serif';
    case Typography.PlayfairDisplay: return '"Playfair Display", serif';
    case Typography.Mulish: return '"Mulish", sans-serif';
    case Typography.Merriweather: return '"Merriweather", serif';
    case Typography.Manrope: return '"Manrope", sans-serif';
    case Typography.Lora: return '"Lora", serif';
    case Typography.LibreBaskerville: return '"Libre Baskerville", serif';
    case Typography.JetbrainsMono: return '"JetBrains Mono", monospace';
    case Typography.Hind: return '"Hind", sans-serif';
    case Typography.DmSans: return '"DM Sans", sans-serif';
    case Typography.Asap: return '"Asap", sans-serif';
    case Typography.Sora: return '"Sora", sans-serif';
    default:
    case Typography.Inter: return '"Inter", sans-serif';
  }
}

/**
 * Unfortunately, polished doesn't provide an easy way to get the brightness of a color, or to check if a color is dark or light.
 * Code comes from the tinycolor package: https://github.com/bgrins/TinyColor/blob/master/tinycolor.js#L48-L50
 */

function getBrightness(color: string) {
  const rgb = parseToRgb(color);
  return (rgb.red * 299 + rgb.green * 587 + rgb.blue * 114) / 1000;
}

export function isDark(color: string) {
  return getBrightness(color) < 128;
}

/**
 * Validates if a string is a valid CSS color using polished's parseToRgb
 */
function isValidColor(color: string | null | undefined): boolean {
  if (!color) return false;
  try {
    parseToRgb(color);
    return true;
  } catch {
    return false;
  }
}
