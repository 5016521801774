import { useQuery } from '@apollo/client';
import { DoctypeType, SearchDocDocument, StatusCategory } from '@cycle-app/graphql-codegen';
import { InfiniteScroll, Skeleton } from '@cycle-app/ui';
import { nodeToArray } from '@cycle-app/utilities';
import { Tabs, TabList, Tab, TabPanel } from '@reach/tabs';
import sortBy from 'lodash/sortBy';
import { useCallback, useMemo, useState } from 'react';
import { twJoin } from 'tailwind-merge';

import DocHierarchyItem from 'src/components/DocHierarchy/DocHierarchyItem/DocHierarchyItem';
import { QuoteCard } from 'src/components/QuoteCard';
import { SEARCH_INITIAL_PAGINATION_SIZE } from 'src/constants/search.constants';

import { TabPanels } from './ProductAreasTabs.styles';
import { useWorkspaceContext } from '../../contexts/workspaceContext';
import { useFeedbackDocType, useOptimizedBooleanState, useInsightDocType } from '../../hooks';
import { useSearchStatusIds } from '../../hooks/commandPanel/useDocSearch';
import { productAreaStatusCategory } from '../../reactives/customerCompanyProfile.reactive';
import { useInsightParentDocTypes } from '../../reactives/docTypes.reactive';
import { getDocKey } from '../../utils/doc.util';
import { defaultCustomerDocsPagination } from '../../utils/pagination.util';
import { StatusCategorySelect } from '../CustomerCompanyProfile/StatusCategorySelect';

export const ProductAreasTabs = ({ id }: { id: string }) => {
  const docTypes = useInsightParentDocTypes();
  const statusCategory = productAreaStatusCategory.hook();
  const sortedDocTypes = sortBy(docTypes, 'doctype.name');
  const [tabId, setTabId] = useState('docs');
  const tabIds = ['docs', 'quotes', ...sortedDocTypes.map(docType => docType.id)];
  const tabIndex = tabIds.indexOf(tabId);
  const feedbackDoctype = useFeedbackDocType();
  const quoteDoctype = useInsightDocType();
  return (
    <Tabs
      index={tabIndex < 0 ? 0 : tabIndex}
      className="relative flex flex-col overflow-hidden pt-8"
      onChange={index => setTabId(tabIds[index] || 'quotes')}
    >
      <div
        className={twJoin(
          'relative flex-none overflow-x-auto pb-3',
          'before:absolute before:left-0 before:top-10 before:h-px before:w-full before:bg-grey-150 before:content-[""] dark:before:bg-grey-800',
        )}
      >
        <TabList className="flex items-center gap-x-3 gap-y-2 px-8">
          <Tab
            className="tab"
            {...tabId === 'docs' && { 'data-selected': true }}
          >
            Docs
          </Tab>
          <Tab
            className="tab"
            {...tabId === 'quotes' && { 'data-selected': true }}
          >
            Quotes
          </Tab>
          {sortedDocTypes.map(doctype => {
            return doctype && (
              <Tab
                key={doctype.id}
                className="tab"
                {...tabId === doctype.id && { 'data-selected': true }}
              >
                {doctype.name}
              </Tab>
            );
          })}
        </TabList>
      </div>
      <TabPanels className="grow">
        <div className="px-8 pb-0 pt-3">
          <StatusCategorySelect
            value={statusCategory}
            onChange={productAreaStatusCategory.set}
          />
        </div>
        <TabPanel className="mt-4 px-8 pb-16 pt-0">
          {tabId === 'docs' && feedbackDoctype && (
            <Docs
              doctypeId={feedbackDoctype.id}
              productAreaId={id}
            />
          )}
        </TabPanel>
        <TabPanel className="mt-4 px-8 pb-16 pt-0">
          {tabId === 'quotes' && quoteDoctype && (
            <Docs
              doctypeId={quoteDoctype.id}
              productAreaId={id}
            />
          )}
        </TabPanel>
        {sortedDocTypes.map(doctype => (
          <TabPanel
            key={doctype.id}
            className="mt-4 px-8 pb-16 pt-0"
          >
            {tabId === doctype.id && (
              <Docs
                doctypeId={doctype.id}
                productAreaId={id}
              />
            )}
          </TabPanel>
        ))}
      </TabPanels>
    </Tabs>
  );
};

type DocsProps = {
  doctypeId: string;
  productAreaId: string;
};

const Docs = ({
  doctypeId, productAreaId,
}: DocsProps) => {
  const statusCategory = productAreaStatusCategory.hook();
  const productId = useWorkspaceContext(ctx => ctx.productId);
  const productKey = useWorkspaceContext(ctx => ctx.productKey);
  const statusIds = useSearchStatusIds({
    CANCELED: statusCategory === StatusCategory.Canceled,
    COMPLETED: statusCategory === StatusCategory.Completed,
    NOT_STARTED: statusCategory === StatusCategory.NotStarted,
    STARTED: statusCategory === StatusCategory.Started,
  });
  const {
    data,
    loading: isLoading,
    fetchMore,
  } = useQuery(SearchDocDocument, {
    variables: {
      productId,
      statusIds,
      doctypeIds: [doctypeId],
      productAreaIds: [productAreaId],
      cursor: '',
      size: SEARCH_INITIAL_PAGINATION_SIZE,
    },
    fetchPolicy: 'cache-and-network',
  });

  const [isPaginationLoading, {
    setTrueCallback: setPaginationLoading,
    setFalseCallback: unsetPaginationLoading,
  }] = useOptimizedBooleanState(false);

  const docs = useMemo(() => nodeToArray(data?.searchDoc).map(({ doc }) => ({
    ...doc,
    _docKey: getDocKey(productKey, doc.publicId) || '',
  })), [data?.searchDoc, productKey]);

  const loadMore = useCallback(async (cursor: string) => {
    setPaginationLoading();
    await fetchMore({
      variables: {
        ...defaultCustomerDocsPagination,
        cursor,
      },
    });
    unsetPaginationLoading();
  }, [fetchMore, setPaginationLoading, unsetPaginationLoading]);

  const pageInfo = data?.searchDoc?.pageInfo;
  return (
    <InfiniteScroll
      loadMore={() => {
        if (!pageInfo?.hasNextPage || !pageInfo?.endCursor) return;
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        loadMore(pageInfo.endCursor);
      }}
      hasMoreData={!!pageInfo?.hasNextPage}
      isLoading={isPaginationLoading}
    >
      <div className="flex flex-col gap-4">
        {docs.map(doc => doc.doctype.type === DoctypeType.Insight ? (
          <QuoteCard
            key={doc.id}
            docId={doc.id}
            showCustomer
            showFeature
          />
        ) : (
          <DocHierarchyItem
            key={doc.id}
            doc={doc}
            showStatus
            showQuotes
            isStatusEditable
          />
        ))}
        {(isLoading || isPaginationLoading) && (
          <div className="flex flex-col gap-4">
            <Skeleton height={16} />
            <Skeleton height={16} />
            <Skeleton height={16} />
            <Skeleton height={16} />
          </div>
        )}
      </div>
    </InfiniteScroll>
  );
};
