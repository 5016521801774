/* eslint-disable react/no-array-index-key */
import { TooltipLegacy, Icon } from '@cycle-app/ui';
import { BulbIcon } from '@cycle-app/ui/icons';

import { Content, Arrow, DocTypeItem } from './RoadmapTemplate.styles';
import { Template, TemplateDocTypeKey, docTypes } from './roadmapTemplates.data';

export const TemplateHierarchy = ({
  template, height,
}: { template: Template; height?: number }) => {
  const rowCount = Math.max(...template.hierarchy.map(line => line.length));

  const items = [];
  for (const line of template.hierarchy) {
    for (let i = 0; i < rowCount; i += 1) {
      const docType = line[i] ?? null;
      if (i > 0) items.push(docType ? 'arrow' : null);
      items.push(docType);
    }
  }

  return (
    <Content
      $layout={template.layout}
      style={{
        gridTemplateRows: `auto ${[...Array(rowCount - 1)].flatMap(() => '0.4fr auto').join(' ')}`,
      }}
      $height={height}
    >
      {items.map((item, index, list) => {
        if (!item) return <div key={index} />;
        if (item === 'arrow') return <Arrow key={index} />;
        const docType = docTypes[item as TemplateDocTypeKey];
        const hasMany = list.filter(i => i === item).length > 1;
        const typeIndex = list.slice(0, index).filter(i => i === item).length;
        return (
          <DocTypeItem
            key={index}
            $layout={template.layout}
          >
            <span>
              {docType.emoji}
            </span>
            <span>
              {docType.name}
            </span>
            {hasMany && (
              <span>
                {typeIndex + 1}
              </span>
            )}
            {docType.icons?.map(id => (
              <ItemIcon
                key={id}
                id={id}
              />
            ))}
          </DocTypeItem>
        );
      })}
    </Content>
  );
};

const ItemIcon = ({ id }: { id: string }) => {
  if (id === 'insight') {
    return (
      <TooltipLegacy
        content="Linked to Insights"
        placement="top"
        withPortal
        withWrapper={false}
      >
        <BulbIcon size={13} />
      </TooltipLegacy>
    );
  }

  if (id === 'release') {
    return (
      <TooltipLegacy
        content="Linked to Releases"
        placement="top"
        withPortal
        withWrapper={false}
      >
        <Icon
          name="release"
          className="size-3"
        />
      </TooltipLegacy>
    );
  }

  return null;
};
