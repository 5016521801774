import { useQuery } from '@apollo/client';
import { BoardsDocument } from '@cycle-app/graphql-codegen';
import { nodeToArray } from '@cycle-app/utilities';
import { useState } from 'react';

import { extract } from '../../types/graphql.types';

export const useBoards = (sectionId: string | null | undefined) => {
  const {
    data, loading, fetchMore,
  } = useQuery(BoardsDocument, {
    variables: {
      id: sectionId as string,
      cursor: '',
    },
    skip: !sectionId,
  });
  const section = extract('BoardSection', data?.node);
  const boards = nodeToArray(section?.boards);
  const pageInfo = section?.boards.pageInfo;
  const hasNextPage = !!pageInfo?.hasNextPage;
  const endCursor = pageInfo?.endCursor;

  const [isLoadingMore, setIsLoadingMore] = useState(false);

  const loadMore = async () => {
    if (hasNextPage && endCursor) {
      setIsLoadingMore(true);
      const result = await fetchMore({ variables: { cursor: endCursor } });
      setIsLoadingMore(false);
      return nodeToArray(extract('BoardSection', result.data.node)?.boards);
    }
    return [];
  };

  return {
    boards,
    isLoading: loading,
    isLoadingMore,
    hasNextPage,
    loadMore,
    endCursor,
  };
};
