import { useMemo } from 'react';

import productTourInsightImageStep2 from 'src/assets/product-tour-feedback-step-2.svg';
import productTourInsightImageStep4 from 'src/assets/product-tour-feedback-step-4.svg';
import productTourInsightImageStep1 from 'src/assets/product-tour-insight-step-1.svg';
import productTourInsightImageStep3 from 'src/assets/product-tour-insight-step-3.svg';
import productTourInsightImageStep5 from 'src/assets/product-tour-insight-step-5.svg';
import { ProductTourModalContent } from 'src/components/ProductTourModalContent';
import { useIsInsightTourActive, useInsightTour } from 'src/hooks/useInsightTour';

export const InsightProductTour = () => {
  const { isActive } = useIsInsightTourActive();
  return isActive ? <OnboardingStarted /> : null;
};

const OnboardingStarted = () => {
  const { close } = useInsightTour();

  /* eslint-disable max-len */
  const slides = useMemo(() => [
    {
      id: 0,
      title: 'Extract quotes from your feedback',
      description: 'Quotes consist of customer quotes extracted from feedback docs and linked to requests. Feedback docs can contain multiple quotes. Each quote is linked to a single request.',
      image: (
        <img
          src={productTourInsightImageStep1}
          alt=""
          height={145}
          draggable="false"
        />
      ),
    },
    {
      id: 1,
      title: 'Create quote from feedback',
      description: 'Open a feedback doc, highlight pieces of content and turn them into quotes. Quotes take the format of customer quotes that you can directly link to requests.',
      image: (
        <img
          src={productTourInsightImageStep2}
          alt=""
          height={145}
          draggable="false"
        />
      ),
    },
    {
      id: 2,
      title: 'Add quote to a request',
      description: 'Open a request, click on “Add insight” in the right panel, pick a customer and add a quote. The corresponding feedback doc is automatically created and marked as processed in the background.',
      image: (
        <img
          src={productTourInsightImageStep3}
          alt=""
          height={155}
          draggable="false"
        />
      ),
    },
    {
      id: 3,
      title: 'Create quote views',
      description: 'Add as many quote views as you want so you can explore quotes in your own way. Add filters, groups & subgroups. Quotes are displayed as quotes. These views are ideal for customer voice reports.',
      image: (
        <img
          src={productTourInsightImageStep4}
          alt=""
          height={145}
          draggable="false"
        />
      ),
    },
    {
      id: 4,
      title: 'Insight status automations',
      description: 'Insight statuses are automated based on the requests they’re linked to. When the status of a request is updated, the status of all of its quotes is automatically updated too.',
      image: (
        <img
          src={productTourInsightImageStep5}
          alt=""
          height={145}
          draggable="false"
        />
      ),
    },
  ], []);
  /* eslint-enable max-len */

  return (
    <ProductTourModalContent
      slides={slides}
      stop={close}
    />
  );
};
