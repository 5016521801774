import { mobile } from '@cycle-app/utilities';
import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

import { headerLight } from '../../theme/typo';
import { colorUtils } from '../../utils/cssFunctions.util';
import { boxShadowZ5 } from '../../utils/styles.util';
import { ShyScrollbarCss } from '../ShyScrollbar/ShyScrollbar.styles';

export type ModalPlacement = 'top' | 'center' | 'bottom-right' | 'right';

const getAlignItems = (placement: ModalPlacement) => {
  switch (placement) {
    case 'right':
      return 'flex-start';
    case 'bottom-right':
      return 'flex-end';
    case 'top':
      return 'flex-start';
    case 'center':
    default:
      return 'center';
  }
};

const getJustifyCenter = (placement: ModalPlacement) => {
  switch (placement) {
    case 'right':
    case 'bottom-right':
      return 'flex-end';
    case 'top':
    case 'center':
    default:
      return 'center';
  }
};

const getPadding = (placement: ModalPlacement) => {
  switch (placement) {
    case 'right':
    case 'bottom-right':
      return '16px';
    case 'top':
      return '10vh 0 0 0';
    case 'center':
    default:
      return '0';
  }
};

export const Container = styled.div<{ placement: ModalPlacement; zIndex: number }>`
  z-index: ${p => p.zIndex};
  position: relative;

  width: 100%;
  height: 100%;

  display: flex;
  align-items: ${p => getAlignItems(p.placement)};
  justify-content: ${p => getJustifyCenter(p.placement)};
  padding: ${p => getPadding(p.placement)};
`;

export const Mask = styled(motion.div) <{ $visible: boolean }>`
  position: fixed;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  ${p => !p.$visible && 'opacity: 0;'}
  background-color: ${p => colorUtils.transparentize(p.theme.colors.modal.mask.bg, 0.5)};
`;

interface ContentProps {
  $naked: boolean;
  $zIndex: number;
  $noMask: boolean;
  $isFullOnMobile: boolean;
}
export const Content = styled(motion.div) <ContentProps>`
  border: 1px solid transparent;
  z-index: ${p => p.$zIndex};
  position: relative;

  max-width: calc(100% - 60px);
  max-height: calc(100% - 60px);

  ${p => !p.$naked && css`
    ${ShyScrollbarCss};
    border-color: ${p.theme.colors.modal.border};
    padding: 20px;
    border-radius: 12px;
    background-color: ${p.theme.colors.modal.content.bg};
    color: ${p.theme.colors.text.primary};
    ${boxShadowZ5};
  `};

  ${p => p.$noMask && css`
    background-color: ${p.theme.colors.modal.content.bgNoMask};
  `};

  ${mobile} {
    max-width: ${p => (p.$isFullOnMobile ? '100%' : 'calc(100% - 32px)')};
    max-height: ${p => (p.$isFullOnMobile ? '100%' : 'calc(100% - 32px)')};
    ${p => p.$isFullOnMobile && css`
      width: 100%;
      height: 100%;
    `}
    border: none;
  }
`;

export const ModalTitle = styled.h1`
  margin: 0;
  ${headerLight};
`;
