import { ButtonHTMLAttributes, ReactNode, forwardRef, ComponentProps } from 'react';

import {
  Container, Variant, Size, SpinnerContainer, ButtonCss, ContainerProps, Children,
} from './Button.styles';
import { Spinner } from '../../Spinner/Spinner.styles';
import { TooltipLegacy, TooltipPlacement } from '../../TooltipLegacy/Tooltip';

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  size?: Size;
  variant?: Variant;
  iconStart?: ReactNode;
  iconEnd?: ReactNode;
  tooltip?: ReactNode;
  tooltipPlacement?: TooltipPlacement;
  tooltipProps?: ComponentProps<typeof TooltipLegacy>;
  useCycleColor?: boolean;
  isLoading?: boolean;
  isGrouped?: boolean;
  forceFocus?: boolean;
  active?: boolean;
  full?: boolean;
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(({
  className = '',
  variant = 'primary',
  size = 'S',
  isLoading,
  isGrouped,
  iconStart,
  iconEnd,
  children,
  useCycleColor = true,
  tooltip,
  tooltipPlacement,
  tooltipProps,
  forceFocus,
  active,
  type = 'button',
  full,
  ...buttonProps
}, ref) => {
  const button = (
    <Container
      ref={ref}
      {...buttonProps}
      className={`${className} ${forceFocus ? 'force-focus' : ''}`}
      $variant={variant}
      $size={size}
      $isLoading={isLoading}
      $isGrouped={isGrouped}
      $iconOnly={!children}
      $useCycleColor={useCycleColor}
      $active={active}
      type={type}
      $full={full}
    >
      {isLoading && (
        <SpinnerContainer>
          <Spinner className="spinner" />
        </SpinnerContainer>
      )}

      {iconStart}
      {children && (
        <Children $hasContentStart={!!iconStart || !!iconEnd}>
          {children}
        </Children>
      )}
      {iconEnd}
    </Container>
  );

  return tooltip || tooltipProps
    ? (
      <TooltipLegacy
        content={tooltip}
        placement={tooltipPlacement}
        disabled={isLoading}
        withPortal
        {...tooltipProps}
      >
        {button}
      </TooltipLegacy>
    )
    : button;
});

export type ButtonVariant = Variant;
export type ButtonSize = Size;
export type ButtonContainerProps = ContainerProps;

export { ButtonCss };
