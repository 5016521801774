import { typo, AvatarLegacy } from '@cycle-app/ui';
import styled, { css } from 'styled-components';

import { Container as CommentMenuContainer } from '../DocCommentMenu/DocCommentMenu.styles';

export const CommentContainer = styled.div<{ sending?: boolean }>`

  &:hover, &.force-hover {
    background-color: ${p => p.theme.colors.background.hoverSoft};
    ${CommentMenuContainer} {
      opacity: 1;
    }
  }

  ${p => p.sending && css`
    &:hover, &.force-hover {
      ${CommentMenuContainer} {
        opacity: 0;
      }
    }
  `};
`;

export const Comment = styled.div`
  padding: 8px 16px;
  display: flex;
  gap: 8px;
  &:last-child {
    padding-top: 16px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: hidden;
`;

export const UserAvatar = styled(AvatarLegacy)`
  margin-top: 4px;
`;

export const Info = styled.div`
  display: flex;
  align-items: baseline;
  gap: 8px;

  > strong {
    ${typo.body500};
  }
  > small {
    ${typo.tiny400};
    color: ${p => p.theme.colors.text.disabled};
  }
`;
