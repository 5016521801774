import { ThemeType } from '@cycle-app/graphql-codegen';
import { useEffect } from 'react';

import { useMaybeMeV2 } from 'src/hooks/api/useMe';
import { useGetThemeConfig } from 'src/reactives/theme.reactive';
import { updatePlainCustomerDetails, updatePlainWidget } from 'src/services/plain';

export const useUpdatePlainTheme = () => {
  const { colorTheme } = useGetThemeConfig();
  useEffect(() => {
    void updatePlainWidget({
      theme: colorTheme === ThemeType.Nightfall ? 'dark' : 'light',
    });
  }, [colorTheme]);
};

export const useUpdatePlainCustomer = () => {
  const { me } = useMaybeMeV2();
  useEffect(() => {
    if (!me?.id) return;
    void updatePlainCustomerDetails({
      fullName: me.firstName && me.lastName ? `${me.firstName} ${me.lastName}` : undefined,
      shortName: me.firstName,
    });
  }, [me?.id, me?.firstName, me?.lastName]);
};
