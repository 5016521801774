import { Input, Icon } from '@cycle-app/ui';
import { isUrl } from '@cycle-app/utilities';
import { twJoin } from 'tailwind-merge';
import { Placement } from 'tippy.js';

import { ChangelogDropdown, ChangelogDropdownContent } from '../shared/ChangelogDropdown';
import { useChangelogBuilderForm } from '../useChangelogBuilderForm';

export function ChangelogBuilderContentSubscription({ dropdownPlacement = 'bottom' }: { dropdownPlacement?: Placement }) {
  const {
    watch, setValue,
  } = useChangelogBuilderForm();

  const subscribeToggled = watch('subscribeToggled');
  const subscribeLabel = watch('subscribeLabel');

  const handleEnableSubscriptionLabel = () => {
    setValue('subscribeToggled', true, { shouldDirty: true });
  };

  return (
    <ChangelogDropdown
      hoverStateClassName="-m-1 p-1 rounded-lg"
      content={({ hide }) => <SubscriptionPopover hide={hide} />}
      onClick={handleEnableSubscriptionLabel}
      placement={dropdownPlacement}
    >
      <div
        className={twJoin(
          'flex items-center gap-2 text-size-inherit font-normal',
          subscribeToggled ? 'text-(--changelog-link-color)' : 'text-secondary',
        )}
      >
        {subscribeToggled ? subscribeLabel : (
          <>
            <Icon
              name="plus"
              className="size-3"
            />
            Subscription system
          </>
        )}
      </div>
    </ChangelogDropdown>
  );
}

function SubscriptionPopover({ hide }: { hide: VoidFunction }) {
  const {
    register, setValue, formState: { errors },
  } = useChangelogBuilderForm();

  const handleDisableSubscriptionSystem = () => {
    setValue('subscribeToggled', false, { shouldDirty: true });
    hide();
  };

  return (
    <ChangelogDropdownContent
      title="Subscription system"
      hide={hide}
    >
      <Input
        label="Title"
        placeholder="Subscribe to changelog"
        error={errors.subscribeLabel?.message}
        {...register('subscribeLabel', {
          validate: (value) => !!value.trim() || 'Please enter a title',
        })}
      />

      <p className="mt-6 text-primary">
        Use a webhook to fetch email addresses of people who subscribe.
      </p>

      <a
        href="https://docs.cycle.app/the-graphql-api/webhooks#changelog_subscribed"
        target="_blank"
        rel="noreferrer"
        className="text-blue-600 transition-colors hover:text-blue-500 dark:text-blue-400 dark:hover:text-blue-300"
      >
        More information
      </a>

      <Input
        className="mt-4"
        label="Webhook URL"
        placeholder="https://my-webhook-url.com"
        error={errors.subscribeWebhook?.url?.message}
        {...register('subscribeWebhook.url', {
          validate: (value) => {
            if (!isUrl(value)) {
              return 'Please enter a valid URL';
            }
            return undefined;
          },
        })}
        type="url"
      />

      <button
        className="btn-tertiary btn-lg mt-4 w-full"
        onClick={handleDisableSubscriptionSystem}
      >
        Remove subscription system
      </button>
    </ChangelogDropdownContent>
  );
}
