import { AvatarLegacy, typo, truncate, Tag } from '@cycle-app/ui';
import styled, { css } from 'styled-components';

export const DocPanelAvatar = styled(AvatarLegacy)`
  margin: -1px;
  transform: scale(0.8);
`;

export const DocItemAvatar = styled(AvatarLegacy)`
  margin: -1px;
`;

type ContainerProps = {
  $context?: 'doc-item' | 'doc-panel';
  $forceFocus: boolean;
  $inheritCursor?: boolean;
  $isDisabled?: boolean;
  $showAssigneeName: boolean;
};

export const Container = styled.div<ContainerProps>`
  display: flex;
  align-items: center;
  white-space: nowrap;
  gap: 6px;
  cursor: pointer;
  ${p => p.$isDisabled && p.$context !== 'doc-item' && !p.$inheritCursor && css`
    cursor: not-allowed;
  `}
  ${p => p.$inheritCursor && css`cursor: inherit;`}

  ${p => p.$showAssigneeName && css`
    border-radius: 4px;
    padding: 2px 4px;

    &:hover {
      background-color: ${p.$isDisabled ? 'transparent' : p.theme.colors.actionButton.bgHover};
    }
    ${p.$forceFocus && `background-color: ${p.theme.colors.actionButton.bgHover};`}
  `}
`;

interface ActionButtonCursorProps {
  $isDisabled?: boolean;
  $showAssigneeName?: boolean;
  $context?: 'doc-item' | 'doc-panel';
}

export const ActionButtonCursor = styled.span<ActionButtonCursorProps>`
  ${p => p.$showAssigneeName && p.$isDisabled && p.$context !== 'doc-item' && css`
    cursor: not-allowed;
  `}
`;

export const Name = styled.span`
  ${truncate}
  ${typo.caption400}
`;

export const NoName = styled(Name)`
  ${typo.caption400}
`;

export const StyledTag = styled(Tag).attrs({
  color: 'grey',
})<{
  $isDisabled?: boolean;
  $isEmpty: boolean;
}>`
  ${p => p.$isDisabled && `
    cursor: not-allowed;
  `}

  ${p => p.$isEmpty && `
    color: ${p.theme.isDark ? p.theme.colors.text.disabled : p.theme.colors.text.secondary};
  `};
`;
