import { twJoin } from 'tailwind-merge';

import { LAYOUT_CONTAINER_CLASS, LAYOUT_GRID_CLASS } from './constants/layout';
import { ChangelogBuilderContentDates } from './content/ChangelogBuilderContentDates';
import { ChangelogBuilderContentDivider } from './content/ChangelogBuilderContentDivider';
import { ChangelogBuilderContentHero } from './content/ChangelogBuilderContentHero';
import { ChangelogBuilderContentRelease } from './content/ChangelogBuilderContentRelease';
import { ChangelogBuilderContentReleaseTitle } from './content/ChangelogBuilderContentReleaseTitle';
import { ChangelogBuilderContentTags } from './content/ChangelogBuilderContentTags';

export function ChangelogBuilderContent() {
  return (
    <div
      // eslint-disable-next-line max-len
      className="-mx-px flex-1 overflow-y-auto rounded-t-xl border-x border-t border-grey-200 bg-(--changelog-background-color) pb-80 shadow-sm [font-family:var(--changelog-typography)] dark:border-grey-950"
    >
      <ChangelogBuilderContentHero  />

      <div className={twJoin(LAYOUT_CONTAINER_CLASS, LAYOUT_GRID_CLASS, 'mt-12')}>
        <div className="col-start-2 pr-4">
          <ChangelogBuilderContentDates />
        </div>

        <div className="flex flex-col gap-8">
          <ChangelogBuilderContentReleaseTitle />
          <ChangelogBuilderContentTags />
          <ChangelogBuilderContentRelease />
          <ChangelogBuilderContentDivider />
        </div>
      </div>
    </div>
  );
}
