import { Color, CustomerFragment, Role } from '@cycle-app/graphql-codegen/generated';
import { MouseEventHandler, HTMLAttributes, useState } from 'react';

import {
  Container, ImageContainer, StyledCompanyLogo, CompanyContainer, Size, LetterAvatar, DefaultAvatar, ImgAvatar,
} from './CustomerAvatar.styles';
import { TooltipLegacy, TooltipLegacyProps } from '../../TooltipLegacy/Tooltip';

export interface CustomerAvatarProps extends Omit<HTMLAttributes<unknown>, 'title'> {
  className?: string;
  customer: Pick<CustomerFragment, 'id' | 'name' | 'email' | 'company' | 'avatar'>;
  hideCompany?: boolean;
  size?: Size;
  tooltip?: TooltipLegacyProps['content'];
  title?: TooltipLegacyProps['title'];
  src?: string;
  onCompanyClick?: MouseEventHandler;
  companyTooltip?: TooltipLegacyProps['content'];
  companyTitle?: TooltipLegacyProps['title'];
  isCompanyEditable?: boolean;
  showCompanyAvatar?: boolean;
  companyInput?: JSX.Element;
  isEditable?: boolean;
}

export const CustomerAvatar = ({
  className,
  customer,
  size = 'M',
  tooltip,
  title,
  src,
  onCompanyClick,
  companyTooltip,
  isCompanyEditable,
  companyInput,
  companyTitle,
  hideCompany,
  showCompanyAvatar = false,
  isEditable,
  ...props
}: CustomerAvatarProps) => {
  const showCompany = !hideCompany && (isCompanyEditable || !!customer.company);

  const avatar = (
    <Avatar
      src={src}
      customer={customer}
      showCompanyAvatar={showCompanyAvatar}
    />
  );

  return (
    <Container
      className={className}
      $size={size}
      $isEditable={isEditable}
      $showCompany={showCompany}
      {...props}
    >
      <ImageContainer $showCompany={showCompany}>
        {tooltip ? (
          <TooltipLegacy
            placement="top"
            content={tooltip}
            title={title}
            withPortal
          >
            {avatar}
          </TooltipLegacy>
        ) : avatar}

        {showCompany && (
          <CompanyContainer onClick={onCompanyClick}>
            <TooltipLegacy
              placement="top"
              content={companyTooltip ?? customer.company?.name ?? 'Add company'}
              title={companyTitle}
              withPortal
              withWrapper={false}
            >
              {isCompanyEditable && !customer.company
                ? (
                  <div>
                    {companyInput}
                  </div>
                )
                : <StyledCompanyLogo company={customer.company} />}
            </TooltipLegacy>
          </CompanyContainer>
        )}
      </ImageContainer>
    </Container>
  );
};

const Avatar = ({
  customer, src, showCompanyAvatar,
}: Pick<CustomerAvatarProps, 'customer' | 'src' | 'showCompanyAvatar'>) => {
  const [imgSrc, setImgSrc] = useState(src ?? customer.avatar);

  const user = {
    id: customer.id,
    firstName: showCompanyAvatar ? customer.company?.name || 'c' : customer.name,
    email: customer.email || '',
    color: Color.A,
    role: Role.User,
  };

  if (imgSrc) {
    return (
      <ImgAvatar
        src={imgSrc}
        onError={() => {
          setImgSrc(null);
        }}
      />
    );
  }

  if (customer.name?.trim().length) return (
    <LetterAvatar
      size={20}
      user={user}
      src={src}
    />
  );

  return <DefaultAvatar />;
};
