import { Tag, TagProps, Icon } from '@cycle-app/ui';
import { nodeToArray } from '@cycle-app/utilities';
import sortBy from 'lodash/sortBy';
import { MouseEvent } from 'react';
import { twMerge } from 'tailwind-merge';

import { ProductAreasHoverCard } from 'src/components/ProductAreasHoverCard/ProductAreasHoverCard';

import { useDoc } from '../../hooks';
import { useProductAreaAttribute } from '../../hooks/api/useAttributes';
import { useDocProductAreaUpdate } from '../../hooks/doc/useDocProductAreaUpdate';
import { useProductAreasCategories } from '../../hooks/product/useProductAreasCategories';
import { useCompatibility } from '../../hooks/useCompatibility';
import { getDocType } from '../../reactives/docTypes.reactive';
import { Layer } from '../../types/layers.types';
import { isFeedback } from '../../utils/docType.util';
import { ToggleDropdown } from '../DropdownLayer';
import { ProductAreasManager } from '../ProductAreasManager';

type Props = {
  docId: string;
  isDisabled?: boolean;
  isCreateEnabled?: boolean;
  layer?: Layer;
  className?: string;
};

export const DocProductAreas = ({
  docId, isDisabled, layer, className,
}: Props) => {
  const { doc } = useDoc(docId);
  const {
    compatibleProductAreas, isProductAreaRequiredToBeVisible,
  } = useCompatibility();
  const docProductAreas = nodeToArray(doc?.productAreas);
  const isMulti = isFeedback(doc?.doctype);
  const productAreaUpdate = useDocProductAreaUpdate();
  const {
    areas, isEnabled,
  } = useProductAreasCategories();

  const selectValues = sortBy(docProductAreas, 'value')
    .filter(selectedValue => !!areas.some(a => a.id === selectedValue.id));

  const {
    attributeNamePlural, attributeName,
  } = useProductAreaAttribute();

  const value = selectValues.length > 1 ? `${selectValues.length} ${attributeNamePlural}` : selectValues[0]?.value;

  if (!isEnabled) return null;

  return (
    <div
      className={twMerge('gap-2 items-start flex-wrap overflow-hidden h-5', className)}
    >
      <ToggleDropdown
        layer={layer}
        placement="bottom-start"
        disabled={isDisabled}
        width={268}
        button={buttonProps => (
          <>
            {!selectValues.length && (
              <ProductAreaTag
                docId={docId}
                value={`Select ${isMulti ? attributeNamePlural : attributeName}`}
                className="text-disabled!"
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  buttonProps.toggle();
                }}
                icon={(
                  <Icon
                    name="tri-shapes"
                    className="text-grey-350 flex-none dark:text-grey-550"
                  />
                )}
              />
            )}
            {value && (
              <ProductAreaTag
                docId={docId}
                value={value}
                isDisabled={isDisabled}
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  buttonProps.toggle();
                }}
                icon={selectValues.length > 1 ? (
                  <Icon
                    name="color/playground"
                    className="flex-none"
                  />
                ) : (
                  <Icon
                    name="tri-shapes"
                    className="text-grey-350 flex-none dark:text-grey-550"
                  />
                )}
              />
            )}
          </>
        )}
        content={contentProps => (
          <ProductAreasManager
            isRequired={isProductAreaRequiredToBeVisible}
            compatibleIds={compatibleProductAreas.map(area => area.id)}
            docTypeName={getDocType(doc?.doctype.id)?.name || ''}
            hideIncompatibleValues={!!doc?.isDraft}
            isMulti={isMulti}
            showWarning={!doc?.isDraft}
            onClearValue={async (clearData) => {
              if (!isMulti) contentProps.hide();
              // Clear is displayed for single select only as there is no bulk mutation yet.
              const selectedArea = docProductAreas?.[0];
              if (selectedArea) {
                await productAreaUpdate.remove({
                  docId,
                  productAreaId: selectedArea.id,
                  isIncompatible: !doc?.isDraft && clearData.isIncompatible,
                });
              }
            }}
            onSelect={async selectData => {
              if (!isMulti) contentProps.hide();
              await productAreaUpdate.update({
                docId,
                productAreaId: selectData.productAreaId,
                isMulti: selectData.isMulti,
                isIncompatible: !doc?.isDraft && selectData.isIncompatible,
              });
            }}
            onUnselect={unselectData => productAreaUpdate.remove({
              docId,
              productAreaId: unselectData.productAreaId,
              isIncompatible: !doc?.isDraft && unselectData.isIncompatible,
            })}
            selectedValues={docProductAreas.map(area => area.id)}
          />
        )}
      />
    </div>
  );
};

type ProductAreaTag = Omit<TagProps, 'onClick'> & {
  docId?: string;
  isDisabled?: boolean;
  value: string;
  onClick?: (e: MouseEvent) => void;
};

export const ProductAreaTag = ({
  docId,
  isDisabled = false,
  value,
  onClick,
  ...props
}: ProductAreaTag) => {
  const tag = (
    <Tag
      onClick={isDisabled ? undefined : onClick}
      limitSize
      {...props}
    >
      {value}
    </Tag>
  );

  if (!docId) return tag;

  return (
    <ProductAreasHoverCard
      docId={docId}
      onClickChange={isDisabled ? undefined : onClick}
    >
      {tag}
    </ProductAreasHoverCard>
  );
};
