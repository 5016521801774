import { Icon } from '@cycle-app/ui';

import { IconsGroup } from './CallRecordingIcons.styles';

export const CallRecordingIcons = () => (
  <IconsGroup>
    <Icon
      name="brand/zoom"
      className="size-4"
    />
    <Icon
      name="brand/google-meet"
      className="size-4"
    />
    <Icon
      name="brand/microsoft-teams"
      className="size-4"
    />
  </IconsGroup>
);
