import { useFragment } from '@apollo/client';
import { ProductDoctypesWithHierarchyFragmentDoc, ProductMinimalFragmentDoc, ProductSourceCategoriesFragmentDoc, PromptFragmentDoc, SourceCategoryFragmentDoc } from '@cycle-app/graphql-codegen';
import { nodeToArray } from '@cycle-app/utilities';

import { getProductId, useProductId } from 'src/hooks/useProductId';
import client from 'src/services/apollo/client';

export const useProductMinimalFragment = (productId?: string) => {
  const currentProductId = useProductId();
  const {
    data, complete,
  } = useFragment({
    fragment: ProductMinimalFragmentDoc,
    fragmentName: 'ProductMinimal',
    from: productId ?? currentProductId ?? '',
  });
  if (!complete) return null;
  return data;
};

export const getProductMinimalFragment = () => {
  const productId = getProductId();
  if (!productId) return null;
  return client.readFragment({
    fragment: ProductMinimalFragmentDoc,
    fragmentName: 'ProductMinimal',
    id: productId,
  });
};

export const usePromptFragment = (id: string | null | undefined) => {
  const {
    data, complete,
  } = useFragment({
    fragment: PromptFragmentDoc,
    fragmentName: 'Prompt',
    from: id ?? '',
  });
  return id && complete ? data : null;
};

export const useSourceCategoryFragment = (id: string | null | undefined) => {
  const {
    data, complete,
  } = useFragment({
    fragment: SourceCategoryFragmentDoc,
    fragmentName: 'SourceCategory',
    from: id ?? '',
  });
  return id && complete ? data : null;
};

export const useProductSourceCategoriesFragment = (productId: string | null | undefined) => {
  const {
    data, complete,
  } = useFragment({
    fragment: ProductSourceCategoriesFragmentDoc,
    fragmentName: 'ProductSourceCategories',
    from: productId ?? '',
  });
  if (!productId || !complete) return [];
  return nodeToArray(data.sourceCategories);
};

export const useProductDoctypesFragment = () => {
  const productId = useProductId();
  const {
    data, complete,
  } = useFragment({
    fragment: ProductDoctypesWithHierarchyFragmentDoc,
    fragmentName: 'ProductDoctypesWithHierarchy',
    from: productId ?? '',
  });
  if (!productId || !complete) return [];
  return nodeToArray(data.doctypes);
};

export const getProductDoctypesFragment = () => {
  const productId = getProductId();
  if (!productId) return [];
  const data = client.readFragment({
    fragment: ProductDoctypesWithHierarchyFragmentDoc,
    fragmentName: 'ProductDoctypesWithHierarchy',
    id: productId,
  });
  return nodeToArray(data?.doctypes);
};
