import { useFragment } from '@apollo/client';
import { ProductAreaCategoryFragmentDoc } from '@cycle-app/graphql-codegen';

export const useProductAreaCategoryFragment = (id: string | undefined) => {
  const {
    data, complete,
  } = useFragment({
    fragment: ProductAreaCategoryFragmentDoc,
    fragmentName: 'ProductAreaCategory',
    from: id ?? '',
  });
  return id && complete ? data : null;
};
