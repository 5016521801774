import { DocProductAreaBulkUpdateDocument, DocProductAreaRemoveDocument, DocProductAreaUpdateDocument } from '@cycle-app/graphql-codegen';
import { produce } from 'immer';

import { useVerifyAiCreatedQuote } from 'src/hooks/doc/useVerifyAiCreatedQuote';

import { RefConnection } from '../../types/apollo.types';
import { addToaster } from '../../utils/toasters.utils';
import { useProductAreaAttribute } from '../api/useAttributes';
import { useBoardRemoveDoc } from '../boards/useBoardRemoveDoc';
import useSafeMutation from '../useSafeMutation';
import { useAddDocToaster } from './useAddDocToaster';

export const useDocProductAreaUpdate = () => {
  const verifyAiCreatedQuote = useVerifyAiCreatedQuote();
  const [mutateUpdate, updateState] = useSafeMutation(DocProductAreaUpdateDocument, {
    onCompleted: (data) => verifyAiCreatedQuote(data.changeDocProductArea?.id),
  });
  const [mutateBulkUpdate, bulkUpdateState] = useSafeMutation(DocProductAreaBulkUpdateDocument, {
  });
  const [mutateDelete, deleteState] = useSafeMutation(DocProductAreaRemoveDocument, {
    onCompleted: (data) => verifyAiCreatedQuote(data.removeDocProductArea?.id),
  });
  const boardRemoveDoc = useBoardRemoveDoc();
  const addDocToaster = useAddDocToaster();
  const { attributeName } = useProductAreaAttribute();

  const bulkUpdate = ({
    docIds, productAreaId, isIncompatible,
  }: { docIds: string[]; productAreaId: string; isIncompatible: boolean }) => {
    return mutateBulkUpdate({
      variables: {
        docIds,
        productAreaId,
      },
      optimisticResponse: {
        changeDocsProductArea: true,
      },
      onCompleted: (data) => {
        if (data.changeDocsProductArea && isIncompatible) {
          docIds.forEach(docId => {
            boardRemoveDoc.remove(docId);
          });
          addToaster({
            title: `${attributeName} updated`,
            message: () => (
              <span>
                {'Yours docs'}
                {' '}
                were successfully updated and left the view
              </span>
            ),
          });
        }
      },
      update: (cache, { data }) => {
        if (data?.changeDocsProductArea) {
          docIds.forEach(docId => {
            cache.modify({
              id: docId,
              fields: {
                // @ts-ignore -- TOFIX later
                productAreas: (value: RefConnection, { toReference }) => {
                  const node = toReference(productAreaId);
                  return node ? produce(value, draft => {
                    const edge = {
                      __typename: 'ProductAreaEdge',
                      node,
                    };
                    // Bulk updates are single.
                    draft.edges.splice(0, draft.edges.length, edge);
                  }) : value;
                },
              },
            });
          });
        }
      },
    });
  };

  const update = ({
    docId, productAreaId, isMulti, isIncompatible,
  }: { docId: string; productAreaId: string; isMulti: boolean; isIncompatible: boolean }) => {
    return mutateUpdate({
      variables: {
        docId,
        productAreaId,
      },
      optimisticResponse: {
        changeDocProductArea: {
          id: docId,
        },
      },
      onCompleted: (data) => {
        if (data.changeDocProductArea?.id && isIncompatible) {
          boardRemoveDoc.remove(docId);
          addDocToaster({
            docId,
            title: `${attributeName} updated`,
            message: tag => (
              <span>
                {tag ?? 'Your doc'}
                {' '}
                was successfully updated and left the view
              </span>
            ),
          });
        }
      },
      update: (cache, { data }) => {
        if (data?.changeDocProductArea?.id) {
          cache.modify({
            id: cache.identify(data.changeDocProductArea),
            fields: {
              // @ts-ignore -- TOFIX later
              productAreas: (value: RefConnection, { toReference }) => {
                const node = toReference(productAreaId);
                return node ? produce(value, draft => {
                  const edge = {
                    __typename: 'ProductAreaEdge',
                    node,
                  };
                  if (isMulti) {
                    draft.edges.push(edge);
                  } else {
                    draft.edges.splice(0, draft.edges.length, edge);
                  }
                }) : value;
              },
            },
          });
        }
      },
    });
  };

  const remove = ({
    docId, productAreaId, isIncompatible,
  }: { docId: string; productAreaId: string; isIncompatible: boolean }) => {
    return mutateDelete({
      variables: {
        docId,
        productAreaId,
      },
      optimisticResponse: {
        removeDocProductArea: {
          id: docId,
        },
      },
      onCompleted: (data) => {
        if (data.removeDocProductArea?.id && isIncompatible) {
          boardRemoveDoc.remove(docId);
          addDocToaster({
            docId,
            title: `${attributeName} updated`,
            message: tag => (
              <span>
                {tag ?? 'Your doc'}
                {' '}
                was successfully updated and left the view
              </span>
            ),
          });
        }
      },
      update: (cache, { data }) => {
        if (data?.removeDocProductArea?.id) {
          cache.modify({
            id: cache.identify(data.removeDocProductArea),
            fields: {
              // @ts-ignore -- TOFIX later
              productAreas: (value: RefConnection, { readField }) => {
                return produce(value, draft => {
                  draft.edges.splice(draft.edges.findIndex(edge => {
                    return readField<string>('id', edge.node) === productAreaId;
                  }), 1);
                });
              },
            },
          });
        }
      },
    });
  };

  return {
    update,
    remove,
    bulkUpdate,
    isUpdateLoading: updateState.loading,
    isDeleteLoading: deleteState.loading,
    isBulkUpdateLoading: bulkUpdateState.loading,
  };
};
