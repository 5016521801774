import { Input, Icon } from '@cycle-app/ui';
import { ERROR_CODE, toShortLocaleDateString } from '@cycle-app/utilities';
import { format, isValid, parse, isAfter, isBefore } from 'date-fns';
import { Link } from 'react-router-dom';
import { twJoin } from 'tailwind-merge';

import { ReleaseStatusIndicator } from 'src/components/ReleaseStatusIndicator';
import { ErrorMessage } from 'src/constants/errors.constants';
import { PageId } from 'src/constants/routing.constant';
import { useProductBase } from 'src/hooks';
import { useReleaseBaseFragment } from 'src/hooks/api/fragments/release';
import { ErrorMap, useEnhancedForm } from 'src/hooks/form/useEnhancedForm';
import { useCreateRelease } from 'src/hooks/releases/useCreateRelease';
import { useRelease } from 'src/hooks/releases/useRelease';
import { useUpdateRelease } from 'src/hooks/releases/useUpdateRelease';
import { getUrl } from 'src/utils/routing.utils';
import { addToaster } from 'src/utils/toasters.utils';

import { Form } from './ReleaseForm.styles';

type FormData = {
  date: string;
  title?: string;
};

const errorsMap: ErrorMap<FormData>[] = [
  {
    fieldName: 'date',
    code: ERROR_CODE.RELEASE_ALREADY_EXISTS,
    renderMessage: () => 'A release with this date already exists',
  },
  {
    fieldName: 'date',
    code: ERROR_CODE.RELEASE_WITH_DATE_ALREADY_EXISTS,
    renderMessage: () => 'A release with this date already exists',
  },
];

export const ReleaseForm = ({
  onReleaseCreated,
  showToaster = false,
  releaseId,
  hide,
}: {
  onReleaseCreated?: (releaseId: string) => void;
  showToaster?: boolean;
  releaseId?: string | null;
  hide: VoidFunction;
}) => {
  const product = useProductBase();
  const { release } = useRelease(releaseId);

  const {
    createRelease, isCreatingRelease,
  } = useCreateRelease(product?.id);

  const {
    updateRelease, isUpdatingRelease,
  } = useUpdateRelease();

  const defaultDate = release?.date ? format(new Date(release.date), 'yyyy-MM-dd') : undefined;

  const {
    handleSubmit, register, displayFieldsErrors, formState: {
      errors, isDirty, dirtyFields,
    },
  } = useEnhancedForm<FormData>({
    defaultValues: {
      date: defaultDate,
      title: release?.title ?? undefined,
    },
  });

  if (!product) return null;

  return (
    <Form
      onSubmit={handleSubmit(async values => {
        let result;

        if (releaseId) {
          result = await updateRelease({
            id: releaseId,
            date: dirtyFields.date ? values.date : undefined,
            title: dirtyFields.title ? values.title : undefined,
          });
        } else {
          result = await createRelease({
            productId: product?.id,
            ...values,
          });
          const createdReleaseId = result.data?.createRelease?.id;
          if (!createdReleaseId) return;
          onReleaseCreated?.(createdReleaseId);
          if (showToaster) {
            addToaster({
              message: props => (
                <ReleaseSuccessMessage
                  releaseId={createdReleaseId}
                  close={props.close}
                />
              ),
            });
          }
        }

        if (result.errors) {
          displayFieldsErrors(result.errors, errorsMap);
        } else {
          hide();
        }
      })}
    >
      <Input
        autoFocus
        type="date"
        label="Release date"
        error={errors.date?.message}
        {...register('date', {
          required: 'You must select a release date',
          validate: {
            valid: value => isValid(parse(value, 'yyyy-MM-dd', new Date())) || ErrorMessage.DATE_INVALID,
            min: value => isAfter(parse(value, 'yyyy-MM-dd', new Date()), new Date('1900')) || ErrorMessage.DATE_TOO_EARLY,
            max: value => isBefore(parse(value, 'yyyy-MM-dd', new Date()), new Date('3000')) || ErrorMessage.DATE_TOO_LATE,
          },
        })}
      />

      <Input
        label="Release title"
        error={errors.title?.message}
        {...register('title', {
          setValueAs: value => value.trim(),
        })}
      />

      <div className="flex items-center justify-end gap-2">
        <button
          className="btn-tertiary btn-lg"
          type="button"
          onClick={hide}
        >
          Cancel
        </button>
        <button
          type="submit"
          className={twJoin(
            'btn-primary btn-lg',
            (isCreatingRelease || isUpdatingRelease) && 'btn-loading',
          )}
          disabled={!!releaseId && !isDirty}
        >
          Save
        </button>
      </div>
    </Form>
  );
};

const ReleaseSuccessMessage = ({
  releaseId, close,
}: {
  releaseId: string;
  close: () => void;
}) => {
  const release = useReleaseBaseFragment(releaseId);
  if (!release) return null;
  const date = toShortLocaleDateString(release.date);
  return (
    <div className="space-y-2">
      <div className="flex items-center gap-2 text-primary">
        <Icon name="check" />
        <div className="font-medium">
          Release successfully created
        </div>
      </div>
      <Link
        className="btn btn-tertiary"
        to={getUrl(PageId.Release, { releaseId })}
        onClick={close}
      >
        <span className="text-body text-primary">
          <Icon
            name="release"
            className="align-text-top mr-1.5"
          />
          {date}
          <ReleaseStatusIndicator
            status={release.publicStatus}
            className="inline-block mx-2"
          />
          <span className="font-normal">
            {release.title}
          </span>
        </span>
      </Link>
    </div>
  );
};
