import { Tag, Emoji } from '@cycle-app/ui';

import { useDocInitialBaseFragment } from 'src/hooks/api/fragments/doc';
import { useDocDoctypeFragment } from 'src/hooks/api/fragments/doctype';
import { useProductMinimalFragment } from 'src/hooks/api/fragments/product';
import { copyDocFullUrl, getDocKey } from 'src/utils/doc.util';

export const DocTagKey = ({ docId }: { docId: string }) => {
  const product = useProductMinimalFragment();
  const doc = useDocInitialBaseFragment(docId);
  const doctype = useDocDoctypeFragment(docId);
  if (!doc) return null;
  return (
    <Tag
      color="grey"
      icon={doctype ? (
        <Emoji
          emoji={doctype.emoji}
          size={14}
        />
      ) : null}
      onClick={() => copyDocFullUrl(doc)}
      tooltip={{
        placement: 'top',
        title: doctype?.name ?? 'Request ID',
        content: 'Copy link',
        withPortal: true,
        withWrapper: false,
      }}
    >
      {getDocKey(product?.key, doc.publicId)}
    </Tag>
  );
};
