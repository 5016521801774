import { CustomerFragment, Profile } from '@cycle-app/graphql-codegen';
import { Tooltip, Icon } from '@cycle-app/ui';
import { FC } from 'react';

import { Container, Item } from './CustomerLinkedProfiles.styles';

interface Props {
  customer: CustomerFragment;
}

export const CustomerLinkedProfiles: FC<React.PropsWithChildren<Props>> = ({ customer }) => {
  return (
    <Container>
      {customer.profiles?.map(profile => profile && (
        <Item key={profile.__typename}>
          <Tooltip content={getTitle(profile)}>
            {renderContent(profile)}
          </Tooltip>
        </Item>
      ))}
    </Container>
  );

  function renderContent(profile: Profile) {
    if (profile.__typename === 'ProfileCycle') {
      return renderIcon(profile);
    }
    if (profile.__typename === 'ProfileSlack' || profile.__typename === 'ProfileIntercom' || profile.__typename === 'ProfileHubspot' || profile.__typename === 'ProfileZendesk' || profile.__typename === 'ProfileSalesforce') {
      return (
        <a
          target="_blank"
          rel="noreferrer"
          href={profile.url}
        >
          {renderIcon(profile)}
        </a>
      );
    }
    return null;
  }

  function getTitle(profile: Profile) {
    if (profile.__typename === 'ProfileCycle') {
      return 'Cycle';
    }
    if (profile.__typename === 'ProfileIntercom') {
      return 'Intercom';
    }
    if (profile.__typename === 'ProfileSlack') {
      return 'Slack';
    }
    if (profile.__typename === 'ProfileHubspot') {
      return 'Hubspot';
    }
    if (profile.__typename === 'ProfileZendesk') {
      return 'Zendesk';
    }
    if (profile.__typename === 'ProfileSalesforce') {
      return 'Salesforce';
    }
    return '';
  }

  function renderIcon(profile: Profile) {
    if (profile.__typename === 'ProfileCycle') {
      return (
        <Icon
          name="brand/cycle"
          className="size-4 text-cycle"
        />
      );
    }
    if (profile.__typename === 'ProfileIntercom') {
      return (
        <Icon
          name="brand/intercom"
          className="size-4"
        />
      );
    }
    if (profile.__typename === 'ProfileSlack') {
      return (
        <Icon
          name="brand/slack"
          className="size-4"
        />
      );
    }
    if (profile.__typename === 'ProfileHubspot') {
      return (
        <Icon
          name="brand/hubspot"
          className="size-4"
        />
      );
    }
    if (profile.__typename === 'ProfileZendesk') {
      return (
        <Icon
          name="brand/zendesk"
          className="size-4"
        />
      );
    }
    if (profile.__typename === 'ProfileSalesforce') {
      return (
        <Icon
          name="brand/salesforce"
          className="size-4"
        />
      );
    }
    return null;
  }
};
