import { Icon } from '@cycle-app/ui';
import { AnimatePresence } from 'framer-motion';
import { useAtom, useAtomValue } from 'jotai';
import { PropsWithChildren, useRef } from 'react';

import { variantsContent } from 'src/components/DialogModal/DialogModal.motion';
import { EditorInstanceProvider } from 'src/components/Editor/Editors/EditorInstanceProvider';
import EditorSkeleton from 'src/components/EditorSkeleton/EditorSkeleton';
import { ErrorBoundary } from 'src/components/ErrorBoundary';
import { ErrorPage, Size } from 'src/components/ErrorPage';
import { PortalModal } from 'src/components/PortalModal';
import { useDocInitialBaseFragment } from 'src/hooks/api/fragments/doc';
import { Layer } from 'src/types/layers.types';

import { NewRequestAttributes } from './NewRequestAttributes';
import { NewRequestEditor } from './NewRequestEditor';
import { draftRequestIdAtom, editorLoadedAtom } from './newRequestModal.atoms';
import { PublishButton } from './NewRequestPublish';
import { NewRequestTitle } from './NewRequestTitle';
import { ScrollableContent } from './ScrollableContent';
import { SelectRequestType } from './SelectRequestType';

type NewRequestModalProps = {
  open: boolean;
  onOpenChange: (open: boolean) => void;
};

export const NewRequestModal = ({
  open, onOpenChange,
}: NewRequestModalProps) => {
  const resolveRef = useRef<(result: boolean) => void>();
  const promiseRef = useRef<Promise<void>>();

  // Close the modal and return a promise that resolves when the close animation is done
  const close = async () => {
    promiseRef.current = new Promise((resolve, reject) => {
      resolveRef.current = (closed: boolean) => closed ? resolve() : reject();
    });
    onOpenChange(false);
    return promiseRef.current;
  };

  return (
    <AnimatePresence>
      {open && (
        <PortalModal
          layer={Layer.DocPanel}
          hide={close}
          motionVariants={variantsContent}
          className="w-2xl min-h-[450px] flex flex-col p-0! dark:bg-grey-950"
          onAnimationComplete={definition => {
            resolveRef.current?.(definition === 'exit');
          }}
        >
          <ContentErrorBoundary>
            <EditorInstanceProvider>
              <ModalContent close={close} />
            </EditorInstanceProvider>
          </ContentErrorBoundary>

          <button
            className="btn-tertiary btn-square absolute top-4 right-4"
            aria-label="Close"
            onClick={close}
          >
            <Icon name="close" />
          </button>
        </PortalModal>
      )}
    </AnimatePresence>
  );
};

const ContentErrorBoundary = ({ children }: PropsWithChildren) => (
  <ErrorBoundary
    fallback={(
      <ErrorPage
        size={Size.SMALL}
        className="flex-1"
      />
    )}
  >
    {children}
  </ErrorBoundary>
);

type ModalContentProps =  {
  close: () => Promise<void>;
};

const ModalContent = ({ close }: ModalContentProps) => {
  const loaded = useAtomValue(editorLoadedAtom);
  const [draftRequestId, setDraftRequestId] = useAtom(draftRequestIdAtom);
  const docInitialBase = useDocInitialBaseFragment(draftRequestId);

  const disabled = !loaded || !docInitialBase;

  const discard = async () => {
    await close();
    setDraftRequestId(null);
  };

  return (
    <>
      <header className="py-6 px-[38px] bg-inherit">
        <SelectRequestType disabled={disabled} />
      </header>

      <ScrollableContent>
        <div className="px-[38px] space-y-4">
          {!loaded && <EditorSkeleton />}
          {loaded && <NewRequestTitle />}
          {loaded && <NewRequestAttributes />}
        </div>

        <NewRequestEditor key={draftRequestId} />
      </ScrollableContent>

      <footer className="flex justify-end gap-4 p-6">
        <button
          className="btn-tertiary btn-lg"
          disabled={disabled}
          onClick={discard}
        >
          Discard
        </button>

        <PublishButton
          onPublish={discard}
          disabled={disabled}
        />
      </footer>
    </>
  );
};
