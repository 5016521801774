
import { useApolloClient } from '@apollo/client';
import { BoardsDocument, BoardWithDoctypeFilterFragment } from '@cycle-app/graphql-codegen';
import { produce } from 'immer';

import { useMoveBoardInSectionsList } from 'src/hooks/api/mutations/useBoardSectionsMutations';

import { useBoardSections } from '../useBoardSections';

export const useMoveFeedbackViewToSectionEnd = () => {
  const { feedbackSectionId } = useBoardSections();
  const { moveBoardInSectionList } = useMoveBoardInSectionsList();
  const client = useApolloClient();

  return async (board: BoardWithDoctypeFilterFragment) => {
    if (!feedbackSectionId) return null;

    const result = await moveBoardInSectionList({
      boardId: board.id,
      fromSectionId: feedbackSectionId,
      toSectionId: feedbackSectionId,
      position: {
        after: '',
      },
    });

    if (board.section?.id) {
      client.cache.updateQuery({
        query: BoardsDocument,
        variables: {
          id: board.section.id,
          cursor: '',
        },
      }, prev => produce(prev, draft => {
        if (draft?.node?.__typename === 'BoardSection' && !draft.node.boards.pageInfo.hasNextPage) {
          draft.node.boards.edges.push({ node: board });
        }
      }));
    }

    return result;
  };
};

export const useMoveRoadmapViewToSectionEnd = () => {
  const { roadmapSectionId } = useBoardSections();
  const { moveBoardInSectionList } = useMoveBoardInSectionsList();
  const client = useApolloClient();

  return async (board: BoardWithDoctypeFilterFragment) => {
    if (!roadmapSectionId) return null;
    const result = await moveBoardInSectionList({
      boardId: board.id,
      fromSectionId: roadmapSectionId,
      toSectionId: roadmapSectionId,
      position: {
        after: '',
      },
    });

    if (board.section?.id) {
      client.cache.updateQuery({
        query: BoardsDocument,
        variables: {
          id: board.section.id,
          cursor: '',
        },
      }, prev => produce(prev, draft => {
        if (draft?.node?.__typename === 'BoardSection' && !draft.node.boards.pageInfo.hasNextPage) {
          draft.node.boards.edges.push({ node: board });
        }
      }));
    }

    return result;
  };
};
