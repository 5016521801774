import { useState } from 'react';

import { AskConversation } from './AskConversation';
import { AskDocHistory } from './AskDocHistory';
import { AskHeader } from './AskHeader';
import { AskNewConversation } from './AskNewConversation';
import { AskContent, closeAskPanel, setDocPanel } from '../../reactives/docRightPanel.reactive';

export const AskDoc = ({
  docId, content,
}: { docId: string; content: AskContent }) => {
  const [conversationId, setConversationId] = useState('');
  return (
    <div className="h-full flex flex-col">
      <AskHeader
        content={content}
        onNavigate={to => {
          if (to === 'new') setConversationId('');
          setDocPanel({
            askContent: to === 'new' || to === 'back'
              ? AskContent.Conversation
              : AskContent.History,
          });
        }}
        onClose={closeAskPanel}
      />
      {content === AskContent.History && (
        <AskDocHistory
          docId={docId}
          onSelect={(id) => {
            setConversationId(id);
            setDocPanel({ askContent: AskContent.Conversation });
          }}
          onAskNew={() => {
            setConversationId('');
            setDocPanel({ askContent: AskContent.Conversation });
          }}
        />
      )}
      {content === AskContent.Conversation && (
        <>
          {conversationId && (
            <AskConversation
              conversationId={conversationId}
              docId={docId}
              onAskNew={() => {
                setConversationId('');
                setDocPanel({ askContent: AskContent.Conversation });
              }}
            />
          )}
          {!conversationId && (
            <AskNewConversation
              docId={docId}
              onAsk={setConversationId}
            />
          )}
        </>
      )}
    </div>
  );
};
