import { DropdownMenu } from 'radix-ui';
import { ComponentPropsWithoutRef, ReactNode, useState } from 'react';

import { SubContent } from './SubContent';
import { SubTrigger } from './SubTrigger';

interface Props extends ComponentPropsWithoutRef<typeof SubContent> {
  trigger: ReactNode;
  portal?: boolean;
}

export const SubMenu = ({
  trigger,
  loop = true,
  portal = false,
  sideOffset = 8,
  ...props
}: Props) => {
  const [open, onOpenChange] = useState(false);
  const content = (
    <SubContent
      loop={loop}
      sideOffset={sideOffset}
      open={open}
      {...props}
    />
  );
  return (
    <DropdownMenu.Sub
      open={open}
      onOpenChange={onOpenChange}
    >
      <SubTrigger open={open}>
        {trigger}
      </SubTrigger>

      {portal ? (
        <DropdownMenu.Portal>
          {content}
        </DropdownMenu.Portal>
      ) : content}
    </DropdownMenu.Sub>
  );
};
