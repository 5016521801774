import { Modal, ModalProps } from '@cycle-app/ui';
import { FC, useMemo } from 'react';

import Portal from 'src/components/Portal/Portal';
import { mappingZindex } from 'src/constants/zIndex.constant';
import { useInitLayer } from 'src/hooks/state/useInitLayer';
import { useEscape } from 'src/hooks/useEscape';
import { Layer } from 'src/types/layers.types';
import { PortalId } from 'src/types/portal.types';

import { Container } from './PortalModal.styles';

export interface PortalModalProps extends Omit<ModalProps, 'zIndex' | 'maskZindex'> {
  closeOnEscape?: boolean;
  disableOverlayPointerEvents?: boolean;
  hide?: () => void;
  layer?: Layer;
  useHighMaskLayer?: boolean;
}

const PortalModal: FC<React.PropsWithChildren<PortalModalProps>> = ({
  children,
  closeOnEscape = true,
  disableOverlayPointerEvents = false,
  hide,
  layer = Layer.Modal,
  useHighMaskLayer,
  ...modalProps
}) => {
  useInitLayer(layer);
  useEscape(() => {
    // Hack to prevent PortalModal from closing when a Radix component is open
    if (document.body.dataset.scrollLocked != null) return;
    if (!closeOnEscape || !hide) return;
    hide();
  }, layer);

  const maskZindex = useMemo(() => {
    if (layer === Layer.Commandbar) return mappingZindex[Layer.MaskCommandbar];
    if (layer === Layer.Modal) return mappingZindex[Layer.Modal] - 1;
    if (layer === Layer.ModalZ2) return mappingZindex[Layer.ModalZ2] - 1;
    if (layer === Layer.ModalZ3) return mappingZindex[Layer.ModalZ3] - 1;
    if (layer === Layer.DialogModal) return mappingZindex[Layer.DialogModal] - 1;
    if (layer === Layer.ProductTour) return mappingZindex[Layer.ProductTour] - 1;
    if (layer === Layer.PreviewModal) return mappingZindex[Layer.PreviewModal] - 1;
    if (layer === Layer.ToasterModal) return mappingZindex[Layer.ToasterModal] - 1;

    // Layer.MaskModalOverDropdown is usually needed when a modal needs opening over dropdowns
    const maskLayer = useHighMaskLayer ? Layer.MaskModalOverDropdown : Layer.MaskModal;

    return mappingZindex[maskLayer];
  }, [layer, useHighMaskLayer]);

  return (
    <Portal portalId={PortalId.Modal}>
      <Container
        onClick={e => e.stopPropagation()}
        onMouseDown={e => e.stopPropagation()}
        style={{ pointerEvents: disableOverlayPointerEvents ? 'none' : 'auto' }}
      >
        <Modal
          {...modalProps}
          hide={hide}
          maskZindex={maskZindex}
          zIndex={mappingZindex[layer]}
        >
          {children}
        </Modal>
      </Container>
    </Portal>
  );
};

export default PortalModal;
