import { useSyncExternalStore } from 'react';

const subscribeNavigatorConnection = (callback: VoidFunction) => {
  const controller = new AbortController();
  navigator.connection?.addEventListener('change', callback, {
    passive: true,
    signal: controller.signal,
  });
  return () => controller.abort();
};

/**
 * Checks whether the user's connection is fast, based on the Navigator Connection API.
 * @returns true - Fast connection
 * @returns false - Slow connection
 * @returns undefined - Information not available
 */
export const useFastConnection = () => {
  const getSnapshot = () => {
    const effectiveType = navigator.connection?.effectiveType;
    return effectiveType ? effectiveType === '4g' : undefined;
  };
  return useSyncExternalStore(subscribeNavigatorConnection, getSnapshot);
};
