import { PARAM_PRODUCT_AREA, PARAM_PROFILE, PARAM_QUOTE } from 'src/constants/routing.constant';
import { useLocationSelector } from 'src/hooks/router/useLocation';
import { getSearchParams } from 'src/utils/routing.utils';

const sideModalParams = [PARAM_PROFILE, PARAM_QUOTE, PARAM_PRODUCT_AREA];

/**
 * Side modal ids (quote, customer/company, productArea) are stored in url search params,
 * and sorted by the order they were opened
 * @returns Id of last modal opened
 */
const getLastSideModalId = () => [...getSearchParams().entries()]
  .filter(([key]) => sideModalParams.includes(key))
  .at(-1)?.[1];

/**
 * Check if the modal is the last opened
 */
export const useIsLastOpenedSideModal = (id: string | null) => {
  return useLocationSelector(() => !!id && !!getLastSideModalId()?.includes(id));
};
