import { SelectPanel, SelectOption, TooltipLegacy } from '@cycle-app/ui';
import { ReorderIcon } from '@cycle-app/ui/icons';
import { useRef, useState } from 'react';

import { Layer } from 'src/types/layers.types';

import { DropdownLayer } from '../DropdownLayer';
import { DragSection, DragHandle, TooltipContainer } from './EditorDragMenu.styles';

type EditorDragMenuProps = {
  /**
   * If at least one option -> Create a dropdown with options
   */
  options?: Array<SelectOption>;
  tooltip?: {
    content: string;
    title?: string;
  };
};

export const EditorDragMenu = ({
  options = [], tooltip,
}: EditorDragMenuProps) => {
  const refDragHandle = useRef<HTMLSpanElement | null>(null);
  const [isOpen, setIsOpen] = useState(false);

  const content = (
    <DragHandle
      ref={refDragHandle}
      data-drag-handle
      $forceFocus={isOpen}
      onClick={() => setIsOpen(true)}
    >
      <ReorderIcon size={16} />
    </DragHandle>
  );

  return (
    <DragSection $forceFocus={isOpen}>
      <DropdownLayer
        visible={isOpen}
        placement="bottom"
        layer={Layer.DropdownModalZ3}
        content={(
          <SelectPanel
            hideSearch
            options={options}
            onOptionChange={(selectedOption) => {
              const clickedOption = options.find(o => o.value === selectedOption.value);
              if (!clickedOption) return;
              clickedOption.onSelect?.();
              setIsOpen(false);
            }}
          />
        )}
        hide={() => setIsOpen(false)}
        offset={[0, 30]}
      >
        {tooltip
          ? (
            <TooltipLegacy
              placement="top"
              content={(
                <TooltipContainer>
                  <span>
                    {tooltip.title}
                  </span>
                  <span>
                    {tooltip.content}
                  </span>
                </TooltipContainer>
              )}
            >
              {content}
            </TooltipLegacy>
          )
          : content}
      </DropdownLayer>
    </DragSection>
  );
};
