import { Combobox, InfiniteScroll, ProductLogo } from '@cycle-app/ui';
import { useState } from 'react';
import { twJoin } from 'tailwind-merge';
import { useDebouncedCallback } from 'use-debounce';

import { INPUT_ONCHANGE_DEBOUNCE } from 'src/constants/inputs.constant';
import { PageId } from 'src/constants/routing.constant';
import { useProducts } from 'src/hooks/api/useProducts';
import { navigate } from 'src/hooks/useNavigate';

export const WorkspaceList = () => {
  const [searchText, setSearchText] = useState('');
  const onSearchChange = useDebouncedCallback(setSearchText, INPUT_ONCHANGE_DEBOUNCE);
  const query = useProducts({ searchText });
  return (
    <Combobox.Root
      shouldFilter={false}
      loop={false}
    >
      <Combobox.Input onValueChange={onSearchChange} />

      <Combobox.List
        className={twJoin(
          'max-h-[492px]',
          query.loading && 'opacity-50',
        )}
      >
        <InfiniteScroll
          hasMoreData={query.hasNextPage}
          loadMore={query.fetchNextPage}
          isLoading={query.loading}
        >
          <Combobox.Empty>
            No results
          </Combobox.Empty>

          {query.products.map(product => (
            <Combobox.Item
              key={product.id}
              value={product.slug}
              onSelect={value => navigate(PageId.Main, { productSlug: value })}
              className="gap-3"
            >
              <ProductLogo
                url={product.logo?.url}
                alt={product.name}
                className="size-8"
              />

              <div className="flex flex-col w-full overflow-hidden gap-0.5">
                <Combobox.Highlight
                  text={product.name}
                  className="leading-snug truncate"
                />
                <div className="text-xs/tight text-secondary">
                  {product.slug}
                </div>
              </div>
            </Combobox.Item>
          ))}
        </InfiniteScroll>
      </Combobox.List>
    </Combobox.Root>
  );
};
