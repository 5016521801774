import { StatusCategory } from '@cycle-app/graphql-codegen';
import { getDocSlug } from '@cycle-app/utilities';
import { twJoin } from 'tailwind-merge';

import { ToggleSlidePanelIcon } from 'src/components/ToggleSlidePanelIcon';
import { navigateToReleaseDoc, navigateToReleaseNote } from 'src/hooks/useNavigate';
import { PageId, usePageId } from 'src/hooks/usePageId';

import { StatusTag } from '../DocStatus';
import { ReleaseDocInsights } from '../ReleaseNotes/ReleaseDocInsights';

type Props = {
  releaseId: string;
  noteId: string;
  doc: {
    id: string;
    title: string;
    status?: {
      category: StatusCategory;
      value: string;
    } | null;
  };
};

export const ReleaseNoteDocFeature = ({
  doc, noteId, releaseId,
}: Props) => {
  const {
    title, status,
  } = doc;
  const docSlug = getDocSlug(doc);
  const isReleaseDocPage = usePageId(pageId => pageId === PageId.ReleaseDoc);
  return (
    <div
      className={twJoin(
        'group relative flex h-12 cursor-pointer items-center justify-between gap-3 overflow-hidden px-3',
        'rounded-xl border border-grey-150 dark:border-grey-850',
        'transition-colors duration-300 hover:bg-grey-100 hover:dark:bg-grey-850',
      )}
      onClick={() => {
        if (isReleaseDocPage) {
          navigateToReleaseNote(releaseId, noteId);
        } else {
          navigateToReleaseDoc(releaseId, noteId, docSlug);
        }
      }}
    >
      <ToggleSlidePanelIcon
        direction={isReleaseDocPage ? 'left' : 'right'}
      />

      <span className="flex-1 truncate font-medium">
        {title}
      </span>

      <div className="relative flex flex-none items-center gap-2">
        <ReleaseDocInsights noteId={noteId} />
        {status && (
          <StatusTag
            category={status.category}
            value={status.value}
            isReadOnly
          />
        )}
      </div>
    </div>
  );
};
