/* eslint-disable no-nested-ternary */
import { DocTargetFragment } from '@cycle-app/graphql-codegen';
import { QuoteIcon } from '@cycle-app/ui/icons';
import { AnimatePresence } from 'framer-motion';
import isEqual from 'lodash/isEqual';
import orderBy from 'lodash/orderBy';
import { useMemo, useRef, useState } from 'react';

import { VerifiedQuotePopover } from 'src/components/QuotePopover';
import { QUOTE_CARD_WIDTH, QUOTE_BUTTON_WIDTH } from 'src/constants/editor.constants';
import { navigateToDocFullPage } from 'src/hooks';
import { useInterval } from 'src/hooks/useInterval';
import { useGetDocPanel } from 'src/reactives/docPanel.reactive';
import { resetHighlight, setHighlight, useGetHighlight } from 'src/reactives/highlight.reactive';

import { FeatureCard } from './FeatureCard';
import { FeatureCardAttributes } from './FeatureCardAttributes';
import { FeatureTitle } from './FeatureTitle';
import { getQuotePositions } from './getQuotesPositions';
import { QuoteCard } from './QuoteCard';
import { Container, QuoteContainer, QuoteButton } from './QuoteCards.styles';
import { QuoteCardSkeleton } from './QuoteCardSkeleton';

type Props = {
  insights: DocTargetFragment[];
  collapsed: boolean;
};

export const QuoteCards = ({
  collapsed, insights,
}: Props) => {
  const quotes = useMemo(() => {
    // Quotes without blockId are placed at the top
    return orderBy(insights, ['blockId'], ['desc']);
  }, [insights]);

  const highlight = useGetHighlight();

  const containerRef = useRef<HTMLDivElement>(null);

  // { quoteId: top }
  const [positions, setPositions] = useState<Record<string, number> | null>(null);

  const { isEditorInit } = useGetDocPanel();

  const checkCardsPositions = () => {
    if (!containerRef.current) return;
    const newPositions = getQuotePositions(containerRef.current, quotes, isEditorInit);
    if (isEqual(newPositions, positions)) return;
    setPositions(newPositions);
  };

  const noBlockId = !insights.some(insight => insight.blockId);

  useInterval(
    checkCardsPositions,
    100,
    { disabled: noBlockId },
  );

  return (
    <AnimatePresence>
      <Container
        ref={containerRef}
        style={{
          width: collapsed ? QUOTE_BUTTON_WIDTH : QUOTE_CARD_WIDTH,
        }}
      >
        {quotes.map(quote => {
          const top = positions?.[quote.id];

          // Quotes can be highlighted by hovering a mark in the editor or a verified quote in the summary
          const highlighted = quote.blockId
            ? highlight.blockId === quote.blockId
            : highlight.blockId === quote.id;

          return (
            <QuoteContainer
              data-quote-id={quote.id}
              data-block-id={quote.blockId ?? 'null'}
              $collapsed={collapsed}
              key={quote.id}
              initial={{ opacity: 0 }}
              exit={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              style={!quote.blockId ? undefined : {
                position: 'absolute',
                top: 0,
                transform: `translateY(${top ?? 0}px)`,
                visibility: quote.blockId && top === undefined ? 'hidden' : 'visible',
              }}
              onMouseDown={e => {
                e.stopPropagation();
              }}
              onMouseEnter={() => {
                setHighlight({
                  docId: null,
                  blockId: quote.blockId ?? quote.id,
                  context: 'doc-link-list',
                });
              }}
              onMouseLeave={() => {
                resetHighlight();
              }}
            >
              {quote.id.includes('temp') ? (
                <QuoteCardSkeleton />
              ) : (
                <VerifiedQuotePopover
                  insight={quote}
                  placement={collapsed ? 'bottom' : 'top'}
                  offset={[0, 10]}
                  featurePlacement="bottom"
                  quoteContent={quote.doc?.parent ? quote.content : null}
                  featureContent={quote.doc?.parent && collapsed
                    ? (
                      <div className="flex flex-col items-start gap-2">
                        <FeatureTitle title={quote.doc.parent.title} />
                        <FeatureCardAttributes doc={quote.doc.parent} />
                      </div>
                    )
                    : null}
                >
                  {active => {
                    if (collapsed) {
                      return (
                        <QuoteButton
                          forceFocus={highlighted || active}
                          onClick={e => {
                            if (!quote.doc?.parent) return;
                            navigateToDocFullPage(quote.doc.parent, {}, e.metaKey);
                          }}
                        >
                          <QuoteIcon size={14} />
                        </QuoteButton>
                      );
                    }

                    if (quote.doc?.parent) {
                      return (
                        <FeatureCard
                          docParent={quote.doc.parent}
                          active={highlighted || active}
                        />
                      );
                    }

                    return (
                      <QuoteCard
                        active={highlighted || active}
                        quote={quote}
                      />
                    );
                  }}
                </VerifiedQuotePopover>
              )}
            </QuoteContainer>
          );
        })}
      </Container>
    </AnimatePresence>
  );
};
