import { TooltipLegacy } from '@cycle-app/ui';
import { TrashIcon } from '@cycle-app/ui/icons';
import { FC } from 'react';

import { ImageInput } from 'src/components/ImageInput';
import { useCompanyUpdate } from 'src/hooks/api/mutations/customers/useCompanyUpdate';
import { useCompany } from 'src/hooks/api/queries/customers/useCompany';

import { ImageSkeleton, StyledCompanyLogo, CompanyRemoveButton } from './Profile.styles';

type Props = {
  companyId: string;
};

export const ProfileCompanyLogo: FC<Props> = ({ companyId }) => {
  const {
    company,
    isLoading: isLoadingCompany,
  } = useCompany(companyId);

  const {
    updateCompany,
    isLoading: isUpdatingCompany,
  } = useCompanyUpdate();

  if (isLoadingCompany || isUpdatingCompany) return <ImageSkeleton />;

  if (!company) return null;

  if (company.isDefault) return <StyledCompanyLogo company={company} />;

  const updateCompanyLogo = (avatar?: File) => updateCompany({
    companyId,
    name: company?.name ?? '',
    avatarInput: avatar ? { avatar } : null,
  });

  return (
    <>
      <ImageInput
        previewModalTitle="New company logo"
        previewModalSubmitLabel="Set new company logo"
        onChange={updateCompanyLogo}
      >
        {(inputRef) => (
          <TooltipLegacy
            content="Change logo"
            placement="top"
            withPortal
            withWrapper={false}
          >
            <StyledCompanyLogo
              isEditable
              company={company}
              onClick={() => {
                inputRef.current?.click();
              }}
            />
          </TooltipLegacy>
        )}
      </ImageInput>

      {!!company?.logo && (
        <TooltipLegacy
          content="Remove logo"
          placement="top"
          withPortal
          withWrapper={false}
        >
          <CompanyRemoveButton
            onClick={() => updateCompanyLogo()}
          >
            <TrashIcon />
          </CompanyRemoveButton>
        </TooltipLegacy>
      )}
    </>
  );
};
