import { Emoji, TooltipLegacy } from '@cycle-app/ui';
import { useState } from 'react';

import { BoardDefaultIcon } from 'src/components/BoardDefaultIcon';
import { BoardStarButton } from 'src/components/BoardHeader/BoardStarButton';
import { ToggleDropdown } from 'src/components/DropdownLayer';
import { ViewContextMenu } from 'src/components/ViewContextMenu';
import { ViewsPanel } from 'src/components/ViewsPanel';
import { useBoardLinkSectionName } from 'src/hooks/api/useBoardLinkSectionName';
import { useBoardId } from 'src/hooks/usePathParams';
import { usePrevious } from 'src/hooks/usePrevious';
import { useGetPermission } from 'src/reactives';
import { SectionParent, getSectionType } from 'src/utils/section.utils';

import { DoubleCaret, ViewButton } from './ViewBreadcrumb.styles';
import { useBoard } from '../../hooks';
import { useBoards } from '../../hooks/boards/useBoards';

export const ViewDropdown = ({
  sectionId, sectionParent,
}: { sectionId: string; sectionParent: SectionParent }) => {
  const currentBoardId = useBoardId();
  const previousBoardId = usePrevious(currentBoardId);
  const boardId = currentBoardId ?? previousBoardId;
  const board = useBoard(boardId);
  const { boards } = useBoards(sectionId);
  const { isPermissionInit } = useGetPermission();
  const [contextMenu, setContextMenu] = useState<{ clientX: number; clientY: number } | null>(null);
  const sectionName = useBoardLinkSectionName(board?.id);

  if (!board) return null;

  return (
    <>
      <ToggleDropdown
        placement="bottom-start"
        withWrapper={false}
        content={props => (
          <ViewsPanel
            sectionParent={sectionParent}
            sectionId={sectionId}
            hide={props.hide}
          />
        )}
        button={props => (
          <ViewButton
            {...props}
            iconEnd={<DoubleCaret />}
            variant="light"
            iconStart={board.emoji ? (
              <Emoji
                emoji={board.emoji}
                color={board.color}
                size={16}
              />
            ) : (
              <BoardDefaultIcon
                sectionType={getSectionType(sectionParent)}
                color={board.color}
              />
            )}
            onContextMenu={e => {
              if (!isPermissionInit || !boardId) return;
              setContextMenu({
                clientX: e.clientX,
                clientY: e.clientY,
              });
              e.preventDefault();
            }}
          >
            <TooltipLegacy
              content={board.description}
              placement="bottom"
              withPortal
              withWrapper={false}
              disabled={!board.description}
            >
              <span className="truncate">
                {board.name}
              </span>
            </TooltipLegacy>

            {sectionName && (
              <span className="ml-1 text-caption text-secondary">
                {sectionName}
              </span>
            )}
          </ViewButton>
        )}
      />

      {boardId && <BoardStarButton boardId={boardId} />}

      {board && contextMenu && (
        <ViewContextMenu
          viewId={board.id}
          type={board.type}
          clientX={contextMenu.clientX}
          clientY={contextMenu.clientY}
          hide={() => setContextMenu(null)}
          isDeleteDisabled={boards.length === 1}
        />
      )}
    </>
  );
};
