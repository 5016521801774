import { delay } from '@cycle-app/utilities';
import { HocuspocusProvider } from '@hocuspocus/provider';
import { useAtom, useAtomValue } from 'jotai';
import { useEffect, useRef } from 'react';
import { twJoin } from 'tailwind-merge';
import { Doc } from 'yjs';

import { Editor } from 'src/components/Editor';
import { useDoc } from 'src/hooks/api/useDoc';
import { ActionId } from 'src/services/editor/editorActions';

import { draftRequestIdAtom, editorLoadedAtom } from './newRequestModal.atoms';

if (!import.meta.env.VITE_WS_API_SYNC_SERVER) {
  throw new Error('The variable WS_API_SYNC_SERVER is not defined');
}

export const NewRequestEditor = () => {
  const docId = useAtomValue(draftRequestIdAtom);
  const { doc } = useDoc(docId);
  const yDocRef = useRef<Doc>(docId ? new Doc() : null);
  const [loaded, setLoaded] = useAtom(editorLoadedAtom);

  // Initialize the Hocuspocus provider
  useEffect(() => {
    if (!docId || !yDocRef.current) return;
    const provider = new HocuspocusProvider({
      url: `${import.meta.env.VITE_WS_API_SYNC_SERVER}/sync/doc/${docId}`,
      name: docId,
      document: yDocRef.current,
      token: JSON.parse(localStorage.getItem('auth') || '{}').token,
      preserveConnection: false,
      forceSyncInterval: 5000,
      onConnect: async () => {
        // Wait for the editor to be fully loaded to prevent flickering
        await delay(400);
        setLoaded(true);
      },
    });
    return () => provider.destroy();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!docId || !doc || !yDocRef.current) return null;

  return (
    <Editor
      key={doc.id}
      doc={doc}
      isDraft
      smallEmptyBlock
      disabledShortcuts={['Mod-Enter', 'Shift-Enter']}
      disabledActions={[ActionId.TurnTextIntoInsight]}
      collaboration={{ document: yDocRef.current }}
      className={twJoin(!loaded && 'hidden!')}
    />
  );
};
