import { InternalRefetchQueriesInclude } from '@apollo/client';
import { CompanyFragment, CustomerFragment } from '@cycle-app/graphql-codegen/generated';
import { Button, CustomerAvatar, InputComponents, Icon } from '@cycle-app/ui';
import { CloseIcon } from '@cycle-app/ui/icons';
import { emailRegex, ERROR_CODE } from '@cycle-app/utilities';
import { useState, useCallback, useRef, useLayoutEffect } from 'react';
import { Controller } from 'react-hook-form';
import { useDebouncedCallback } from 'use-debounce';

import { CompanySelectOption } from 'src/components/CustomerCompanyAction/CustomerCompanyAction';
import { Header, Title, CloseButtonStyled, Actions } from 'src/components/DialogModal/DialogModal.styles';
import ImageInput from 'src/components/ImageInput/ImageInput';
import { ErrorMessage } from 'src/constants/errors.constants';
import { useWorkspaceContext } from 'src/contexts/workspaceContext';
import { useCompanyUpdate } from 'src/hooks/api/mutations/customers/useCompanyUpdate';
import { useCustomerCreate } from 'src/hooks/api/mutations/customers/useCustomerCreate';
import { useCustomerUpdate } from 'src/hooks/api/mutations/customers/useCustomerUpdate';
import { ErrorMap, useEnhancedForm } from 'src/hooks/form/useEnhancedForm';
import { openCustomerModal } from 'src/hooks/modals/useCustomerModal';
import { useCustomerExistenceCheck } from 'src/hooks/useCustomerExistenceCheck';
import { Layer } from 'src/types/layers.types';
import { getCompanyOption } from 'src/utils/companies.util';
import { addToaster } from 'src/utils/toasters.utils';

import {
  CompanyActionSelect, CompanyActionSelectLabel, Form, FormInput, Label,
  InputContent,
  UploadNewBtn,
  RemoveBtn,
  CustomerModal,
} from './Customers.styles';

interface SettingsCustomerAddCustomerModalProps {
  onClose: () => void;
  customer?: CustomerFragment;
  defaultValues?: Partial<CreateCustomerFormData>;
  defaultCompany?: CompanyFragment | null;
  layer?: Layer;
  dropdownsLayer?: Layer;
  onCreated?: (data: CustomerFragment) => void;
  showToaster?: boolean;
  hiddenFields?: ('name' | 'avatar' | 'company')[];
  refetchQueries?: InternalRefetchQueriesInclude;
}

interface CreateCustomerFormData {
  email: string;
  name: string;
  companyId: string;
  avatar?: string;
  avatarFile?: File;
  avatarPreviewUrl?: string;
}

// We do not have an unified format for business errors from the server (yet !)
// so we need to filter only expected errors.
const mutationErrorsMap: ErrorMap<CreateCustomerFormData>[] = [
  {
    code: ERROR_CODE.CUSTOMER_WITH_EMAIL_ALREADY_EXISTS,
    fieldName: 'email',
    renderMessage: () => ErrorMessage.CUSTOMER_WITH_EMAIL_ALREADY_EXISTS,
  },
  {
    code: ERROR_CODE.CUSTOMER_EMAIL_ALREADY_USED,
    fieldName: 'email',
    renderMessage: () => ErrorMessage.CUSTOMER_EMAIL_ALREADY_USED,
  },
  {
    code: ERROR_CODE.CANT_REMOVE_CUSTOMER_FROM_COMPANY,
    fieldName: 'companyId',
    renderMessage: () => ErrorMessage.CANT_REMOVE_CUSTOMER_FROM_COMPANY,
  },
];

const GRAPH_QL_ERROR_SAME_EMAIL = {
  extensions: {
    code: ERROR_CODE.CUSTOMER_WITH_EMAIL_ALREADY_EXISTS,
  },
  message: ERROR_CODE.CUSTOMER_WITH_EMAIL_ALREADY_EXISTS,
  locations: [],
  name: '',
  nodes: [],
  originalError: null,
  path: [],
  positions: [],
  source: undefined,
};

export const CustomersAddCustomerModal = ({
  onClose,
  customer,
  defaultValues,
  defaultCompany,
  layer,
  onCreated,
  showToaster = !!onCreated,
  hiddenFields = [],
  refetchQueries,
  dropdownsLayer = Layer.DropdownModalZ3,
}: SettingsCustomerAddCustomerModalProps) => {
  const productId = useWorkspaceContext(ctx => ctx.productId);
  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors },
    displayFieldsErrors,
    getValues,
    clearErrors,
  } = useEnhancedForm<CreateCustomerFormData>({
    defaultValues: {
      email: customer?.email || '',
      name: customer?.name || '',
      companyId: defaultCompany?.id ?? '',
      avatarPreviewUrl: customer?.avatar || '',
      ...defaultValues,
    },
  });
  const [selectedCompany, setSelectedCompany] = useState<CompanySelectOption | null>(
    defaultCompany ? getCompanyOption(defaultCompany) : null,
  );
  const checkCustomer = useCustomerExistenceCheck(productId);
  const createCustomer = useCustomerCreate(productId);
  const updateCustomer = useCustomerUpdate(customer);
  const { updateCompany } = useCompanyUpdate();

  const isEditing = !!customer?.id;

  const updateCompanyLogo = useCallback((companyId?: string) => {
    const avatarUrl = selectedCompany?.data?.logo;
    if (!companyId || !avatarUrl) return;
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    updateCompany({
      companyId,
      avatarInput: {
        avatarUrl,
      },
    });
  }, [selectedCompany?.data?.logo, updateCompany]);

  const onSubmit = async ({
    name: formName, email: formEmail, ...data
  }: CreateCustomerFormData) => {
    if (createCustomer.isLoading || updateCustomer.isLoading) return;
    if (isEditing) {
      const result = await updateCustomer.update({
        customerId: customer.id,
        avatarInput: data.avatarFile ? {
          avatar: data.avatarFile,
        } : null,
        name: formName,
        email: formEmail,
        ...data,
      });
      if (result?.errors) {
        displayFieldsErrors(result.errors, mutationErrorsMap);
        return;
      }
      updateCompanyLogo(result?.data?.updateCustomer?.company?.id);
    } else {
      const result = await createCustomer.mutate({
        ...data,
        name: formName,
        email: formEmail,
        companyName: selectedCompany?.label,
      }, { refetchQueries });

      if (result?.errors) {
        displayFieldsErrors(result.errors, mutationErrorsMap);
        return;
      }
      updateCompanyLogo(result?.data?.createCustomer?.company?.id);
      const createdCustomer = result?.data?.createCustomer;
      if (!createdCustomer) return;
      onCreated?.(createdCustomer);
      if (showToaster) {
        addToaster({
          message: props => (
            <div className="space-y-2">
              <div className="flex items-center gap-2 text-primary">
                <Icon name="check" />
                <div className="font-medium">
                  Customer successfully created
                </div>
              </div>
              <button
                className="btn btn-tertiary text-primary font-normal"
                onClick={() => {
                  openCustomerModal(createdCustomer.id);
                  props.close();
                }}
              >
                <CustomerAvatar
                  customer={createdCustomer}
                  size="S"
                />
                {createdCustomer?.displayName ?? createdCustomer.email}
              </button>
            </div>
          ),
        });
      }
    }
    onClose();
  };

  const onEmailChange = useDebouncedCallback(async () => {
    const currentEmail = getValues().email;
    const matchedCustomer = await checkCustomer(currentEmail);
    if (matchedCustomer) {
      displayFieldsErrors([GRAPH_QL_ERROR_SAME_EMAIL], mutationErrorsMap);
    } else {
      clearErrors();
    }
  }, 300);

  const [avatarUrl, name, email] = watch(['avatarPreviewUrl', 'name', 'email']);

  const hideName = hiddenFields.includes('name');
  const hideAvatar = hiddenFields.includes('avatar');
  const hideCompany = hiddenFields.includes('company');
  const isSubmitDisabled = (!email.trim() && (hideName || !name.trim())) ||
    !!Object.keys(errors).length;

  // Focus on the first input when the form is rendered
  const formRef = useRef<HTMLFormElement>(null);
  useLayoutEffect(() => {
    setTimeout(() => {
      formRef.current?.querySelector('input')?.focus();
    });
  }, []);

  return (
    <CustomerModal
      layer={layer}
      hide={onClose}
    >
      <Header>
        <Title>
          {isEditing ? 'Edit customer' : 'Add new customer'}
        </Title>
        <CloseButtonStyled onClick={onClose}>
          <CloseIcon />
        </CloseButtonStyled>
      </Header>

      <Form
        ref={formRef}
        onSubmit={async e => {
          // Prevents parent form from beeing submitted.
          e.stopPropagation();
          await handleSubmit(onSubmit)(e);
        }}
      >
        {!hideName && (
          <FormInput
            type="text"
            label="Name"
            placeholder="Customer name"
            error={errors.name?.message}
            {...register('name', {
              min: 3,
            })}
          />
        )}
        <FormInput
          readOnly={!!customer?.profiles?.some(p => p?.__typename === 'ProfileCycle')}
          label="Email"
          placeholder="Customer email"
          helper={customer?.email ? undefined : 'Please add an email address if you want to communicate with this customer'}
          helperSize="M"
          error={errors.email?.message}
          {...register('email', {
            pattern: {
              value: emailRegex,
              message: 'Email format is incorrect',
            },
            onChange: onEmailChange,
          })}
        />

        {!hideCompany && (
          <div>
            <Label>
              Company
            </Label>
            <Controller
              control={control}
              name="companyId"
              render={({ field }) => (
                <CompanyActionSelect
                  productId={productId}
                  isReadOnly={!customer && !!defaultCompany}
                  layer={dropdownsLayer}
                  shouldCreate={false}
                  onOptionChange={selectedOption => {
                    field.onChange(selectedOption.value);
                    setSelectedCompany(selectedOption);
                  }}
                  onClearValue={() => {
                    field.onChange('');
                    setSelectedCompany(null);
                  }}
                  showCaret
                >
                  {selectedCompany ? (
                    <CompanyActionSelectLabel>
                      {selectedCompany.icon}
                      {selectedCompany.label}
                    </CompanyActionSelectLabel>
                  ) : 'Select a company'}
                </CompanyActionSelect>
              )}
            />
            {errors.companyId?.message && (
              <InputComponents.Helper
                $hasError
                $size="M"
              >
                {errors.companyId.message}
              </InputComponents.Helper>
            )}
          </div>
        )}

        {!hideAvatar && isEditing && (
          <ImageInput
            label="Avatar"
            previewModalTitle="New customer picture"
            previewModalSubmitLabel="Set new customer picture"
            onChange={(file) => {
              setValue('avatarFile', file);
              setValue('avatarPreviewUrl', URL.createObjectURL(file));
            }}
          >
            {(inputRef) => (
              <InputContent>
                {customer && (
                  <CustomerAvatar
                    size="M"
                    onClick={() => inputRef.current?.click()}
                    customer={customer}
                    src={avatarUrl}
                  />
                )}
                <UploadNewBtn onClick={() => inputRef.current?.click()}>
                  Upload new
                </UploadNewBtn>

                <RemoveBtn
                  onClick={() => {
                    setValue('avatarFile', undefined);
                    setValue('avatarPreviewUrl', '');
                  }}
                >
                  Remove
                </RemoveBtn>
              </InputContent>
            )}
          </ImageInput>
        )}

        <Actions>
          <Button
            size="M"
            type="button"
            variant="secondary"
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            size="M"
            type="submit"
            isLoading={createCustomer.isLoading || updateCustomer.isLoading}
            disabled={isSubmitDisabled}
          >
            {isEditing ? 'Update' : 'Create'}
          </Button>
        </Actions>
      </Form>
    </CustomerModal>
  );
};
