import { Color } from '@cycle-app/graphql-codegen';
import { CSSProperties, FC, ReactNode } from 'react';

import {
  Container,
  UserName,
  AvatarContainer,
  Viewing,
} from './MemberCard.styles';
import { AvatarLegacy } from '../Avatar/AvatarLegacy';
import { TooltipLegacy } from '../TooltipLegacy/Tooltip';

export type MemberCardProps = {
  avatar?: string;
  color?: Color;
  email: string;
  mode: 'online' | 'offline' | 'pending';
  name?: string;
  viewing: ReactNode;
  onClick?: VoidFunction;
  style?: CSSProperties;
};

export const MemberCard: FC<React.PropsWithChildren<MemberCardProps>> = ({
  avatar,
  color = Color.A,
  email,
  mode,
  name,
  viewing,
  onClick = () => undefined,
  style,
}) => (
  <Container
    $color={color}
    $pending={mode === 'pending'}
    onClick={onClick}
    style={style}
  >
    <AvatarContainer>
      <TooltipLegacy
        placement="top"
        content={(
          <>
            <UserName>
              {name}
            </UserName>
            <Viewing>
              {viewing}
            </Viewing>
          </>
        )}
      >
        <AvatarLegacy
          user={{
            color,
            email,
            firstName: name,
          }}
          size={24}
          src={avatar}
          pending={mode === 'pending'}
        />
      </TooltipLegacy>
    </AvatarContainer>
  </Container>
);
