import { ConversationRole } from '@cycle-app/graphql-codegen';
import { InfiniteScroll, Skeleton } from '@cycle-app/ui';
import { AiIcon } from '@cycle-app/ui/icons';
import { nodeToArray } from '@cycle-app/utilities';
import { format, parseISO } from 'date-fns';
import { useState } from 'react';
import scrollIntoView from 'scroll-into-view-if-needed';
import { twJoin } from 'tailwind-merge';

import { AskAnswer } from './AskAnswer';
import { useConversation } from '../../hooks/ask/useConversation';
import { useConversationAsk } from '../../hooks/ask/useConversationAsk';
import { askVar } from '../../reactives/ask.reactive';
import { addToaster } from '../../utils/toasters.utils';
import { AnswerDocs } from '../Ask/AnswerDocs';
import { QuestionInput } from '../Ask/Ask.styles';

type Props = {
  conversationId: string;
  docId?: string;
  onAskNew: VoidFunction;
  pending?: boolean;
};

export const AskConversation = ({
  conversationId, docId, onAskNew, pending,
}: Props) => {
  const {
    conversation, isLoading, isLoadingMore, hasNextPage, loadMore,
  } = useConversation(conversationId);
  const { ask } = useConversationAsk();
  const messages = nodeToArray(conversation?.messages);
  const [displayDocs, setDisplayDocs] = useState(false);

  if (isLoading) {
    return (
      <div className="flex flex-col items-center gap-4 px-5">
        <Skeleton className="w-20! h-3.5! flex-none" />
        <Skeleton className="h-8!" />
      </div>
    );
  }

  if (!conversation) {
    return (
      <div className="grow flex justify-center items-center">
        <div className="text-center">
          <AiIcon className="text-grey-350" />
          <div className="text-secondary mt-3.5">
            Conversation not found
          </div>
          <button
            type="button"
            onClick={onAskNew}
            className="btn-tertiary text-cycle dark:text-white mt-5"
          >
            New
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="grow relative flex flex-col justify-between overflow-hidden">
      <InfiniteScroll
        isLoading={isLoading || isLoadingMore}
        hasMoreData={hasNextPage}
        loadMore={loadMore}
        className={twJoin('shy-scrollbar grow', displayDocs && 'opacity-0')}
      >
        <ul className="flex flex-col gap-5 px-5 mb-2">
          <li>
            <div className="flex flex-col items-center gap-4">
              <div className="text-caption text-disabled">
                {format(parseISO(conversation.createdAt), 'PP')}
              </div>
              <div className="self-end bg-blue-500 text-white px-2 py-1 rounded-lg">
                {conversation.content}
              </div>
            </div>
          </li>
          {messages.map(message => (
            <li
              key={message.id}
              data-id={message.id}
            >
              {message.role === ConversationRole.Bot ? (
                <AskAnswer
                  content={message.content}
                  conversationId={conversationId}
                  loader={docId ? 'Thinking…' : 'Analyzing…'}
                  openDocs={() => setDisplayDocs(true)}
                  pending={!!message._isPending}
                />
              ) : (
                <div className="flex flex-col items-center gap-4">
                  <div className="text-caption text-disabled">
                    {format(parseISO(message.createdAt), 'PP')}
                  </div>
                  <div className="self-end bg-blue-500 text-white px-2 py-1 rounded-lg">
                    {message.content}
                  </div>
                </div>
              )}
            </li>
          ))}
        </ul>
      </InfiniteScroll>
      {displayDocs && (
        <AnswerDocs
          conversationId={conversationId}
          hide={() => setDisplayDocs(false)}
        />
      )}
      {docId && (
        // Cannot create a follow up on product level ask for now
        <div className="py-5 bg-primary dark:bg-grey-950 flex-none px-5">
          <QuestionInput
            autoFocus
            placeholder="Ask follow-up"
            onKeyDown={async (e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                const question = e.currentTarget.value.trim();
                if (!question) return;
                if (pending) {
                  // Product level ask should be blocked if a ask is still pending.
                  addToaster({
                    title: 'Wait for previous question to finish…',
                  });
                  return;
                }
                askVar.set(prev => ({
                  ...prev,
                  progress: 0,
                }));
                e.currentTarget.value = '';
                await ask({
                  docId,
                  question,
                  conversationId,
                  onSuccess: (questionId) => {
                    const element = document.querySelector<HTMLLIElement>(`[data-id="${questionId}"]`);
                    if (element) {
                      scrollIntoView(element);
                    }
                  },
                });
              }
            }}
          />
        </div>
      )}
    </div>
  );
};
