import { SectionType } from '@cycle-app/graphql-codegen';
import { nodeToArray, toObject } from '@cycle-app/utilities';
import { useMemo } from 'react';

import { useProduct } from './useProduct';

export const useBoardSections = () => {
  const {
    product,
    loading,
  } = useProduct();

  const feedbackSectionId = useMemo(
    () => nodeToArray(product?.boardSections).find(section => section.type === SectionType.Feedback)?.id,
    [product],
  );

  const roadmapSectionId = useMemo(
    () => nodeToArray(product?.boardSections).find(section => section.type === SectionType.Roadmaps)?.id,
    [product],
  );

  const defaultSection = useMemo(
    () => nodeToArray(product?.boardSections).find(section => section.type === SectionType.Default),
    [product],
  );

  const customSections = useMemo(
    () => nodeToArray(product?.boardSections).filter(section => section.type === SectionType.Custom),
    [product],
  );

  const sections = useMemo(() => ([
    ...defaultSection ? [defaultSection] : [],
    ...customSections,
  ]), [defaultSection, customSections]);

  const mappingBoardLinks = useMemo(
    () => Object.fromEntries(
      sections.flatMap(section => nodeToArray(section.boardLinks).map(boardLink => ({
        ...boardLink,
        section,
      }))).map(boardLink => [boardLink.id, boardLink]),
    ),
    [sections],
  );

  const mappingSections = useMemo(() => toObject(sections), [sections]);

  return {
    sections,
    loading,
    mappingSections,
    customSections,
    mappingBoardLinks,
    defaultSection,
    sectionsCount: sections.length,
    feedbackSectionId,
    roadmapSectionId,
  };
};
