import { StatusCategory } from '@cycle-app/graphql-codegen';
import { Menu, DropdownMenu, ToggleInput, StatusIcon as StatusCategoryIcon } from '@cycle-app/ui';
import { StatusIcon } from '@cycle-app/ui/icons';
import { STATUS_CATEGORY } from '@cycle-app/utilities/src/constants/statusCategory.constants';
import { ComponentProps } from 'react';

import { useStatusFilter, setStatusFilter, getStatusFilter } from 'src/reactives/commandbar.reactive';

import { FilterButton, ArrowIcon } from './CommandStatusFilter.styles';

export const CommandStatusFilter = (props: Pick<ComponentProps<typeof Menu>, 'open' | 'onOpenChange'>) => {
  const statusFilter = useStatusFilter();

  const isSingle = Object.values(statusFilter).filter(Boolean).length < 2;

  const options = Object.entries(STATUS_CATEGORY).map(([cat, label]) => {
    const category = cat as StatusCategory;
    const selected = statusFilter[category];
    return ({
      label,
      value: category,
      icon: (
        <StatusCategoryIcon
          category={category}
          withBackground
        />
      ),
      selected,
      disabled: selected && isSingle,
    });
  });

  return (
    <Menu
      {...props}
      side="bottom"
      align="start"
      className="w-50 z-10"
      onCloseAutoFocus={e => e.preventDefault()}
      trigger={(
        <FilterButton>
          <StatusIcon />
          {getStatusFilterLabel()}
          <ArrowIcon size={16} />
        </FilterButton>
      )}
    >
      {options.map(option => (
        <DropdownMenu.Item
          key={option.value}
          disabled={option.disabled}
          onSelect={e => {
            e.preventDefault();
            setStatusFilter({ [option.value]: !statusFilter[option.value] });
          }}
        >
          {option.icon}
          {option.label}
          <ToggleInput
            id={option.value}
            checked={statusFilter[option.value]}
            onChange={() => {}}
            className="ml-auto pointer-events-none"
          />
        </DropdownMenu.Item>
      ))}
    </Menu>
  );
};

const getStatusFilterLabel = () => {
  const isAll = Object.values(getStatusFilter()).every(Boolean);
  if (isAll) return 'All statuses';

  const state = getStatusFilter();
  const selected = Object.keys(state).filter(key => state[key as StatusCategory]);
  if (selected.length < 2) return STATUS_CATEGORY[selected[0] as StatusCategory];

  return `${selected.length} statuses`;
};
