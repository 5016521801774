import { getOptimizedImgSrc } from '@cycle-app/utilities';
import { Avatar as RadixAvatar } from 'radix-ui';
import { forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';

export type ProductLogoProps = React.ComponentProps<'div'> & {
  url: string | undefined;
  alt: string | undefined;
};

export const ProductLogo = forwardRef<HTMLSpanElement, ProductLogoProps>(
  function ProductLogo({
    url, alt, className, ...props
  }, ref) {
    return (
      <RadixAvatar.Root
        ref={ref}
        className={twMerge(
          'size-4 flex-none rounded-sm align-middle select-none overflow-hidden inline-flex',
          className,
        )}
        {...props}
      >
        <RadixAvatar.Image
          className="size-full object-cover rounded-inherit"
          src={getOptimizedImgSrc(url)}
          alt={alt}
        />
        <RadixAvatar.Fallback
          className="size-full rounded-inherit bg-grey-950/5 dark:bg-grey-100/5"
        />
      </RadixAvatar.Root>
    );
  });
