import { AnimatePresence, motion } from 'framer-motion';
import { HoverCard as RadixHoverCard } from 'radix-ui';
import {
  forwardRef, ReactNode, useState, ComponentPropsWithoutRef, PropsWithChildren, createContext, useContext,
} from 'react';
import { twMerge } from 'tailwind-merge';

interface ContentProps extends Omit<ComponentPropsWithoutRef<typeof RadixHoverCard.Content>, 'content' | 'forceMount' | 'asChild'> {
  open?: boolean;
}

const Content = forwardRef<HTMLDivElement, ContentProps>(
  function Content({
    open = false,
    className,
    children,
    ...props
  }, ref) {
    return (
      <AnimatePresence>
        {open && (
          <RadixHoverCard.Content
            ref={ref}
            asChild
            side="top"
            align="center"
            sideOffset={8}
            {...props}
          >
            <motion.div
              className={twMerge(
                'relative min-w-80 max-w-[340px] cursor-auto rounded-[10px] border border-grey-300 bg-white text-primary shadow-z2 dark:border-grey-800 dark:bg-grey-850 z-3000',
                className,
              )}
              animate={open ? 'open' : 'closed'}
              initial="closed"
              exit="closed"
              variants={{
                open: {
                  opacity: 1,
                  scale: 1,
                },
                closed: {
                  opacity: 0,
                  scale: 0.9,
                },
              }}
              transition={{
                duration: 0.15,
              }}
              style={{
                transformOrigin: 'var(--radix-hover-card-content-transform-origin)',
              }}
            >
              {children}
            </motion.div>
          </RadixHoverCard.Content>
        )}
      </AnimatePresence>
    );
  },
);

export interface HoverCardProps extends
  Omit<RadixHoverCard.HoverCardProps, 'open' | 'onOpenChange'>,
  Omit<ContentProps, 'open'> {
  disabled?: boolean;
  content: ReactNode;
}

export const HoverCard = ({
  children,
  content,
  defaultOpen,
  openDelay = 400,
  closeDelay = 200,
  disabled = false,
  ...props
}: HoverCardProps) => {
  const [open, onOpenChange] = useState(defaultOpen);

  if (disabled) return (
    <>
      {children}
    </>
  );

  return (
    <RadixHoverCard.Root
      onOpenChange={onOpenChange}
      defaultOpen={defaultOpen}
      openDelay={openDelay}
      closeDelay={closeDelay}
    >
      <RadixHoverCard.Trigger asChild>
        {children}
      </RadixHoverCard.Trigger>

      <RadixHoverCard.Portal forceMount>
        <Content
          open={open}
          onClick={e => e.stopPropagation()}
          {...props}
        >
          <HoverCardProvider close={() => onOpenChange(false)}>
            {content}
          </HoverCardProvider>
        </Content>
      </RadixHoverCard.Portal>
    </RadixHoverCard.Root>
  );
};

export const HoverCardGroup = ({
  className, ...props
}: PropsWithChildren<ComponentPropsWithoutRef<'div'>>) => {
  return (
    <div
      className={twMerge(
        'p-3 border-grey-200 dark:border-grey-800 not-first:border-t',
        className,
      )}
      {...props}
    />
  );
};

export const HoverCardButtonGroup = ({
  className, ...props
}: PropsWithChildren<ComponentPropsWithoutRef<'div'>>) => {
  return (
    <HoverCardGroup
      className={twMerge('flex items-center gap-1 p-1', className)}
      {...props}
    />
  );
};

const HoverCardContext = createContext<() => void>(() => {});

const HoverCardProvider = ({
  close,
  ...props
}: {
  close: () => void;
  children: ReactNode;
}) => (
  <HoverCardContext.Provider
    value={close}
    {...props}
  />
);

export const useCloseHoverCard = () => {
  const context = useContext(HoverCardContext);
  if (!context) {
    throw new Error('useCloseHoverCard must be used within a HoverCard');
  }
  return context;
};
