import { AnimatePresence, motion } from 'framer-motion';
import { Tooltip as RadixTooltip } from 'radix-ui';
import { forwardRef, PropsWithChildren, ReactNode, useState, ComponentPropsWithoutRef } from 'react';
import { twJoin } from 'tailwind-merge';

export const TooltipProvider = ({ children }: PropsWithChildren) => (
  <RadixTooltip.Provider
    delayDuration={400}
    skipDelayDuration={300}
  >
    {children}
  </RadixTooltip.Provider>
);

interface ContentProps extends Omit<ComponentPropsWithoutRef<typeof RadixTooltip.Content>, 'content' | 'forceMount' | 'asChild'> {
  open?: boolean;
}

const Content = forwardRef<HTMLDivElement, ContentProps>(
  function Content({
    open = false,
    className,
    children,
    ...props
  }, ref) {
    return (
      <AnimatePresence>
        {open && (
          <RadixTooltip.Content
            ref={ref}
            asChild
            side="top"
            align="center"
            sideOffset={8}
            {...props}
          >
            <motion.div
              className={twJoin(
                'bg-grey-950 text-white dark:bg-grey-600 py-1 px-2 rounded-md max-w-xs shadow-z1 text-caption font-medium z-3000',
                className,
              )}
              animate={open ? 'open' : 'closed'}
              initial="closed"
              exit="closed"
              variants={{
                open: {
                  opacity: 1,
                  scale: 1,
                },
                closed: {
                  opacity: 0,
                  scale: 0.9,
                },
              }}
              transition={{
                duration: 0.15,
              }}
              style={{
                transformOrigin: 'var(--radix-tooltip-content-transform-origin)',
              }}
            >
              {children}
            </motion.div>
          </RadixTooltip.Content>
        )}
      </AnimatePresence>
    );
  },
);

export interface TooltipProps extends
  Omit<RadixTooltip.TooltipProps, 'open' | 'onOpenChange'>,
  Omit<ContentProps, 'open'> {
  disabled?: boolean;
  content: ReactNode;
}

export const Tooltip = ({
  children,
  content,
  defaultOpen,
  delayDuration,
  disableHoverableContent,
  disabled = false,
  ...props
}: TooltipProps) => {
  const [open, onOpenChange] = useState(defaultOpen);

  if (disabled) return (
    <>
      {children}
    </>
  );

  return (
    <RadixTooltip.Root
      onOpenChange={onOpenChange}
      defaultOpen={defaultOpen}
      delayDuration={delayDuration}
      disableHoverableContent={disableHoverableContent}
    >
      <RadixTooltip.Trigger asChild>
        {children}
      </RadixTooltip.Trigger>

      <RadixTooltip.Portal forceMount>
        <Content
          open={open}
          {...props}
        >
          {content}
        </Content>
      </RadixTooltip.Portal>
    </RadixTooltip.Root>
  );
};
