import { Color, LinearAssignee as LinearAssigneeType, Role } from '@cycle-app/graphql-codegen';
import { TooltipLegacy } from '@cycle-app/ui';
import { FC } from 'react';

import { Container, StyledAvatar } from './LinearAssignee.styles';

type Props = {
  assignee?: LinearAssigneeType | null;
  tooltip?: boolean;
  className?: string;
};
export const LinearAssignee: FC<React.PropsWithChildren<Props>> = ({
  assignee, className, tooltip = true,
}) => {
  return tooltip ? (
    <Container className={className}>
      <TooltipLegacy
        content={assignee?.name || 'Unassigned'}
        placement="top"
        withPortal
      >
        {renderContent()}
      </TooltipLegacy>
    </Container>
  ) : renderContent();

  function renderContent() {
    return (
      <StyledAvatar
        size={20}
        unassigned={!assignee}
        src={assignee?.avatarUrl ?? ''}
        {...assignee && {
          user: {
            firstName: assignee.name,
            email: assignee.login,
            color: Color.A,
            role: Role.User,
          },
        }}
        {...!tooltip && { className }}
      />
    );
  }
};
