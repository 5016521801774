import {
  AddBoardFromBoardConfigDocument,
  AddBoardFromBoardConfigMutationVariables,
} from '@cycle-app/graphql-codegen';
import { useCallback, useMemo } from 'react';

import { defaultPagination } from 'src/utils/pagination.util';

import useSafeMutation from '../../useSafeMutation';

export default function useForkBoardMutation() {
  const [addBoardFromBoardConfigMutation, { loading }] = useSafeMutation(AddBoardFromBoardConfigDocument);

  const forkBoardFromBoardConfig = useCallback(async (params: AddBoardFromBoardConfigMutationVariables) => {
    return addBoardFromBoardConfigMutation({
      variables: {
        ...params,
        ...defaultPagination,
      },
    });
  }, [addBoardFromBoardConfigMutation]);

  return useMemo(() => ({
    forkBoardFromBoardConfig,
    loadingForkBoard: loading,
  }), [forkBoardFromBoardConfig, loading]);
}
