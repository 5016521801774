import { AskDocV2Document, ConversationNodeDocument, ConversationRole } from '@cycle-app/graphql-codegen';
import { produce } from 'immer';

import { askVar } from '../../reactives/ask.reactive';
import useSafeMutation from '../useSafeMutation';
import { CONVERSATION_PAGINATION } from './useConversation';

export const useConversationAsk = () => {
  const [askDocMutation, { loading }] = useSafeMutation(AskDocV2Document);

  const ask = ({
    question, conversationId, onSuccess,
  }: { question: string; conversationId: string; docId?: string; onSuccess?: (questionId: string) => void }) => {
    const questionId = btoa(`Conversation_${crypto.randomUUID()}`);
    return askDocMutation({
      variables: {
        question,
        conversationId,
      },
      update: (cache, { data }) => {
        const mutationId = data?.askDocV2;
        if (mutationId) {
          askVar.set(prev => ({
            ...prev,
            progress: 0,
          }));
          cache.updateQuery({
            query: ConversationNodeDocument,
            variables: {
              id: conversationId,
              ...CONVERSATION_PAGINATION,
            },
          }, prev => produce(prev, draft => {
            const createdAt = new Date().toISOString();
            if (draft?.node?.__typename === 'Conversation') {
              draft.node.messages?.edges.push({
                __typename: 'ConversationEdge',
                cursor: '',
                node: {
                  __typename: 'Conversation',
                  id: questionId,
                  content: question,
                  role: ConversationRole.User,
                  createdAt,
                },
              });
              // This id will be used by the ai stream to update the content.
              const answerId = btoa(`Conversation_${mutationId}`);
              draft.node.messages?.edges.push({
                __typename: 'ConversationEdge',
                cursor: '',
                node: {
                  __typename: 'Conversation',
                  id: answerId,
                  content: '',
                  role: ConversationRole.Bot,
                  createdAt,
                  // Display "Thinking..." state.
                  _isPending: true,
                },
              });
            }
          }));
        }
      },
      onCompleted: (data) =>{
        if (onSuccess && data.askDocV2) {
          onSuccess(questionId);
        }
      },
    });
  };

  return {
    ask,
    isLoading: loading,
  };
};
