
import { AskHistory } from './AskHistory';
import { useDocConversations } from '../../hooks/ask/useDocConversations';

export const AskDocHistory = ({
  docId, onSelect, onAskNew,
}: { docId: string; onSelect: (conversationId: string) => void; onAskNew: VoidFunction }) => {
  const {
    conversations, isLoading,
  } = useDocConversations(docId);
  return (
    <AskHistory
      conversations={conversations}
      onSelect={onSelect}
      onAskNew={onAskNew}
      docId={docId}
      isLoading={isLoading}
    />
  );
};
