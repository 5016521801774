import { mobile } from '@cycle-app/utilities';
import { motion } from 'framer-motion';
import styled, { DefaultTheme, ThemeProps } from 'styled-components';

import { body400, body500 } from '../../theme/typo';
import { BOX_SHADOW_COLOR_Z2, BOX_SHADOW_COLOR_Z3 } from '../../utils/styles.util';
import { ActionButton, Button } from '../Buttons';
import { Shortcut } from '../Shortcut/Shortcut';
import { Keys, Key } from '../Shortcut/Shortcut.styles';

const toasterColors = (p: ThemeProps<DefaultTheme>) => p.theme.colors.components.Toaster;

export const Container = styled(motion.div)`
  width: 415px;
  max-height: 100%;
  border-radius: 8px;
  background: ${p => toasterColors(p).bg};
  color: ${p => toasterColors(p).color};
  overflow: clip;
  ${body400}

  box-shadow:
    0px 2px 5px ${BOX_SHADOW_COLOR_Z2},
    0px 3px 11px ${BOX_SHADOW_COLOR_Z3},
    0 0 0 1px ${p => toasterColors(p).border};

  ${mobile} {
    width: 100%;
  }
`;

export const Content = styled.div`
  padding: 12px;
  overflow: hidden;
`;

export const ContentBody = styled.div`
  display: flex;
`;

export const Body = styled.div`
  flex: 1;
  overflow: hidden;
`;

export const Icon = styled.div`
  height: 24px;
  margin-right: 8px;
  float: left;
  display: flex;
  align-items: center;
  flex: none;
`;

export const Title = styled.div`
  color: ${p => p.theme.colors.text.primary};
  user-select: text;
  ${body500}
`;

export const Message = styled.div`
  color: ${p => toasterColors(p).color};
  user-select: text;
  ${body400}

  &:not(:first-child) {
    margin-top: 4px;
  }
`;

export const SideActions = styled.div`
  margin-left: 8px;
  float: right;
  display: flex;
  align-items: center;
  gap: 4px;
  z-index: 4;
  position: relative;
`;

export const BottomActions = styled.div`
  display: flex;
  gap: 4px;
  margin: 8px 0 0 0;
`;

export const Action = styled(Button).attrs({
  variant: 'secondary',
  size: 'M',
})`
  ${body500}
  border-radius: 4px;
  padding: 0px 8px;
  border: none;

  :hover, :focus {
    background: ${p => p.theme.colors.button.secondary.bgHover};
  }
  :active {
    background: ${p => p.theme.colors.button.secondary.bgActive};
  }
`;

export const CloseButton = styled(ActionButton).attrs({
  size: 'M',
})`
  width: 24px;
  height: 24px;
  --color: ${p => toasterColors(p).light};
  --colorHover: ${p => p.theme.colors.text.secondary};
`;

export const StyledShortcut = styled(Shortcut)`
  ${Keys} {
    gap: 0;
  }
  ${Key} {
    min-width: 14px;
    padding: 0;
    background: none;
    color: ${p => p.theme.colors.text.disabled};
  }
`;
