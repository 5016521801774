import { ChangelogHeroStyle, RessourceType } from '@cycle-app/graphql-codegen';
import { parseToRgb } from 'polished';
import { ReactNode } from 'react';

import { useCreateSubscribeWebhook } from 'src/app/Main/Settings/SettingsAPI/WebhooksSection/useCreateWebhook';
import { useUpdateWebhook } from 'src/app/Main/Settings/SettingsAPI/WebhooksSection/useUpdateWebhook';
import { useChangelog } from 'src/hooks/releases/useChangelog';
import { useChangelogUpdate } from 'src/hooks/releases/useChangelogUpdate';

import { heroImageSuggestions } from './content/hero/heroImages';
import { generateChangelogDefaultValues, useChangelogBuilderForm } from './useChangelogBuilderForm';

export function ChangelogBuilderFormSubmit({ children }: { children: ReactNode }) {
  const handleCreateSubscribeWebhook = useCreateSubscribeWebhook();
  const handleUpdateSubscribeWebhook = useUpdateWebhook();
  const { changelog } = useChangelog();
  const { changelogUpdate } = useChangelogUpdate();
  const {
    handleSubmit, reset, getFieldState,
  } = useChangelogBuilderForm();

  const handleFormSubmit = handleSubmit(async (data) => {
    if (!changelog?.id) return;

    const webhookChanged = getFieldState('subscribeWebhook').isDirty;

    if (webhookChanged) {
      await (changelog.subscribeWebhook
        ? handleUpdateSubscribeWebhook({
          url: data.subscribeWebhook.url,
          label: data.subscribeWebhook.label,
        }, changelog.subscribeWebhook)
        : handleCreateSubscribeWebhook({
          url: data.subscribeWebhook.url,
          label: data.subscribeWebhook.label,
          ressources: [RessourceType.ChangelogSubscribed],
        }));
    }

    const result = await changelogUpdate({
      id: changelog.id,
      title: data.title,
      subtitle: data.subtitle,
      socialLinkText: data.socialLinkText,
      socialLinkURL: data.socialLinkURL,
      language: data.language,
      logo: getMaybeFileAvatar(data.logo, changelog.logo?.url),
      favicon: getMaybeFileAvatar(data.favicon, changelog.favicon?.url),
      shipBadgeColor: data.shipBadgeColor,
      typography: data.typography,
      heroSection: data.heroSection,
      heroStyle: data.heroStyle,
      heroImage: getMaybeFileAvatar(await getHeroImageFile(data.heroImage, data.heroStyle), changelog.heroImage?.url),
      availableHeroImages: data.availableHeroImages,
      contentSection: data.contentSection,
      headersColor: getMaybeColor(data.headersColor),
      bodyColor: getMaybeColor(data.bodyColor),
      backgroundColor: getMaybeColor(data.backgroundColor),
      dividerColor: getMaybeColor(data.dividerColor),
      linkColor: getMaybeColor(data.linkColor),
      releaseTagStyle: data.releaseTagStyle,
      releaseTagColor: getMaybeColor(data.releaseTagColor),
      tags: data.tags.filter(tag => tag.enabled).map(tag => ({
        id: tag.id,
        value: tag.value,
        color: getMaybeColor(tag.color) || '#FFFFFF',
      })),
      subscribeLabel: data.subscribeLabel,
      subscribeToggled: data.subscribeWebhook.url ? data.subscribeToggled : false,
      dateStyle: data.dateStyle,
    });

    if (result?.data?.updateChangelog) {
      reset(generateChangelogDefaultValues(result.data.updateChangelog));
    }
  });

  return (
    <form
      className="contents"
      onSubmit={handleFormSubmit}
    >
      {children}
    </form>
  );
}

function getMaybeFileAvatar(file: File | string | null, previousAvatar?: string) {
  // elemnt has already been uploaded, no need for a change
  if (typeof file === 'string') {
    if (file === previousAvatar) return undefined;
    return { avatarUrl: file };
  }

  // the file was removed
  if (!file) return null;

  return {
    avatar: file,
  };
}

async function getHeroImageFile(heroImage: File | string | null, heroStyle: ChangelogHeroStyle) {
  const shouldUploadMedia = [ChangelogHeroStyle.Image, ChangelogHeroStyle.TextImage].includes(heroStyle);

  if (heroImage instanceof File && shouldUploadMedia) {
    // we don't want to upload a new media if the hero does not contain an image
    return shouldUploadMedia ? heroImage : null;
  }

  if (typeof heroImage !== 'string') return heroImage;

  // image is a suggestion. We need to upload it to the backend so we treat it as a new upload
  if (heroImageSuggestions.includes(heroImage)) {
    // we don't want to upload a new media if the hero does not contain an image
    if (!shouldUploadMedia) return null;

    const response = await fetch(heroImage);
    const blob = await response.blob();
    return new File([blob], heroImage, { type: blob.type });
  }

  return heroImage;
}

function getMaybeColor(color: string | null | undefined): string | undefined {
  if (!color) return undefined;

  try {
    parseToRgb(color);
    return color;
  } catch {
    return undefined;
  }
}
