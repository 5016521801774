import { BoardConfigContextProvider } from 'src/contexts/boardConfigContext';
import { useFeatureFlag } from 'src/hooks';

import { StyledDropdown } from './Ask.styles';
import { useCycleAskExamples } from './useAskExamples';
import { askVar, closeAsk } from '../../reactives/ask.reactive';
import { AskProduct } from '../AskV2/AskProduct';

export const Ask = () => {
  const isAskEnabled = useFeatureFlag('ask').isEnabled;
  const { isOpen } = askVar.hook();
  const examples = useCycleAskExamples();
  if (!isAskEnabled) return null;

  // BoardConfigContextProvider is needed for the docs
  return (
    <BoardConfigContextProvider>
      <StyledDropdown
        withPortal
        placement="top-start"
        visible={isOpen}
        hide={closeAsk}
        offset={[30, 30]}
        getReferenceClientRect={() => new DOMRect(0, window.innerHeight, 0, 0)}
        width={596}
        content={(
          <AskProduct examples={examples} />
        )}
        className="dark:bg-grey-900!"
      />
    </BoardConfigContextProvider>
  );
};
