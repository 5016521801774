import { Input, Icon } from '@cycle-app/ui';
import { isUrl } from '@cycle-app/utilities';
import { twJoin } from 'tailwind-merge';
import { Placement } from 'tippy.js';

import { ChangelogDropdown, ChangelogDropdownContent } from '../shared/ChangelogDropdown';
import { useChangelogBuilderForm } from '../useChangelogBuilderForm';

export function ChangelogBuilderContentLink({ dropdownPlacement = 'bottom' }: { dropdownPlacement?: Placement }) {
  const { watch } = useChangelogBuilderForm();

  const socialLinkText = watch('socialLinkText');

  return (
    <ChangelogDropdown
      hoverStateClassName="-m-1 p-1 rounded-lg"
      content={({ hide }) => <LinkModal hide={hide} />}
      placement={dropdownPlacement}
    >
      <div
        className={twJoin(
          'flex items-center gap-2 text-size-inherit font-normal',
          socialLinkText ? 'text-(--changelog-link-color)' : 'text-secondary',
        )}
      >
        {socialLinkText || (
          <>
            <Icon
              name="plus"
              className="size-3"
            />
            Link
          </>
        )}
      </div>
    </ChangelogDropdown>
  );
}

function LinkModal({ hide }: { hide: VoidFunction }) {
  const {
    register, setValue, watch, formState: { errors },
  } = useChangelogBuilderForm();

  const handleRemoveLink = () => {
    setValue('socialLinkText', '', { shouldDirty: true });
    setValue('socialLinkURL', '', { shouldDirty: true });
    hide();
  };

  return (
    <ChangelogDropdownContent
      title="Link"
      hide={hide}
    >
      <Input
        label="URL"
        autoFocus
        placeholder="https://example.com"
        error={errors.socialLinkURL?.message}
        {...register('socialLinkURL', {
          required: 'Required',
          validate: (newUrl) => {
            if (!newUrl) return true;
            const isValid = isUrl(newUrl, { strict: false });
            return isValid || 'Invalid URL';
          },
        })}
      />

      <Input
        className="mt-4"
        label="Title"
        placeholder="Follow us on Twitter"
        error={errors.socialLinkText?.message}
        {...register('socialLinkText', { required: 'Title is required' })}
      />

      {watch('socialLinkText') && (
        <button
          className="btn-tertiary btn-lg mt-4 w-full"
          onClick={handleRemoveLink}
        >
          Remove link
        </button>
      )}
    </ChangelogDropdownContent>
  );
}
