import { CycleStandardIcon } from '@cycle-app/ui/icons';
import { motion } from 'framer-motion';
import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  bottom: 24px;
  right: 24px;
  z-index: 1;
`;

export const LogoContainer = styled(motion.div)`
  cursor: pointer;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 48px;
  background: hsl(var(--cycle));
`;

export const Logo = styled(CycleStandardIcon)`
  width: 26px;
  height: 26px;
`;

export const TooltipLegacy = styled(motion.div)`
  position: absolute;
  right: 0;
  padding: 4px 8px 4px 8px;
  background: ${p => p.theme.colors.background.primary};
  bottom: calc(100% + 12px);
  box-shadow: 0px 3px 21px 0px rgba(0, 0, 0, 0.13);
  white-space: nowrap;
  border-radius: 8px;
`;
