import { useFormState } from 'react-hook-form';
import { twJoin } from 'tailwind-merge';

import { useChangelogBuilderForm } from './useChangelogBuilderForm';

export function ChangelogBuilderFooter() {
  const {
    control, reset,
  } = useChangelogBuilderForm();

  const {
    isDirty, isSubmitting,
  } = useFormState({ control });

  if (!isDirty) return null;

  return (
    <footer
      // eslint-disable-next-line max-len
      className="fixed inset-x-0 bottom-0 z-10 flex items-center justify-end gap-2 border-t border-grey-150 bg-white px-10 py-5 dark:border-grey-950 dark:bg-black"
    >
      <button
        type="button"
        className="btn-secondary btn-lg"
        onClick={() => reset()}
      >
        Discard
      </button>
      <button
        type="submit"
        className={twJoin('btn-primary btn-lg', isSubmitting && 'btn-loading')}
      >
        Save changes
      </button>
    </footer>
  );
}
