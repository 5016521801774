import { ComponentProps } from 'react';
import { twJoin } from 'tailwind-merge';

export const Badge = ({
  className, ...props
}: ComponentProps<'div'>) => (
  <div
    className={twJoin(
      'inline-flex h-5 min-w-4 items-center justify-center rounded-sm px-1 text-caption font-medium leading-none',
      'bg-grey-150 text-grey-600 dark:bg-grey-800 dark:text-grey-500',
      className,
    )}
    {...props}
  />
);
