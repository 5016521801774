import { Language } from '@cycle-app/graphql-codegen';
import { produce } from 'immer';

import { LocalKey } from 'src/types/localStorage.types';
import { make } from 'src/utils/reactives.util';

type Value = Language | null;

// { userId: language }
type State = Partial<Record<string, Value>>;

const {
  hookState,
  hookValue,
  getValue,
  setValue,
  resetValue: resetLanguage,
} = make<State>({
  defaultState: {},
  localKey: LocalKey.Language,
  crossTabSync: true,
});

export const setLanguage = (userId: string, language: Value) => {
  setValue(produce(getValue(), draft => {
    // eslint-disable-next-line no-param-reassign
    draft[userId] = language;
  }));
};

export const getLanguage = (userId: string) => getValue()[userId];

export const useGetLanguage = (userId: string) => hookValue()[userId];

export const useLanguage = (userId: string | undefined) => {
  const [state, setState] = hookState();

  const setter = (language: Value) => {
    if (!userId) return;
    setState(produce(state, draft => {
      // eslint-disable-next-line no-param-reassign
      draft[userId] = language;
    }));
  };

  return [
    userId ? state[userId] : undefined,
    setter,
  ] as const;
};

export { resetLanguage };
