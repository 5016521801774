import { Skeleton } from '@cycle-app/ui';
import { RefObject } from 'react';
import { useInView } from 'react-intersection-observer';

import { QuoteCard } from 'src/components/QuoteCard';
import { useDocV2 } from 'src/hooks';

type Props = {
  docId: string;
  rootRef: RefObject<HTMLDivElement>;
};

export const AnswerDoc = ({
  docId, rootRef,
}: Props) => {
  const data = useDocV2(docId);

  const {
    ref, inView,
  } = useInView({
    root: rootRef.current,
    rootMargin: '100px',
  });

  if (data.isLoading) return <Skeleton className="h-2" />;

  if (!data.doc) return null;

  return (
    <div ref={ref}>
      {inView ? (
        <QuoteCard
          docId={docId}
          showCustomer
          showFeature
        />
      ) : (
        <div
          className="h-32 flex-none rounded-lg border border-grey-200 dark:border-grey-800"
        />
      )}
    </div>
  );
};
