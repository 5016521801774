import { Color as ColorApi } from '@cycle-app/graphql-codegen';
import { MouseEvent } from 'react';
import styled, { css } from 'styled-components';

import { colorUtils } from '../../utils/cssFunctions.util';

export const Container = styled.div`
  display: grid;
  gap: 8px;
  grid-template-columns: repeat(auto-fill, 24px);
`;

interface ColorItemProps {
  $color: ColorApi;
  $hasIcon?: boolean;
  $small?: boolean;
  onClick?: (e: MouseEvent<HTMLDivElement>) => void;
  $variant?: 'user' | 'property';
}

export const ColorItem = styled.div<ColorItemProps>`
  background-color: ${p => (p.$variant === 'property'
    ? colorUtils.setLightness(p.theme.nuances[p.$color].tag, 0.7)
    : p.theme.nuances[p.$color].main)};
  border-radius: 50%;
  cursor: ${p => (p.onClick ? 'pointer' : 'initial')};
  width: 24px;
  height: 24px;

  ${p => p.$small && css`
    width: 20px;
    height: 20px;
  `};

  ${p => p.$hasIcon && css`
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      color: ${p.theme.colors.text.white};
      width: 12px;
      height: 12px;
    }
  `}
`;
