import { Icon } from '@cycle-app/ui';
import { EmailIcon } from '@cycle-app/ui/icons';

export const sources = [
  {
    id: 'slack',
    icon: (
      <Icon
        name="brand/slack"
        className="size-4"
      />
    ),
    label: 'Slack',
  },
  {
    id: 'intercom',
    icon: (
      <Icon
        name="brand/intercom"
        className="size-4"
      />
    ),
    label: 'Intercom',
  },
  {
    id: 'zendesk',
    icon: (
      <Icon
        name="brand/zendesk"
        className="size-4"
      />
    ),
    label: 'Zendesk',
  },
  {
    id: 'hubspot',
    icon: (
      <Icon
        name="brand/hubspot"
        className="size-4"
      />
    ),
    label: 'HubSpot',
  },
  {
    id: 'salesforce',
    icon: (
      <Icon
        name="brand/salesforce"
        className="size-4"
      />
    ),
    label: 'Salesforce',
  },
  {
    id: 'email',
    icon: <EmailIcon />,
    label: 'Email',
  },
  {
    id: 'productboard',
    icon: (
      <Icon
        name="brand/productboard"
        className="size-4"
      />
    ),
    label: 'ProductBoard',
  },
  {
    id: 'canny',
    icon: (
      <Icon
        name="brand/canny"
        className="size-4"
      />
    ),
    label: 'Canny',
  },
  {
    id: 'gong',
    icon: (
      <Icon
        name="brand/gong"
        className="size-4"
      />
    ),
    label: 'Gong',
  },
  {
    id: 'front',
    icon: (
      <Icon
        name="brand/front"
        className="size-4"
      />
    ),
    label: 'Front',
  },
  {
    id: 'discord',
    icon: (
      <Icon
        name="brand/discord"
        className="size-4"
      />
    ),
    label: 'Discord',
  },
  {
    id: 'freshdesk',
    icon: (
      <Icon
        name="brand/freshdesk"
        className="size-4"
      />
    ),
    label: 'Freshdesk',
  },
  {
    id: 'attio',
    icon: (
      <Icon
        name="brand/attio"
        className="size-4"
      />
    ),
    label: 'Attio',
  },
  {
    id: 'fullstory',
    icon: (
      <Icon
        name="brand/fullstory"
        className="size-4"
      />
    ),
    label: 'FullStory',
  },
  {
    id: 'gainsight',
    icon: (
      <Icon
        name="brand/gainsight"
        className="size-4"
      />
    ),
    label: 'Gainsight',
  },
  {
    id: 'google-meet',
    icon: (
      <Icon
        name="brand/google-meet"
        className="size-4"
      />
    ),
    label: 'Google Meet',
  },
  {
    id: 'fathom',
    icon: (
      <Icon
        name="brand/fathom"
        className="size-4"
      />
    ),
    label: 'Fathom',
  },
  {
    id: 'fireflies',
    icon: (
      <Icon
        name="brand/fireflies"
        className="size-4"
      />
    ),
    label: 'Fireflies',
  },
];
