import { ShyScrollbar, Skeleton } from '@cycle-app/ui';
import { AiIcon } from '@cycle-app/ui/icons';
import { AnimatePresence, motion } from 'framer-motion';
import { useRef, useState } from 'react';

import { useConversationCreate } from '../../hooks/ask/useConversationCreate';
import { askVar } from '../../reactives/ask.reactive';
import { addToaster } from '../../utils/toasters.utils';
import { AskExample, AskExampleList, Question, QuestionDate, QuestionInput } from '../Ask/Ask.styles';

type Props = {
  docId?: string;
  onAsk: (conversationId: string) => void;
  examples?: typeof docExamples;
  pending?: boolean;
};

export const AskNewConversation = ({
  docId, onAsk, examples, pending,
}: Props) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const {
    create, isLoading,
  } = useConversationCreate();
  const [defaultValue, setDefaultValue] = useState('');
  const [loader, setLoader] = useState({
    createdAt: '',
    question: '',
  });
  return (
    <div className="grow flex flex-col justify-between overflow-hidden">
      {isLoading && (
        <div className="flex flex-col gap-5 px-5">
          <div className="flex flex-col items-center gap-4">
            <QuestionDate>
              {loader.createdAt}
            </QuestionDate>
            <Question>
              {loader.question}
            </Question>
          </div>
          <Skeleton className="h-6! w-20! opacity-50" />
        </div>
      )}
      {!isLoading && (
        <ShyScrollbar className="flex items-center grow">
          <div className="px-5">
            <span className="text-header-small font-medium block">
              <AiIcon
                className="align-middle mr-2"
                hasGradient
                size={16}
              />
              Ask Cycle
            </span>
            <p className="text-secondary mt-1.5 mb-5">
              {docId ? 'You can ask me questions about your feedback' : 'You can ask me questions about your feedbacks'}
            </p>
            <AnimatePresence>
              <AskExampleList>
                {(examples || docExamples).map((question, i) => (
                  <motion.li
                    key={question.id}
                    initial={{
                      opacity: 0,
                      y: 2,
                    }}
                    animate={{
                      opacity: 1,
                      y: 0,
                      transition: { delay: 0.05 * i },
                    }}
                  >
                    <AskExample
                      variant="outlined-alt"
                      size="M"
                      onClick={() => {
                        inputRef.current?.focus();
                        setDefaultValue(question.content);
                      }}
                    >
                      {question.content}
                    </AskExample>
                  </motion.li>
                ))}
              </AskExampleList>
            </AnimatePresence>
          </div>
        </ShyScrollbar>
      )}
      <div className="py-5 bg-primary dark:bg-grey-950 flex-none px-5">
        <QuestionInput
          ref={inputRef}
          key={defaultValue}
          defaultValue={defaultValue}
          autoFocus
          placeholder={docId ? 'Ask a question to your feedback' : 'Ask a question to your feedbacks'}
          onKeyDown={async (e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
              if (pending) {
                // Product level ask should be blocked if a ask is still pending.
                addToaster({
                  title: 'Wait for previous question to finish…',
                });
                return;
              }
              const question = e.currentTarget.value.trim();
              if (!question) return;
              askVar.set(prev => ({
                ...prev,
                progress: 0,
              }));
              e.currentTarget.value = '';
              setLoader({
                createdAt: new Date().toISOString(),
                question: question,
              });
              await create({
                question,
                docId,
                onSuccess: onAsk,
              });
            }
          }}
        />
      </div>
    </div>
  );
};

const docExamples = [
  {
    id: 'a',
    content: 'Can you please summarize this feedback?',
  },
  {
    id: 'b',
    content: 'What feature requests do we have in this feedback?',
  },
  {
    id: 'c',
    content: 'What are the top pain points you identified here?',
  },
  {
    id: 'd',
    content: 'Can you please list some action points based on this feedback?',
  },
];
