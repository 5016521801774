import { useQuery } from '@apollo/client';
import { FilterPropertyRuleAssigneeWithSearchDocument, FilterPropertyRuleCreatorWithSearchDocument } from '@cycle-app/graphql-codegen';
import { SelectPanel, SelectOption, SelectPanelProps } from '@cycle-app/ui';
import { nodeToArray } from '@cycle-app/utilities';
import { FC, useState } from 'react';
import { twJoin } from 'tailwind-merge';

import DropdownLayer from 'src/components/DropdownLayer/DropdownLayer';
import useOptimizedBooleanState from 'src/hooks/useOptimizedBooleanState';
import { FilterRule } from 'src/types/filters.types';
import { extract } from 'src/types/graphql.types';
import { Layer } from 'src/types/layers.types';
import { UserFromFilter, selectableUserToOption  } from 'src/utils/boardConfig/filtersData.util';

import { Placeholder } from '../Filter.styles';
import { Container, MoreValues } from './FilterElements.styles';

interface Props {
  className?: string;
  dropdownLayer?: Layer;
  filter: FilterRule;
  selectedUsers: UserFromFilter[];
  defaultOptions: SelectOption[];
  onOptionsAdded: (newOptions: SelectOption[]) => void;
  onOptionRemoved: (optionValue: string) => void;
  disabled?: boolean;
}

const UserFilterElements: FC<React.PropsWithChildren<Props>> = ({
  className = '',
  dropdownLayer = Layer.DropdownBoardConfig,
  children,
  filter,
  selectedUsers,
  defaultOptions,
  onOptionsAdded,
  onOptionRemoved,
  disabled = false,
}) => {
  const [isDropdownVisible, {
    setFalseCallback: hideDropdown,
    toggleCallback: toggleDropdown,
  }] = useOptimizedBooleanState(false);

  return (
    <DropdownLayer
      layer={dropdownLayer}
      placement="bottom-start"
      visible={isDropdownVisible}
      hide={hideDropdown}
      disabled={disabled}
      withWrapper={false}
      content={(
        <DropdownContent
          filter={filter}
          selectedUsers={selectedUsers}
          defaultOptions={defaultOptions}
          onUnselectOption={o => onOptionRemoved(o.value)}
          onSelectOption={o => onOptionsAdded([...selectedUsers.map(selectableUserToOption), {
            ...o,
            selected: true,
          }])}
        />
      )}
    >
      <Container
        className={className}
        forceFocus={isDropdownVisible}
        onClick={toggleDropdown}
        disabled={disabled || defaultOptions.length === 0}
      >
        {selectedUsers?.[0]
          ? (
            <>
              <span className="truncate">
                {children ?? selectedUsers[0].value.firstName}
              </span>
              {selectedUsers.length > 1 && (
                <MoreValues>
                  {`+${selectedUsers.length - 1}`}
                </MoreValues>
              )}
            </>
          )
          : (
            <Placeholder>
              Select users
            </Placeholder>
          )}
      </Container>
    </DropdownLayer>
  );
};

export default UserFilterElements;

type DropdownContentProps = Pick<SelectPanelProps, 'onSelectOption' | 'onUnselectOption'> & {
  selectedUsers: UserFromFilter[];
  defaultOptions: SelectOption[];
  filter: FilterRule;
};

const DropdownContent = ({
  selectedUsers,
  defaultOptions,
  filter,
  ...props
}: DropdownContentProps) => {
  const [searchText, setSearchText] = useState('');

  const creatorQuery = useQuery(FilterPropertyRuleCreatorWithSearchDocument, {
    skip: !searchText || filter.__typename !== 'FilterPropertyRuleCreator',
    variables: {
      id: filter.id,
      searchText,
    },
  });

  const assigneeQuery = useQuery(FilterPropertyRuleAssigneeWithSearchDocument, {
    skip: !searchText || filter.__typename !== 'FilterPropertyRuleAssignee',
    variables: {
      id: filter.id,
      searchText,
    },
  });

  const query = filter.__typename === 'FilterPropertyRuleCreator' ? creatorQuery : assigneeQuery;

  const rule = filter.__typename === 'FilterPropertyRuleCreator'
    ? extract('FilterPropertyRuleCreator', creatorQuery.data?.node ?? creatorQuery.previousData?.node)?.rule
    : extract('FilterPropertyRuleAssignee', assigneeQuery.data?.node ?? assigneeQuery.previousData?.node)?.rule;

  const options = !searchText || rule?.__typename !== 'RuleUserMultipleValues'
    ? defaultOptions
    : nodeToArray(rule.values).map(v => ({
      ...selectableUserToOption(v),
      selected: !!selectedUsers.some(user => user.value.id === v.value.id),
    }));

  return (
    <SelectPanel
      className={twJoin('max-w-sm!', query.loading && 'opacity-50')}
      isMulti
      title="Selected users"
      selectedCount={selectedUsers.length}
      toggleAllValuesVariant="toggle"
      debounceSearch
      filterOptionsOnInputChange={false}
      options={options}
      onSearchChange={setSearchText}
      {...props}
    />
  );
};
