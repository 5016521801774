import {
  ProductAreaCategoryFragmentDoc,
  ProductAreaCreateDocument,
  ProductAreaDeleteDocument,
  ProductAreaUpdateDocument,
  ProdutAreaCategoryCreateDocument,
  ProductAreaPropertyUpdateDocument,
  ProductAreaMoveCategoryDocument,
} from '@cycle-app/graphql-codegen';
import { ERROR_CODE } from '@cycle-app/utilities';
import { produce } from 'immer';

import { ErrorMessage } from '../../constants/errors.constants';
import { useWorkspaceContext } from '../../contexts/workspaceContext';
import { getPermission, setLimitationsModal } from '../../reactives';
import { RefConnection } from '../../types/apollo.types';
import { addErrorToaster } from '../../utils/errorToasters.utils';
import { addToaster } from '../../utils/toasters.utils';
import { useProductAreaAttribute } from '../api/useAttributes';
import useSafeMutation from '../useSafeMutation';

const blockAction = () => setLimitationsModal({ action: 'HANDLE_ATTRIBUTES_OPTIONS' });

export const useWorkspaceProductAreaUpdate = () => {
  const [mutateCreate, createState] = useSafeMutation(ProductAreaCreateDocument);
  const [mutateDelete, deleteState] = useSafeMutation(ProductAreaDeleteDocument);
  const [mutateUpate, updateState] = useSafeMutation(ProductAreaUpdateDocument);
  const [mutateCategoryCreate, createCategoryState] = useSafeMutation(ProdutAreaCategoryCreateDocument);
  const [mutateProperty] = useSafeMutation(ProductAreaPropertyUpdateDocument);
  const [mutateMoveCategory] = useSafeMutation(ProductAreaMoveCategoryDocument);
  const productId = useWorkspaceContext(ctx => ctx.productId);
  const {
    attributeName, categoryName, attribute, categoryAttribute,
  } = useProductAreaAttribute();

  const updateAttributeCategory = (name: string, onSuccess?: (name: string) => void) => {
    if (!getPermission().canUpdateAttributeOption) {
      blockAction();
      return;
    }
    return categoryAttribute?.id && mutateProperty({
      variables: {
        id: categoryAttribute.id,
        name,
      },
      optimisticResponse: {
        updateBuiltinProperty: {
          __typename: 'ProductAreaCategoryDefinition',
          id: categoryAttribute.id,
          name,
        },
      },
      onCompleted: (data) => {
        if (onSuccess && data.updateBuiltinProperty?.__typename === 'ProductAreaCategoryDefinition') {
          onSuccess(data.updateBuiltinProperty.name);
        }
      },
    });
  };

  const updateAttributeArea = (name: string, onSuccess?: (name: string) => void) => {
    if (!getPermission().canUpdateAttributeOption) {
      blockAction();
      return;
    }
    return attribute?.id && mutateProperty({
      variables: {
        id: attribute.id,
        name,
      },
      optimisticResponse: {
        updateBuiltinProperty: {
          __typename: 'ProductAreaDefinition',
          id: attribute.id,
          name,
        },
      },
      onCompleted: (data) => {
        if (onSuccess && data.updateBuiltinProperty?.__typename === 'ProductAreaDefinition') {
          onSuccess(data.updateBuiltinProperty.name);
        }
      },
    });

  };

  const createCategory = ({
    name, onSuccess,
  }: { name: string; onSuccess?: VoidFunction }) => {
    if (!getPermission().canUpdateAttributeOption) {
      blockAction();
      return;
    }
    return mutateCategoryCreate({
      variables: {
        productId,
        name,
      },
      optimisticResponse: {
        createProductAreaCategory: {
          id: 'temp-id',
          value: name,
          productAreas: {
            edges: [],
          },
        },
      },
      update: (cache, { data }) => {
        if (data?.createProductAreaCategory?.id) {
          const {
            id, value,
          } = data.createProductAreaCategory;
          cache.modify({
            id: productId,
            fields: {
              productAreaCategories: (current) => {
                return {
                  edges: [
                    ...current.edges,
                    {
                      node: cache.writeFragment({
                        id,
                        fragment: ProductAreaCategoryFragmentDoc,
                        fragmentName: 'ProductAreaCategory',
                        data: {
                          id,
                          productAreas: { edges: [] },
                          value,
                        },
                      }),
                    },
                  ],
                };
              },
            },
          });
        }
      },
      onCompleted: (data) => {
        if (data.createProductAreaCategory?.id) {
          addToaster({
            title: `${categoryName} ${data.createProductAreaCategory.value} was successfully created`,
          });
          onSuccess?.();
        }
      },
    });
  };

  const create = ({
    name, description, onSuccess, categoryId,
  }: { name: string; description?: string; onSuccess?: VoidFunction; categoryId: string | null }) => {
    if (!getPermission().canUpdateAttributeOption) {
      blockAction();
      return;
    }
    return mutateCreate({
      variables: {
        productId,
        name,
        description,
        categoryId,
      },
      errorPolicy: 'all',
      optimisticResponse: {
        createProductArea: {
          id: 'temp-id',
          value: name,
          description: description || null,
          category: categoryId ? { id: categoryId } : null,
        },
      },
      onCompleted: (data) => {
        if (data.createProductArea?.id) {
          addToaster({
            title: `${attributeName} ${data.createProductArea.value} was successfully created`,
          });
          onSuccess?.();
        }
      },
      onError: (error) => {
        if (error.message === ERROR_CODE.PRODUCT_AREA_ALREADY_EXISTS) {
          addErrorToaster({ message: `${attributeName} ${name} already exists` });
        } else {
          addErrorToaster({ message: ErrorMessage._GENERIC });
        }
      },
      update: (cache, { data }) => {
        if (data?.createProductArea?.id) {
          const productAreaId = data.createProductArea.id;
          cache.modify({
            // The productAreas resolver of the product includes all the product areas.
            id: categoryId || productId,
            fields: {
              // @ts-ignore -- TOFIX later
              productAreas: (value: RefConnection, { toReference }) => {
                const node = toReference(productAreaId);
                return node ? produce(value, draft => {
                  const edge = {
                    __typename: 'ProductAreaEdge',
                    node,
                  };
                  draft.edges.push(edge);
                }) : value;
              },
            },
          });
        }
      },
    });
  };

  const remove = ({
    id, onRemove, categoryId,
  }: { id: string; categoryId: string | null; onRemove?: (productAreaId: string) => void }) => {
    if (!getPermission().canUpdateAttributeOption) {
      blockAction();
      return;
    }
    return mutateDelete({
      variables: {
        id,
      },
      optimisticResponse: {
        removeProductArea: {
          id,
        },
      },
      onCompleted: (data) => {
        if (data.removeProductArea?.id) {
          onRemove?.(id);
          addToaster({
            title: `${attributeName} was successfully deleted`,
          });
        }
      },
      update: (cache, { data }) => {
        if (data?.removeProductArea?.id) {
          cache.modify({
            // The productAreas resolver of the product includes all the product areas.
            id: categoryId || productId,
            fields: {
              // @ts-ignore -- TOFIX later
              productAreas: (value: RefConnection, { readField }) => {
                return produce(value, draft => {
                  draft.edges.splice(draft.edges.findIndex(edge => {
                    return readField<string>('id', edge.node) === id;
                  }), 1);
                });
              },
            },
          });
          cache.evict({
            id: cache.identify(data.removeProductArea),
          });
          cache.gc();
        }
      },
    });
  };

  const update = ({
    id, name, description,
  }: { id: string; name?: string; description?: string }) => {
    if (!getPermission().canUpdateAttributeOption) {
      blockAction();
      return;
    }
    return mutateUpate({
      errorPolicy: 'all',
      variables: {
        id,
        ...typeof name !== 'undefined' && { name },
        ...typeof description !== 'undefined' && { description },
      },
      onError: (error) => {
        if (error.message === ERROR_CODE.PRODUCT_AREA_ALREADY_EXISTS) {
          addErrorToaster({ message: `${attributeName} ${name} already exists` });
        }
      },
    });
  };

  const moveCategory = ({
    id, currentCategoryId, categoryId,
  }: { id: string; currentCategoryId: string | null; categoryId: string | null }) => {
    if (currentCategoryId === categoryId) {
      return;
    }
    if (!getPermission().canUpdateAttributeOption) {
      blockAction();
      return;
    }
    return mutateMoveCategory({
      variables: {
        id,
        categoryId,
      },
      optimisticResponse: {
        updateProductAreaProductAreaCategory: {
          id,
          category: categoryId ? { id: categoryId } : null,
        },
      },
      update: (cache, { data }) => {
        if (data?.updateProductAreaProductAreaCategory?.id) {
          if (categoryId) {
            // Add to new category
            cache.modify({
              id: categoryId,
              fields: {
                // @ts-ignore -- TOFIX later
                productAreas: (value: RefConnection, { toReference }) => {
                  const node = toReference(id);
                  return node ? produce(value, draft => {
                    const edge = {
                      __typename: 'ProductAreaEdge',
                      node,
                    };
                    draft.edges.push(edge);
                  }) : value;
                },
              },
            });
          }
          if (currentCategoryId) {
            // Remove from current category
            cache.modify({
              id: currentCategoryId,
              fields: {
                // @ts-ignore -- TOFIX later
                productAreas: (value: RefConnection, { readField }) => {
                  return produce(value, draft => {
                    draft.edges.splice(draft.edges.findIndex(edge => {
                      return readField<string>('id', edge.node) === id;
                    }), 1);
                  });
                },
              },
            });
          }
        }
      },
    });
  };

  return {
    create,
    createCategory,
    remove,
    update,
    updateAttributeCategory,
    updateAttributeArea,
    moveCategory,
    isCreateLoading: createState.loading,
    isCreateCategoryLoading: createCategoryState.loading,
    isDeleteLoading: deleteState.loading,
    isUpdateLoading: updateState.loading,
  };
};
