import { Button, colorUtils } from '@cycle-app/ui';
import { DownIcon } from '@cycle-app/ui/icons';
import styled from 'styled-components';

export const FilterButton = styled(Button).attrs({
  variant: 'light',
  size: 'M',
})`
  height: 24px;
  padding: 0 8px;
  gap: 4px;
  color: ${p => p.theme.colors.text.secondary};

  ${p => p.theme.isDark && `
    --bg: ${p.theme.colors.background.tertiary};
    :hover, :focus, :active {
      --bg: ${colorUtils.lighten(p.theme.colors.background.hoverSoft, 0.1)};
    }
  `}
`;

export const ArrowIcon = styled(DownIcon)`
  color: ${p => p.theme.colors.text.disabled};
`;
