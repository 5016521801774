import { Icon } from '@cycle-app/ui';

import { TooltipClickToCopy } from 'src/components/TooltipClickToCopy';
import { useCompany } from 'src/hooks/api/queries/customers/useCompany';

import { ExternalIdsContainer } from './Profile.styles';

const builtInKeys = ['zendeskId', 'hubspotId', 'intercomId', 'pipedriveId', 'snowflakeId', 'attioId', 'salesforceId'] as const;

type BuitInAttributeKeys = typeof builtInKeys[number];

const data: Record<BuitInAttributeKeys, { name?: string; icon?: JSX.Element }> = {
  zendeskId: {
    name: 'Zendesk ID',
    icon: (
      <Icon
        name="brand/zendesk"
        className="size-3.5"
      />
    ),
  },
  hubspotId: {
    name: 'HubSpot ID',
    icon: (
      <Icon
        name="brand/hubspot"
        className="size-3.5"
      />
    ),
  },
  intercomId: {
    name: 'Intercom ID',
    icon: (
      <Icon
        name="brand/intercom"
        className="size-3.5"
      />
    ),
  },
  pipedriveId: {
    name: 'Pipedrive ID',
    icon: (
      <Icon
        name="brand/pipedrive"
        className="size-3.5"
      />
    ),
  },
  snowflakeId: {
    name: 'Snowflake ID',
    icon: (
      <Icon
        name="brand/snowflake"
        className="size-3.5"
      />
    ),
  },
  attioId: {
    name: 'Attio ID',
    icon: (
      <Icon
        name="brand/attio"
        className="size-3.5"
      />
    ),
  },
  salesforceId: {
    name: 'Salesforce ID',
    icon: (
      <Icon
        name="brand/salesforce"
        className="size-3.5"
      />
    ),
  },
};

type Props = {
  companyId: string;
};

export const ExternalIds = ({ companyId }: Props) => {
  const { company } = useCompany(companyId);
  if (!company) return null;
  const keys = builtInKeys.filter((key) => company[key]);
  if (keys.length === 0) return null;
  return (
    <ExternalIdsContainer>
      {keys.map((key) => {
        const value = company[key];
        const { icon } = data[key];
        if (!value || !icon) return null;
        return (
          <TooltipClickToCopy
            key={key}
            title={data[key].name}
            value={value}
          >
            {props => (
              <button
                type="button"
                {...props}
              >
                {icon}
              </button>
            )}
          </TooltipClickToCopy>
        );
      })}
    </ExternalIdsContainer>
  );
};
