import { KeywordFragment } from '@cycle-app/graphql-codegen';
import { SelectPanel } from '@cycle-app/ui';

import { ToggleDropdown } from 'src/components/DropdownLayer';
import { useDocPanelContext } from 'src/contexts/docPanelContext';
import { useProductKeywords } from 'src/hooks/api/fragments/keywords';
import { useCreateKeyword } from 'src/hooks/api/mutations/keywords/useCreateKeyword';
import { useUpdateKeyword } from 'src/hooks/api/mutations/keywords/useUpdateKeyword';
import { Action as ActionType } from 'src/services/editor/editorActions';
import { Layer } from 'src/types/layers.types';

import { ActionButton } from './ActionButton';

export const ActionKeywords = ({ action }: { action: ActionType }) => (
  <ToggleDropdown
    placement="bottom"
    layer={Layer.DropdownModalZ3}
    content={<KeywordsPanel />}
    button={props => (
      <ActionButton
        action={action}
        onClick={props.onClick}
        active={props['data-active']}
      />
    )}
  />
);

const KeywordsPanel = () => {
  const keywords = useProductKeywords();
  const [createKeyword] = useCreateKeyword();
  const [updateKeyword] = useUpdateKeyword();

  const editor = useDocPanelContext(ctx => ctx.editor);
  if (!editor) return null;

  const state = editor.view.state;
  const misspelling = state.doc.textBetween(state.selection.from, state.selection.to).trim();
  if (!misspelling) return null;

  const replaceAll = (value: string) => {
    const content = editor.getHTML();
    const newContent = content.replaceAll(misspelling, value);
    editor.commands.setContent(newContent);
  };

  return (
    <SelectPanel<KeywordFragment>
      className="max-w-96"
      header={(
        <div>
          {'Select a keyword to replace '}
          <span className="font-medium">
            {misspelling}
          </span>
        </div>
      )}
      searchPlaceholder="Search keyword..."
      options={keywords.map(keyword => ({
        value: keyword.id,
        label: keyword.value,
        data: keyword,
      }))}
      onOptionChange={async option => {
        const keyword = option.data;
        if (!keyword) return;
        await updateKeyword(keyword, [misspelling], { optimistic: false });
        replaceAll(keyword.value);
      }}
      onCreateOption={async label => {
        const result = await createKeyword(label, { optimistic: false });
        const keyword = result.data?.createKeyword;
        if (!keyword) return;
        replaceAll(keyword.value);
      }}
    />
  );
};
