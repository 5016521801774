import { QuoteFragment, UpdateQuoteProductAreaDocument } from '@cycle-app/graphql-codegen';
import { TooltipLegacy, Tag, Icon, CheckboxInput } from '@cycle-app/ui';
import { HTMLMotionProps, motion } from 'framer-motion';
import { useRef } from 'react';
import scrollIntoView from 'scroll-into-view-if-needed';
import { twJoin } from 'tailwind-merge';

import { FeatureCardAttributes } from 'src/components/QuoteCards/FeatureCardAttributes';
import { QuoteContentEditable, QuoteFeatureDropdown } from 'src/components/QuotePopover';
import { Quote } from 'src/components/QuotePopover/VerifyQuoteModal.styles';
import { useDiscardQuote } from 'src/hooks/api/mutations/quotes/useDiscardQuote';
import { useVerifyQuotes } from 'src/hooks/api/mutations/quotes/useVerifyQuotes';

import { useSafeMutation } from '../../hooks';
import { ProductAreaTag } from '../DocProductAreas/DocProductAreas';
import { ToggleDropdown } from '../DropdownLayer';
import { ProductAreasManager } from '../ProductAreasManager';

type Props = {
  quote: QuoteFragment;
  selected: boolean;
  onClick: () => void;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
  hasProductAreas: boolean;
  productAreaName: string;
};

export const QuoteListItem = ({
  quote, selected, onClick, onMouseEnter, onMouseLeave, productAreaName, hasProductAreas,
}: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  const { discard } = useDiscardQuote();
  const { verifyQuotes } = useVerifyQuotes();
  const [productAreaUpdate] = useSafeMutation(UpdateQuoteProductAreaDocument);
  // Hide if has parent. quote inherits from parent.
  const hideProductArea = !hasProductAreas || !!quote.parent?.id;
  return (
    <AnimatedListItem
      onAnimationComplete={definition => {
        if (!ref.current || definition === 'hidden') return;
        scrollIntoView(ref.current, {
          scrollMode: 'if-needed',
          behavior: 'smooth',
        });
      }}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={e => {
        if (e.target !== e.currentTarget && e.target !== ref.current) return;
        onClick();
      }}
    >
      <TooltipLegacy
        content="Unselected AI-generated quotes will be discarded"
        withPortal
        withWrapper={false}
        placement="top"
      >
        <div
          className={twJoin(
            'absolute -left-2 top-4 z-10 opacity-0 group-hover:opacity-100',
            selected && 'opacity-100',
          )}
        >
          <CheckboxInput
            id={quote.id}
            checked={selected}
            onChange={onClick}
          />
        </div>
      </TooltipLegacy>

      <Quote
        ref={ref}
        className={twJoin(
          'mb-4 shadow-z1 outline hover:bg-grey-50 hover:dark:bg-grey-850/50',
          selected
            ? 'pointer-events-none outline-2 outline-blue-500'
            : 'outline-1 outline-grey-200 dark:outline-grey-800/50',
        )}
      >
        {!hideProductArea && (
          <ToggleDropdown
            placement="bottom-start"
            button={buttonProps => quote.productArea ? (
              <ProductAreaTag
                docId={quote.id}
                limitSize={false}
                className="w-fit max-w-none! whitespace-nowrap"
                value={quote.productArea?.value}
                onClick={buttonProps.toggle}
                icon={(
                  <Icon
                    name="tri-shapes"
                    className="flex-none text-disabled"
                  />
                )}
              />
            ) : (
              <Tag
                onClick={buttonProps.toggle}
                limitSize={false}
                className="w-fit max-w-none! whitespace-nowrap text-secondary!"
                icon={(
                  <Icon
                    name="tri-shapes"
                    className="flex-none text-disabled"
                  />
                )}
              >
                Select
                {' '}
                {productAreaName}
              </Tag>
            )}
            content={contentProps => (
              <ProductAreasManager
                compatibleIds={undefined}
                docTypeName={undefined}
                hideIncompatibleValues={false}
                showWarning={false}
                isMulti={false}
                isRequired={true}
                onSelect={async data => {
                  contentProps.hide();
                  await productAreaUpdate({
                    variables: {
                      id: quote.id,
                      productAreaId: data.productAreaId,
                    },
                    optimisticResponse: {
                      updateQuote: {
                        id: quote.id,
                        productArea: {
                          id: data.productAreaId,
                        },
                      },
                    },
                  });
                }}
                selectedValues={[]}
              />
            )}
          />
        )}

        <QuoteFeatureDropdown
          quote={quote}
          className={hasProductAreas ? 'my-2' : 'mb-2'}
        />

        {quote.parent && (
          <FeatureCardAttributes
            doc={quote.parent}
            showDoctype={false}
            className="mb-2 w-fit"
          />
        )}

        <QuoteContentEditable quote={quote} />

        <div className="mt-4 flex items-center gap-2">
          <button
            className="btn-primary btn-sm"
            onClick={() => verifyQuotes(quote.feedback.id, [quote.id])}
          >
            Validate
          </button>

          <button
            className="btn-tertiary btn-sm"
            onClick={() => discard(quote.feedback.id, quote.id)}
          >
            Discard
          </button>
        </div>
      </Quote>
    </AnimatedListItem>
  );
};

export const AnimatedListItem = (props: HTMLMotionProps<'div'>) => {
  return (
    <motion.div
      className="group relative cursor-pointer"
      variants={{
        hidden: {
          opacity: 0,
          height: 0,
          overflow: 'clip',
        },
        visible: {
          opacity: 1,
          height: 'auto',
          overflow: 'clip',
          transitionEnd: {
            overflow: 'visible',
          },
        },
      }}
      initial="hidden"
      animate="visible"
      exit="hidden"
      {...props}
    />
  );
};
