import { PublishDocDocument } from '@cycle-app/graphql-codegen';
import { Icon, Emoji, Tag, TooltipLegacy, Shortcuts } from '@cycle-app/ui';
import { getDocSlug } from '@cycle-app/utilities';
import { useAtomValue } from 'jotai';
import { Link } from 'react-router-dom';

import { PageId } from 'src/constants/routing.constant';
import { shortcuts, ShortcutCreateModal } from 'src/constants/shortcuts.constants';
import { navigateToDocFullPage, useDocBase } from 'src/hooks';
import { useDoctypeFragment } from 'src/hooks/api/fragments/doctype';
import { useProductMinimalFragment } from 'src/hooks/api/fragments/product';
import { useHotkeyListener } from 'src/hooks/useHotkeyListener';
import useSafeMutation from 'src/hooks/useSafeMutation';
import { refetchBoardWithConfigQuery } from 'src/utils/boardConfig/boardConfig.util';
import { getDocKey } from 'src/utils/doc.util';
import { getUrl } from 'src/utils/routing.utils';
import { addToaster } from 'src/utils/toasters.utils';

import { draftRequestIdAtom, draftRequestTypeIdAtom } from './newRequestModal.atoms';

type Props = {
  disabled: boolean;
  onPublish: () => Promise<void>;
};

export const PublishButton = ({
  disabled, onPublish,
}: Props) => {
  const docId = useAtomValue(draftRequestIdAtom);
  const draftRequestTypeId = useAtomValue(draftRequestTypeIdAtom);
  const requestType = useDoctypeFragment(draftRequestTypeId);

  const [mutate] = useSafeMutation(PublishDocDocument);

  const publish = async () => {
    if (!docId) return;
    await onPublish();
    const result = await mutate({
      variables: { docId },
      refetchQueries: refetchBoardWithConfigQuery,
    });
    return result.data?.publishDoc;
  };

  const save = async () => {
    const doc = await publish();
    if (doc) addRequestToaster(doc.id);
  };

  const saveAndOpen = async () => {
    const doc = await publish();
    if (doc) navigateToDocFullPage(doc);
  };

  useHotkeyListener({
    callbacks: {
      [ShortcutCreateModal.Save]: save,
      [ShortcutCreateModal.SaveAndOpen]: saveAndOpen,
    },
    shortcuts: [
      ShortcutCreateModal.Save,
      ShortcutCreateModal.SaveAndOpen,
    ],
    enabled: () => !!docId,
  });

  return (
    <TooltipLegacy
      withPortal
      withWrapper={false}
      placement="top"
      content={(
        <Shortcuts
          shortcuts={[{
            label: 'Save',
            keys: shortcuts[ShortcutCreateModal.Save],
          }, {
            label: 'Save & Open',
            keys: shortcuts[ShortcutCreateModal.SaveAndOpen],
          }]}
        />
      )}
    >
      <button
        className="btn-primary btn-lg"
        disabled={disabled}
        onClick={save}
      >
        {requestType
          ? `Create ${requestType.name.toLocaleLowerCase()}`
          : 'Create'}
      </button>
    </TooltipLegacy>
  );
};

const addRequestToaster = (docId: string) => {
  addToaster({
    message: props => (
      <RequestToaster
        docId={docId}
        close={props.close}
      />
    ),
  });
};

const RequestToaster = ({
  docId, close,
}: {
  docId: string;
  close: VoidFunction;
}) => {
  const { doc } = useDocBase(docId);
  const doctype = useDoctypeFragment(doc?.doctype.id);
  const product = useProductMinimalFragment();
  if (!product || !doc || !doctype) return null;
  return (
    <div className="space-y-2">
      <div className="font-medium text-primary flex items-center gap-2">
        <Icon name="check" />
        {`${doctype.name} successfully created`}
      </div>

      <Link
        className="btn-tertiary btn-lg font-normal text-primary"
        to={getUrl(PageId.DocFullPage, { docSlug: getDocSlug(doc) })}
        onClick={close}
      >
        <Tag
          color="grey"
          icon={(
            <Emoji
              emoji={doctype.emoji}
              size={14}
            />
          )}
        >
          {getDocKey(product?.key, doc.publicId)}
        </Tag>
        {doc.title.trim() || 'Untitled'}
      </Link>
    </div>
  );
};
