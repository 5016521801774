import { useFragment } from '@apollo/client';
import { StatusBaseFragmentDoc } from '@cycle-app/graphql-codegen';
import { StatusIcon, TooltipLegacy } from '@cycle-app/ui';

export const QuoteStatus = ({ statusId }: { statusId: string }) => {
  const {
    data, complete,
  } = useFragment({
    fragment: StatusBaseFragmentDoc,
    from: statusId,
  });
  if (!complete) return null;
  return (
    <TooltipLegacy
      content={data.value}
      placement="bottom"
      withPortal
      withWrapper={false}
    >
      <StatusIcon
        className="flex-none"
        category={data.category}
        withBackground
        size={16}
      />
    </TooltipLegacy>
  );
};
