import { useQuery } from '@apollo/client';
import { SearchDocDocument } from '@cycle-app/graphql-codegen';
import { TooltipLegacy } from '@cycle-app/ui';
import { LinkIcon } from '@cycle-app/ui/icons';
import { isUrl } from '@cycle-app/utilities';
import { useReducer, useRef, useState } from 'react';

import { InsightCreate } from 'src/components/InsightCreate';
import { MeetingButtonInstall } from 'src/components/MeetingButtonInstall';
import { integrationsDataMap } from 'src/constants/integrations.constants';
import { mappingZindex } from 'src/constants/zIndex.constant';
import { useEditorContext } from 'src/contexts/editorContext';
import { useWorkspaceContext } from 'src/contexts/workspaceContext';
import { PasteData } from 'src/editorExtensions/EventHandler/EventHandler';
import { useProductIntegrations, useProductAddOn } from 'src/hooks';
import { useUpdateDocAutomation } from 'src/hooks/doc/useUpdateDocAutomation';
import { useEmbedGithubIssue } from 'src/hooks/editor/useEmbedGithubIssue';
import { useEmbedLinearIssue } from 'src/hooks/editor/useEmbedLinearIssue';
import { useEmbedNotionPage } from 'src/hooks/editor/useEmbedNotionPage';
import { useGetLinkRect } from 'src/hooks/editor/useGetLinkRect';
import { useMeetingsIntegration } from 'src/hooks/integration/useMeetingsIntegration';
import { useClickOutside } from 'src/hooks/useClickOutside';
import { useEscape } from 'src/hooks/useEscape';
import { useInstallIntegration } from 'src/hooks/useInstallIntegration';
import { setLimitationsModal } from 'src/reactives';
import { openSettingsRecording } from 'src/reactives/settingsModal.reactive';
import { Layer } from 'src/types/layers.types';
import { PortalId } from 'src/types/portal.types';
import { isCustom } from 'src/utils/docType.util';
import { addEmbed } from 'src/utils/editor/iframely.util';
import { getPortalElement } from 'src/utils/elements.util';
import { isEmbeddable, getLinkType, linkData, getLinkIntegrationType, isMeetingRelated } from 'src/utils/embeddable.util';
import { getOpenedLayers } from 'src/utils/layers.util';

import { Action } from './DiscoverTooltip.styles';

const DISCOVERY_URLS = [
  'app.gong.io',
];

const isDiscoverable = (url: string) => {
  if (!isUrl(url)) return false;
  return DISCOVERY_URLS.some(domain => url.includes(domain));
};

/**
 * Hook to handle paste events and return a tooltip component if the pasted text is an embeddable URL.
 */
export const useDiscoverTooltip = () => {
  const [data, setData] = useState<PasteData | null>(null);
  const { isEnabled } = useProductAddOn('MEETINGS');
  return {
    discoverTooltip: !data ? null : (
      <DiscoverTooltip
        url={data.text}
        pos={data.pos}
        clearPaste={() => setData(null)}
      />
    ),
    handlePaste: (pasteData: PasteData) => {
      if (
        isEmbeddable(pasteData.text) ||
        (isEnabled && isDiscoverable(pasteData.text))
      ) setData(pasteData);
    },
  };
};

/**
 * Shiny tooltip component showing an icon, a title, a description and 2 actions:
 * - Embed and Ignore if the integration is installed or if the url is embeddable with iframely
 * - Install and Learn more if the integration is not installed
 * @param url - url from which the data should be parsed
 * @param pos - position of the node in the editor that the tooltip should target
 * @param clearPaste - callback to run when the tooltip is closed
 */
const DiscoverTooltip = ({
  url, pos, clearPaste,
}: {
  url: string;
  pos: number;
  clearPaste: VoidFunction;
}) => {
  const [visible, setVisible] = useState(true);
  const integrations = useProductIntegrations();
  const installIntegration = useInstallIntegration();
  const [isEmbedding, setEmbedding] = useReducer(() => true, false);

  const doc = useEditorContext(ctx => ctx.doc);
  const hasAutomation = doc?.automationId;

  const editor = useEditorContext(ctx => ctx.editor);
  const isDoctypeCustom = useEditorContext(ctx => isCustom(ctx.doc?.doctype));
  const onError = useEditorContext(ctx => ctx.onError);
  const { updateDocAutomation } = useUpdateDocAutomation();

  const hide = () => {
    if (getOpenedLayers().includes(Layer.DropdownModalZ1)) return true;
    setVisible(false);
    editor.commands.focus();
    return true;
  };

  const ref = useRef<HTMLDivElement>(null);
  useClickOutside(ref, hide);
  useEscape(hide);

  const embedLinearIssue = useEmbedLinearIssue(url, isEmbedding, hide);
  const embedGithubIssue = useEmbedGithubIssue(url, isEmbedding, hide);
  const embedNotionPage = useEmbedNotionPage(url, isEmbedding, hide);
  const isLoading = isEmbedding || (embedLinearIssue?.isLoading || embedGithubIssue?.isLoading || embedNotionPage?.isLoading);
  const matchIntegration = embedLinearIssue.match || embedGithubIssue.match || embedNotionPage.match;

  const error =
    (embedLinearIssue.match && embedLinearIssue.error) ||
    (embedGithubIssue.match && embedGithubIssue.error) ||
    (embedNotionPage.match && embedNotionPage.error);

  const getLinkRect = useGetLinkRect(url, pos);
  const linkType = getLinkType(url);

  const integrationType = getLinkIntegrationType(linkType);
  const integration = integrationType ? integrations.getIntegration(integrationType) : null;
  const isInstalled = integrationType ? !!integration?.provider : true;

  const install = () => {
    if (!integration?.type) return;
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    installIntegration(integration.type, integration);
  };

  const learnMore = () => {
    if (!integrationType) return;
    const { learnUrl } = integrationsDataMap[integrationType];
    if (!learnUrl) return;
    window.open(learnUrl, '_blank');
  };

  const embed = () => {
    setEmbedding();
    if (matchIntegration) return;
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    addEmbed({
      url,
      editor,
      onError,
      onSuccess: () => {
        hide();
        editor.commands.undo();
      },
    });
  };

  const link = async () => {
    if (embedLinearIssue.match && embedLinearIssue.mentionId && doc?.id) {
      embed();
      await updateDocAutomation(doc.id, url, embedLinearIssue.mentionId);
    }
  };

  const productId = useWorkspaceContext(ctx => ctx.productId);
  const searchDocQuery = useQuery(SearchDocDocument, {
    variables: {
      productId,
      size: 1,
      cursor: '',
      automationId: embedLinearIssue.mentionId,
    },
    fetchPolicy: 'network-only',
    skip: !embedLinearIssue.mentionId,
  });
  const isLinearLoading = !!embedLinearIssue.match && !searchDocQuery.data;

  const docLinkedToLinear = searchDocQuery.data?.searchDoc?.edges[0];

  // Mention a Linear issue in a feature doc
  // -> link the Linear issue/project if there’s nothing linked yet
  const isLinkableLinear =
    isDoctypeCustom &&
    (linkType === 'LinearIssue' || linkType === 'LinearProject') &&
    !hasAutomation &&
    isInstalled;

  // Mention a Linear issue in a feedback doc
  // -> create an insight and link the Linear issue/project to a parent who does not yet have a link
  const isTurnableIntoInsight =
    !isDoctypeCustom &&
    (linkType === 'LinearIssue' || linkType === 'LinearProject') &&
    isInstalled &&
    !isLinearLoading &&
    !docLinkedToLinear;

  const data = linkType ? linkData[linkType] : null;
  const dataOptions = {
    isInstalled,
    isTurnableIntoInsight,
  };

  return (
    <TooltipLegacy
      variant="shiny"
      visible={visible}
      interactive
      placement="bottom-start"
      offset={[0, 12]}
      appendTo={getPortalElement(PortalId.Modal) ?? document.body}
      getReferenceClientRect={getLinkRect}
      zIndex={mappingZindex[Layer.DocPanel] + 1}
      hide={hide}
      onAnimationComplete={definition => definition === 'exit' && clearPaste()}
      ref={ref}
      icon={data?.icon ?? <LinkIcon />}
      title={data?.title?.(dataOptions) ?? 'You pasted a link that can be embedded'}
      content={data?.description?.(dataOptions) ?? 'Embed this link to visualize it directly from Cycle.'}
      footer={(
        <>
          {isTurnableIntoInsight && doc && (
            <InsightCreate
              dropdownLayer={Layer.DropdownModalZ1}
              formLayer={Layer.DropdownModalZ1}
              feedbackDoc={doc}
              linearId={embedLinearIssue.mentionId}
              linearUrl={url}
              linearDisabled
              onInsightCreated={() => {
                embed();
              }}
              searchVariables={{
                hasAutomation: false,
              }}
              button={props => (
                <Action
                  onClick={props.onClick}
                  isLoading={isLoading}
                  autoFocus
                  disabled={props.isDisabled || !!error}
                  forceFocus={props.isFocus}
                >
                  Turn into insight
                </Action>
              )}
            />
          )}

          {isLinkableLinear && (
            <Action
              onClick={link}
              isLoading={isLoading}
              autoFocus
              disabled={!!error}
            >
              Link
              {linkType === 'LinearIssue' ? ' issue' : ' project'}
            </Action>
          )}

          {isInstalled && !isTurnableIntoInsight && linkType !== 'Gong' && (
            <Action
              onClick={embed}
              // Avoid two loaders
              isLoading={(isLoading || isLinearLoading) && !isLinkableLinear}
              autoFocus={!isLinkableLinear}
              disabled={!!error}
            >
              Embed
            </Action>
          )}

          {isMeetingRelated(linkType) && <MeetingIntegrationAction />}
          {isInstalled && (
            <Action onClick={hide}>
              Ignore
            </Action>
          )}
          {!isInstalled && (
            <Action
              onClick={install}
              autoFocus
            >
              Install
            </Action>
          )}
          {!isInstalled && (
            <Action onClick={learnMore}>
              Learn more
            </Action>
          )}
        </>
      )}
    />
  );
};

const MeetingIntegrationAction = () => {
  const { isEnabled } = useProductAddOn('MEETINGS');
  const { isInstalled } = useMeetingsIntegration();
  return (
    <MeetingButtonInstall
      button={buttonProps => (
        <Action
          onClick={(e) => {
            if (!isEnabled) {
              setLimitationsModal({
                action: 'USE_ADD_ON',
                brand: 'MEETINGS',
              });
              return;
            } if (isInstalled) {
              openSettingsRecording();
              return;
            }
            buttonProps?.onClick(e);
          }}
        >
          Try call recording
        </Action>
      )}
    />
  );
};
