import { boxShadowZ1, Button, typo, truncate, colorUtils } from '@cycle-app/ui';
import { ColorTheme } from '@cycle-app/utilities';
import styled from 'styled-components';

export type FileCardVariant = 'primary' | 'secondary';

export const Title = styled.div`
  user-select: text;
  flex: 2;
  display: flex;
  flex-direction: column;
  ${typo.body500}
  color: ${p => p.theme.colors.text.primary};
  overflow: hidden;
`;

export const Name = styled.div`
  ${typo.body500}
  color: ${p => p.theme.colors.text.primary};
  ${truncate}
  user-select: text;
`;

export const Infos = styled.div`
  ${typo.caption400}
  text-transform: uppercase;
  color: ${p => p.theme.colors.text.secondary};
  margin-top: -2px;
  ${truncate}
  > * + *:before {
    content: '·';
    margin: 0 4px;
  }
`;

export const Error = styled.div`
  user-select: text;
  ${typo.caption500}
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
  overflow: hidden;
  flex: none;
  span {
    ${truncate}
  }
  svg {
    flex: none;
  }
`;

type ButtonProps = {
  $isCompact?: boolean;
};

export const CreateButton = styled(Button).attrs({
  size: 'M',
})<ButtonProps>`
  padding: 2px 8px;
  ${typo.caption500}

  ${p => p.$isCompact && `
    border-radius: 9999px;
    width: 22px;
    height: 22px;
    padding: 0;
  `};
`;

export const DiscardButton = styled(CreateButton).attrs({
  variant: 'light',
})<ButtonProps>`
  --bg: 'transparent';
`;

export const IconContainer = styled.div<{ $variant: FileCardVariant }>`
  flex: none;
  width: 32px;
  height: 32px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${p => p.theme.colors.background.secondaryElevated};
  color: ${p => p.theme.colors.text.secondary};
  ${p => p.$variant === 'secondary' && `
    background: ${p.theme.isDark ? ColorTheme.Grey800 : ColorTheme.Grey300};
  `};
  position: relative;
  overflow: hidden;
`;

export const Card = styled.div<{
  $isLoading: boolean;
  $hasError: boolean;
  $variant: FileCardVariant;
}>`
    border-radius: 10px;
    border: 1px solid ${p => p.theme.colors.border.primary};
    ${boxShadowZ1}
    padding: 0 12px;
    height: 56px;
    flex: none;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    overflow: hidden;
    width: 100%;

    ${p => p.$variant === 'secondary' && `
      background: ${p.theme.isDark ? ColorTheme.Grey850 : ColorTheme.Grey200};
    `};

    ${p => p.$isLoading && `
      color: ${p.theme.colors.text.disabled};
    `};

    ${p => p.$hasError && `
      color: ${p.theme.isDark ? ColorTheme.OrangeRed500 : ColorTheme.OrangeRed600};
      background: ${p.theme.isDark ? colorUtils.transparentize(ColorTheme.Red600, 0.1) : ColorTheme.Red100};
      border-color: ${p.theme.isDark ? colorUtils.transparentize(ColorTheme.Red600, 0.1) : ColorTheme.Red300};

      ${IconContainer} {
        background: ${p.theme.isDark ? colorUtils.transparentize(ColorTheme.Red600, 0.1) : ColorTheme.Red200};
        color: ${p.theme.isDark ? ColorTheme.OrangeRed500 : ColorTheme.OrangeRed600};
      }
    `};

    ${p => p.$variant === 'secondary' && `
      border-color: transparent;
      box-shadow: none;
    `};
  `;

export const Buttons = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const FileImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: zoom-in;
`;
