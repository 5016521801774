import { UpdateReleaseNoteMakersDocument } from '@cycle-app/graphql-codegen';

import useSafeMutation from 'src/hooks/useSafeMutation';

export function useUpdateReleaseNoteMakers() {
  const [mutate, { loading }] = useSafeMutation(UpdateReleaseNoteMakersDocument, {
    errorPolicy: 'all',
  });

  const updateReleaseNoteMakers = async (releaseNoteId: string, makers: string[]) => {
    return mutate({
      variables: {
        releaseNoteId,
        makers,
      },
    });
  };

  return {
    updateReleaseNoteMakers,
    isLoading: loading,
  };
}
