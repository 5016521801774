import {
  MeFragmentDoc,
  DocBaseFragmentDoc,
  DocChildrensDocument,
  DocChildrensQueryVariables,
  DocChildNodeDocument,
  DocNodeDocument,
  DocFullNodeDocument,
  DocResultFragmentDoc,
  DoctypeFragmentDoc,
} from '@cycle-app/graphql-codegen';

import { getAuth } from 'src/reactives/auth.reactive';
import client from 'src/services/apollo/client';
import { extract } from 'src/types/graphql.types';
import { defaultHierarchyPagination, hierarchyPaginationMostRecent } from 'src/utils/pagination.util';

export const getMeFromCache = () => {
  const { userId } = getAuth();
  if (!userId) return undefined;
  return client.cache.readFragment({
    fragment: MeFragmentDoc,
    fragmentName: 'Me',
    id: userId,
  });
};

export const getDocBaseFromCache = (docId: string) => {
  return client.cache.readFragment({
    fragment: DocBaseFragmentDoc,
    fragmentName: 'DocBase',
    id: docId,
  });
};

export const getDocFromCache = (docId: string) => {
  const result = client.cache.readQuery({
    query: DocNodeDocument,
    variables: {
      id: docId,
    },
  });
  return extract('Doc', result?.node);
};

export const getDocFullFromCache = (docId: string) => {
  const result = client.cache.readQuery({
    query: DocFullNodeDocument,
    variables: {
      id: docId,
    },
  });
  return extract('Doc', result?.node);
};

export const getDocResultFromCache = (docId: string) => {
  return client.cache.readFragment({
    fragment: DocResultFragmentDoc,
    fragmentName: 'DocResult',
    id: docId,
  });
};

export const getDocChildFromCache = (docId: string) => {
  const result = client.cache.readQuery({
    query: DocChildNodeDocument,
    variables: {
      id: docId,
    },
  });
  return extract('Doc', result?.node);
};

export const getDocChildrensFromCache = (
  variables: Pick<DocChildrensQueryVariables, 'docId' | 'doctypeId'>,
  options?: {
    skip?: boolean;
    pagination?: 'most-recent' | 'default';
  },
) => {
  const result = client.cache.readQuery({
    query: DocChildrensDocument,
    variables: {
      ...variables,
      ...(options?.pagination === 'default' ? defaultHierarchyPagination : hierarchyPaginationMostRecent),
    },
  });
  return extract('Doc', result?.node);
};

export const getDoctypeFragment = (id: string) => client.cache.readFragment({
  fragment: DoctypeFragmentDoc,
  fragmentName: 'Doctype',
  id: id,
});
