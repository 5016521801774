import { MateBaseFragment } from '@cycle-app/graphql-codegen';
import { Avatar, Tooltip, Icon } from '@cycle-app/ui';
import { DndContext, DragOverlay, closestCenter, DragEndEvent, DragStartEvent } from '@dnd-kit/core';
import { SortableContext, useSortable, horizontalListSortingStrategy, arrayMove } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { useState, useEffect } from 'react';
import { twJoin } from 'tailwind-merge';

import { getMakerLabel } from './ReleaseNoteMakers';

// Define the maker type based on the existing component
type Maker = {
  maker: MateBaseFragment;
  id?: string;
};

type ReorderableMakersProps = {
  makers: Maker[];
  onChange: (newMakers: Maker[]) => void;
};

export function ReorderableMakers({
  makers,
  onChange,
}: ReorderableMakersProps) {
  const [orderedMakers, setOrderedMakers] = useState<Maker[]>(makers);
  const [activeMakerId, setActiveMakerId] = useState<string | null>(null);

  useEffect(() => {
    setOrderedMakers(makers);
  }, [makers]);

  const activeMaker = activeMakerId ? orderedMakers.find(m => m.maker.id === activeMakerId)?.maker : null;

  // Handle drag start
  const handleDragStart = (event: DragStartEvent) => {
    const { active } = event;
    setActiveMakerId(active.id as string);
  };

  // Handle drag end
  const handleDragEnd = (event: DragEndEvent) => {
    const {
      active, over,
    } = event;

    if (over && active.id !== over.id) {
      const oldIndex = orderedMakers.findIndex(m => m.maker.id === active.id);
      const newIndex = orderedMakers.findIndex(m => m.maker.id === over.id);

      const newOrderedMakers = arrayMove(orderedMakers, oldIndex, newIndex);
      setOrderedMakers(newOrderedMakers);
      onChange(newOrderedMakers);
    }

    // Reset active states
    setActiveMakerId(null);
  };

  const handleRemove = (makerId: string) => {
    const newOrderedMakers = orderedMakers.filter(m => m.maker.id !== makerId);
    setOrderedMakers(newOrderedMakers);
    onChange(newOrderedMakers);
  };

  return (
    <DndContext
      collisionDetection={closestCenter}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
    >
      <div className="flex items-center">
        <SortableContext
          items={orderedMakers.map(maker => maker.maker.id)}
          strategy={horizontalListSortingStrategy}
        >
          {orderedMakers.map((maker) => (
            <SortableMaker
              key={maker.maker.id}
              maker={maker.maker}
              isActive={maker.maker.id === activeMakerId}
              onRemove={() => handleRemove(maker.maker.id)}
            />
          ))}
        </SortableContext>
      </div>

      {/* Drag Overlay */}
      <DragOverlay adjustScale={false}>
        {activeMakerId && activeMaker ? (
          <Avatar
            name={activeMaker.firstName || activeMaker.email}
            src={activeMaker.avatar?.url}
            className="z-50 mt-0.5 ml-0.5 size-7"
          />
        ) : null}
      </DragOverlay>
    </DndContext>
  );
}

function SortableMaker({
  maker, isActive, onRemove,
}: {
  maker: MateBaseFragment;
  isActive: boolean;
  onRemove: () => void;
}) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({
    id: maker.id,
    data: { maker },
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div className="relative group">
      {/* Draggable element */}
      <div
        ref={setNodeRef}
        style={style}
        {...attributes}
        {...listeners}
        className={twJoin(
          'relative -ml-2 hover:z-10 block rounded-full bg-white border-2 dark:bg-grey-950 group',
          isActive ? 'z-10 border-blue-400 dark:border-blue-500' : 'cursor-grab border-white dark:border-grey-950',
        )}
      >
        <Tooltip content={getMakerLabel(maker)}>
          <Avatar
            name={maker.firstName || maker.email}
            src={maker.avatar?.url}
            className={twJoin(
              'size-7',
              isActive && 'opacity-0',
            )}
          />
        </Tooltip>
      </div>

      {/* Remove button outside the draggable area */}
      <div
        className="absolute -top-0 -right-0 z-30"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <button
          type="button"
          onClick={() => onRemove()}
          className="transition opacity-0 group-hover:opacity-100 size-3 bg-white dark:bg-grey-800 rounded-full flex items-center justify-center p-0.5 cursor-pointer shadow-sm"
        >
          <Icon
            name="close"
            className="size-full"
          />
        </button>
      </div>
    </div>
  );
}
