import { DocResultFragment } from '@cycle-app/graphql-codegen';
import { SelectLineProps } from '@cycle-app/ui';
import { nodeToArray } from '@cycle-app/utilities';

import { StatusTag } from 'src/components/DocStatus';
import { DocTypeIcon } from 'src/components/DocTypeIcon';
import { useGetDocTypes } from 'src/reactives/docTypes.reactive';

import { StyledSelectLine, ResultLabel, SelectLinePreSlotWrapper } from './DocSearchDropdown.styles';
import { DocSearchTitle } from './DocSearchTitle';
import { ProductAreaTag } from '../DocProductAreas/DocProductAreas';

type Props = Omit<SelectLineProps, 'label' | 'startSlot'> & {
  doc: DocResultFragment;
  showProductAreaTag: boolean;
};

export const DocSuggestionLine = ({
  doc, showProductAreaTag, ...props
}: Props) => {
  const { docTypes } = useGetDocTypes();
  // product area should be enabled only on features line.
  // its single select.
  const productArea = nodeToArray(doc.productAreas)[0];
  return (
    <StyledSelectLine
      {...props}
      startSlot={(
        <SelectLinePreSlotWrapper>
          <DocTypeIcon doctype={docTypes[doc.doctype.id]} />
          {doc.status && (
            <StatusTag
              category={doc.status.category}
              value={doc.status.value}
              isReadOnly
              hideLabel
            />
          )}
          {showProductAreaTag && productArea && (
            <ProductAreaTag
              isDisabled
              value={productArea.value}
            />
          )}
        </SelectLinePreSlotWrapper>
      )}
      label={(
        <ResultLabel>
          <DocSearchTitle title={doc.title} />
        </ResultLabel>
      )}
    />
  );
};
