/**
 * This file is generated by running `yarn workspace @cycle-app/ui build:icons`
 */
import brandAirtableIcon from './icons/brand.airtable.icon.svg?no-inline';
import brandAsanaIcon from './icons/brand.asana.icon.svg?no-inline';
import brandAttioIcon from './icons/brand.attio.icon.svg?no-inline';
import brandCannyIcon from './icons/brand.canny.icon.svg?no-inline';
import brandCapterraIcon from './icons/brand.capterra.icon.svg?no-inline';
import brandChromeIcon from './icons/brand.chrome.icon.svg?no-inline';
import brandClaapIcon from './icons/brand.claap.icon.svg?no-inline';
import brandClickUpIcon from './icons/brand.click-up.icon.svg?no-inline';
import brandConfluenceIcon from './icons/brand.confluence.icon.svg?no-inline';
import brandCycleIcon from './icons/brand.cycle.icon.svg?no-inline';
import brandDescriptIcon from './icons/brand.descript.icon.svg?no-inline';
import brandDiscordIcon from './icons/brand.discord.icon.svg?no-inline';
import brandDovetailIcon from './icons/brand.dovetail.icon.svg?no-inline';
import brandDropboxIcon from './icons/brand.dropbox.icon.svg?no-inline';
import brandEnjoyhqIcon from './icons/brand.enjoyhq.icon.svg?no-inline';
import brandFathomIcon from './icons/brand.fathom.icon.svg?no-inline';
import brandFeyIcon from './icons/brand.fey.icon.svg?no-inline';
import brandFigmaIcon from './icons/brand.figma.icon.svg?no-inline';
import brandFilloutIcon from './icons/brand.fillout.icon.svg?no-inline';
import brandFirefliesIcon from './icons/brand.fireflies.icon.svg?no-inline';
import brandFramerIcon from './icons/brand.framer.icon.svg?no-inline';
import brandFreshdeskIcon from './icons/brand.freshdesk.icon.svg?no-inline';
import brandFrontIcon from './icons/brand.front.icon.svg?no-inline';
import brandFullstoryIcon from './icons/brand.fullstory.icon.svg?no-inline';
import brandG2Icon from './icons/brand.g2.icon.svg?no-inline';
import brandGainsightIcon from './icons/brand.gainsight.icon.svg?no-inline';
import brandGithubIcon from './icons/brand.github.icon.svg?no-inline';
import brandGitlabIcon from './icons/brand.gitlab.icon.svg?no-inline';
import brandGongIcon from './icons/brand.gong.icon.svg?no-inline';
import brandGoogleCalendarIcon from './icons/brand.google-calendar.icon.svg?no-inline';
import brandGoogleDocIcon from './icons/brand.google-doc.icon.svg?no-inline';
import brandGoogleDriveIcon from './icons/brand.google-drive.icon.svg?no-inline';
import brandGoogleFormIcon from './icons/brand.google-form.icon.svg?no-inline';
import brandGoogleMeetIcon from './icons/brand.google-meet.icon.svg?no-inline';
import brandGoogleSheetIcon from './icons/brand.google-sheet.icon.svg?no-inline';
import brandGoogleIcon from './icons/brand.google.icon.svg?no-inline';
import brandGrainIcon from './icons/brand.grain.icon.svg?no-inline';
import brandGraphqlIcon from './icons/brand.graphql.icon.svg?no-inline';
import brandGumroadIcon from './icons/brand.gumroad.icon.svg?no-inline';
import brandHotjarIcon from './icons/brand.hotjar.icon.svg?no-inline';
import brandHubspotIcon from './icons/brand.hubspot.icon.svg?no-inline';
import brandIntercomIcon from './icons/brand.intercom.icon.svg?no-inline';
import brandJiraProductDiscoveryIcon from './icons/brand.jira-product-discovery.icon.svg?no-inline';
import brandJiraIcon from './icons/brand.jira.icon.svg?no-inline';
import brandJuneIcon from './icons/brand.june.icon.svg?no-inline';
import brandLinearIcon from './icons/brand.linear.icon.svg?no-inline';
import brandLinkedinIcon from './icons/brand.linkedin.icon.svg?no-inline';
import brandLoomIcon from './icons/brand.loom.icon.svg?no-inline';
import brandLoopsIcon from './icons/brand.loops.icon.svg?no-inline';
import brandMagicboxIcon from './icons/brand.magicbox.icon.svg?no-inline';
import brandMakeIcon from './icons/brand.make.icon.svg?no-inline';
import brandMicrosoftTeamsIcon from './icons/brand.microsoft-teams.icon.svg?no-inline';
import brandMiroIcon from './icons/brand.miro.icon.svg?no-inline';
import brandModjoIcon from './icons/brand.modjo.icon.svg?no-inline';
import brandMondayIcon from './icons/brand.monday.icon.svg?no-inline';
import brandNotionIcon from './icons/brand.notion.icon.svg?no-inline';
import brandOnedriveIcon from './icons/brand.onedrive.icon.svg?no-inline';
import brandOutlookIcon from './icons/brand.outlook.icon.svg?no-inline';
import brandPinterestIcon from './icons/brand.pinterest.icon.svg?no-inline';
import brandPipedriveIcon from './icons/brand.pipedrive.icon.svg?no-inline';
import brandPitchIcon from './icons/brand.pitch.icon.svg?no-inline';
import brandPlanhatIcon from './icons/brand.planhat.icon.svg?no-inline';
import brandProductboardIcon from './icons/brand.productboard.icon.svg?no-inline';
import brandRedditIcon from './icons/brand.reddit.icon.svg?no-inline';
import brandSalesforceIcon from './icons/brand.salesforce.icon.svg?no-inline';
import brandSharepointIcon from './icons/brand.sharepoint.icon.svg?no-inline';
import brandShortcutIcon from './icons/brand.shortcut.icon.svg?no-inline';
import brandSkypeIcon from './icons/brand.skype.icon.svg?no-inline';
import brandSlackSimpleIcon from './icons/brand.slack-simple.icon.svg?no-inline';
import brandSlackIcon from './icons/brand.slack.icon.svg?no-inline';
import brandSliteIcon from './icons/brand.slite.icon.svg?no-inline';
import brandSnowflakeIcon from './icons/brand.snowflake.icon.svg?no-inline';
import brandTallyIcon from './icons/brand.tally.icon.svg?no-inline';
import brandTrustpilotIcon from './icons/brand.trustpilot.icon.svg?no-inline';
import brandTwitterIcon from './icons/brand.twitter.icon.svg?no-inline';
import brandTypeformIcon from './icons/brand.typeform.icon.svg?no-inline';
import brandUservoiceIcon from './icons/brand.uservoice.icon.svg?no-inline';
import brandVitallyIcon from './icons/brand.vitally.icon.svg?no-inline';
import brandYcIcon from './icons/brand.yc.icon.svg?no-inline';
import brandZapierIcon from './icons/brand.zapier.icon.svg?no-inline';
import brandZendeskIcon from './icons/brand.zendesk.icon.svg?no-inline';
import brandZoomIcon from './icons/brand.zoom.icon.svg?no-inline';
import colorCameraIcon from './icons/color.camera.icon.svg?no-inline';
import colorLinearStatusBacklogIcon from './icons/color.linear-status-backlog.icon.svg?no-inline';
import colorLinearStatusCanceledIcon from './icons/color.linear-status-canceled.icon.svg?no-inline';
import colorLinearStatusDoneIcon from './icons/color.linear-status-done.icon.svg?no-inline';
import colorLinearStatusInProgressIcon from './icons/color.linear-status-in-progress.icon.svg?no-inline';
import colorLinearStatusToDoIcon from './icons/color.linear-status-to-do.icon.svg?no-inline';
import colorPlaygroundIcon from './icons/color.playground.icon.svg?no-inline';
import activityIcon from './icons/activity.icon.svg?no-inline';
import addMemberOutlineIcon from './icons/add-member-outline.icon.svg?no-inline';
import addMemberIcon from './icons/add-member.icon.svg?no-inline';
import addPictureIcon from './icons/add-picture.icon.svg?no-inline';
import addUserIcon from './icons/add-user.icon.svg?no-inline';
import addIcon from './icons/add.icon.svg?no-inline';
import aiCompleteIcon from './icons/ai-complete.icon.svg?no-inline';
import aiExtendIcon from './icons/ai-extend.icon.svg?no-inline';
import aiRephraseIcon from './icons/ai-rephrase.icon.svg?no-inline';
import aiShortenIcon from './icons/ai-shorten.icon.svg?no-inline';
import aiSimplifyIcon from './icons/ai-simplify.icon.svg?no-inline';
import aiSpellingIcon from './icons/ai-spelling.icon.svg?no-inline';
import aiSummarizeIcon from './icons/ai-summarize.icon.svg?no-inline';
import aiTextIcon from './icons/ai-text.icon.svg?no-inline';
import aiToneIcon from './icons/ai-tone.icon.svg?no-inline';
import aiTranslateIcon from './icons/ai-translate.icon.svg?no-inline';
import allStatusesIcon from './icons/all-statuses.icon.svg?no-inline';
import arrowCornerDownRightIcon from './icons/arrow-corner-down-right.icon.svg?no-inline';
import arrowRightUpIcon from './icons/arrow-right-up.icon.svg?no-inline';
import arrowUpCircleIcon from './icons/arrow-up-circle.icon.svg?no-inline';
import arrowsAllSidesIcon from './icons/arrows-all-sides.icon.svg?no-inline';
import attributeIcon from './icons/attribute.icon.svg?no-inline';
import backArrowIcon from './icons/back-arrow.icon.svg?no-inline';
import bagIcon from './icons/bag.icon.svg?no-inline';
import bellActiveIcon from './icons/bell-active.icon.svg?no-inline';
import bellIcon from './icons/bell.icon.svg?no-inline';
import boldIcon from './icons/bold.icon.svg?no-inline';
import botIcon from './icons/bot.icon.svg?no-inline';
import brushIcon from './icons/brush.icon.svg?no-inline';
import bubbleIcon from './icons/bubble.icon.svg?no-inline';
import buildingDashIcon from './icons/building-dash.icon.svg?no-inline';
import buildingIcon from './icons/building.icon.svg?no-inline';
import bulbLightIcon from './icons/bulb-light.icon.svg?no-inline';
import bulbIcon from './icons/bulb.icon.svg?no-inline';
import bulkIcon from './icons/bulk.icon.svg?no-inline';
import bulletListIcon from './icons/bullet-list.icon.svg?no-inline';
import calendarEditIcon from './icons/calendar-edit.icon.svg?no-inline';
import calendarTimeIcon from './icons/calendar-time.icon.svg?no-inline';
import calendarIcon from './icons/calendar.icon.svg?no-inline';
import cameraPlusFillIcon from './icons/camera-plus-fill.icon.svg?no-inline';
import cancelCircleIcon from './icons/cancel-circle.icon.svg?no-inline';
import caretIcon from './icons/caret.icon.svg?no-inline';
import chartIcon from './icons/chart.icon.svg?no-inline';
import chatIcon from './icons/chat.icon.svg?no-inline';
import checkAltIcon from './icons/check-alt.icon.svg?no-inline';
import checkCircleAltIcon from './icons/check-circle-alt.icon.svg?no-inline';
import checkCircleFillIcon from './icons/check-circle-fill.icon.svg?no-inline';
import checkCircleIcon from './icons/check-circle.icon.svg?no-inline';
import checkIcon from './icons/check.icon.svg?no-inline';
import checkboxIcon from './icons/checkbox.icon.svg?no-inline';
import checklistIcon from './icons/checklist.icon.svg?no-inline';
import chevronDoubleRightIcon from './icons/chevron-double-right.icon.svg?no-inline';
import chevronLeftIcon from './icons/chevron-left.icon.svg?no-inline';
import clipboardIcon from './icons/clipboard.icon.svg?no-inline';
import closeIcon from './icons/close.icon.svg?no-inline';
import codeBorderIcon from './icons/code-border.icon.svg?no-inline';
import codeIcon from './icons/code.icon.svg?no-inline';
import collapseSidebarIcon from './icons/collapse-sidebar.icon.svg?no-inline';
import colorsFillIcon from './icons/colors-fill.icon.svg?no-inline';
import commentAddIcon from './icons/comment-add.icon.svg?no-inline';
import commentAltIcon from './icons/comment-alt.icon.svg?no-inline';
import commentChatIcon from './icons/comment-chat.icon.svg?no-inline';
import commentOutlineIcon from './icons/comment-outline.icon.svg?no-inline';
import commentIcon from './icons/comment.icon.svg?no-inline';
import compassIcon from './icons/compass.icon.svg?no-inline';
import copyIcon from './icons/copy.icon.svg?no-inline';
import cornerDownRightIcon from './icons/corner-down-right.icon.svg?no-inline';
import cursorIcon from './icons/cursor.icon.svg?no-inline';
import customViewsIcon from './icons/custom-views.icon.svg?no-inline';
import customerIcon from './icons/customer.icon.svg?no-inline';
import dashCircleIcon from './icons/dash-circle.icon.svg?no-inline';
import dashboardIcon from './icons/dashboard.icon.svg?no-inline';
import deleteFillIcon from './icons/delete-fill.icon.svg?no-inline';
import deleteIcon from './icons/delete.icon.svg?no-inline';
import desktopIcon from './icons/desktop.icon.svg?no-inline';
import detailsIcon from './icons/details.icon.svg?no-inline';
import diamondIcon from './icons/diamond.icon.svg?no-inline';
import directionIcon from './icons/direction.icon.svg?no-inline';
import documentAddHoverIcon from './icons/document-add-hover.icon.svg?no-inline';
import documentEmptyIcon from './icons/document-empty.icon.svg?no-inline';
import documentIcon from './icons/document.icon.svg?no-inline';
import dollarIcon from './icons/dollar.icon.svg?no-inline';
import doubleCaretIcon from './icons/double-caret.icon.svg?no-inline';
import downArrowIcon from './icons/down-arrow.icon.svg?no-inline';
import downIcon from './icons/down.icon.svg?no-inline';
import downloadIcon from './icons/download.icon.svg?no-inline';
import duplicateIcon from './icons/duplicate.icon.svg?no-inline';
import editAltIcon from './icons/edit-alt.icon.svg?no-inline';
import editFillIcon from './icons/edit-fill.icon.svg?no-inline';
import editIcon from './icons/edit.icon.svg?no-inline';
import embedIcon from './icons/embed.icon.svg?no-inline';
import emojiIcon from './icons/emoji.icon.svg?no-inline';
import envelopePillIcon from './icons/envelope-pill.icon.svg?no-inline';
import envelopeIcon from './icons/envelope.icon.svg?no-inline';
import expandIcon from './icons/expand.icon.svg?no-inline';
import exportIcon from './icons/export.icon.svg?no-inline';
import export2Icon from './icons/export2.icon.svg?no-inline';
import eyeClosedIcon from './icons/eye-closed.icon.svg?no-inline';
import eyeIcon from './icons/eye.icon.svg?no-inline';
import featureIcon from './icons/feature.icon.svg?no-inline';
import feedbackIcon from './icons/feedback.icon.svg?no-inline';
import fileDownloadIcon from './icons/file-download.icon.svg?no-inline';
import fileIcon from './icons/file.icon.svg?no-inline';
import filterIcon from './icons/filter.icon.svg?no-inline';
import flashIcon from './icons/flash.icon.svg?no-inline';
import forwardIcon from './icons/forward.icon.svg?no-inline';
import gearIcon from './icons/gear.icon.svg?no-inline';
import generalOutlineIcon from './icons/general-outline.icon.svg?no-inline';
import generalIcon from './icons/general.icon.svg?no-inline';
import giftIcon from './icons/gift.icon.svg?no-inline';
import groupCanceledIcon from './icons/group-canceled.icon.svg?no-inline';
import groupCompletedIcon from './icons/group-completed.icon.svg?no-inline';
import groupNotStartedIcon from './icons/group-not-started.icon.svg?no-inline';
import groupStartedIcon from './icons/group-started.icon.svg?no-inline';
import h1Icon from './icons/h1.icon.svg?no-inline';
import h2Icon from './icons/h2.icon.svg?no-inline';
import h3Icon from './icons/h3.icon.svg?no-inline';
import hierarchyIcon from './icons/hierarchy.icon.svg?no-inline';
import historyIcon from './icons/history.icon.svg?no-inline';
import homeIcon from './icons/home.icon.svg?no-inline';
import idIcon from './icons/id.icon.svg?no-inline';
import imageIcon from './icons/image.icon.svg?no-inline';
import infoCircleIcon from './icons/info-circle.icon.svg?no-inline';
import infoOutlineIcon from './icons/info-outline.icon.svg?no-inline';
import infoIcon from './icons/info.icon.svg?no-inline';
import italicIcon from './icons/italic.icon.svg?no-inline';
import kanbanIcon from './icons/kanban.icon.svg?no-inline';
import languageFillIcon from './icons/language-fill.icon.svg?no-inline';
import layerIcon from './icons/layer.icon.svg?no-inline';
import layoutIcon from './icons/layout.icon.svg?no-inline';
import letterAIcon from './icons/letter-a.icon.svg?no-inline';
import linearEstimationIcon from './icons/linear-estimation.icon.svg?no-inline';
import linkArrowIcon from './icons/link-arrow.icon.svg?no-inline';
import linkIcon from './icons/link.icon.svg?no-inline';
import listSparkleIcon from './icons/list-sparkle.icon.svg?no-inline';
import listIcon from './icons/list.icon.svg?no-inline';
import loadingIcon from './icons/loading.icon.svg?no-inline';
import lockIcon from './icons/lock.icon.svg?no-inline';
import logoutIcon from './icons/logout.icon.svg?no-inline';
import loopLeftIcon from './icons/loop-left.icon.svg?no-inline';
import mediaIcon from './icons/media.icon.svg?no-inline';
import minusIcon from './icons/minus.icon.svg?no-inline';
import moonIcon from './icons/moon.icon.svg?no-inline';
import moreHorizCircleIcon from './icons/more-horiz-circle.icon.svg?no-inline';
import moreHorizIcon from './icons/more-horiz.icon.svg?no-inline';
import moreVertIcon from './icons/more-vert.icon.svg?no-inline';
import nextArrowIcon from './icons/next-arrow.icon.svg?no-inline';
import noCommentIcon from './icons/no-comment.icon.svg?no-inline';
import noteIcon from './icons/note.icon.svg?no-inline';
import notebookIcon from './icons/notebook.icon.svg?no-inline';
import numberedListIcon from './icons/numbered-list.icon.svg?no-inline';
import offlineIcon from './icons/offline.icon.svg?no-inline';
import openIcon from './icons/open.icon.svg?no-inline';
import paletteIcon from './icons/palette.icon.svg?no-inline';
import paperclipIcon from './icons/paperclip.icon.svg?no-inline';
import parentAltIcon from './icons/parent-alt.icon.svg?no-inline';
import parentIcon from './icons/parent.icon.svg?no-inline';
import passwordIcon from './icons/password.icon.svg?no-inline';
import pauseIcon from './icons/pause.icon.svg?no-inline';
import penFillIcon from './icons/pen-fill.icon.svg?no-inline';
import penFilledIcon from './icons/pen-filled.icon.svg?no-inline';
import penIcon from './icons/pen.icon.svg?no-inline';
import phoneAltIcon from './icons/phone-alt.icon.svg?no-inline';
import phoneIcon from './icons/phone.icon.svg?no-inline';
import pictureIcon from './icons/picture.icon.svg?no-inline';
import pinIcon from './icons/pin.icon.svg?no-inline';
import placeholderIcon from './icons/placeholder.icon.svg?no-inline';
import playFillIcon from './icons/play-fill.icon.svg?no-inline';
import playIcon from './icons/play.icon.svg?no-inline';
import plusBoxRoundedIcon from './icons/plus-box-rounded.icon.svg?no-inline';
import plusIcon from './icons/plus.icon.svg?no-inline';
import questionOutlineIcon from './icons/question-outline.icon.svg?no-inline';
import questionIcon from './icons/question.icon.svg?no-inline';
import quoteIcon from './icons/quote.icon.svg?no-inline';
import recordOutlineIcon from './icons/record-outline.icon.svg?no-inline';
import recordIcon from './icons/record.icon.svg?no-inline';
import refreshIcon from './icons/refresh.icon.svg?no-inline';
import releaseNoteIcon from './icons/release-note.icon.svg?no-inline';
import releaseIcon from './icons/release.icon.svg?no-inline';
import reloadIcon from './icons/reload.icon.svg?no-inline';
import reorderIcon from './icons/reorder.icon.svg?no-inline';
import rescueRingIcon from './icons/rescue-ring.icon.svg?no-inline';
import resizeImageIcon from './icons/resize-image.icon.svg?no-inline';
import rightIcon from './icons/right.icon.svg?no-inline';
import rotateIcon from './icons/rotate.icon.svg?no-inline';
import searchIcon from './icons/search.icon.svg?no-inline';
import settingsAltIcon from './icons/settings-alt.icon.svg?no-inline';
import settingsFillIcon from './icons/settings-fill.icon.svg?no-inline';
import settingsIcon from './icons/settings.icon.svg?no-inline';
import shapesIcon from './icons/shapes.icon.svg?no-inline';
import shareIcon from './icons/share.icon.svg?no-inline';
import shortcutIcon from './icons/shortcut.icon.svg?no-inline';
import signalIcon from './icons/signal.icon.svg?no-inline';
import singleSelectIcon from './icons/single-select.icon.svg?no-inline';
import slideRightIcon from './icons/slide-right.icon.svg?no-inline';
import slidersIcon from './icons/sliders.icon.svg?no-inline';
import smileyIcon from './icons/smiley.icon.svg?no-inline';
import sortHorizontalIcon from './icons/sort-horizontal.icon.svg?no-inline';
import sortIcon from './icons/sort.icon.svg?no-inline';
import starFillIcon from './icons/star-fill.icon.svg?no-inline';
import starIcon from './icons/star.icon.svg?no-inline';
import starsIcon from './icons/stars.icon.svg?no-inline';
import statusIcon from './icons/status.icon.svg?no-inline';
import strikeIcon from './icons/strike.icon.svg?no-inline';
import sumIcon from './icons/sum.icon.svg?no-inline';
import summaryIcon from './icons/summary.icon.svg?no-inline';
import sunIcon from './icons/sun.icon.svg?no-inline';
import syncDataIcon from './icons/sync-data.icon.svg?no-inline';
import syncStrikeIcon from './icons/sync-strike.icon.svg?no-inline';
import syncIcon from './icons/sync.icon.svg?no-inline';
import tableFillIcon from './icons/table-fill.icon.svg?no-inline';
import tableOfContentIcon from './icons/table-of-content.icon.svg?no-inline';
import tableIcon from './icons/table.icon.svg?no-inline';
import tabletIcon from './icons/tablet.icon.svg?no-inline';
import tagIcon from './icons/tag.icon.svg?no-inline';
import textIcon from './icons/text.icon.svg?no-inline';
import ticketIcon from './icons/ticket.icon.svg?no-inline';
import trashIcon from './icons/trash.icon.svg?no-inline';
import triShapesIcon from './icons/tri-shapes.icon.svg?no-inline';
import triangleIcon from './icons/triangle.icon.svg?no-inline';
import txtIcon from './icons/txt.icon.svg?no-inline';
import typeIcon from './icons/type.icon.svg?no-inline';
import underlineIcon from './icons/underline.icon.svg?no-inline';
import undoIcon from './icons/undo.icon.svg?no-inline';
import unionBoxIcon from './icons/union-box.icon.svg?no-inline';
import unionIcon from './icons/union.icon.svg?no-inline';
import unlinkIcon from './icons/unlink.icon.svg?no-inline';
import upIcon from './icons/up.icon.svg?no-inline';
import uploadIcon from './icons/upload.icon.svg?no-inline';
import userEditIcon from './icons/user-edit.icon.svg?no-inline';
import userSearchIcon from './icons/user-search.icon.svg?no-inline';
import userIcon from './icons/user.icon.svg?no-inline';
import usersIcon from './icons/users.icon.svg?no-inline';
import videoIcon from './icons/video.icon.svg?no-inline';
import volumeFullOutlineIcon from './icons/volume-full-outline.icon.svg?no-inline';
import volumeFullIcon from './icons/volume-full.icon.svg?no-inline';
import volumeLowIcon from './icons/volume-low.icon.svg?no-inline';
import volumeMutedIcon from './icons/volume-muted.icon.svg?no-inline';
import vttIcon from './icons/vtt.icon.svg?no-inline';
import warningAltIcon from './icons/warning-alt.icon.svg?no-inline';
import warningIcon from './icons/warning.icon.svg?no-inline';
import webPageCloseIcon from './icons/web-page-close.icon.svg?no-inline';
import webhookIcon from './icons/webhook.icon.svg?no-inline';
import whatsNewIcon from './icons/whats-new.icon.svg?no-inline';
import wheelIcon from './icons/wheel.icon.svg?no-inline';
import workspaceIcon from './icons/workspace.icon.svg?no-inline';

export const allIconsMap = {
  'brand/airtable': brandAirtableIcon,
  'brand/asana': brandAsanaIcon,
  'brand/attio': brandAttioIcon,
  'brand/canny': brandCannyIcon,
  'brand/capterra': brandCapterraIcon,
  'brand/chrome': brandChromeIcon,
  'brand/claap': brandClaapIcon,
  'brand/click-up': brandClickUpIcon,
  'brand/confluence': brandConfluenceIcon,
  'brand/cycle': brandCycleIcon,
  'brand/descript': brandDescriptIcon,
  'brand/discord': brandDiscordIcon,
  'brand/dovetail': brandDovetailIcon,
  'brand/dropbox': brandDropboxIcon,
  'brand/enjoyhq': brandEnjoyhqIcon,
  'brand/fathom': brandFathomIcon,
  'brand/fey': brandFeyIcon,
  'brand/figma': brandFigmaIcon,
  'brand/fillout': brandFilloutIcon,
  'brand/fireflies': brandFirefliesIcon,
  'brand/framer': brandFramerIcon,
  'brand/freshdesk': brandFreshdeskIcon,
  'brand/front': brandFrontIcon,
  'brand/fullstory': brandFullstoryIcon,
  'brand/g2': brandG2Icon,
  'brand/gainsight': brandGainsightIcon,
  'brand/github': brandGithubIcon,
  'brand/gitlab': brandGitlabIcon,
  'brand/gong': brandGongIcon,
  'brand/google-calendar': brandGoogleCalendarIcon,
  'brand/google-doc': brandGoogleDocIcon,
  'brand/google-drive': brandGoogleDriveIcon,
  'brand/google-form': brandGoogleFormIcon,
  'brand/google-meet': brandGoogleMeetIcon,
  'brand/google-sheet': brandGoogleSheetIcon,
  'brand/google': brandGoogleIcon,
  'brand/grain': brandGrainIcon,
  'brand/graphql': brandGraphqlIcon,
  'brand/gumroad': brandGumroadIcon,
  'brand/hotjar': brandHotjarIcon,
  'brand/hubspot': brandHubspotIcon,
  'brand/intercom': brandIntercomIcon,
  'brand/jira-product-discovery': brandJiraProductDiscoveryIcon,
  'brand/jira': brandJiraIcon,
  'brand/june': brandJuneIcon,
  'brand/linear': brandLinearIcon,
  'brand/linkedin': brandLinkedinIcon,
  'brand/loom': brandLoomIcon,
  'brand/loops': brandLoopsIcon,
  'brand/magicbox': brandMagicboxIcon,
  'brand/make': brandMakeIcon,
  'brand/microsoft-teams': brandMicrosoftTeamsIcon,
  'brand/miro': brandMiroIcon,
  'brand/modjo': brandModjoIcon,
  'brand/monday': brandMondayIcon,
  'brand/notion': brandNotionIcon,
  'brand/onedrive': brandOnedriveIcon,
  'brand/outlook': brandOutlookIcon,
  'brand/pinterest': brandPinterestIcon,
  'brand/pipedrive': brandPipedriveIcon,
  'brand/pitch': brandPitchIcon,
  'brand/planhat': brandPlanhatIcon,
  'brand/productboard': brandProductboardIcon,
  'brand/reddit': brandRedditIcon,
  'brand/salesforce': brandSalesforceIcon,
  'brand/sharepoint': brandSharepointIcon,
  'brand/shortcut': brandShortcutIcon,
  'brand/skype': brandSkypeIcon,
  'brand/slack-simple': brandSlackSimpleIcon,
  'brand/slack': brandSlackIcon,
  'brand/slite': brandSliteIcon,
  'brand/snowflake': brandSnowflakeIcon,
  'brand/tally': brandTallyIcon,
  'brand/trustpilot': brandTrustpilotIcon,
  'brand/twitter': brandTwitterIcon,
  'brand/typeform': brandTypeformIcon,
  'brand/uservoice': brandUservoiceIcon,
  'brand/vitally': brandVitallyIcon,
  'brand/yc': brandYcIcon,
  'brand/zapier': brandZapierIcon,
  'brand/zendesk': brandZendeskIcon,
  'brand/zoom': brandZoomIcon,
  'color/camera': colorCameraIcon,
  'color/linear-status-backlog': colorLinearStatusBacklogIcon,
  'color/linear-status-canceled': colorLinearStatusCanceledIcon,
  'color/linear-status-done': colorLinearStatusDoneIcon,
  'color/linear-status-in-progress': colorLinearStatusInProgressIcon,
  'color/linear-status-to-do': colorLinearStatusToDoIcon,
  'color/playground': colorPlaygroundIcon,
  'activity': activityIcon,
  'add-member-outline': addMemberOutlineIcon,
  'add-member': addMemberIcon,
  'add-picture': addPictureIcon,
  'add-user': addUserIcon,
  'add': addIcon,
  'ai-complete': aiCompleteIcon,
  'ai-extend': aiExtendIcon,
  'ai-rephrase': aiRephraseIcon,
  'ai-shorten': aiShortenIcon,
  'ai-simplify': aiSimplifyIcon,
  'ai-spelling': aiSpellingIcon,
  'ai-summarize': aiSummarizeIcon,
  'ai-text': aiTextIcon,
  'ai-tone': aiToneIcon,
  'ai-translate': aiTranslateIcon,
  'all-statuses': allStatusesIcon,
  'arrow-corner-down-right': arrowCornerDownRightIcon,
  'arrow-right-up': arrowRightUpIcon,
  'arrow-up-circle': arrowUpCircleIcon,
  'arrows-all-sides': arrowsAllSidesIcon,
  'attribute': attributeIcon,
  'back-arrow': backArrowIcon,
  'bag': bagIcon,
  'bell-active': bellActiveIcon,
  'bell': bellIcon,
  'bold': boldIcon,
  'bot': botIcon,
  'brush': brushIcon,
  'bubble': bubbleIcon,
  'building-dash': buildingDashIcon,
  'building': buildingIcon,
  'bulb-light': bulbLightIcon,
  'bulb': bulbIcon,
  'bulk': bulkIcon,
  'bullet-list': bulletListIcon,
  'calendar-edit': calendarEditIcon,
  'calendar-time': calendarTimeIcon,
  'calendar': calendarIcon,
  'camera-plus-fill': cameraPlusFillIcon,
  'cancel-circle': cancelCircleIcon,
  'caret': caretIcon,
  'chart': chartIcon,
  'chat': chatIcon,
  'check-alt': checkAltIcon,
  'check-circle-alt': checkCircleAltIcon,
  'check-circle-fill': checkCircleFillIcon,
  'check-circle': checkCircleIcon,
  'check': checkIcon,
  'checkbox': checkboxIcon,
  'checklist': checklistIcon,
  'chevron-double-right': chevronDoubleRightIcon,
  'chevron-left': chevronLeftIcon,
  'clipboard': clipboardIcon,
  'close': closeIcon,
  'code-border': codeBorderIcon,
  'code': codeIcon,
  'collapse-sidebar': collapseSidebarIcon,
  'colors-fill': colorsFillIcon,
  'comment-add': commentAddIcon,
  'comment-alt': commentAltIcon,
  'comment-chat': commentChatIcon,
  'comment-outline': commentOutlineIcon,
  'comment': commentIcon,
  'compass': compassIcon,
  'copy': copyIcon,
  'corner-down-right': cornerDownRightIcon,
  'cursor': cursorIcon,
  'custom-views': customViewsIcon,
  'customer': customerIcon,
  'dash-circle': dashCircleIcon,
  'dashboard': dashboardIcon,
  'delete-fill': deleteFillIcon,
  'delete': deleteIcon,
  'desktop': desktopIcon,
  'details': detailsIcon,
  'diamond': diamondIcon,
  'direction': directionIcon,
  'document-add-hover': documentAddHoverIcon,
  'document-empty': documentEmptyIcon,
  'document': documentIcon,
  'dollar': dollarIcon,
  'double-caret': doubleCaretIcon,
  'down-arrow': downArrowIcon,
  'down': downIcon,
  'download': downloadIcon,
  'duplicate': duplicateIcon,
  'edit-alt': editAltIcon,
  'edit-fill': editFillIcon,
  'edit': editIcon,
  'embed': embedIcon,
  'emoji': emojiIcon,
  'envelope-pill': envelopePillIcon,
  'envelope': envelopeIcon,
  'expand': expandIcon,
  'export': exportIcon,
  'export2': export2Icon,
  'eye-closed': eyeClosedIcon,
  'eye': eyeIcon,
  'feature': featureIcon,
  'feedback': feedbackIcon,
  'file-download': fileDownloadIcon,
  'file': fileIcon,
  'filter': filterIcon,
  'flash': flashIcon,
  'forward': forwardIcon,
  'gear': gearIcon,
  'general-outline': generalOutlineIcon,
  'general': generalIcon,
  'gift': giftIcon,
  'group-canceled': groupCanceledIcon,
  'group-completed': groupCompletedIcon,
  'group-not-started': groupNotStartedIcon,
  'group-started': groupStartedIcon,
  'h1': h1Icon,
  'h2': h2Icon,
  'h3': h3Icon,
  'hierarchy': hierarchyIcon,
  'history': historyIcon,
  'home': homeIcon,
  'id': idIcon,
  'image': imageIcon,
  'info-circle': infoCircleIcon,
  'info-outline': infoOutlineIcon,
  'info': infoIcon,
  'italic': italicIcon,
  'kanban': kanbanIcon,
  'language-fill': languageFillIcon,
  'layer': layerIcon,
  'layout': layoutIcon,
  'letter-a': letterAIcon,
  'linear-estimation': linearEstimationIcon,
  'link-arrow': linkArrowIcon,
  'link': linkIcon,
  'list-sparkle': listSparkleIcon,
  'list': listIcon,
  'loading': loadingIcon,
  'lock': lockIcon,
  'logout': logoutIcon,
  'loop-left': loopLeftIcon,
  'media': mediaIcon,
  'minus': minusIcon,
  'moon': moonIcon,
  'more-horiz-circle': moreHorizCircleIcon,
  'more-horiz': moreHorizIcon,
  'more-vert': moreVertIcon,
  'next-arrow': nextArrowIcon,
  'no-comment': noCommentIcon,
  'note': noteIcon,
  'notebook': notebookIcon,
  'numbered-list': numberedListIcon,
  'offline': offlineIcon,
  'open': openIcon,
  'palette': paletteIcon,
  'paperclip': paperclipIcon,
  'parent-alt': parentAltIcon,
  'parent': parentIcon,
  'password': passwordIcon,
  'pause': pauseIcon,
  'pen-fill': penFillIcon,
  'pen-filled': penFilledIcon,
  'pen': penIcon,
  'phone-alt': phoneAltIcon,
  'phone': phoneIcon,
  'picture': pictureIcon,
  'pin': pinIcon,
  'placeholder': placeholderIcon,
  'play-fill': playFillIcon,
  'play': playIcon,
  'plus-box-rounded': plusBoxRoundedIcon,
  'plus': plusIcon,
  'question-outline': questionOutlineIcon,
  'question': questionIcon,
  'quote': quoteIcon,
  'record-outline': recordOutlineIcon,
  'record': recordIcon,
  'refresh': refreshIcon,
  'release-note': releaseNoteIcon,
  'release': releaseIcon,
  'reload': reloadIcon,
  'reorder': reorderIcon,
  'rescue-ring': rescueRingIcon,
  'resize-image': resizeImageIcon,
  'right': rightIcon,
  'rotate': rotateIcon,
  'search': searchIcon,
  'settings-alt': settingsAltIcon,
  'settings-fill': settingsFillIcon,
  'settings': settingsIcon,
  'shapes': shapesIcon,
  'share': shareIcon,
  'shortcut': shortcutIcon,
  'signal': signalIcon,
  'single-select': singleSelectIcon,
  'slide-right': slideRightIcon,
  'sliders': slidersIcon,
  'smiley': smileyIcon,
  'sort-horizontal': sortHorizontalIcon,
  'sort': sortIcon,
  'star-fill': starFillIcon,
  'star': starIcon,
  'stars': starsIcon,
  'status': statusIcon,
  'strike': strikeIcon,
  'sum': sumIcon,
  'summary': summaryIcon,
  'sun': sunIcon,
  'sync-data': syncDataIcon,
  'sync-strike': syncStrikeIcon,
  'sync': syncIcon,
  'table-fill': tableFillIcon,
  'table-of-content': tableOfContentIcon,
  'table': tableIcon,
  'tablet': tabletIcon,
  'tag': tagIcon,
  'text': textIcon,
  'ticket': ticketIcon,
  'trash': trashIcon,
  'tri-shapes': triShapesIcon,
  'triangle': triangleIcon,
  'txt': txtIcon,
  'type': typeIcon,
  'underline': underlineIcon,
  'undo': undoIcon,
  'union-box': unionBoxIcon,
  'union': unionIcon,
  'unlink': unlinkIcon,
  'up': upIcon,
  'upload': uploadIcon,
  'user-edit': userEditIcon,
  'user-search': userSearchIcon,
  'user': userIcon,
  'users': usersIcon,
  'video': videoIcon,
  'volume-full-outline': volumeFullOutlineIcon,
  'volume-full': volumeFullIcon,
  'volume-low': volumeLowIcon,
  'volume-muted': volumeMutedIcon,
  'vtt': vttIcon,
  'warning-alt': warningAltIcon,
  'warning': warningIcon,
  'web-page-close': webPageCloseIcon,
  'webhook': webhookIcon,
  'whats-new': whatsNewIcon,
  'wheel': wheelIcon,
  'workspace': workspaceIcon,
} as const;
