import { BoardType } from '@cycle-app/graphql-codegen';
import { SelectPanel, SelectOption, Icon } from '@cycle-app/ui';
import { TrashIcon, DuplicateIcon, ExportIcon2, WheelIcon, PinIcon } from '@cycle-app/ui/icons';

import { useIsBoardStarred, useOptimizedBooleanState, useMaybeMeV2 } from 'src/hooks';
import { useBoardStar } from 'src/hooks/api/mutations/boards/useBoardStar';
import { useBoardSections } from 'src/hooks/api/useBoardSections';
import { useBoardLink } from 'src/hooks/boards/useBoardLink';
import { useGetPermission } from 'src/reactives';
import { setLimitationsModal } from 'src/reactives/limitationsModal.reactive';
import { Layer } from 'src/types/layers.types';
import { getSectionName } from 'src/utils/viewSection.utils';

import { OptionCaption, SubmenuIcon, SubmenuTitle } from './useBoardMenuOptions.styles';
import { DropdownLayer } from '../../components/DropdownLayer';

interface Props {
  boardConfigId?: string;
  hide?: VoidFunction;
  hideSettings?: boolean;
  isDeleteDisabled?: boolean;
  onDeleteView: (id: string) => void;
  onDuplicateView: (id: string) => void;
  onExportView: (id: string) => void;
  onOpenBoardConfig?: VoidFunction;
  viewId: string;
  type: BoardType;
}

export const useBoardMenuOptions = ({
  boardConfigId,
  hide,
  hideSettings,
  isDeleteDisabled,
  onDeleteView,
  onDuplicateView,
  onExportView,
  onOpenBoardConfig,
  viewId,
  type,
}: Props) => {
  const {
    canDeleteView, canCreateView, canExportCSV, canUpdateView,
  } = useGetPermission();
  const {
    star, unStar,
  } = useBoardStar();
  const isBoardStarred = useIsBoardStarred();
  const [isSectionsMenuOpened, {
    setTrueCallback: openSectionsMenu, setFalseCallback: closeSectionsMenu,
  }] = useOptimizedBooleanState(false);
  const {
    defaultSection, customSections, mappingBoardLinks, sectionsCount,
  } = useBoardSections();
  const {
    createBoardLink, removeBoardLink,
  } = useBoardLink();
  const { isSuperAdmin } = useMaybeMeV2();

  const hasCustomSections = !!customSections.length;

  const boardLink = Object.values(mappingBoardLinks).find(link => link.board.id === viewId);

  const showSectionsDropdown =
    !boardLink &&
    canUpdateView &&
    hasCustomSections;

  const onMouseEnterItem = (value: string) => {
    if (value === 'link' && showSectionsDropdown) {
      openSectionsMenu();
    }
  };

  const onMouseLeaveItem = (value: string) => {
    if (value === 'link') {
      closeSectionsMenu();
    }
  };

  const isCustom = type === BoardType.Custom;

  const options: SelectOption[] = (() => {
    const result: SelectOption[] = [];

    if (!hideSettings && onOpenBoardConfig) {
      result.push({
        value: 'settings',
        label: 'View settings',
        onSelect: () => {
          if (!canUpdateView) {
            setLimitationsModal({ action: 'VIEW_UPDATE' });
            return;
          }
          onOpenBoardConfig();
          setTimeout(() => hide?.());
        },
        disabled: isCustom && !boardConfigId,
        icon: <WheelIcon />,
      });
    }

    if (isCustom) {
      result.push({
        icon: <DuplicateIcon />,
        label: 'Duplicate',
        value: 'duplicate',
        onSelect: () => {
          hide?.();
          if (!canCreateView) {
            setLimitationsModal({ action: 'VIEW_CREATE' });
            return;
          }
          onDuplicateView(viewId);
        },
      });
      result.push({
        icon: <ExportIcon2 />,
        label: 'Export in CSV',
        value: 'export',
        onSelect: () => {
          hide?.();
          if (!canExportCSV) {
            setLimitationsModal({ action: 'EXPORT_CSV' });
            return;
          }
          onExportView(viewId);
        },
      });
    }

    result.push({
      style: boardLink ? {
        // We have two rows with the label and the section name.
        alignItems: 'flex-start',
        height: 'auto',
      } : undefined,
      // renderLabel takes over label.
      label: 'Add to sidebar',
      renderLabel: () => {
        if (boardLink) {
          return (
            <div>
              Remove from sidebar
              <OptionCaption>
                {getSectionName(boardLink.section, sectionsCount)}
              </OptionCaption>
            </div>
          );
        }
        if (hasCustomSections) {
          return (
            <DropdownLayer
              content={(
                <BoardLinkPanel
                  viewId={viewId}
                  onChange={() => {
                    closeSectionsMenu();
                    hide?.();
                  }}
                />
              )}
              hide={closeSectionsMenu}
              layer={Layer.DropdownModal}
              offset={[-10, 30]}
              onHide={closeSectionsMenu}
              placement="right-start"
              visible={isSectionsMenuOpened}
              width={182}
              closingArea={false}
            >
              Add to sidebar
            </DropdownLayer>
          );
        }
        return (
          <>
            Add to sidebar
          </>
        );
      },
      // Pin view in custom sections
      end: showSectionsDropdown && <SubmenuIcon $isActive={isSectionsMenuOpened} />,
      icon: boardLink ? <PinIcon style={{ marginTop: '5px' }} /> : <PinIcon />,
      value: 'link',
      // Keep the menu open if we have custom sections.
      // overwise click on add to sidebar will add the board link to the default section.
      keepDropdownOnSelect: hasCustomSections,
      onSelect: async () => {
        if (!canUpdateView) {
          hide?.();
          setLimitationsModal({ action: 'VIEW_UPDATE' });
          return;
        }
        if (boardLink) {
          hide?.();
          await removeBoardLink({ boardLinkId: boardLink.id });
          return;
        }
        if (hasCustomSections) {
          // Open custom sections sub-menu
          openSectionsMenu();
          return;
        }
        hide?.();
        if (defaultSection?.id) {
          // No custom sections, close and add to the default section
          await createBoardLink({
            boardId: viewId,
            sectionId: defaultSection.id,
          });
        }
      },
    });

    if (isBoardStarred(viewId)) {
      result.push({
        value: 'unstar',
        label: 'Unstar',
        icon: <Icon name="star-fill" />,
        onSelect: async () => {
          hide?.();
          await unStar(viewId);
        },
      });
    }

    if (!isBoardStarred(viewId)) {
      result.push({
        value: 'star',
        label: 'Star',
        icon: <Icon name="star" />,
        onSelect: async () => {
          hide?.();
          await star(viewId);
        },
      });
    }

    if (isCustom || isSuperAdmin) {
      result.push({
        icon: <TrashIcon />,
        label: 'Delete',
        value: 'delete',
        variant: 'danger' as const,
        disabled: isDeleteDisabled,
        onSelect: () => {
          hide?.();
          if (!canDeleteView) {
            setLimitationsModal({ action: 'VIEW_DELETE' });
            return;
          }
          hide?.();
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          onDeleteView(viewId);
        },
      });
    }

    return result;
  })();

  return {
    options,
    onMouseEnterItem,
    onMouseLeaveItem,
  };
};

type BoardLinkPanelProps = {
  viewId: string;
  onChange?: (option?: SelectOption) => void;
  boardLinkId?: string;
  boardLinkSectionId?: string;
  showHeader?: boolean;
};

export const BoardLinkPanel = ({
  viewId, onChange, boardLinkId, boardLinkSectionId, showHeader = true,
}: BoardLinkPanelProps) => {
  const {
    customSections, sections, defaultSection,
  } = useBoardSections();
  const {
    createBoardLink, removeBoardLink,
  } = useBoardLink();
  const isDefaultSectionEmpty = defaultSection?.boardLinks.edges.length === 0;
  const options = (isDefaultSectionEmpty ? customSections : sections)
    .map(section => ({
      value: section.id,
      label: getSectionName(section, sections.length),
    }));
  return (
    <SelectPanel
      hideSearch
      header={showHeader ? (
        <SubmenuTitle>
          Sections
        </SubmenuTitle>
      ) : undefined}
      options={options}
      onOptionChange={async (option) => {
        onChange?.(option);
        if (option.value === boardLinkSectionId) return;
        if (boardLinkId) {
          await removeBoardLink({
            boardLinkId,
          });
        }
        await createBoardLink({
          boardId: viewId,
          sectionId: option.value,
        });
      }}
      onClearValue={!boardLinkId ? undefined : async () => {
        onChange?.();
        await removeBoardLink({
          boardLinkId,
        });
      }}
    />
  );
};
