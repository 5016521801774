import { ReactNode, useEffect, useRef } from 'react';
import { z } from 'zod';

import { VIDEO_PLAYBACK_RATES } from 'src/constants/video.constants';
import { useOptimizedBooleanState } from 'src/hooks';
import { useStateWithStorage } from 'src/hooks/useStateWithStorage';
import { useScript } from 'src/reactives';
import { LocalKey } from 'src/types/localStorage.types';
import { Script } from 'src/types/scripts.types';
import { logError } from 'src/utils/errors.utils';

import { useCloudinaryScript } from './VideoPlayer.hook';
import { Container } from './VideoPlayer.styles';
import { isDevEnv } from '../../utils/env.util';

type VideoPlayerProps = {
  src: string;
  errorFallback?: ReactNode;
  className?: string;
  fluid?: boolean;
};

export const VideoPlayer = ({
  src, className, errorFallback = null, fluid = true,
}: VideoPlayerProps) => {
  useCloudinaryScript();
  const { isLoaded } = useScript(Script.CLOUDINARY);
  const videoRef = useRef<HTMLVideoElement>(null);
  const isVideoLoaded = useRef(false);
  const [hasError, {
    setTrueCallback: setTrueError,
    setFalseCallback: setFalseError,
  }] = useOptimizedBooleanState(false);

  /**
   * This effect loads cloudinary player
   */
  useEffect(() => {
    const videoElement = videoRef.current;
    if (!videoElement || !isLoaded || isVideoLoaded.current) return;
    let player: VideoPlayerReturn;

    try {
      if (!window.cloudinary) throw new Error('Cloudinary not loaded');

      player = window.cloudinary.videoPlayer(videoElement, {
        cloud_name: isDevEnv() ? 'cycle-dev' : 'do44szjts',
        secure: true,
      });
      player.source(src);
      setFalseError();
      isVideoLoaded.current = true;
    } catch (error) {
      logError(error);
      setTrueError();
    }

    return () => {
      // Destroy the Cloudinary player
      player.dispose();

      // Reset the video element
      // This prevents audio playback if the video element is still in memory (if not yet garbage collected)
      videoElement.setAttribute('src', '');
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoaded, src]);

  const [playbackRate, setPlaybackRate] = useStateWithStorage(1, {
    key: LocalKey.VideoPlaybackRate,
    schema: z.number()
      .min(Math.min(...VIDEO_PLAYBACK_RATES))
      .max(Math.max(...VIDEO_PLAYBACK_RATES)),
  });

  const videoClasseNames = ['cld-video-player'];
  if (fluid) videoClasseNames.push('cld-fluid');

  return (
    <Container className={className}>
      {hasError
        ? errorFallback
        : (
          <video
            className={videoClasseNames.join(' ')}
            ref={videoRef}
            controls
            data-cld-playback-rates={`[${VIDEO_PLAYBACK_RATES.join(',')}]`}
            onCanPlay={e => {
              if (!(e.target instanceof HTMLVideoElement)) return;
              e.target.playbackRate = playbackRate;
            }}
            onRateChange={e => {
              if (!(e.target instanceof HTMLVideoElement)) return;
              if (e.target.playbackRate === playbackRate) return;
              setPlaybackRate(e.target.playbackRate);
            }}
          />
        )}
    </Container>
  );
};
