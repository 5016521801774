import { make } from 'src/utils/reactives.util';

import { makeBooleanVar } from '../utils/makeVar.utils';

export enum Content {
  Comments = 'Comments',
  Activity = 'Activity',
  Insights = 'Insights',
}

export enum AskContent {
  Conversation = 'Conversation',
  History = 'History',
}

interface DocPanelState {
  content: Content | null;
  askContent: AskContent | null;
  isExpanded: boolean;
  autoFocus: boolean;
  isHovered: boolean;
}

const {
  hookValue,
  getValue,
  setValue,
} = make<DocPanelState>({
  defaultState: {
    content: null,
    askContent: null,
    isExpanded: false,
    autoFocus: true,
    isHovered: false,
  },
});

export const useGetDocPanel = hookValue;
export const getDocPanel = getValue;
export const setDocPanel = (...params: Parameters<typeof setValue>) => {
  if (getVerifyQuotesOpen() && params[0].isExpanded) {
    closeVerifyQuotes();
  }
  if (getFeatureReleaseNoteOpen() && params[0].isExpanded) {
    closeFeatureReleaseNote();
  }
  setValue(...params);
};

export const closePanel = () => setDocPanel({ isExpanded: false });

export const openCommentsPanel = () => setDocPanel({
  isExpanded: true,
  askContent: null,
  content: Content.Comments,
});

export const closeAskPanel = () => setDocPanel({
  askContent: null,
  // Closes the panel if the user was not previously on comment or activity.
  ...(!getDocPanel().content && {
    isExpanded: false,
  }),
});

export const openAskPanel = () => setDocPanel({
  isExpanded: true,
  askContent: getDocPanel().askContent || AskContent.Conversation,
  // If the user directly clicks on the doc ask button when panel is closed, make sure the content panel is null.
  // When the user will clicks on close in the ask header, it will close the right panel.
  ...!getDocPanel().isExpanded && {
    content: null,
  },
});

/* Opening state of the verify quotes panel in a feedback doc */
export const {
  hook: useVerifyQuotesOpen,
  get: getVerifyQuotesOpen,
  setTrue: openVerifyQuotes,
  setFalse: closeVerifyQuotes,
} = makeBooleanVar(false);

/* Opening state of the release note panel in a feature doc */
export const {
  hook: useFeatureReleaseNoteOpen,
  get: getFeatureReleaseNoteOpen,
  setTrue: openFeatureReleaseNote,
  setFalse: closeFeatureReleaseNote,
  toggle: toggleFeatureReleaseNote,
} = makeBooleanVar(false);
