import { PageId, PARAM_PRODUCT_AREA } from 'src/constants/routing.constant';
import { useLocationSelector } from 'src/hooks/router/useLocation';
import { navigate } from 'src/hooks/useNavigate';
import { history } from 'src/providers';
import { getParams, getSearchParams } from 'src/utils/routing.utils';

export const getProductAreaParam = () => {
  const params = getSearchParams();
  return params.get(PARAM_PRODUCT_AREA);
};

export const closeProductAreaModal = () => {
  const params = getSearchParams();
  params.delete(PARAM_PRODUCT_AREA);
  history.push({ search: params.toString() });
};

export const openProductAreaModal = (productAreaId: string, metaKey = false) => {
  if (metaKey) {
    navigate(PageId.ProductHierarchy, { productHierarchy: productAreaId });
    return;
  }
  // For the same productAreaId, the modal must not be opened if the full page is already open
  if (getParams().productAreaId === productAreaId) {
    closeProductAreaModal();
    return;
  }
  const params = getSearchParams();
  params.delete(PARAM_PRODUCT_AREA);
  // Order is important, the last modal opened will be displayed on top
  params.append(PARAM_PRODUCT_AREA, productAreaId);
  history.push({ search: params.toString() });
};

export const useProductAreaModal = () => useLocationSelector(getProductAreaParam);
