import { ChangelogHeroStyle } from '@cycle-app/graphql-codegen';
import { twJoin } from 'tailwind-merge';

import { ChangelogBuilderHeroImage } from './hero/ChangelogBuilderHeroImage';
import { ChangelogBuilderHeroPopoverContent } from './hero/ChangelogBuilderHeroPopoverContent';
import { ChangelogBuilderHeroText } from './hero/ChangelogBuilderHeroText';
import { ChangelogBuilderHeroTextImage } from './hero/ChangelogBuilderHeroTextImage';
import { LAYOUT_CONTAINER_CLASS, LAYOUT_GRID_CLASS } from '../constants/layout';
import { ChangelogDropdown, ChangelogDropdownContent } from '../shared/ChangelogDropdown';
import { useChangelogBuilderForm } from '../useChangelogBuilderForm';
import { ChangelogBuilderContentLogo } from './ChangelogBuilderContentLogo';
import { ChangelogBuilderHeroLinks } from './hero/ChangelogBuilderHeroLinks';

export function ChangelogBuilderContentHero() {
  const { watch } = useChangelogBuilderForm();

  const heroStyle = watch('heroStyle');

  return (
    <>
      {[ChangelogHeroStyle.Image, ChangelogHeroStyle.Hidden].includes(heroStyle) && (
        <div className="flex items-center justify-between bg-(--changelog-background-color) px-8 py-4">
          <ChangelogBuilderContentLogo />

          <ChangelogBuilderHeroLinks dropdownPlacement="bottom-end" />
        </div>
      )}

      <div className={twJoin(LAYOUT_CONTAINER_CLASS, 'mt-12')}>
        <ChangelogDropdown
          content={({ hide }) => (
            <ChangelogDropdownContent
              title="Header"
              hide={hide}
            >
              <ChangelogBuilderHeroPopoverContent />
            </ChangelogDropdownContent>
          )}
          empty={heroStyle === ChangelogHeroStyle.Hidden}
        >
          <div className={twJoin(LAYOUT_GRID_CLASS, 'items-center')}>
            {heroStyle === ChangelogHeroStyle.Text && (
              <ChangelogBuilderHeroText className="col-span-2 col-start-2" />
            )}

            {heroStyle === ChangelogHeroStyle.Image && (
              <ChangelogBuilderHeroImage className="col-span-2 col-start-2" />
            )}

            {heroStyle === ChangelogHeroStyle.TextImage && (
              <>
                <ChangelogBuilderHeroTextImage className="col-span-2" />
                <ChangelogBuilderHeroImage className="col-span-2" />
              </>
            )}
            {heroStyle === ChangelogHeroStyle.Hidden && (
              <div className="col-span-full flex items-center justify-center py-4">
                + Header
              </div>
            )}
          </div>
        </ChangelogDropdown>
      </div>
    </>
  );
}

