import { Skeleton, Input, ActionButton, ShyScrollbar, Icon } from '@cycle-app/ui';
import { AiIcon } from '@cycle-app/ui/icons';
import { useState } from 'react';
import { useForm } from 'react-hook-form';

import { useConversationRemove } from '../../hooks/ask/useConversationRemove';
import { useConversationUpdate } from '../../hooks/ask/useConversationUpdate';
import DialogModal from '../DialogModal/DialogModal';

type Props = {
  conversations: { id: string; title?: string | null; content: string }[];
  onSelect: (conversationId: string) => void;
  docId?: string;
  onAskNew: VoidFunction;
  isLoading: boolean;
};

export const AskHistory = ({
  conversations, onSelect, docId, onAskNew, isLoading,
}: Props) => {
  if (isLoading) {
    return (
      <div className="px-5">
        <Skeleton className="h-7!" />
      </div>
    );
  }

  if (!conversations.length) {
    return (
      <div className="grow flex justify-center items-center">
        <div className="text-center">
          <AiIcon className="text-grey-350" />
          <div className="text-secondary mt-3.5">
            No conversation yet
          </div>
          <button
            type="button"
            onClick={onAskNew}
            className="btn-tertiary text-cycle dark:text-white mt-5"
          >
            New
          </button>
        </div>
      </div>
    );
  }

  return (
    <ShyScrollbar>
      <ul className="flex flex-col gap-2 px-5 mb-4">
        {conversations.map(conversation => (
          <li
            key={conversation.id}
            className="group"
          >
            <Item
              content={conversation.content}
              docId={docId}
              id={conversation.id}
              onSelect={onSelect}
              title={conversation.title}
            />
          </li>
        ))}
      </ul>
    </ShyScrollbar>
  );
};

type ItemProps = {
  content: string;
  docId?: string;
  id: string;
  onSelect: (id: string) => void;
  title?: string | null;
};

const Item = ({
  id, title, content, onSelect, docId,
}:  ItemProps) => {
  const [isEditing, setIsEditing] = useState(false);
  const { remove } = useConversationRemove();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  return (
    <>
      <div
        className="relative cursor-pointer rounded-lg group-focus-within:bg-grey-100 dark:group-focus-within:bg-grey-850 group-hover:bg-grey-100 dark:group-hover:bg-grey-850 p-2"
      >
        <div className="font-medium flex gap-1 justify-between">
          {isEditing && (
            <div className="h-5 relative grow">
              <div className="absolute left-0 right-0 top-0">
                <EditTitle
                  id={id}
                  defaultValue={title || ''}
                  onBlur={() => setIsEditing(false)}
                />
              </div>
            </div>
          )}
          {!isEditing && (
            <>
              <button
                type="button"
                onClick={() => onSelect(id)}
                className="max-w-full text-ellipsis px-0 before:content-[''] before:inset-0 before:absolute cursor-pointer overflow-hidden whitespace-nowrap"
              >
                {title || 'Untitled'}
              </button>
              <div className="hidden relative flex-none rounded-lg group-hover:flex group-focus-within:flex bg-grey-100 dark:bg-grey-850">
                <ActionButton
                  tooltip="Edit title"
                  tooltipPlacement="top"
                  onClick={() => setIsEditing(true)}
                >
                  <Icon name="edit" />
                </ActionButton>
                <ActionButton
                  tooltip="Delete"
                  tooltipPlacement="top"
                  onClick={() => setShowDeleteModal(true)}
                >
                  <Icon name="delete" />
                </ActionButton>
              </div>
            </>
          )}
        </div>
        <div className="text-secondary text-ellipsis overflow-hidden whitespace-nowrap">
          {content}
        </div>
      </div>
      {showDeleteModal && (
        <DialogModal
          hide={() => setShowDeleteModal(false)}
          title="Delete conversation"
          info="Are you sure you want to delete this conversation?"
          onCancel={() => setShowDeleteModal(false)}
          confirmLabel="Delete"
          onConfirm={async () => {
            await remove({
              id,
              docId,
            });
          }}
        />
      )}
    </>
  );
};

const EditTitle = ({
  defaultValue, onBlur, id,
}: { defaultValue: string; id: string; onBlur: VoidFunction }) => {
  const {
    register, handleSubmit,
  } = useForm<{ title: string }>({
    defaultValues: { title: defaultValue },
  });
  const { update } = useConversationUpdate();

  const onSubmit = async (data: { title: string }) => {
    onBlur();
    await update({
      id,
      title: data.title,
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Input
        className="[&_input]:bg-primary! [&_input]:h-8! [&_input]:px-2! [&_input]:py-1! shadow-z3"
        autoFocus
        {...register('title', { onBlur })}
        onKeyDown={e => {
          if (e.code === 'Escape') {
            e.stopPropagation();
            onBlur();
          }
        }}
      />
      <button
        type="submit"
        className="hidden"
      >
        Submit
      </button>
    </form>
  );
};
