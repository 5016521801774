import { ContentSection, ReleaseTagStyle } from '@cycle-app/graphql-codegen';
import { Input, Icon } from '@cycle-app/ui';
import { Fragment, useState } from 'react';
import { Controller, useFieldArray } from 'react-hook-form';
import { twJoin } from 'tailwind-merge';

import { ChangelogTag } from '../../ChangelogTag';
import { SCROLLBAR_VISIBLE_CLASS } from '../constants/layout';
import { ChangelogDropdown, ChangelogDropdownContent, ChangelogDropdownDivider } from '../shared/ChangelogDropdown';
import { ColorInput } from '../shared/ColorInput';
import { useThemeVariant } from '../shared/useThemeVariant';
import { useChangelogBuilderForm } from '../useChangelogBuilderForm';

const CREATE_COLOR = '#E2FDEE';

export function ChangelogBuilderContentTags() {
  const { watch } = useChangelogBuilderForm();

  const tags = watch('tags');
  const releaseTagStyle = watch('releaseTagStyle');
  const contentSection = watch('contentSection');

  const enabledTags = tags.filter(tag => tag.enabled);

  return (
    <ChangelogDropdown
      content={({ hide }) => <EditTagsContent hide={hide} />}
    >
      <div className="flex flex-wrap gap-x-2 gap-y-1">
        {enabledTags.map((tag) => (
          <TagDisplay
            key={tag.id}
            value={tag.value}
            color={tag.color}
            contentSection={contentSection}
            releaseTagStyle={releaseTagStyle}
          />
        ))}

        <div
          className="flex items-center gap-2 rounded-sm px-2 bg-(--interaction-color-level-1) leading-[24px] text-(--changelog-body-color) border border-(--interaction-color-level-2)"
        >
          <Icon name="plus" />
          {enabledTags.length === 0 && (
            <span>
              Add tag
            </span>
          )}
        </div>
      </div>
    </ChangelogDropdown>
  );
}

function TagDisplay({
  value, color, releaseTagStyle, contentSection,
}: { value: string; color: string; releaseTagStyle: ReleaseTagStyle; contentSection: ContentSection }) {
  const contentClasses = useThemeVariant(contentSection);

  return releaseTagStyle === ReleaseTagStyle.Background ? (
    <div
      style={{
        '--contrast-color': color,
        '--contrast-light-opacity': '90%',
        '--contrast-dark-opacity': '80%',
      }}
      className={twJoin(
        'rounded-sm px-2 bg-(--contrast-color) leading-[24px] text-contrast',
        contentClasses({
          DISCREET: 'text-xs',
          UNIFORM: 'text-sm',
          CLASSIC: 'text-sm',
          SOBER: 'text-base',
          SHOW_OFF: 'text-lg',
          UNIQUE: 'text-xs',
        }),
      )}
    >
      {value}
    </div>
  ) : (
    <div
      className={twJoin(
        'flex items-center gap-2 p-1 text-(--changelog-body-color)',
        contentClasses({
          DISCREET: 'text-sm',
          UNIFORM: 'text-base',
          CLASSIC: 'text-base',
          SOBER: 'text-lg',
          SHOW_OFF: 'text-xl',
          UNIQUE: 'text-sm',
        }),
      )}
    >
      <div
        className="size-2 rounded-full"
        style={{ backgroundColor: color }}
      />
      {value}
    </div>
  );

}

function EditTagsContent({ hide }: { hide: VoidFunction }) {
  const [editIndex, setEditIndex] = useState<number | null>(null);

  return (
    <ChangelogDropdownContent
      title={editIndex === null ? 'Release tags' : (
        <button
          autoFocus
          className="btn-tertiary btn-inline-y"
          onClick={() => setEditIndex(null)}
          type="button"
        >
          <Icon name="chevron-left" />
          Back
        </button>
      )}
      hide={hide}
    >
      {editIndex === null
        ? <EditAllTagsContent onEditTag={setEditIndex} />
        : (
          <EditTagContent tagIndex={editIndex} />
        )}
    </ChangelogDropdownContent>
  );
}

function EditAllTagsContent({ onEditTag }: { onEditTag: (tagIndex: number) => void }) {
  const [search, setSearch] = useState('');
  const {
    control, register, watch,
  } = useChangelogBuilderForm();

  const {
    fields, append,
  } = useFieldArray({
    control,
    name: 'tags',
  });

  const tagVisible = (tagValue: string) => {
    if (!search) return true;
    if (tagValue.toLowerCase().includes(search.toLowerCase())) return true;
    return false;
  };

  const handleCreateTag = () => {
    append({
      id: crypto.randomUUID(),
      value: search,
      color: CREATE_COLOR,
      enabled: true,
    });
    setSearch('');
    onEditTag(fields.length);
  };

  const isCreateVisible = search.length > 0 && !fields.some(field => field.value.toLowerCase() === search.toLowerCase());

  return (
    <div>
      <label className="-mt-4 flex items-center py-4">
        <Icon
          name="search"
          className="text-disabled"
        />
        <input
          autoFocus
          type="search"
          className="w-full bg-transparent pl-3 text-sm text-primary placeholder:text-disabled focus:outline-hidden"
          placeholder="Search or create tag"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          onKeyUp={(e) => {
            if (e.key === 'Enter') {
              handleCreateTag();
            }
          }}
        />
      </label>

      <ChangelogDropdownDivider />

      <div
        // eslint-disable-next-line max-len
        className={twJoin('-mx-3 max-h-64 px-3 pt-2', SCROLLBAR_VISIBLE_CLASS)}
      >
        {fields.map((field, fieldIndex) => (
          <Fragment key={field.id}>
            {tagVisible(field.value) && (
              <label className="group -mx-2 flex items-center gap-2 rounded-sm px-2 py-1 hover:bg-grey-100 dark:hover:bg-grey-800">
                <input
                  type="checkbox"
                  className="text-cycle accent-current"
                  {...register(`tags.${fieldIndex}.enabled`)}
                />
                <ChangelogTag
                  color={field.color}
                  value={field.value}
                  releaseTagStyle={watch('releaseTagStyle')}
                />
                <button
                  type="button"
                  className="btn-tertiary ml-auto opacity-0 focus-visible:opacity-100 group-hover:opacity-100"
                  onClick={() => onEditTag(fieldIndex)}
                >
                  Edit
                </button>
              </label>
            )}
          </Fragment>
        ))}
        {isCreateVisible && (
          <button
            type="button"
            className="flex w-full cursor-pointer items-center gap-2 rounded-sm p-2 hover:bg-grey-100 dark:hover:bg-grey-800"
            onClick={handleCreateTag}
          >
            <Icon
              name="plus"
              className="size-3.5 text-primary"
            />
            <span className="text-secondary">
              Create:
            </span>
            <span className="text-disabled">
              {search}
            </span>
          </button>
        )}
      </div>

      {!search && (
        <>
          <ChangelogDropdownDivider className="mb-4" />
          <div className="mb-2 font-medium">
            Style
          </div>

          <label
            // eslint-disable-next-line max-len
            className="-mx-2 flex items-center gap-2 rounded-sm px-2 py-1 hover:bg-grey-100 has-[:focus-visible]:bg-grey-100 dark:hover:bg-grey-800 dark:has-[:focus-visible]:bg-grey-800"
          >
            <input
              type="radio"
              className="size-4 text-cycle accent-current focus:outline-hidden"
              value={ReleaseTagStyle.Dot}
              {...register('releaseTagStyle')}
            />
            Bullet

            <div className="ml-auto flex w-8 items-center justify-center gap-1 text-grey-200 dark:text-grey-600">
              <div className="size-1 rounded-full bg-current" />
              <div className="h-1 w-4 rounded-full bg-current" />
            </div>
          </label>

          <label
            // eslint-disable-next-line max-len
            className="-mx-2 flex items-center gap-2 rounded-sm px-2 py-1 hover:bg-grey-100 has-[:focus-visible]:bg-grey-100 dark:hover:bg-grey-800 dark:has-[:focus-visible]:bg-grey-800"
          >
            <input
              type="radio"
              className="size-4 text-cycle accent-current focus:outline-hidden"
              value={ReleaseTagStyle.Background}
              {...register('releaseTagStyle')}
            />
            Marked
            <div
              // eslint-disable-next-line max-len
              className="ml-auto flex w-8 items-center justify-center gap-1 rounded-sm bg-grey-100 p-1 text-grey-200 dark:bg-grey-800 dark:text-grey-600"
            >
              <div className="h-1 w-4 rounded-full bg-current" />
            </div>
          </label>
        </>
      )}
    </div>
  );
}

function EditTagContent({ tagIndex }: { tagIndex: number }) {
  const {
    control, register,
  } = useChangelogBuilderForm();
  return (
    <div>
      <Input
        label="Title"
        {...register(`tags.${tagIndex}.value`)}
      />

      <div className="mt-4 grid grid-cols-2 items-center gap-2">
        <span className="text-primary">
          Color
        </span>
        <Controller
          control={control}
          name={`tags.${tagIndex}.color`}
          render={({ field }) => (
            <ColorInput
              value={field.value}
              onChange={field.onChange}
            />
          )}
        />
      </div>
    </div>
  );
}
