import { Editor } from '@tiptap/core';
import { ReactNode, useRef, createContext, useContext, MutableRefObject } from 'react';

export const EditorInstanceContext = createContext<MutableRefObject<Editor | undefined> | undefined>(undefined);

/** Provider to store the Tiptap editor instance */
export const EditorInstanceProvider = (props: { children: ReactNode }) => {
  const editorRef = useRef<Editor>();
  return (
    <EditorInstanceContext.Provider
      value={editorRef}
      {...props}
    />
  );
};

export const useEditorInstance = () => {
  const context = useContext(EditorInstanceContext);
  if (!context) throw new Error('useEditorInstance must be used within a EditorInstanceProvider');
  return context;
};
