import { useQuery } from '@apollo/client';
import { BoardSearchDocument } from '@cycle-app/graphql-codegen';
import { nodeToArray } from '@cycle-app/utilities';

import { extract } from '../../types/graphql.types';
import { useBoardSections } from '../api/useBoardSections';

export const useBoardSearch = (searchDebounced: string) => {
  const {
    feedbackSectionId, roadmapSectionId,
  } = useBoardSections();
  const inboxResult = useQuery(BoardSearchDocument, {
    variables: {
      id: feedbackSectionId as string,
      searchText: searchDebounced,
    },
    fetchPolicy: 'cache-and-network',
    skip: !searchDebounced.trim() || !feedbackSectionId,
  });
  const roadmapResult = useQuery(BoardSearchDocument, {
    variables: {
      id: roadmapSectionId as string,
      searchText: searchDebounced,
    },
    fetchPolicy: 'cache-and-network',
    skip: !searchDebounced.trim() || !roadmapSectionId,
  });
  const inbox = extract('BoardSection', inboxResult.data?.node);
  const roadmap = extract('BoardSection', roadmapResult.data?.node);
  return {
    boards: [
      ...nodeToArray(inbox?.boards),
      ...nodeToArray(roadmap?.boards),
    ].sort((a, b) => a.name.localeCompare(b.name)),
  };
};
