import { useFragment } from '@apollo/client';
import { DocDocTypeIdFragmentDoc, DoctypeFragmentDoc } from '@cycle-app/graphql-codegen';

export const useDocDoctypeIdFragment = (docId: string) => {
  const { data } = useFragment({
    fragment: DocDocTypeIdFragmentDoc,
    fragmentName: 'DocDocTypeId',
    from: docId,
  });
  return data.doctype?.id ?? null;
};

export const useDoctypeFragment = (doctypeId: string | null | undefined) => {
  const {
    data, complete,
  } = useFragment({
    fragment: DoctypeFragmentDoc,
    fragmentName: 'Doctype',
    from: doctypeId ?? '',
  });
  return doctypeId && complete ? data : null;
};

export const useDocDoctypeFragment = (docId: string) => {
  const doctypeId = useDocDoctypeIdFragment(docId);
  return useDoctypeFragment(doctypeId);
};
