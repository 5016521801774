import { CompanyFragment, CustomerFragment } from '@cycle-app/graphql-codegen/generated';
import { Tooltip, TextHighlighter, CompanyLogo, CustomerAvatar, Tag } from '@cycle-app/ui';
import { useAtomValue } from 'jotai';

import { TooltipClickToCopy } from 'src/components/TooltipClickToCopy';
import { openCompanyModal } from 'src/hooks/modals/useCompanyModal';
import { openCustomerModal } from 'src/hooks/modals/useCustomerModal';
import { copyToClipboard } from 'src/utils/clipboard.utils';

import { customersSearchAtom } from './customers.atoms';

export const CellCustomer = ({ customer }: { customer: CustomerFragment }) => {
  const searchText = useAtomValue(customersSearchAtom);
  return (
    <Tooltip content="Open customer">
      <button
        className="btn-tertiary btn-sm h-8 max-w-full gap-2 text-body font-normal text-primary"
        onClick={() => openCustomerModal(customer.id)}
      >
        <CustomerAvatar
          size="S"
          customer={customer}
          hideCompany
        />
        <TextHighlighter
          className="highlight truncate"
          searchWords={[searchText]}
          textToHighlight={customer.name ?? ''}
        />
      </button>
    </Tooltip>
  );
};

export const CellCustomerCompany = ({ company }: { company: CompanyFragment | null | undefined }) => {
  if (!company) return null;
  return (
    <Tooltip content="Open company">
      <button
        className="btn-tertiary btn-sm max-w-full gap-2 text-body font-normal text-primary"
        onClick={() => openCompanyModal(company.id)}
      >
        <CompanyLogo
          size="S"
          company={company}
        />
        <span className="truncate">
          {company.name}
        </span>
      </button>
    </Tooltip>
  );
};

export const CellCompany = ({ company }: { company: CompanyFragment }) => {
  const searchText = useAtomValue(customersSearchAtom);
  return (
    <Tooltip content="Open company">
      <button
        className="btn-tertiary btn-sm max-w-full gap-2 text-body font-normal text-primary"
        onClick={() => openCompanyModal(company.id)}
      >
        <CompanyLogo
          size="S"
          company={company}
        />
        <TextHighlighter
          className="highlight truncate"
          searchWords={[searchText]}
          textToHighlight={company.name ?? ''}
        />
      </button>
    </Tooltip>
  );
};

export const CellEmail = ({ email }: { email: string | null | undefined }) => {
  const searchText = useAtomValue(customersSearchAtom);
  if (!email) return null;
  return (
    <Tooltip
      content={(
        <div>
          {email}
          <div className="text-grey-350 font-normal">
            Copy email
          </div>
        </div>
      )}
    >
      <button
        className="btn-tertiary btn-sm max-w-full gap-2 text-body font-normal text-primary"
        onClick={() => copyToClipboard({
          text: email,
          notification: 'Email copied to clipboard',
        })}
      >
        <TextHighlighter
          className="highlight truncate"
          searchWords={[searchText]}
          textToHighlight={email}
        />
      </button>
    </Tooltip>
  );
};

export const CellCustomId = ({ customId }: { customId: string | null | undefined }) => {
  if (!customId) return null;
  return (
    <TooltipClickToCopy
      title="Custom ID"
      value={customId}
    >
      {props => (
        <Tag
          color="greyAlt"
          {...props}
        >
          {customId}
        </Tag>
      )}
    </TooltipClickToCopy>
  );
};
