import { useQuery } from '@apollo/client';
import { DocConversationsNodeDocument } from '@cycle-app/graphql-codegen';
import { nodeToArray } from '@cycle-app/utilities';

import { extract } from '../../types/graphql.types';

export const useDocConversations = (docId: string) => {
  const {
    data, loading,
  } = useQuery(DocConversationsNodeDocument, {
    variables: { docId },
  });
  const doc = extract('Doc', data?.node);
  return {
    conversations: nodeToArray(doc?.conversations),
    isLoading: loading,
  };
};
