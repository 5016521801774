import { BoardWithDraftConfigFragment, Color, SectionType } from '@cycle-app/graphql-codegen';
import { Button, DEFAULT_ICON_COLOR } from '@cycle-app/ui';
import { FC, ReactNode, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { BoardLinkSectionField } from 'src/app/Main/Board/BoardLinkSectionField';
import { Label } from 'src/components/BoardConfigForm/BoardConfigForm.styles';
import { BoardConfigSkeletons } from 'src/components/BoardConfigForm/BoardConfigSkeletons';
import { Title, Actions } from 'src/components/DialogModal/DialogModal.styles';
import { InboxBoardConfigFields } from 'src/components/InboxBoardConfigFields';
import { ViewPreview } from 'src/components/ViewPreview';
import { PageId } from 'src/constants/routing.constant';
import { usePublishBoardConfigMutation } from 'src/hooks/api/mutations/boardConfig/useDraftBoardConfigMutations';
import useBoardMutations from 'src/hooks/api/mutations/useBoardMutations';
import { useMoveFeedbackViewToSectionEnd } from 'src/hooks/api/mutations/useMoveViewToSectionEnd';
import useDraftBoardConfig from 'src/hooks/api/useDraftBoardConfig';
import { useBoardLink } from 'src/hooks/boards/useBoardLink';
import { useNavigate } from 'src/hooks/useNavigate';
import { Layer } from 'src/types/layers.types';
import { getBoardSlug } from 'src/utils/slug.util';

import { BoardIconInput } from '../BoardIconInput';
import {
  Modal, Content, Preview,
  Header, InputField, Form, FormRow,
} from './InboxCreateView.styles';

type FormData = {
  name: string;
  description: string;
  emoji: string | null;
  color: Color | null;
};

export type InboxCreateViewModalProps = {
  hide: VoidFunction;
  draftBoard: BoardWithDraftConfigFragment | null;
  sidebarSectionId?: string | null;
  defaultName?: string;
};

export const InboxCreateViewModal: FC<React.PropsWithChildren<InboxCreateViewModalProps>> = ({
  hide, draftBoard, sidebarSectionId, defaultName = '',
}) => {
  const { data } = useDraftBoardConfig(draftBoard?.id);
  const draftBoardId = data?.node.id;
  const draftBoardConfig = data?.node.draftBoardConfig;

  const { navigate } = useNavigate();
  const moveFeedbackViewToSectionEnd = useMoveFeedbackViewToSectionEnd();
  const { removeBoard } = useBoardMutations();
  const [publishBoardConfig, { loading: isPublishLoading }] = usePublishBoardConfigMutation(draftBoardConfig?.id ?? '');

  const {
    loading: isUpdateLoading,
    publishBoard,
    updateBoard,
  } = useBoardMutations();

  const {
    formState,
    handleSubmit,
    control,
    setValue,
    register,
    watch,
  } = useForm<FormData>({
    defaultValues: {
      name: defaultName,
      description: '',
      color: DEFAULT_ICON_COLOR,
      emoji: null,
    },
  });

  const [boardLinkSectionId, setBoardLinkSectionId] = useState<string | null>(sidebarSectionId ?? null);
  const { createBoardLink } = useBoardLink();

  const isLoading = isUpdateLoading || isPublishLoading;
  const hasError = !!Object.keys(formState.errors).length;

  const onCancel = async () => {
    if (isLoading) return;
    hide();
    if (!draftBoard?.section) return;
    await removeBoard({
      boardId: draftBoard.id,
      sectionId: draftBoard.section.id,
    });
  };

  return (
    <Modal
      hide={onCancel}
      layer={Layer.Modal}
    >
      <Layout
        preview={draftBoardConfig && (
          <ViewPreview
            draftBoardConfig={draftBoardConfig}
            isFeedbackView
          />
        )}
        form={draftBoardId && draftBoardConfig
          ? (
            <Form
              onReset={async () => {
                if (isLoading) return;
                await onCancel();
              }}
              onSubmit={handleSubmit(async (formData) => {
                if (isLoading) return;
                const [updateResult, publishResult] = await Promise.all([
                  updateBoard({
                    boardId: draftBoardId,
                    ...formData,
                  }),
                  publishBoard({ boardId: draftBoardId }),
                ]);
                if (!updateResult.data?.updateBoard || !publishResult.data?.publishBoard) return;
                // Publish config works only on published board.
                const publishBoardConfigResult = await publishBoardConfig();
                if (!publishBoardConfigResult.data?.publishBoardConfigV2) return;

                await moveFeedbackViewToSectionEnd(publishBoardConfigResult.data?.publishBoardConfigV2.board);
                hide();
                navigate(PageId.InboxView, { boardSlug: getBoardSlug(updateResult.data.updateBoard) });
                if (boardLinkSectionId) {
                  await createBoardLink({
                    boardId: updateResult.data.updateBoard.id,
                    sectionId: boardLinkSectionId,
                  });
                }
              })}
            >
              <FormRow>
                <Controller
                  name="emoji"
                  control={control}
                  render={({
                    field: {
                      value,
                      onChange,
                    },
                  }) => (
                    <BoardIconInput
                      sectionType={SectionType.Feedback}
                      color={watch('color')}
                      icon={value}
                      onColorChange={newColor => setValue('color', newColor)}
                      onIconChange={onChange}
                    />
                  )}
                />
                <InputField
                  id="boardEdit-name"
                  label="View name"
                  placeholder="Your view name"
                  autoFocus
                  {...register('name', {
                    required: 'You must have at least one character.',
                  })}
                  error={formState.errors.name?.message}
                />
              </FormRow>

              <div>
                <Label>
                  Description
                </Label>
                <InputField
                  id="boardEdit-description"
                  placeholder="Your view description"
                  {...register('description')}
                />
              </div>

              <BoardLinkSectionField
                value={boardLinkSectionId}
                onChange={setBoardLinkSectionId}
              />

              <InboxBoardConfigFields boardId={draftBoardId} />

              <Actions>
                <Button
                  size="M"
                  type="reset"
                  variant="secondary"
                  disabled={isLoading}
                >
                  Cancel
                </Button>
                <Button
                  size="M"
                  type="submit"
                  disabled={hasError}
                  isLoading={isLoading}
                >
                  Save
                </Button>
              </Actions>
            </Form>
          )
          : <BoardConfigSkeletons />}
      />
    </Modal>
  );
};

const Layout = ({
  form, preview,
}: { form: ReactNode; preview?: ReactNode }) => {
  return (
    <>
      <Content>
        <Header>
          <Title>
            Create feedback view
          </Title>
        </Header>
        {form}
      </Content>

      <Preview>
        {preview}
      </Preview>
    </>
  );
};
