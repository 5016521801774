import { AddIcon } from '@cycle-app/ui/icons';
import { nodeToArray } from '@cycle-app/utilities';

import { useBoardConfig } from 'src/contexts/boardConfigContext';
import { findFilterDoctypePropertyRule } from 'src/utils/boardConfig/boardConfig.util';

import { DocTypeIcon } from '../DocTypeIcon';
import { Container, Content, StyledButton, EmptyDocItems, EmptyDocItem } from './RoadmapsEmpty.styles';

export const RoadmapsEmpty = ({ onClick }: { onClick: VoidFunction }) => {
  const doctypes = useBoardConfig(
    ctx => {
      if (!ctx.boardConfig) return null;
      return nodeToArray(findFilterDoctypePropertyRule(ctx.boardConfig)?.doctypeRule.values)
        .filter(node => node.selected)
        .map(node => node.value);
    },
  );

  if (!doctypes || doctypes.length === 0) return null;

  // Get the doctype name from the board config
  // If there are multiple doctypes, we use the generic name "request"
  // Otherwise, we use the doctype name in lowercase
  const doctypeName = doctypes.length > 1 ? 'request' : doctypes[0]?.name.toLowerCase();

  const sampleDoctypes = doctypes.length === 1
    ? [doctypes[0], doctypes[0]]
    : doctypes.slice(0, 2);

  return (
    <Container>
      <Content>
        <p>
          {`No ${doctypeName} yet in this view`}
        </p>

        <StyledButton
          onClick={onClick}
        >
          <AddIcon size={12} />
          {`New ${doctypeName}`}
        </StyledButton>
      </Content>

      <EmptyDocItems>
        {sampleDoctypes.map((doctype, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <EmptyDocItem key={index}>
            <DocTypeIcon doctype={doctype} />
          </EmptyDocItem>
        ))}
      </EmptyDocItems>
    </Container>
  );
};
