import { useQuery } from '@apollo/client';
import { ProductConversationsNodeDocument } from '@cycle-app/graphql-codegen';
import { nodeToArray } from '@cycle-app/utilities';

import { useWorkspaceContext } from '../../contexts/workspaceContext';
import { extract } from '../../types/graphql.types';

export const useProductConversations = () => {
  const productId = useWorkspaceContext(ctx => ctx.productId);
  const {
    data, loading,
  } = useQuery(ProductConversationsNodeDocument, {
    variables: { productId },
  });
  const product = extract('Product', data?.node);
  return {
    conversations: nodeToArray(product?.conversations),
    isLoading: loading,
  };
};
