/**
 * MainRoot is there as a guard and based on a token, fetch 2 things
 * 1. The current user -> me
 * 2. The current workspace (formerly product)
 */
import { UserJourney } from '@cycle-app/graphql-codegen';
import { nodeToArray } from '@cycle-app/utilities';
import { captureException } from '@sentry/browser';
import { memo, useEffect, useRef, useState } from 'react';
import { Redirect } from 'react-router-dom';

import { PageId, routing } from 'src/constants/routing.constant';
import { useProduct, useNavigate, useMaybeMeV2, useLogout } from 'src/hooks';
import { useMeError, useMeSelector } from 'src/hooks/api/selectors/useMeSelector';
import { useInitPermission } from 'src/hooks/useInitPermission';
import { useProductSlug } from 'src/hooks/usePathParams';
import { getCreateDoc } from 'src/reactives/createDoc.reactive';
import { setInitial, setInitialLoadingFalse, getInitialLoading, useInitialLoading } from 'src/reactives/initial.reactive';
import { getLastInboxBoard, getLastRoadmapBoard, getLastView } from 'src/reactives/lastView.reactive';
import { getNetworkState } from 'src/utils/network.utils';
import { getHasUserAppAccess } from 'src/utils/users.util';

import { LocalKey } from '../../types/localStorage.types';
import { AppLoader } from '../AppLoader';
import { ProductSwitch, ProductSwitchListeners } from './ProductSwitch';

export const MainRoot = () => {
  const meError = useMeError();
  const accessDenied = useMeSelector(me => me && !getHasUserAppAccess(me));
  const logout = useLogout();
  const [isTimeout, setIsTimeout] = useState(false);

  if (accessDenied) {
    return <Redirect to={{ pathname: routing[PageId.GetStarted] }} />;
  }

  if (meError) {
    /**
     * This is happening mostly when you have a token in local storage which is
     * incorrect.
     * Maybe from non existing user for example. The me should return an error
     * and we should logout the user in order to reset the cache.
     */
    logout();
    return null;
  }

  return (
    <>
      <MainRootListeners setIsTimeout={setIsTimeout} />
      <AppLoader isTimeout={isTimeout}>
        <ProductSwitchListeners />
        <ProductSwitch />
      </AppLoader>
    </>
  );
};

const MainRootListeners = memo(({ setIsTimeout }: { setIsTimeout: (isTimeout: true) => void }) => {
  useInitPermission();
  const { me } = useMaybeMeV2();
  const {
    product,
    loading: isProductLoading,
  } = useProduct();
  const productSlug = useProductSlug();
  const { navigate } = useNavigate();
  const initialLoading = useInitialLoading();
  const timeoutRef = useRef(0);
  // We need the latest value in the effect.
  const meRef = useRef(me);
  const productRef = useRef(product);
  const isProductLoadingRef = useRef(isProductLoading);
  const productSlugRef = useRef(productSlug);
  meRef.current = me;
  productRef.current = product;
  productSlugRef.current = productSlug;
  isProductLoadingRef.current = isProductLoading;

  useEffect(() => {
    timeoutRef.current = window.setTimeout(() => {
      if (initialLoading) {
        setIsTimeout(true);
        captureException(new Error('initial timeout'), {
          extra: {
            me: {
              id: meRef.current?.id,
              jobTitle: meRef.current?.jobTitle,
              products: nodeToArray(meRef.current?.products).map(node => node.slug),
              userJourney: meRef.current?.userJourney,
            },
            product: {
              id: productRef.current?.id,
              slug: productRef.current?.slug,
              slugFromParams: productSlugRef.current,
              isLoading: isProductLoadingRef.current,
            },
            [LocalKey.DocDraft]: getCreateDoc(),
            [LocalKey.LastView]: getLastView(),
            [LocalKey.LastInboxBoard]: getLastInboxBoard(),
            [LocalKey.LastRoadmapBoard]: getLastRoadmapBoard(),
            networkState: getNetworkState(),
          },
        });
      }
    }, 20_000);
    return () => {
      window.clearTimeout(timeoutRef.current);
    };
  }, [initialLoading]);

  useEffect(() => {
    if (!getInitialLoading() || !me?.id || isProductLoading) return;
    if (!product && !me.products?.edges.length) {
      setInitial({ products: [] });
    } else if (!product && !productSlug) {
      const firstProductSlug = me.products?.edges?.[0]?.node.slug;
      if (firstProductSlug) {
        if (me.userJourney !== UserJourney.Done) {
          navigate(PageId.GetStarted);
        } else {
          navigate(PageId.Main, { productSlug: firstProductSlug });
        }
      }
      // If no slug, empty state will show up
      setInitial({ products: nodeToArray(me.products) });
    } else {
      setInitial({ products: nodeToArray(me.products) });
    }
    setInitialLoadingFalse();
  }, [
    me?.id, me?.products, me?.userJourney,
    product, productSlug, isProductLoading,
    navigate,
  ]);

  return null;
});
