import { useFragment } from '@apollo/client';
import { KeywordFragmentDoc, ProductKeywordsFragmentDoc } from '@cycle-app/graphql-codegen';
import { nodeToArray } from '@cycle-app/utilities';

import { useWorkspaceContext } from 'src/contexts/workspaceContext';
export const useProductKeyword = (keywordId: string) => {
  const {
    data, complete,
  } = useFragment({
    fragment: KeywordFragmentDoc,
    fragmentName: 'Keyword',
    from: keywordId,
  });
  if (!complete) return null;
  return data;
};

export const useProductKeywords = () => {
  const productId = useWorkspaceContext(ctx => ctx.productId);
  const {
    data, complete,
  } = useFragment({
    fragment: ProductKeywordsFragmentDoc,
    fragmentName: 'ProductKeywords',
    from: productId,
  });
  if (!complete) return [];
  return nodeToArray(data.keywords);
};
