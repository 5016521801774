import { Color } from '@cycle-app/graphql-codegen';
import { getOptimizedImgSrc } from '@cycle-app/utilities';
import { Avatar as RadixAvatar } from 'radix-ui';
import { CSSProperties, forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';

type AvatarProps = {
  className?: string;
  src?: string;
  name?: string;
  accentColor?: Color | string;
  /**
   * Shouldn't be used most of the time. It's better to keep the same size most of the time than to "micro" manage the size.
   * This will allow better image caching by the browser.
   */
  srcSize?: number;
};

export const Avatar = forwardRef<HTMLSpanElement, AvatarProps>(
  function Avatar({
    className, src, name, accentColor, srcSize = 32, ...props
  }, ref) {

    return (
      <RadixAvatar.Root
        ref={ref}
        style={getNuanceColorStyle(accentColor)}
        className={twMerge(
          'inline-flex items-center justify-center align-middle overflow-hidden select-none rounded-full aspect-square size-6 text-base bg-grey-100 dark:bg-grey-850 text-grey-550 dark:text-grey-350',
          accentColor ? 'border-(--nuance-color) inset-ring inset-ring-white dark:inset-ring-black p-px border' : 'border-transparent',
          className,
        )}
        {...props}
      >
        <RadixAvatar.Image
          className="block size-full object-cover rounded-inherit"
          src={getOptimizedImgSrc(src, srcSize)}
          alt={name}
        />
        {name && (
          <RadixAvatar.Fallback
            delayMs={src ? 200 : 0}
            asChild
          >
            <svg
              viewBox="0 0 40 40"
              aria-label={name}
              className="text-current size-full"
            >
              <text
                x="50%"
                y="50%"
                dominantBaseline="central"
                textAnchor="middle"
                fontSize="24"
                fill="currentColor"
              >
                {name?.trimStart()?.[0]?.toUpperCase()}
              </text>
            </svg>
          </RadixAvatar.Fallback>
        )}
      </RadixAvatar.Root>
    );
  },
);

function getNuanceColorStyle(accentColor?: Color | string): CSSProperties {
  if (!accentColor) return {};

  if (Object.values(Color).includes(accentColor as Color)) {
    return { '--nuance-color': `var(--nuance-${accentColor}-main)` };
  }

  return { '--nuance-color': accentColor };
}
