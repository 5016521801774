import { twJoin } from 'tailwind-merge';

import { useChangelogBuilderForm } from '../../useChangelogBuilderForm';
import { ChangelogBuilderContentLogo } from '../ChangelogBuilderContentLogo';
import { ChangelogBuilderHeroLinks } from './ChangelogBuilderHeroLinks';

export function ChangelogBuilderHeroTextImage({ className }: { className?: string }) {
  const { watch } = useChangelogBuilderForm();

  return (
    <div className={twJoin('flex flex-col items-start pr-12', className)}>
      <ChangelogBuilderContentLogo className="justify-start" />

      <h1 className="mt-3 text-5xl font-bold text-(--changelog-headers-color)">
        {watch('title')}
      </h1>
      <p className="mt-4 text-lg font-normal text-(--changelog-body-color)">
        {watch('subtitle')}
      </p>

      <ChangelogBuilderHeroLinks
        className="mt-6"
        dropdownPlacement="bottom-start"
      />
    </div>
  );
}
